import { useCreateSetupIntentMutation } from '@/api'

export const useCreateSetupIntent = () => {
  const [execute, result] = useCreateSetupIntentMutation()
  const createSetupIntent = async () => {
    const { data } = await execute()
    return data!.createSetupIntent
  }
  return [createSetupIntent, result] as const
}
