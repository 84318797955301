import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import { timeout, sum } from '@/utils/common'
import { useSafeStateUpdate } from './useSafeStateUpdate'
import { usePrevious } from './usePrevious'

export type Ping = 1 | 0

export type OnlineStatus = {
  isOnline: boolean
  stability: number
}

const OnlineStatusContext = React.createContext<OnlineStatus | null>(null)

export type OnlineStatusChangeListener = (
  status: OnlineStatus,
  previousStatus: OnlineStatus,
) => void

type Unsubscribe = () => void
type RegisterOnlineStatusChangeListener = (
  listener: OnlineStatusChangeListener,
) => Unsubscribe

const OnlineStatusListenerContext =
  React.createContext<RegisterOnlineStatusChangeListener | null>(null)

export type OnlineStatusProviderProps = React.PropsWithChildren<{
  /**
   * The url to ping for online status checks
   */
  pingUrl: string
  /**
   * The duration in ms after which a ping is aborted and marked as failed
   */
  pingTimeoutMs?: number
  /**
   * The duration in ms to wait between pings
   */
  pollingIntervalMs?: number
}>

const getStability = (pings: Ping[], isOnline: boolean) => {
  if (!isOnline) return 0
  if (!pings.length) return 1
  return sum(pings) / pings.length
}

export const OnlineStatusProvider = (props: OnlineStatusProviderProps) => {
  const { pingUrl, pingTimeoutMs = 3000, pollingIntervalMs = 10000 } = props

  const safeUpdate = useSafeStateUpdate()
  const [isOnline, setIsOnline] = useState<boolean>(true)
  const [pings, setPings] = useState<Ping[]>([])
  const stability = getStability(pings, isOnline)
  const listenersRef = useRef<Set<OnlineStatusChangeListener>>(new Set())

  const handlePing = useCallback(
    (ping: Ping) => {
      safeUpdate(() => setPings((pings) => [...pings, ping].slice(-4)))
    },
    [safeUpdate],
  )

  const ping = useCallback(async () => {
    if (!navigator.onLine) return
    const controller = new AbortController()
    const { signal } = controller
    try {
      await timeout(fetch(pingUrl, { method: 'GET', signal }), pingTimeoutMs)
      handlePing(1)
    } catch {
      controller.abort()
      handlePing(0)
    }
  }, [pingUrl, pingTimeoutMs, handlePing])

  useEffect(() => {
    setIsOnline(navigator.onLine)
  }, [])

  useEffect(() => {
    const handleOffline = () => setIsOnline(false)
    const handleOnline = () => setIsOnline(true)
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)
    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  useEffect(() => {
    const id = setInterval(ping, pollingIntervalMs)
    return () => clearInterval(id)
  }, [pollingIntervalMs, ping])

  const status = useMemo(
    () => ({
      isOnline: stability === 0 ? false : isOnline,
      stability,
    }),
    [isOnline, stability],
  )

  const previousStatus = usePrevious(status)
  useEffect(() => {
    if (!previousStatus) return
    const listeners = listenersRef.current
    for (const listener of listeners) {
      listener(status, previousStatus)
    }
  }, [previousStatus, status])

  const registerListener = useCallback(
    (listener: OnlineStatusChangeListener) => {
      const listeners = listenersRef.current
      listeners.add(listener)
      return () => {
        listeners.delete(listener)
      }
    },
    [],
  )

  return (
    <OnlineStatusContext.Provider value={status}>
      <OnlineStatusListenerContext.Provider value={registerListener}>
        {props.children}
      </OnlineStatusListenerContext.Provider>
    </OnlineStatusContext.Provider>
  )
}

export const useOnlineStatus = (): OnlineStatus => {
  const context = useContext(OnlineStatusContext)
  if (context === null) {
    throw new Error(
      `The \`useOnlineStatus\` hook must be used inside the <OnlineStatusProvider> component's context.`,
    )
  }
  return context
}

export const useOnOnlineStatusChange = (
  listener?: OnlineStatusChangeListener,
) => {
  const registerListener = useContext(OnlineStatusListenerContext)
  if (registerListener === null) {
    throw new Error(
      `The \`useOnOnlineStatusChange\` hook must be used inside the <OnlineStatusProvider> component's context.`,
    )
  }
  useEffect(() => {
    if (!listener) return
    return registerListener(listener)
  }, [registerListener, listener])
}

export default useOnlineStatus
