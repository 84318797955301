import { useOnSummaryRequestStatusChangeSubscription } from './hooks'
import { onSummaryRequestStatusChange } from './typePolicies'

type SummaryRequestSubscriptionsProps = {
  skip?: boolean
}

export function SummaryRequestSubscriptions(
  props: SummaryRequestSubscriptionsProps,
) {
  const { skip } = props
  useOnSummaryRequestStatusChangeSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const summaryRequest = subscriptionData.data?.onSummaryRequestStatusChange
      if (!summaryRequest) return
      onSummaryRequestStatusChange(client.cache, summaryRequest)
    },
  })
  return null
}
