import { createStyles, Text, TextProps } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  label: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    fontWeight: 500,
    fontSize: theme.fontSizes.xs,
    padding: `${theme.spacing.xs / 2}px ${theme.spacing.sm}px`,
    cursor: 'default',
  },
}))

export type MenuLabelProps = TextProps<'div'> & {
  children: React.ReactNode
}

export function MenuLabel(props: MenuLabelProps) {
  const { className, ...delegated } = props
  const { classes, cx } = useStyles()
  return <Text className={cx(classes.label, className)} {...delegated} />
}
