import { Link } from 'react-router-dom'
import { ActionIcon, createStyles, Divider, Text } from '@mantine/core'
import { IconArrowLeft as BackIcon } from '@tabler/icons-react'
import {
  PromotionCodeCompleteFragment,
  SubscriptionPlanCompleteFragment,
} from '@/api'
import { Logo } from '@/common/components'
import { getDimmedColor } from '@/styles/helpers'
import { typographicScale } from '@/styles/typography'
import { getPlanDetails } from './constants'
import { SelectPromotionCode } from './PromotionCode'
import {
  currencyToDisplay,
  getPromotionCodeAmountOff,
  periodToDiplay,
  toDemicalString,
} from './helpers'

const useDetailsStyles = createStyles((theme) => ({
  root: {
    '& > * + *': {
      marginBlockStart: 2 * theme.spacing.md,
    },
  },
  branding: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.xs,
  },
  backIcon: {
    position: 'absolute',
    left: -theme.spacing.xs,
    top: '50%',
    transform: 'translate(-100%, -50%)',
  },
  title: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    color: getDimmedColor(theme),
  },
  pricing: {
    fontSize: typographicScale.getNext(theme.fontSizes.xl),
    fontWeight: 600,
  },
  superscript: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 600,
    color: getDimmedColor(theme),
    verticalAlign: 'super',
    marginLeft: '0.3em',
  },
  pricingSecondary: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    color: getDimmedColor(theme),
  },
  spacedMd: {
    '& > * + *': {
      marginBlockStart: theme.spacing.md,
    },
  },
  lineItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemTextBold: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
  },
  itemTextDimmed: {
    fontSize: theme.fontSizes.sm,
    color: getDimmedColor(theme),
  },
}))

type PlanDetailsProps = {
  plan: SubscriptionPlanCompleteFragment
  trialDays: number
  onBack?: (() => void) | string
  promotionCode: PromotionCodeCompleteFragment | null
  onPromotionCode: (promotionCode: PromotionCodeCompleteFragment | null) => void
}

export function PlanDetails(props: PlanDetailsProps) {
  const { plan, trialDays, onBack, promotionCode, onPromotionCode } = props
  const { classes } = useDetailsStyles()
  const details = getPlanDetails(plan.tier)
  const displayPeriod = periodToDiplay[plan.period]
  const displayCurrency = currencyToDisplay[plan.currency]
  const decimalAmount = toDemicalString(plan.amount)
  const displayPrice = `${displayCurrency}${decimalAmount}`
  const displayTrialPeriod = `${trialDays} days free`
  const academicSuffix = plan.requiresAcademic ? '(Academic)' : ''
  const amountOff = promotionCode
    ? getPromotionCodeAmountOff(plan, promotionCode)
    : 0
  const decimalDiscountedAmount = toDemicalString(
    Math.max(plan.amount - amountOff, 0),
  )
  const displayDiscountedPrice = `${displayCurrency}${decimalDiscountedAmount}`
  return (
    <div className={classes.root}>
      <div className={classes.branding}>
        {onBack && (
          <div className={classes.backIcon}>
            {typeof onBack === 'string' ? (
              <ActionIcon size="sm" component={Link} to={onBack}>
                <BackIcon size={16} />
              </ActionIcon>
            ) : (
              <ActionIcon size="sm" onClick={onBack}>
                <BackIcon size={16} />
              </ActionIcon>
            )}
          </div>
        )}
        <Logo radius="sm" color="blue" />
        <Text size="sm" weight={500}>
          Genei
        </Text>
      </div>
      <div>
        {trialDays ? (
          <>
            <Text className={classes.title}>
              {`Try Genei ${details.shortName}`}
            </Text>
            <Text className={classes.pricing}>{displayTrialPeriod}</Text>
            <Text className={classes.pricingSecondary}>
              {`Then ${displayPrice} per ${displayPeriod.normal}`}
            </Text>
          </>
        ) : (
          <>
            <Text className={classes.title}>
              {`Subscribe to Genei ${details.shortName}`}
            </Text>
            <Text className={classes.pricing}>
              {displayPrice}
              <Text component="span" className={classes.superscript}>
                {displayPeriod.slashCap}
              </Text>
            </Text>
          </>
        )}
      </div>
      <div>
        {trialDays ? (
          <>
            <div className={classes.lineItem}>
              <Text className={classes.itemTextBold}>
                {`Genei ${details.shortName} ${academicSuffix}`}
              </Text>
              <Text className={classes.itemTextBold}>{displayTrialPeriod}</Text>
            </div>
            <div className={classes.lineItem}>
              <Text className={classes.itemTextDimmed}>Qty 1</Text>
              <Text className={classes.itemTextDimmed}>
                {`${displayPrice} ${displayPeriod.slash} after`}
              </Text>
            </div>
          </>
        ) : (
          <>
            <div className={classes.lineItem}>
              <Text className={classes.itemTextBold}>
                {`Genei ${details.shortName} ${academicSuffix}`}
              </Text>
              <Text className={classes.itemTextBold}>{displayPrice}</Text>
            </div>
            <div className={classes.lineItem}>
              <Text className={classes.itemTextDimmed}>
                {`Qty 1, billed ${displayPeriod.normalSuffixed}`}
              </Text>
            </div>
          </>
        )}
      </div>
      <div className={classes.spacedMd}>
        <div className={classes.lineItem}>
          <Text className={classes.itemTextBold}>Subtotal</Text>
          <Text className={classes.itemTextBold}>{displayPrice}</Text>
        </div>
        <div>
          <Divider />
        </div>
        <SelectPromotionCode plan={plan} onPromotionCode={onPromotionCode} />
        <div>
          <Divider />
        </div>
        {trialDays ? (
          <>
            <div className={classes.lineItem}>
              <Text className={classes.itemTextDimmed}>Total after trial</Text>
              <Text className={classes.itemTextDimmed}>
                {displayDiscountedPrice}
              </Text>
            </div>
            <div className={classes.lineItem}>
              <Text className={classes.itemTextBold}>Total due today</Text>
              <Text className={classes.itemTextBold}>
                {displayCurrency}
                0.00
              </Text>
            </div>
          </>
        ) : (
          <>
            <div className={classes.lineItem}>
              <Text className={classes.itemTextBold}>Total due today</Text>
              <Text className={classes.itemTextBold}>
                {displayDiscountedPrice}
              </Text>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
