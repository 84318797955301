import { SubscriptionPlanTier } from '@/api'
import { MantineTheme } from '@mantine/core'

type PlanFeature = {
  label: string
  included: boolean
}

type PlanDetail = {
  shortName: string
  description: string
  background: (theme: MantineTheme) => string
  features: Array<PlanFeature>
}

const planDetailsByTier: Record<SubscriptionPlanTier, PlanDetail> = {
  [SubscriptionPlanTier.Basic]: {
    shortName: 'Basic',
    description:
      'One place for all your research needs. Read, write, explore, and organise.',
    background: (theme) =>
      theme.colorScheme === 'dark'
        ? theme.fn.linearGradient(
            45,
            theme.colors.blue[9],
            theme.colors.cyan[9],
          )
        : theme.fn.linearGradient(
            45,
            theme.colors.blue[7],
            theme.colors.cyan[7],
          ),
    features: [
      {
        label: 'Unlimited uploads',
        included: true,
      },
      {
        label: 'Document summaries',
        included: true,
      },
      {
        label: 'Document keywords',
        included: true,
      },
      {
        label: 'Document annotations',
        included: true,
      },
      {
        label: 'Linked note-taking',
        included: true,
      },
    ],
  },
  [SubscriptionPlanTier.Pro]: {
    shortName: 'Pro',
    description:
      'Take your research and writing to the next level with Genei Pro.',
    background: (theme) =>
      theme.colorScheme === 'dark'
        ? theme.fn.linearGradient(
            45,
            theme.colors.violet[9],
            theme.colors.grape[9],
          )
        : theme.fn.linearGradient(
            45,
            theme.colors.violet[7],
            theme.colors.grape[7],
          ),
    features: [
      {
        label: 'Everything in Basic',
        included: true,
      },
      {
        label: 'Multi-document summaries',
        included: true,
      },
      {
        label: 'Multi-document search',
        included: true,
      },
      {
        label: 'Multi-document Q&A',
        included: true,
      },
      {
        label: 'Advanced summariser',
        included: true,
      },
      {
        label: 'Text paraphrasing',
        included: true,
      },
      {
        label: '150,000 credits/month',
        included: true,
      },
    ],
  },
}

export function getPlanDetails(tier: SubscriptionPlanTier) {
  return planDetailsByTier[tier]
}
