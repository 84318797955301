import { useEffect } from 'react'
import { Button, createStyles, Modal, Text } from '@mantine/core'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {
  SubscriptionStatus,
  UserSubscriptionWithScheduledUpdateFragment,
} from '@/api'
import { Callout } from '@/common'
import { useEndSubscriptionTrial } from './useEndSubscriptionTrial'
import { tierToDisplay } from './helpers'

dayjs.extend(relativeTime)

export type EndSubscriptionTrialModalProps = {
  opened: boolean
  subscription: UserSubscriptionWithScheduledUpdateFragment
  onClose: () => void
}

export function EndSubscriptionTrialModal(
  props: EndSubscriptionTrialModalProps,
) {
  const { opened, subscription, onClose } = props
  useEffect(() => {
    if (subscription.status !== SubscriptionStatus.Trialing) onClose()
  }, [subscription, onClose])
  return (
    <Modal opened={opened} onClose={onClose} title="End trial now">
      <ModalContent subscription={subscription} onClose={onClose} />
    </Modal>
  )
}

const useStyles = createStyles((theme) => ({
  root: {
    marginTop: 2 * theme.spacing.md,
    '& > * + *': {
      marginTop: theme.spacing.xl,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing.lg,
  },
}))

type ModalContentProps = {
  subscription: UserSubscriptionWithScheduledUpdateFragment
  onClose: () => void
}

function ModalContent(props: ModalContentProps) {
  const { subscription, onClose } = props
  const { classes, theme } = useStyles()
  const [endSubscriptionTrial, { loading, error }] = useEndSubscriptionTrial()
  const displayTier = tierToDisplay[subscription.plan.tier]
  return (
    <div className={classes.root}>
      <div>
        <Text size="sm">
          {`Are you sure you want to end your Genei ${displayTier} trial now?`}
        </Text>
        <Text size="sm">
          {
            'A good reason for doing this is if you ran out of your trial credits.'
          }
        </Text>
      </div>
      <Callout size="sm" color="yellow">
        {`You still have ${dayjs(subscription.currentPeriodEnd).fromNow(
          true,
        )} of your trial left.`}
      </Callout>
      <Callout size="sm" color="red">
        {`Ending your trial now means you will be charged the full subscription payment immediately.`}
      </Callout>
      <div className={classes.actions}>
        <Button color="blue" loading={loading} onClick={endSubscriptionTrial}>
          End trial now
        </Button>
        <Button
          data-autofocus
          onClick={onClose}
          variant="subtle"
          color={theme.colorScheme === 'dark' ? 'dark' : 'gray'}
        >
          Cancel
        </Button>
      </div>
      {error && (
        <Text color="red" size="sm">
          {error.message}
        </Text>
      )}
    </div>
  )
}
