import { useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import {
  SubscriptionPlanPeriod,
  SubscriptionPlanTier,
  useRegisterGlobalErrorListener,
  useUser,
} from '@/api'
import { useDisclosure } from '@mantine/hooks'
import {
  Modal,
  Text,
  Button,
  Divider,
  createStyles,
  ActionIcon,
  Stack,
} from '@mantine/core'
import { IconX as CloseIcon } from '@tabler/icons-react'
import { ErrorResponse } from '@apollo/client/link/error'
import {
  ChangeSubscriptionPlanModal,
  ChoosePeriod,
  LicenseKey,
  PlanCard,
  PlanCardSkeleton,
  SocialProofs,
  ToggleAcademic,
  useSubscriptionPlans,
  useSubscriptionWithScheduledUpdateAndPromotion,
} from '@/shared-billing'
import { Logo } from '@/common'
import { typographicScale } from '@/styles/typography'
import { getCurrentFullPath } from '@/utils/web'
import {
  getEncompassingSubscriptionPlanTiers,
  isSubscriptionPlanTier,
  tierToDisplay,
} from '@/shared-billing/helpers'
import { capitalize } from '@/utils/common'
import { showNotification } from '@mantine/notifications'

type UpgradeData = {
  requiredTier: SubscriptionPlanTier
  reason: string
}

export const TierUpgradeModal = () => {
  const [opened, handlers] = useDisclosure(false)
  const [upgradeData, setUpgradeData] = useState<UpgradeData | null>(null)
  const registerErrorListener = useRegisterGlobalErrorListener()

  useEffect(() => {
    return registerErrorListener((response: ErrorResponse) => {
      const { graphQLErrors } = response
      if (!graphQLErrors) {
        return
      }
      const tierError = graphQLErrors.find(
        (error) => error.extensions.code === 'TIER_UNAUTHORIZED',
      )
      if (!tierError) {
        return
      }
      unstable_batchedUpdates(() => {
        setUpgradeData({
          reason: tierError.message,
          requiredTier: isSubscriptionPlanTier(
            tierError.extensions.requiredTier,
          )
            ? tierError.extensions.requiredTier
            : SubscriptionPlanTier.Basic,
        })
        handlers.open()
      })
    })
  }, [handlers, registerErrorListener])

  if (!upgradeData) {
    return null
  }

  return (
    <Modal
      size={900}
      padding="xl"
      opened={opened}
      onClose={handlers.close}
      withCloseButton={false}
    >
      <ModalContent
        reason={upgradeData.reason}
        requiredTier={upgradeData.requiredTier}
        onClose={handlers.close}
      />
    </Modal>
  )
}

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flow-root',
    '& > *': {
      marginInline: 'auto',
    },
    '& > * + *': {
      marginBlockStart: theme.spacing.xl,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    margin: 0,
    textAlign: 'center',
    fontSize: typographicScale.getNthNext(theme.fontSizes.xl, 2),
    lineHeight: 1.2,
  },
  description: {
    margin: 0,
    textAlign: 'center',
    fontWeight: 400,
  },
  divider: {
    marginBlockStart: 2 * theme.spacing.lg,
    marginBlockEnd: 3 * theme.spacing.lg,
    marginInline: 'auto',
    maxWidth: 600,
  },
  plans: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing.xl,
    flexWrap: 'wrap',
  },
  proofs: {
    marginBlock: 3 * theme.spacing.xl,
  },
  retryButton: {
    marginInline: 'auto',
    display: 'block',
  },
}))

type ModalContentProps = {
  reason: string
  requiredTier: SubscriptionPlanTier
  onClose: () => void
}

function ModalContent(props: ModalContentProps) {
  const { requiredTier, onClose } = props
  const navigate = useNavigate()
  const { classes } = useStyles()
  const user = useUser()
  const [requiresAcademic, setRequiresAcademic] = useState(
    !!user.academic?.isVerified,
  )
  const [changePlanModalOpen, setChangePlanModalOpen] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)
  const [period, setPeriod] = useState(SubscriptionPlanPeriod.Year)
  const { subscription, ...subscriptionResult } =
    useSubscriptionWithScheduledUpdateAndPromotion()
  const { plans, ...plansResult } = useSubscriptionPlans(user.currency, {
    requiresAcademic,
    period,
    tiers: getEncompassingSubscriptionPlanTiers(requiredTier),
  })

  const loading = subscriptionResult.loading || plansResult.loading
  const error = subscriptionResult.error || plansResult.error
  const selectedPlan = plans.find((plan) => plan.id === selectedPlanId) ?? null

  const handleClickSubscribe = (planId: string) => {
    const currentPath = encodeURIComponent(getCurrentFullPath())
    navigate(
      `/subscription/subscribe/${planId}?cancel-path=${currentPath}&success-path=${currentPath}`,
    )
    onClose()
  }

  const handleClickChangePlan = (planId: string) => {
    setSelectedPlanId(planId)
    setChangePlanModalOpen(true)
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <Logo radius="sm" color="green" />
          <ActionIcon onClick={onClose}>
            <CloseIcon size={18} />
          </ActionIcon>
        </div>
        <Stack spacing="md">
          <Text component="h1" className={classes.title}>
            Upgrade to Genei {capitalize(requiredTier)}
          </Text>
          <Text component="h2" color="dimmed" className={classes.description}>
            Upgrade your subscription to access this feature.
          </Text>
        </Stack>
        <Divider className={classes.divider} />
        {!user.licenseKey && !user.lifetimeSubscriptionTier ? (
          <>
            <ToggleAcademic
              showAcademic={requiresAcademic}
              onChange={setRequiresAcademic}
            />
            <ChoosePeriod period={period} onChange={setPeriod} />
            {!loading ? (
              <>
                {!error ? (
                  <>
                    <div className={classes.plans}>
                      {plans.map((plan) => (
                        <PlanCard
                          key={plan.id}
                          plan={plan}
                          user={user}
                          subscription={subscription}
                          onClick={(_, action) => {
                            if (action === 'subscribe') {
                              handleClickSubscribe(plan.id)
                            } else if (action === 'change-plan') {
                              handleClickChangePlan(plan.id)
                            }
                          }}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <Text color="red" size="sm" align="center">
                      Failed to fetch plans:
                      <br />
                      {error.message}
                    </Text>
                    <Button
                      className={classes.retryButton}
                      loading={loading}
                      variant="light"
                      color="gray"
                      onClick={() => {
                        if (subscriptionResult.error) {
                          subscriptionResult.refetch()
                        }
                        if (plansResult.error) {
                          plansResult.refetch()
                        }
                      }}
                    >
                      Try again
                    </Button>
                  </>
                )}
              </>
            ) : (
              <div className={classes.plans}>
                <PlanCardSkeleton />
                <PlanCardSkeleton />
              </div>
            )}
            <SocialProofs className={classes.proofs} />
            {!user.subscription && <LicenseKey />}
          </>
        ) : (
          <Text color="red" align="center" mb="xl" sx={{ maxWidth: 600 }}>
            Sorry! We don't support this upgrade path just yet. If you'd like to
            upgrade to Genei {capitalize(requiredTier)}, please contact us at{' '}
            <Text
              component="a"
              variant="link"
              href={'mailto:support@genei.io'}
              inline
              inherit
            >
              support@genei.io
            </Text>
            .
          </Text>
        )}
      </div>
      {selectedPlan && subscription && (
        <ChangeSubscriptionPlanModal
          opened={changePlanModalOpen}
          subscription={subscription}
          plan={selectedPlan}
          onClose={() => setChangePlanModalOpen(false)}
          onChangePlan={(newSubscription) => {
            showNotification({
              message: `Successfully changed plan to Genei ${
                tierToDisplay[newSubscription.plan.tier]
              }.`,
              color: 'green',
            })
            onClose()
          }}
        />
      )}
    </>
  )
}
