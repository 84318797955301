import { createStyles, Modal } from '@mantine/core'
import { useUser } from '@/api'
import { TypeForm } from '@/common'

const useStyles = createStyles({
  body: {
    height: 600,
  },
})

export type CancelReasonFormModalProps = {
  opened: boolean
  onClose: () => void
}

export function CancelReasonFormModal(props: CancelReasonFormModalProps) {
  const { opened, onClose } = props
  const { classes } = useStyles()
  return (
    <Modal
      size={900}
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      classNames={{ body: classes.body }}
    >
      <ModalContent onClose={onClose} />
    </Modal>
  )
}

type ModalContentProps = {
  onClose: () => void
}

function ModalContent(props: ModalContentProps) {
  const { onClose } = props
  const user = useUser()
  const handleSubmit = () => {
    setTimeout(onClose, 5000)
  }
  return (
    <TypeForm formId="YVW23nfX" initialUser={user} onSubmit={handleSubmit} />
  )
}
