import { isPlainObject as _isPlainObject } from 'is-plain-object'
import { FreeTierType, UserCompleteFragment as User } from '@/api'
import { OptionalKeys } from './types'

export function splitIntoWords(str: string): string[] {
  return str.trim().split(/\s+/)
}

export function countWords(str: string): number {
  return splitIntoWords(str).length
}

export function countChars(str: string): number {
  return str.length
}

export function countTokens(str: string): number {
  return Math.ceil(countChars(str) / 4)
}

export function capitalize(value: string) {
  return value[0].toUpperCase() + value.slice(1)
}

export function isPlainObject(
  value: unknown,
): value is Record<string, unknown> {
  return _isPlainObject(value)
}

export function insertImmutably<T>(array: T[], item: T, index?: number): T[] {
  if (index === undefined || index > array.length - 1) return [...array, item]
  if (index <= 0) return [item, ...array]
  return [...array.slice(0, index), item, ...array.slice(index)]
}

export function removeImmutably<T>(array: T[], item: T): T[] {
  const index = array.indexOf(item)
  if (index === -1) return array
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export function removeImmutablyByIndex<T>(array: T[], index?: number): T[] {
  if (index === undefined || index > array.length - 1) index = array.length - 1
  if (index < 0) index = 0
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export function setPropImmutably<T extends object, K extends keyof T>(
  obj: T,
  key: K,
  value: T[K],
): T {
  return { ...obj, [key]: value }
}

export function removePropImmutably<T extends object>(
  obj: T,
  key: OptionalKeys<T>,
): T {
  const { [key]: _, ...newObj } = obj
  return newObj as T
}

export function timeout<T>(promise: Promise<T>, time: number): Promise<T> {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error('Request timed out.'))
    }, time)
    promise.then(resolve, reject)
  })
}

export function delay(ms: number = 1000) {
  return <T>(value: T) =>
    new Promise<T>((resolve) => setTimeout(() => resolve(value), ms))
}

export function wait(ms: number = 1000) {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms))
}

/**
 * Returns a random float in the range [min, max)
 * @param min Minimum float (inclusive)
 * @param max Maximum float (exclusive)
 * @returns Random float in range [min, max)
 */
export function randomFloat(min: number, max: number) {
  return Math.random() * (max - min) + min
}

/**
 * Returns a random integer in the range [min, max)
 * @param min Minimum integer (inclusive)
 * @param max Maximum integer (exclusive)
 * @returns Random integer in range [min, max)
 */
export function randomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

export function sum(arr: number[]): number {
  return arr.reduce((partialSum, a) => partialSum + a, 0)
}

export function collapseWhitespace(str: string): string {
  return str.trim().replace(/\s+/g, ' ')
}

type TruncateStringOptions = {
  length?: number
  sep?: string
  position?: 'start' | 'middle' | 'end'
}

export function truncateString(str: string, options?: TruncateStringOptions) {
  const { length = 40, sep = '...', position = 'end' } = options ?? {}
  if (str.length <= length) return str
  const availableLength = length - sep.length
  switch (position) {
    case 'start':
      return `${sep}${str.slice(str.length - availableLength)}`
    case 'middle':
      const startLength = Math.ceil(availableLength / 2)
      const endLength = availableLength - startLength
      return `${str.slice(0, startLength)}${sep}${str.slice(
        str.length - endLength,
      )}`
    case 'end':
    default:
      return `${str.slice(0, availableLength)}${sep}`
  }
}

export function range(end: number): number[]
export function range(start: number, end: number): number[]
export function range(start: number, end: number, step: number): number[]
export function range(
  startOrEnd: number,
  end?: number,
  step?: number,
): number[] {
  let actualStart: number
  let actualEnd: number
  let actualStep: number
  if (arguments.length === 1) {
    actualStart = 0
    actualEnd = startOrEnd
    actualStep = 1
  } else if (arguments.length === 2) {
    actualStart = startOrEnd
    actualEnd = end as number
    actualStep = 1
  } else {
    actualStart = startOrEnd
    actualEnd = end as number
    actualStep = step as number
  }
  const range: number[] = []
  for (let i = actualStart; i < actualEnd; i += actualStep) {
    range.push(i)
  }
  return range
}

export function getenv(key: string): string | undefined
export function getenv(key: string, defaultValue: string): string
export function getenv(key: string, mustExist: false): string | undefined
export function getenv(key: string, mustExist: true): string
export function getenv(key: string, mustExist: boolean | string = false) {
  const value = process.env[key]
  if (typeof mustExist === 'string') {
    return value || mustExist
  }
  if (!mustExist) {
    return value
  }
  if (value == null) {
    throw new Error(`Missing value for environment variable ${key}`)
  }
  return value
}

export function* zip<T extends unknown[][]>(
  ...iterables: T
): Generator<{ [K in keyof T]: T[K] extends (infer V)[] ? V : never }> {
  const iterators = iterables.map((i) => i[Symbol.iterator]())
  while (true) {
    const results = iterators.map((iter) => iter.next())
    if (results.some((res) => res.done)) return
    // @ts-expect-error
    else yield results.map((res) => res.value)
  }
}

export function checkIsValidHttpUrl(string: string): boolean {
  try {
    const url = new URL(string)
    return url.protocol === 'http:' || url.protocol === 'https:'
  } catch {
    return false
  }
}

export function mod(n: number, m: number): number {
  if (m === 0) return 0
  return ((n % m) + m) % m
}

export const checkIsString = (value: unknown): value is string => {
  return typeof value === 'string'
}

export function checkAreSetsEqual<T>(a: Set<T>, b: Set<T>) {
  if (a.size !== b.size) return false
  for (const x of a) {
    if (!b.has(x)) return false
  }
  return true
}

export function* iterReverse<T>(arr: T[]): Generator<T> {
  for (let i = arr.length - 1; i >= 0; i--) {
    yield arr[i]
  }
}

export function withTiming<T extends (...args: any[]) => any>(
  fn: T,
  name?: string,
): T {
  return ((...args: Parameters<T>) => {
    const start = performance.now()
    const result = fn(...args) as ReturnType<T>
    const end = performance.now()
    console.log(`${name || fn.name} took ${end - start}ms`)
    return result
  }) as T
}

export function nullThrows<T>(
  value: T,
  message: string = 'Value is null or undefined',
): NonNullable<T> {
  if (value == null) throw new Error(message)
  return value as NonNullable<T>
}

type Accessible = Record<string, any>
type AccessKey<TData extends Accessible> = keyof TData
type AccessFn<TData extends Accessible> = (
  data: TData,
  index: number,
  array: TData[],
) => any

type Accessed<
  TData extends Accessible,
  TAccessor extends AccessKey<TData> | AccessFn<TData>,
> = TAccessor extends AccessKey<TData>
  ? TData[TAccessor]
  : TAccessor extends AccessFn<TData>
  ? ReturnType<TAccessor>
  : never

export function normaliseArrayOfObjects<
  TData extends Accessible,
  TAccessor extends AccessKey<TData> | AccessFn<TData>,
>(array: TData[], accessor: TAccessor) {
  const map = new Map<Accessed<TData, TAccessor>, TData>()
  for (let i = 0; i < array.length; i++) {
    const item = array[i]
    const accessed =
      typeof accessor === 'function'
        ? accessor(item, i, array)
        : item[accessor as keyof TData]
    map.set(accessed, item)
  }
  return map
}

export function segregateArrayOfObjects<
  TData extends Record<string, any>,
  TAccessor extends AccessKey<TData> | AccessFn<TData>,
>(array: TData[], accessor: TAccessor) {
  const map = new Map<Accessed<TData, TAccessor>, TData[]>()
  for (let i = 0; i < array.length; i++) {
    const item = array[i]
    const accessed =
      typeof accessor === 'function'
        ? accessor(item, i, array)
        : item[accessor as keyof TData]
    let items = map.get(accessed)
    if (!items) {
      items = []
      map.set(accessed, items)
    }
    items.push(item)
  }
  return map
}

export const toDefaultImport = <
  K extends keyof T,
  T extends Record<string, any>,
>(
  key: K,
) => {
  return (module: T) => ({ default: module[key] })
}

export function validateEmail(email: string) {
  if (!/^\S+@\S+$/.test(email)) {
    return 'Invalid email'
  }
  return null
}

export function invariant(
  condition: boolean,
  message?: string,
): asserts condition {
  if (!condition) {
    throw new Error(message ?? 'Assertion failed')
  }
}

/**
 * Fisher-Yates shuffle modern algorithm (in-place)
 * See https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
 * @param array Array to shuffle
 */
export function shuffleArray<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    // Pick random index in range [0, i]
    const j = randomInt(0, i + 1)
    // Swap elements at i and j
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

// TODO: probably belongs in own module
export function getUserFullName(user: {
  firstName?: string | null
  lastName?: string | null
}) {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  }
  if (user.firstName) {
    return user.firstName
  }
  if (user.lastName) {
    return user.lastName
  }
  return null
}

// TODO: probably belongs in own module
export function getUserInitials(user: {
  firstName?: string | null
  lastName?: string | null
}) {
  if (user.firstName && user.lastName) {
    return `${user.firstName[0]}${user.lastName[0]}`
  }
  if (user.firstName) {
    return user.firstName[0]
  }
  if (user.lastName) {
    return user.lastName[0]
  }
  return null
}

export const getUserTier = (user: User) => {
  if (user.lifetimeSubscriptionTier) return user.lifetimeSubscriptionTier
  if (user.licenseKey) return user.licenseKey.tier
  return user.subscription?.plan.tier
}

export const getTrialDays = (
  trialDays: number,
  freeTierType?: FreeTierType | null,
) => {
  if (freeTierType) return Math.floor(trialDays / 2)
  return trialDays
}

export function mustShowPricing(user: User) {
  return !user.everSubscribed && user.freeTierType == null
}
