/**
 * TODO: The following has been copied pretty mindlessly from slate's codebase.
 * TODO: It would be wise to fully understand the logic, especially `normalizeDOMPoint`
 */

type DOMNode = globalThis.Node
type DOMComment = globalThis.Comment
type DOMElement = globalThis.Element
type DOMText = globalThis.Text
type DOMRange = globalThis.Range
type DOMSelection = globalThis.Selection
type DOMStaticRange = globalThis.StaticRange

export type {
  DOMNode,
  DOMComment,
  DOMElement,
  DOMText,
  DOMRange,
  DOMSelection,
  DOMStaticRange,
}

declare global {
  interface Window {
    Selection: typeof Selection['constructor']
    DataTransfer: typeof DataTransfer['constructor']
    Node: typeof Node['constructor']
    HTMLElement: typeof HTMLElement['constructor']
  }
}

export type DOMPoint<T extends Node = Node> = [T, number]

/**
 * Returns the host window of a DOM node
 */

export const getDefaultView = (value: any): Window | null => {
  return (
    (value && value.ownerDocument && value.ownerDocument.defaultView) || null
  )
}

/**
 * Check if a DOM node is a comment node.
 */

export const isDOMComment = (value: any): value is DOMComment => {
  return isDOMNode(value) && value.nodeType === 8
}

/**
 * Check if a DOM node is an element node.
 */

export const isDOMElement = (value: any): value is DOMElement => {
  return isDOMNode(value) && value.nodeType === 1
}

/**
 * Check if a value is an html element node
 */

export const isHTMLElement = (value: any): value is HTMLElement => {
  const window = getDefaultView(value)
  return !!window && value instanceof window.HTMLElement
}

/**
 * Check if a value is a DOM node.
 */

export const isDOMNode = (value: any): value is DOMNode => {
  const window = getDefaultView(value)
  return !!window && value instanceof window.Node
}

/**
 * Check if a value is a DOM selection.
 */

export const isDOMSelection = (value: any): value is DOMSelection => {
  const window = value && value.anchorNode && getDefaultView(value.anchorNode)
  return !!window && value instanceof window.Selection
}

/**
 * Check if a DOM node is an element node.
 */

export const isDOMText = (value: any): value is DOMText => {
  return isDOMNode(value) && value.nodeType === 3
}

export const getBoundingClientRectOfElements = (
  elements: Element[],
): DOMRect => {
  if (!elements.length) {
    return {
      x: 0,
      y: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: 0,
      height: 0,
      toJSON() {
        return this
      },
    }
  }
  const rects = elements.map((element) => element.getBoundingClientRect())
  const top = Math.min(...rects.map((rect) => rect.top))
  const right = Math.max(...rects.map((rect) => rect.right))
  const bottom = Math.max(...rects.map((rect) => rect.bottom))
  const left = Math.min(...rects.map((rect) => rect.left))
  const width = right - left
  const height = bottom - top
  return {
    x: left,
    y: top,
    top,
    right,
    bottom,
    left,
    width,
    height,
    toJSON() {
      return this
    },
  }
}
