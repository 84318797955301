import { useChangeSubscriptionPlanMutation } from '@/api'

export const useChangeSubscriptionPlan = () => {
  const [execute, result] = useChangeSubscriptionPlanMutation()
  const changeSubscriptionPlan = async (
    planId: string,
    promotionCode?: string,
  ) => {
    const { data } = await execute({
      variables: {
        input: {
          planId,
          promotionCode,
        },
      },
    })
    return data!.changeSubscriptionPlan
  }
  return [changeSubscriptionPlan, result] as const
}
