import { MantineSize, Switch } from '@mantine/core'

export type ToggleAcademicProps = {
  showAcademic: boolean
  onChange: (value: boolean) => void
  size?: MantineSize
}

export function ToggleAcademic(props: ToggleAcademicProps) {
  const { showAcademic, onChange, size } = props
  return (
    <div style={{ width: 'fit-content' }}>
      <Switch
        size={size}
        checked={showAcademic}
        onChange={(event) => onChange(event.currentTarget.checked)}
        label="Show academic pricing"
      />
    </div>
  )
}
