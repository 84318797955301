import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import * as Sentry from '@sentry/react'
import { useMeQuery, UserCompleteFragment, useSyncTraitsMutation } from '@/api'
import { ApolloError } from '@apollo/client'
import { useAuth } from '@clerk/clerk-react'
import { getUserFullName } from '@/utils/common'

type UserProviderProps = {
  children: React.ReactNode
}

type UserContextValue = {
  user?: UserCompleteFragment
  loading: boolean
  error: ApolloError | undefined
  refetch: () => void
}

const UserContext = createContext<UserContextValue | undefined>(undefined)

export const UserProvider = (props: UserProviderProps) => {
  const { children } = props
  const auth = useAuth()
  const {
    data,
    loading,
    error,
    refetch: _refetch,
  } = useMeQuery({ skip: !auth.isSignedIn })
  const [syncTraits] = useSyncTraitsMutation()
  const user = data?.me

  useEffect(() => {
    if (!user) return
    syncTraits().catch(console.error)
    broadcastIdentity(user)
    // don't care about granular changes - only update when the user id changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  const refetch = useCallback(() => {
    if (!auth.isSignedIn) return
    _refetch()
  }, [auth.isSignedIn, _refetch])

  const value = useMemo(
    () => ({
      user,
      loading,
      error,
      refetch,
    }),
    [user, loading, error, refetch],
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

function broadcastIdentity(user: UserCompleteFragment) {
  try {
    window.analytics.identify(user.id, {
      name: getUserFullName(user),
      firstName: user.firstName ?? undefined,
      lastName: user.lastName ?? undefined,
      email: user.email,
      userType: 'genei-1.0',
    })
  } catch {}
  try {
    window.analytics.alias(user.id)
  } catch {}
  try {
    Sentry.setUser({ id: user.id, email: user.email })
  } catch {}
  try {
    // @ts-ignore
    if (typeof window.profitwell === 'function') {
      // @ts-ignore
      window.profitwell('start', { user_id: user.customerId })
    }
  } catch {}
}

export const useMaybeUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(
      `The \`useMaybeUser\` hook must be used inside the <UserProvider> component's context`,
    )
  }
  return context
}

export const useUser = () => {
  const { user, refetch } = useMaybeUser()
  if (!user) {
    throw new Error(
      `The \`useUser\` hook can only be used inside an authenticated route`,
    )
  }
  const userWithRefetch = useMemo(() => ({ ...user, refetch }), [user, refetch])
  return userWithRefetch
}
