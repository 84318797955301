import { createStyles, Text, TextProps } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  root: {
    marginBlock: 0,
    marginInline: 0,
    '& > dt': {
      marginBlockEnd: 0,
    },
    '& > dd': {
      display: 'flow-root',
      marginInlineStart: theme.spacing.sm,
    },
    '& > dd + dt': {
      marginBlockStart: theme.spacing.xl,
    },
    '& > dt + dd': {
      marginBlockStart: theme.spacing.xs,
    },
  },
}))

function DescriptionList(props: React.PropsWithChildren<{}>) {
  const { classes } = useStyles()
  return <dl className={classes.root}>{props.children}</dl>
}

export type DescriptionTermProps = TextProps<'dt'>

function DescriptionTerm(props: DescriptionTermProps) {
  const { component = 'dt', size = 'lg', ...delegated } = props
  return <Text component={component} size={size} {...delegated} />
}

export type DescriptionItemProps = TextProps<'dd'>

function DescriptionItem(props: DescriptionItemProps) {
  const { component = 'dd', size = 'sm', ...delegated } = props
  return <Text component={component} size={size} {...delegated} />
}

interface IDescription {
  List: typeof DescriptionList
  Term: typeof DescriptionTerm
  Item: typeof DescriptionItem
}

export const Description: IDescription = {
  List: DescriptionList,
  Term: DescriptionTerm,
  Item: DescriptionItem,
}
