import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Paper,
  Text,
  TextInput,
  createStyles,
  UnstyledButton,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useConsumeLicenseKey } from './useConsumeLicenseKey'

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing.xs / 2,
  },
  text: {
    display: 'inline',
  },
  button: {
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
  },
}))

export const LicenseKey = () => {
  const { classes, theme } = useStyles()
  const [showCard, setShowCard] = useState(false)
  const handleClick = () => setShowCard(true)
  return (
    <Box pb={theme.spacing.xl}>
      {showCard ? (
        <LicenseKeyCard />
      ) : (
        <div className={classes.root}>
          <Text size="sm" className={classes.text}>
            Do you have a license key?{' '}
            <UnstyledButton onClick={handleClick} className={classes.button}>
              Click here
            </UnstyledButton>
          </Text>
        </div>
      )}
    </Box>
  )
}

const useCardStyles = createStyles((theme) => ({
  container: {
    width: 555,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.lg,
    padding: theme.spacing.lg,
    background: theme.fn.linearGradient(
      45,
      theme.colors.indigo[9],
      theme.colors.indigo[7],
    ),
    color: theme.white,
    '& > *': {
      flex: '0 0 auto',
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  name: {
    margin: 0,
    fontWeight: 400,
  },
  input: {
    width: '100%',
  },
  button: {
    fontWeight: 600,
    color: 'white',
    border: '1.5px solid white',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    gap: theme.spacing.xl,
  },
}))

export const LicenseKeyCard = () => {
  const { classes } = useCardStyles()
  const [consumeLicenseKey, { loading, error }] = useConsumeLicenseKey()
  const navigate = useNavigate()
  const form = useForm({
    initialValues: {
      key: '',
    },
  })
  const handleSubmit = form.onSubmit(async (values) => {
    await consumeLicenseKey(values.key).then(() => navigate('/summarise'))
  })

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Paper className={classes.container} radius="md" withBorder>
        <Text className={classes.name} component="h3" size="lg">
          License Key
        </Text>
        <TextInput
          className={classes.input}
          size="sm"
          radius="md"
          placeholder="Enter your secret code..."
          {...form.getInputProps('key')}
        />
        <div className={classes.buttonContainer}>
          <Button
            variant="outline"
            size="sm"
            className={classes.button}
            type="submit"
            loading={loading}
          >
            Submit
          </Button>
          {error && (
            <Text size="sm" color="red">
              {error.message}
            </Text>
          )}
        </div>
      </Paper>
    </form>
  )
}
