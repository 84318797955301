import { useRef, useEffect } from 'react'

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T | undefined>(undefined)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const usePreviousNonNullish = <T>(value: T): T | undefined => {
  const ref = useRef<T | undefined>(undefined)
  useEffect(() => {
    if (value != null) {
      ref.current = value
    }
  }, [value])
  return ref.current
}

export const usePreviousCaptured = <T>(
  value: T,
  shouldCapture: boolean,
): T | undefined => {
  const ref = useRef<T | undefined>(undefined)
  useEffect(() => {
    if (!shouldCapture) return
    ref.current = value
  }, [value, shouldCapture])
  return ref.current
}
