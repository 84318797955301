import { Expand } from '@/utils/types'
import {
  PolymorphicComponentProps,
  PolymorphicRef,
  Button,
  SharedButtonProps,
  useMantineTheme,
} from '@mantine/core'
import { forwardRef } from 'react'
import { DynamicChevron } from './DynamicChevron'

type SharedChipProps = Expand<
  Omit<SharedButtonProps, 'radius' | 'compact' | 'rightIcon'> & {
    opened?: boolean
  }
>

export type ChipProps<C> = PolymorphicComponentProps<C, SharedChipProps>

type ChipComponent = (<C = 'button'>(
  props: ChipProps<C>,
) => React.ReactElement | null) & {
  displayName?: string
}

export const Chip: ChipComponent = forwardRef(function Chip(
  props: ChipProps<'button'>,
  ref: PolymorphicRef<'button'>,
) {
  const {
    component,
    className,
    children,
    opened,
    variant,
    size = 'sm',
    ...delegated
  } = props

  const theme = useMantineTheme()
  const chrevonSize = theme.fontSizes[size] * 0.9

  const rightIcon =
    opened !== undefined ? (
      <DynamicChevron opened={opened} degree={180} size={chrevonSize} />
    ) : undefined

  return (
    <Button
      className={className}
      component={component || 'button'}
      variant={variant ?? 'outline'}
      radius={10000}
      rightIcon={rightIcon}
      size={size}
      compact
      {...delegated}
      ref={ref}
    >
      {children}
    </Button>
  )
}) as any

Chip.displayName = '@genei/Chip'
