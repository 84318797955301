import React from 'react'
import { Outlet, RouteObject as ReactRouterRouteObject } from 'react-router-dom'
import { Navigate } from './Navigate'
import { AuthWrapper } from './AuthWrapper'
import { RouteObject } from './types'

export function constructRoutes(
  routes: RouteObject[],
): ReactRouterRouteObject[] {
  return routes.map((route) => {
    if (route.type === 'redirect') {
      const { redirect, push, propagateSearchParams, ...rest } = route
      return {
        ...rest,
        element: (
          <Navigate
            to={redirect}
            replace={!push}
            propagateSearchParams={propagateSearchParams}
          />
        ),
      }
    }
    if (route.type === 'lazy') {
      const { LazyComponent, fallback, auth, children, ...rest } = route
      return {
        ...rest,
        children: children ? constructRoutes(children) : undefined,
        element: (
          <AuthWrapper auth={route.auth}>
            <React.Suspense fallback={route.fallback}>
              <route.LazyComponent />
            </React.Suspense>
          </AuthWrapper>
        ),
      }
    }
    const { element, auth, children, ...rest } = route
    return {
      ...rest,
      children: children ? constructRoutes(children) : undefined,
      element: (
        <AuthWrapper auth={route.auth}>
          {route.hasOwnProperty('element') ? element : <Outlet />}
        </AuthWrapper>
      ),
    }
  })
}
