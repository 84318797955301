import { forwardRef } from 'react'
import {
  createStyles,
  DefaultProps,
  MantineNumberSize,
  PolymorphicComponentProps,
  PolymorphicRef,
  UnstyledButton,
} from '@mantine/core'
import { getLineColor } from '@/styles/helpers'

interface PaperButtonStyles {
  radius?: MantineNumberSize
  padding: MantineNumberSize
  withBorder: boolean
}

const useStyles = createStyles(
  (theme, { radius, padding, withBorder }: PaperButtonStyles) => ({
    root: {
      ...theme.fn.focusStyles(),
      WebkitTapHighlightColor: 'transparent',
      display: 'block',
      textDecoration: 'none',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
      boxSizing: 'border-box',
      borderRadius: radius
        ? theme.fn.size({ size: radius, sizes: theme.radius })
        : 'unset',
      padding: theme.fn.size({ size: padding, sizes: theme.spacing }),
      border: withBorder ? `1px solid ${getLineColor(theme)}` : undefined,
    },
  }),
)

export interface SharedPaperButtonProps extends DefaultProps {
  /** Predefined padding value from theme.spacing or number for padding in px */
  padding?: MantineNumberSize
  /** Predefined border-radius value from theme.radius or number for border-radius in px */
  radius?: MantineNumberSize
  /** Adds 1px border with theme.colors.gray[2] color in light color scheme and theme.colors.dark[6] in dark color scheme */
  withBorder?: boolean
}

export type PaperButtonProps<C> = PolymorphicComponentProps<
  C,
  SharedPaperButtonProps
>
type PaperButtonComponent = (<C = 'button'>(
  props: PaperButtonProps<C>,
) => React.ReactElement | null) & {
  displayName?: string
}

export const PaperButton: PaperButtonComponent = forwardRef(
  function PaperButton(
    props: PaperButtonProps<'button'>,
    ref: PolymorphicRef<'button'>,
  ) {
    const {
      component,
      className,
      children,
      padding = 0,
      radius,
      withBorder = false,
      ...others
    } = props
    const { classes, cx } = useStyles(
      { radius, padding, withBorder },
      { name: 'GeneiPaperButton' },
    )

    return (
      <UnstyledButton<any>
        component={component || 'button'}
        className={cx(classes.root, className)}
        ref={ref}
        {...others}
      >
        {children}
      </UnstyledButton>
    )
  },
) as any

PaperButton.displayName = '@genei/PaperButton'
