import { useRef } from 'react'

const None = Symbol('None')

export const useCaptureOnMount = <T>(value: T) => {
  const ref = useRef<T | typeof None>(None)
  if (ref.current === None) {
    ref.current = value
  }
  return ref.current
}
