import { useCallback, useState } from 'react'
import { useMigrateBetaMutation } from '@/api'
import { ApolloError } from '@apollo/client'
import {
  Button,
  createStyles,
  Group,
  Loader,
  Modal,
  Stack,
  Text,
  CloseButton,
  Checkbox,
  Divider,
} from '@mantine/core'
import { ErrorMessage, useBeforeUnload } from '@/common'
import { useBlockNavigation } from '@/router'

export type MigrationModalProps = {
  opened: boolean
  onClose: () => void
}

export function MigrationModal(props: MigrationModalProps) {
  const { opened, onClose } = props

  return (
    <Modal
      opened={opened}
      onClose={() => {}}
      radius="lg"
      withCloseButton={false}
    >
      <ModalContent onClose={onClose} />
    </Modal>
  )
}

type ModelContentProps = {
  onClose: () => void
}

function ModalContent(props: ModelContentProps) {
  const { onClose } = props
  const [migrate, { loading, error, called }] = useMigrateBeta()
  useBlockNavigation(loading)
  useBeforeUnload((event) => {
    if (loading) {
      event.preventDefault()
    }
  })
  return (
    <>
      {!called ? (
        <StartContent onStart={migrate} onClose={onClose} />
      ) : (
        <MigratingContent
          loading={loading}
          error={error}
          onRetry={migrate}
          onClose={onClose}
        />
      )}
    </>
  )
}

const useStartStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

type StartContentProps = {
  onStart: () => void
  onClose: () => void
}

function StartContent(props: StartContentProps) {
  const { onStart, onClose } = props
  const { classes } = useStartStyles()
  return (
    <Stack align="center" spacing="xl" className={classes.root}>
      <div className={classes.close}>
        <CloseButton onClick={onClose} />
      </div>
      <Text size="lg">Start Migration</Text>
      <Text size="sm" align="center">
        We are ready to migrate your files from Genei Beta.
      </Text>
      <Text size="sm" align="center">
        During migration, your documents, files, and notes stored in Genei Beta
        will be copied over to Genei 1.0.
      </Text>
      <Button onClick={onStart} color="teal" autoFocus data-autofocus>
        Start migration
      </Button>
      <Text size="xs" align="center">
        Click{' '}
        <Text
          component="a"
          variant="link"
          href="https://genei.notion.site/Migration-5a14d00659b74359a9852a59fd244375"
          inline
          inherit
        >
          here
        </Text>{' '}
        to learn more about the migration process.
      </Text>
    </Stack>
  )
}

const useMigratingStyles = createStyles((theme) => ({
  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing.lg,
  },
}))

type MigratingContentProps = {
  loading: boolean
  error?: ApolloError
  onRetry: () => void
  onClose: () => void
}

function MigratingContent(props: MigratingContentProps) {
  const { loading, error, onRetry, onClose } = props
  const { classes, theme } = useMigratingStyles()
  const [disclaimerRead, setDisclaimerRead] = useState(false)
  if (loading) {
    return (
      <Stack align="center" spacing="xl">
        <Group spacing="sm">
          <Text size="lg">Migration in progress</Text>
          <Loader color="gray" size="sm" />
        </Group>
        <Text size="sm" align="center">
          Migration can take anywhere from 30 seconds to 5 minutes to finish ⌛.
        </Text>
        <Text size="sm" align="center">
          Do not leave this page and do not make any changes on Genei Beta
          whilst the migration is running ✋.
        </Text>
        <Text size="sm" align="center">
          If you do not believe your files have been migrated correctly, please
          contact{' '}
          <Text
            component="a"
            variant="link"
            href="mailto:support@genei.io"
            inline
            inherit
          >
            support@genei.io
          </Text>{' '}
          📚.
        </Text>
      </Stack>
    )
  }
  if (error) {
    return (
      <Stack align="center" spacing="xl">
        <Text size="lg">Migration failed ⭕</Text>
        <ErrorMessage error={error} size="sm" align="center" />
        <div className={classes.actions}>
          <Button onClick={onRetry}>Try again</Button>
          <Button
            onClick={onClose}
            variant="subtle"
            color={theme.colorScheme === 'dark' ? 'dark' : 'gray'}
          >
            Remind me later
          </Button>
        </div>
      </Stack>
    )
  }
  return (
    <Stack align="center" spacing="xl">
      <Text size="lg">🎉 Migration complete 🎉</Text>
      <Text size="sm" align="center">
        All of your data has been migrated to Genei 1.0. Please contact{' '}
        <Text
          component="a"
          variant="link"
          href="mailto:support@genei.io"
          inline
          inherit
        >
          support@genei.io
        </Text>{' '}
        if there are any issues.
      </Text>
      <Text size="sm" align="center">
        <b>Highlights</b> are stored but not yet accessible. We will be
        releasing an update for this shortly.
      </Text>
      <Text size="sm" align="center">
        <b>Your old account</b> on beta.genei.io will be archived soon and is
        now read-only.
      </Text>
      <div style={{ width: '100%' }}>
        <Divider />
      </div>
      <Checkbox
        size="sm"
        label="I have read and understood this information."
        checked={disclaimerRead}
        onChange={(e) => setDisclaimerRead(e.target.checked)}
      />
      <Button onClick={onClose} disabled={!disclaimerRead}>
        Close
      </Button>
    </Stack>
  )
}

const useMigrateBeta = () => {
  const [execute, result] = useMigrateBetaMutation()
  const migrate = useCallback(async () => {
    const { data } = await execute()
    return data!.migrateBeta
  }, [execute])
  return [migrate, result] as const
}
