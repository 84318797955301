import { useState } from 'react'
import { WithOptional } from '@/utils/types'
import { unstable_batchedUpdates } from 'react-dom'

type UploadWithProgressOptions = {
  httpMethod: string
  file: Blob
  onProgress: (percentage: number) => any
}

function uploadWithProgress(url: string, options: UploadWithProgressOptions) {
  const request = new XMLHttpRequest()
  request.open(options.httpMethod, url)
  request.upload.addEventListener('progress', (event) => {
    const percentageComplete = (100 * event.loaded) / event.total
    options.onProgress(percentageComplete)
  })
  const promise = new Promise((resolve, reject) => {
    request.addEventListener('load', () => {
      if (request.status !== 200) {
        console.error(request.status, request.response)
        reject('Something went wrong')
      }
      resolve(request.response)
    })
    request.addEventListener('error', () => reject('Something went wrong'))
  })
  request.setRequestHeader('Content-Type', options.file.type)
  request.send(options.file)
  return promise
}

export const useUploadWithProgress = () => {
  const [isUploading, setIsUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState<Error | undefined>(undefined)

  const upload = async (
    url: string,
    options: WithOptional<UploadWithProgressOptions, 'onProgress'>,
  ) => {
    unstable_batchedUpdates(() => {
      setIsUploading(true)
      setProgress(0)
      setError(undefined)
    })
    try {
      await uploadWithProgress(url, {
        ...options,
        onProgress: (percentage) => {
          setProgress(percentage)
          if (options.onProgress) options.onProgress(percentage)
        },
      })
      setIsUploading(false)
    } catch (error) {
      unstable_batchedUpdates(() => {
        setIsUploading(false)
        setError(
          error instanceof Error ? error : new Error('Something went wrong'),
        )
      })
    }
  }
  const reset = () => {
    unstable_batchedUpdates(() => {
      setIsUploading(false)
      setProgress(0)
      setError(undefined)
    })
  }

  return [upload, { isUploading, progress, error, reset }] as const
}
