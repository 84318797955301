import { DocumentClient } from './document-client'
import { NlpClient } from './nlp-client'
import { Database } from './database'

export class DatabaseClient {
  public readonly document: DocumentClient
  public readonly nlp: NlpClient

  constructor(db: Database) {
    this.document = new DocumentClient(db)
    this.nlp = new NlpClient(db)
  }
}
