import {
  useOnCreditsChangeSubscription,
  useOnSubscriptionUpdateSubscription,
  useOnSubscriptionDeleteSubscription,
} from './hooks'
import { onCreateSubscription, onDeleteSubscription } from './typePolicies'

type BillingSubscriptionsProps = {
  skip?: boolean
}

export function BillingSubscriptions(props: BillingSubscriptionsProps) {
  const { skip } = props
  useOnSubscriptionUpdateSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const subscription = subscriptionData.data?.onSubscriptionUpdate
      if (!subscription) return
      onCreateSubscription(client.cache, subscription)
    },
  })
  useOnSubscriptionDeleteSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const subscription = subscriptionData.data?.onSubscriptionDelete
      if (!subscription) return
      onDeleteSubscription(client.cache, subscription)
    },
  })
  useOnCreditsChangeSubscription({ skip })
  return null
}
