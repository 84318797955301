import { useSetDefaultPaymentMethodMutation } from '@/api'

export const useSetDefaultPaymentMethod = () => {
  const [execute, result] = useSetDefaultPaymentMethodMutation()
  const setDefaultPaymentMethod = async (id: string) => {
    const { data } = await execute({
      variables: {
        input: { id },
      },
    })
    return data!.setDefaultPaymentMethod
  }
  return [setDefaultPaymentMethod, result] as const
}
