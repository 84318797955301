import { Text, Stack, Modal, Center } from '@mantine/core'
import { IconMoodSad as CrashIcon } from '@tabler/icons-react'

function ErrorView(/* { error, resetErrorBoundary } */) {
  return (
    <Modal opened centered withCloseButton={false} onClose={() => {}}>
      <Center mb="xs">
        <CrashIcon size={28} />
      </Center>
      <Stack spacing="lg">
        <Text component="h1" size="xl" align="center" my={0}>
          Oops!
        </Text>
        <Text size="lg" align="center">
          Looks like Genei crashed.
        </Text>
        <Text size="sm" align="center">
          Refresh the page and try again. If the problem persists, please
          message support through intercom or email{' '}
          <Text
            inherit
            component="a"
            variant="link"
            href="mailto:support@genei.io"
          >
            support@genei.io
          </Text>
          .
        </Text>
      </Stack>
    </Modal>
  )
}

export default ErrorView
