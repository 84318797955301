import { useSubscriptionPlanQuery } from '@/api'

export const useSubscriptionPlan = (planId: string) => {
  const { data, ...rest } = useSubscriptionPlanQuery({
    variables: {
      id: planId,
    },
  })
  const plan = data?.subscriptionPlan
  return { plan, ...rest }
}
