import { Json } from '@/utils/types'

type MaybeJson = Json | undefined

type TrackEventProperties = Record<string, MaybeJson>

type TrackEventParams = {
  eventName: string
  properties?: TrackEventProperties
  integrations?: {
    All?: boolean
    Mixpanel?: boolean
  }
  context?: Record<string, any>
}

export function track(params: TrackEventParams) {
  const {
    eventName,
    properties = {},
    context = {},
    integrations: _integrations,
  } = params
  const integrations = {
    All: false,
    Mixpanel: true,
    ..._integrations,
  }
  try {
    window.analytics.track(eventName, properties, {
      context,
      integrations,
    })
  } catch (error) {
    console.error(error)
  }
}
