import { forwardRef } from 'react'
import {
  Box,
  createStyles,
  DefaultProps,
  MantineNumberSize,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '@mantine/core'

// equal to action icon
const sizes = {
  xs: 18,
  sm: 22,
  md: 28,
  lg: 34,
  xl: 44,
}

type StyleParams = {
  radius: MantineNumberSize
  size: MantineNumberSize
}

const useStyles = createStyles((theme, { radius, size }: StyleParams) => ({
  root: {
    width: 'min-content',
    display: 'flex',
    borderRadius: theme.fn.radius(radius),
    overflow: 'hidden',
  },
  image: {
    height: theme.fn.size({ size, sizes }),
    width: theme.fn.size({ size, sizes }),
  },
}))

export interface SharedLogoProps extends DefaultProps {
  size?: MantineNumberSize
  radius?: MantineNumberSize
  color?: 'green' | 'blue'
  alt?: string
  cropped?: boolean
}

export type LogoProps<C> = PolymorphicComponentProps<C, SharedLogoProps>
type LogoComponent = (<C = 'div'>(
  props: LogoProps<C>,
) => React.ReactElement | null) & {
  displayName?: string
}

export const Logo: LogoComponent = forwardRef(function Logo(
  props: LogoProps<'div'>,
  ref: PolymorphicRef<'div'>,
) {
  const {
    component,
    className,
    radius = 'sm',
    size = 'md',
    alt,
    cropped,
    color,
    ...delegated
  } = props
  const { classes, cx } = useStyles({ radius, size })
  let src: string
  if (color === 'blue') {
    src = cropped ? '/logo-blue-cropped.png' : '/logo-blue.png'
  } else {
    src = cropped ? '/logo-cropped.png' : '/logo.png'
  }
  return (
    <Box
      component={component || 'div'}
      className={cx(classes.root, className)}
      ref={ref}
      {...delegated}
    >
      <img
        src={src}
        alt={alt ?? 'Genei Logo'}
        className={cx(classes.image, className)}
      />
    </Box>
  )
}) as any

Logo.displayName = '@genei/Logo'
