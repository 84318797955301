import { useEffect, useRef, useState } from 'react'
import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'
import { getUserFullName } from '@/utils/common'
import { useValueRef } from '../hooks'

const BYPASS_CODE = 'byeform768'

type HiddenFields = Record<string, string>

type User = {
  id: string
  email: string
  firstName?: string | null
  lastName?: string | null
}

type TypeFormProps = {
  formId: string
  initialUser?: User
  initialHiddenFields?: HiddenFields
  onSubmit: () => void
}

export function TypeForm(props: TypeFormProps) {
  const { formId, initialUser, initialHiddenFields, onSubmit } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const onSubmitRef = useValueRef(onSubmit)
  const [code, setCode] = useState('')

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      const char = String.fromCharCode(e.keyCode).toLowerCase()
      setCode((currCode) => currCode + char)
    }
    document.addEventListener('keydown', handler)
    return () => document.removeEventListener('keydown', handler)
  }, [])

  useEffect(() => {
    if (code.includes(BYPASS_CODE)) onSubmitRef.current()
  }, [code, onSubmitRef])

  useEffect(() => {
    const containerEl = containerRef.current
    if (!containerEl) return

    const hidden: Record<string, string> = { ...initialHiddenFields }
    if (initialUser) {
      hidden.userid = initialUser.id
      hidden.email = initialUser.email
      const name = getUserFullName(initialUser)
      if (name) hidden.name = name
    }
    createWidget(formId, {
      container: containerEl,
      onSubmit: onSubmitRef.current,
      hidden,
      inlineOnMobile: true,
    })
  }, [formId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
      }}
    />
  )
}
