import { usePromotionCodeLazyQuery } from '@/api'

export const usePromotionCode = (planId: string) => {
  const [execute, { data, ...rest }] = usePromotionCodeLazyQuery()
  const getPromotionCode = async (code: string) => {
    const { data } = await execute({ variables: { input: { code, planId } } })
    return data?.promotionCode
  }
  const promotionCode = data?.promotionCode
  return [getPromotionCode, { promotionCode, ...rest }] as const
}
