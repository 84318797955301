import { useMemo } from 'react'
import {
  MantineProvider,
  MantineProviderProps,
  ColorScheme,
} from '@mantine/core'
import { createTheme, styles, defaultProps } from './theme'

type ThemeProviderProps = Omit<
  MantineProviderProps,
  'theme' | 'styles' | 'defaultProps'
> & {
  colorScheme: ColorScheme
}

export function ThemeProvider(props: ThemeProviderProps) {
  const { colorScheme, children, ...delegated } = props
  const theme = useMemo(() => createTheme(colorScheme), [colorScheme])
  return (
    <MantineProvider
      theme={theme}
      styles={styles}
      defaultProps={defaultProps}
      {...delegated}
    >
      {children}
    </MantineProvider>
  )
}
