import { useState } from 'react'
import { Button, createStyles, Modal, Text } from '@mantine/core'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import {
  PromotionCodeCompleteFragment,
  SubscriptionPlanCompleteFragment,
  SubscriptionStatus,
  UserSubscriptionWithAllAdditionalInfoFragment,
  UserSubscriptionWithScheduledUpdateAndPromotionFragment,
} from '@/api'
import { Callout } from '@/common'
import { useChangeSubscriptionPlan } from './useChangeSubscriptionPlan'
import {
  currencyToDisplay,
  periodToDiplay,
  shouldScheduleUpdate,
  toDemicalString,
} from './helpers'
import { SelectPromotionCode } from './PromotionCode'

dayjs.extend(calendar)

export type ChangeSubscriptionPlanModalProps = {
  opened: boolean
  subscription: UserSubscriptionWithScheduledUpdateAndPromotionFragment
  plan: SubscriptionPlanCompleteFragment
  onChangePlan?: (
    subscription: UserSubscriptionWithAllAdditionalInfoFragment,
  ) => void
  onClose: () => void
}

export function ChangeSubscriptionPlanModal(
  props: ChangeSubscriptionPlanModalProps,
) {
  const { opened, subscription, plan, onChangePlan, onClose } = props
  return (
    <Modal title="Change plan" opened={opened} onClose={onClose}>
      <ModalContent
        subscription={subscription}
        plan={plan}
        onChangePlan={onChangePlan}
        onClose={onClose}
      />
    </Modal>
  )
}

const useStyles = createStyles((theme) => ({
  root: {
    marginTop: 2 * theme.spacing.md,
    '& > * + *': {
      marginTop: theme.spacing.xl,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing.lg,
  },
}))

type ModalContentProps = {
  subscription: UserSubscriptionWithScheduledUpdateAndPromotionFragment
  plan: SubscriptionPlanCompleteFragment
  onChangePlan?: (
    subscription: UserSubscriptionWithAllAdditionalInfoFragment,
  ) => void
  onClose: () => void
}

function ModalContent(props: ModalContentProps) {
  const { subscription, plan, onChangePlan, onClose } = props
  const { classes, theme } = useStyles()
  const [promotionCode, setPromotionCode] =
    useState<PromotionCodeCompleteFragment | null>(null)
  const [changePlan, { loading, error }] = useChangeSubscriptionPlan()
  const isTrialing = subscription.status === SubscriptionStatus.Trialing
  const displayCurrency = currencyToDisplay[plan.currency]
  const decimalAmount = toDemicalString(plan.amount)
  const displayPrice = `${displayCurrency}${decimalAmount}`
  const displayPeriod = periodToDiplay[plan.period]
  const shouldSchedule = shouldScheduleUpdate(subscription, plan)
  const handleChangePlan = async () => {
    try {
      const newSubscription = await changePlan(plan.id, promotionCode?.code)
      if (onChangePlan) {
        onChangePlan(newSubscription)
      }
      // TODO: handle pending intent
      onClose()
    } catch {}
  }
  return (
    <div className={classes.root}>
      <Text size="sm">
        {`You are changing your ${isTrialing ? 'trial' : 'subscription'} to `}
        <span style={{ fontWeight: 600 }}>{plan.name}</span>
        {', billed at '}
        <span style={{ fontWeight: 600 }}>
          {`${displayPrice} ${displayPeriod.slash}`}
        </span>
        {'.'}
      </Text>
      {shouldSchedule && (
        <Callout size="sm" color="yellow">
          {
            "This change won't come into effect until the end of the current billing period"
          }
          {` (${dayjs(subscription.currentPeriodEnd).calendar()}).`}
        </Callout>
      )}
      {isTrialing && (
        <Callout size="sm" color="teal">
          {"You won't be charged until the end of your trial."}
        </Callout>
      )}
      <SelectPromotionCode plan={plan} onPromotionCode={setPromotionCode} />
      <div className={classes.actions}>
        <Button color="blue" loading={loading} onClick={handleChangePlan}>
          Change plan
        </Button>
        <Button
          data-autofocus
          onClick={onClose}
          variant="subtle"
          color={theme.colorScheme === 'dark' ? 'dark' : 'gray'}
        >
          Cancel
        </Button>
      </div>
      {error && (
        <Text color="red" size="sm">
          {error.message}
        </Text>
      )}
    </div>
  )
}
