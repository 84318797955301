import { useUser } from '@/api'
import { useCompleteTour } from './useCompleteTour'

// ! TOUR IDS BELOW 5000 ARE RESERVED FOR THESE TOURS.

// ! THIS ENUM SHOULD ONLY EVER GET LARGER. EVEN IF A TOUR IS REMOVED FROM THE APP,
// ! THE ENUM VALUE SHOULD NOT BE REUSED. TO MAKE THIS EASIER JUST APPEND NEW TOURS
// ! TO THE END OF THE ENUM AND NEVER REMOVE ANY TOURS EVEN IF DEPRECATED.

export enum Tour {
  CustomPromptsIndicator1 = 0,
  CustomPromptsIndicator2 = 1,
  CustomPromptsDialog = 2,
}

export const useTour = (tourId: Tour) => {
  const { completedTours } = useUser()
  const [completeTour, { loading }] = useCompleteTour()
  const isCompleted = completedTours.includes(tourId)
  const complete = () => {
    if (isCompleted || loading) return
    completeTour(tourId)
  }
  return { complete, isCompleted }
}
