import React, { useEffect, useState } from 'react'
import {
  ActionIcon,
  Button,
  createStyles,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core'
import { useClickOutside } from '@mantine/hooks'
import { useSearchParams } from 'react-router-dom'
import {
  IconTag as PromotionIcon,
  IconX as CloseIcon,
} from '@tabler/icons-react'
import {
  PromotionCodeCompleteFragment,
  SubscriptionPlanCompleteFragment,
} from '@/api'
import { getDimmedColor } from '@/styles/helpers'
import { isEsc } from '@/utils/hotkeys'
import { usePromotionCode } from './usePromotionCode'
import {
  getPromotionCodeAmountOffDisplay,
  getPromotionCodeDisplay,
} from './helpers'

const useStyles = createStyles((theme) => ({
  addButton: {
    ...theme.fn.fontStyles(),
    lineHeight: 1.55,
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    color: theme.colors.blue[5],
  },
  lineItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemTextDimmed: {
    fontSize: theme.fontSizes.sm,
    color: getDimmedColor(theme),
  },
}))

export type SelectPromotionCodeProps = {
  plan: SubscriptionPlanCompleteFragment
  onPromotionCode: (promotionCode: PromotionCodeCompleteFragment | null) => void
}

export function SelectPromotionCode(props: SelectPromotionCodeProps) {
  const { plan, onPromotionCode } = props
  const { classes } = useStyles()
  // TODO: This feels like it's breaking encapsulation but I can't think of a better way rn
  const [searchParams, setSearchParams] = useSearchParams()
  const initialCode = searchParams.get('plan-promotion')
  const [showInput, setShowInput] = useState(!!initialCode ? true : false)
  useEffect(() => {
    if (initialCode) {
      // we want to remove it from the URL after it's prepopulated
      // so that the user can remove the code and apply a different one
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete('plan-promotion')
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [initialCode]) // eslint-disable-line react-hooks/exhaustive-deps
  const [promotionCode, setPromotionCode] =
    useState<PromotionCodeCompleteFragment | null>(null)
  const handlePromotionCode = (
    promotionCode: PromotionCodeCompleteFragment,
  ) => {
    setShowInput(false)
    setPromotionCode(promotionCode)
    onPromotionCode(promotionCode)
  }
  const handleReset = () => {
    setShowInput(false)
    setPromotionCode(null)
    onPromotionCode(null)
  }
  if (promotionCode) {
    const promotionCodeDisplay = getPromotionCodeDisplay(promotionCode)
    const amountOffDisplay = getPromotionCodeAmountOffDisplay(
      plan,
      promotionCode,
    )
    return (
      <div>
        <div className={classes.lineItem}>
          <PromotionCodeChip code={promotionCode.code} onDelete={handleReset} />
          <Text className={classes.itemTextDimmed}>{amountOffDisplay}</Text>
        </div>
        <Text ml={4} mt={4} color="dimmed" size="xs" weight={500}>
          {promotionCodeDisplay}
        </Text>
      </div>
    )
  }
  if (!showInput) {
    return (
      <UnstyledButton
        className={classes.addButton}
        onClick={() => setShowInput(true)}
      >
        Add promotion code
      </UnstyledButton>
    )
  }
  return (
    <PromotionCodeInput
      planId={plan.id}
      initialCode={initialCode ?? undefined}
      onClose={() => setShowInput(false)}
      onPromotionCode={handlePromotionCode}
    />
  )
}

const useInputStyles = createStyles({
  rightSection: {
    paddingInlineEnd: 5,
    justifyContent: 'flex-end',
  },
})

export type PromotionCodeInputProps = {
  initialCode?: string
  buttonLabel?: string
  planId: string
  onPromotionCode: (promotionCode: PromotionCodeCompleteFragment) => void
  onClose?: () => void
}

export function PromotionCodeInput(props: PromotionCodeInputProps) {
  const { initialCode, buttonLabel, planId, onPromotionCode, onClose } = props
  const { classes } = useInputStyles()
  const [getPromotionCode, { loading, error }] = usePromotionCode(planId)
  const [code, setCode] = useState(initialCode ?? '')
  const handleClose = () => {
    if (code.length) return
    onClose?.()
  }
  const inputRef = useClickOutside(handleClose)
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isEsc(event)) {
      event.preventDefault()
      onClose?.()
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value.replace(/[^a-z0-9]/gi, '').toUpperCase()
    setCode(code)
  }
  const handleSubmit = (event?: React.FormEvent) => {
    if (event) event.preventDefault()
    if (!code.length) return
    getPromotionCode(code)
      .then((promotionCode) => {
        if (!promotionCode) return
        onPromotionCode(promotionCode)
      })
      .catch(() => {})
  }
  useEffect(() => {
    if (initialCode) {
      handleSubmit()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        ref={inputRef}
        value={code}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleClose}
        placeholder="Enter promotion code"
        autoFocus
        data-autofocus
        classNames={classes}
        rightSectionWidth={96}
        rightSection={
          code.length > 0 && (
            <Button
              loading={loading}
              type="submit"
              variant="subtle"
              radius="sm"
              size="sm"
              compact
            >
              {buttonLabel ?? 'Apply'}
            </Button>
          )
        }
      />
      {error && (
        <Text mt={4} color="red" size="xs">
          {error.message}
        </Text>
      )}
    </form>
  )
}

const useChipStyles = createStyles((theme) => ({
  root: {
    width: 'max-content',
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    flexWrap: 'nowrap',
    borderRadius: theme.radius.md,
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[3],
  },
  icon: {
    marginLeft: 4,
  },
}))

export type PromotionCodeChipProps = {
  code: string
  onDelete?: () => void
}

export function PromotionCodeChip(props: PromotionCodeChipProps) {
  const { code, onDelete } = props
  const { classes } = useChipStyles()
  return (
    <div className={classes.root}>
      <PromotionIcon size={16} className={classes.icon} />
      <Text size="sm" weight={600}>
        {code}
      </Text>
      {onDelete && (
        <ActionIcon size="sm" variant="transparent" onClick={onDelete}>
          <CloseIcon size={16} />
        </ActionIcon>
      )}
    </div>
  )
}
