import { memo, useCallback, useEffect } from 'react'
import { useMantineColorScheme } from '@mantine/core'
import {
  IconWifi as OnlineIcon,
  IconWifiOff as OfflineIcon,
} from '@tabler/icons-react'
import { showNotification } from '@mantine/notifications'
import { useAuth } from '@clerk/clerk-react'
import { useLocation, useRoutes } from 'react-router-dom'
import { routes } from '@/routes'
import { ResourceSubscriptions } from '@/api/ResourceSubscriptions'
import { SummaryRequestSubscriptions } from '@/api/SummaryRequestSubscriptions'
import { BillingSubscriptions } from '@/api/BillingSubscriptions'
import { ProjectSubscriptions } from '@/api/ProjectSubscriptions'
import { AiChatSubscriptions } from '@/api/AiChatSubscriptions'
import { OnlineStatusChangeListener, useOnOnlineStatusChange } from '@/common'

function App() {
  const auth = useAuth()
  const element = useRoutes(routes)
  useNotifyOnlineStatusChange()
  return (
    <>
      {element}
      <BillingSubscriptions skip={!auth.isSignedIn} />
      <ResourceSubscriptions skip={!auth.isSignedIn} />
      <ProjectSubscriptions skip={!auth.isSignedIn} />
      <SummaryRequestSubscriptions skip={!auth.isSignedIn} />
      <AiChatSubscriptions skip={!auth.isSignedIn} />
      <PageAnalytics />
    </>
  )
}

function PageAnalytics() {
  useLocation()
  const { colorScheme } = useMantineColorScheme()
  const url = window.location.href
  useEffect(() => {
    window.analytics.page({ colorScheme })
  }, [url, colorScheme])
  return null
}

const useNotifyOnlineStatusChange = () => {
  useOnOnlineStatusChange(
    useCallback<OnlineStatusChangeListener>((status, prevStatus) => {
      if (status.isOnline && !prevStatus.isOnline) {
        showNotification({
          icon: <OnlineIcon size={18} />,
          message: 'You are back online!',
          color: 'green',
        })
      }
      if (!status.isOnline && prevStatus.isOnline) {
        showNotification({
          icon: <OfflineIcon size={18} />,
          message:
            'Your network is unavailable. Check your data or wifi connection.',
          color: 'red',
        })
      }
    }, []),
  )
}

export default memo(App)
