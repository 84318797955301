import { useState } from 'react'
import { v4 as autoId } from 'uuid'

export const useId = () => {
  const [id] = useState(autoId)
  return id
}

const REFERENCE_TO_ID = new WeakMap<Record<any, any>, string>()

export const useReferentialId = (reference: Record<any, any>) => {
  let id = REFERENCE_TO_ID.get(reference)
  if (id == null) {
    id = autoId()
    REFERENCE_TO_ID.set(reference, id)
  }
  return id
}
