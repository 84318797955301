import {
  createStyles,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
} from '@mantine/core'
import { ForwardedRef, forwardRef } from 'react'
import { MenuItem } from './MenuItem'
import { MenuLabel } from './MenuLabel'

const MENU_PADDING = 4

const useStyles = createStyles((theme) => ({
  body: {
    overflowY: 'auto',
    pointerEvents: 'all',
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    padding: MENU_PADDING,

    '& .mantine-Divider-horizontal': {
      margin: `${theme.spacing.xs / 2}px -${MENU_PADDING}px`,
      borderTopColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.gray[2],
    },
  },
}))

type SimpleMenuProps<TReferenceElement extends HTMLElement> =
  PopperProps<TReferenceElement> & {
    width?: React.CSSProperties['width']
    maxHeight?: React.CSSProperties['maxHeight']
    paperProps?: PaperProps<'div'>
  }

type SimpleMenuComponent = (<
  TReferenceElement extends HTMLElement = HTMLElement,
>(
  props: SimpleMenuProps<TReferenceElement>,
) => React.ReactElement | null) & {
  displayName?: string
} & {
  Item: typeof MenuItem
  Label: typeof MenuLabel
}

// @ts-expect-error
export const SimpleMenu: SimpleMenuComponent = forwardRef(function SimpleMenu(
  props: SimpleMenuProps<HTMLElement>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const {
    width = 250,
    maxHeight = 400,
    children,
    paperProps,
    ...delegatedProps
  } = props
  const {
    shadow = 'md',
    radius = 'sm',
    style: paperStyle,
    ...delegatedPaperProps
  } = paperProps || {}
  const { classes } = useStyles()
  return (
    <Popper {...delegatedProps}>
      <Paper
        className={classes.body}
        shadow={shadow}
        radius={radius}
        role="menu"
        aria-orientation="vertical"
        style={{
          width,
          maxHeight,
          ...paperStyle,
        }}
        {...delegatedPaperProps}
        ref={ref}
      >
        {children}
      </Paper>
    </Popper>
  )
})

SimpleMenu.Item = MenuItem
SimpleMenu.Label = MenuLabel
