import {
  Currency,
  SubscriptionPlanCompleteFragment,
  SubscriptionPlanPeriod,
  SubscriptionPlanTier,
  useSubscriptionPlansQuery,
} from '@/api'

type FilterPlansOptions = {
  requiresAcademic?: boolean
  tiers?: Set<SubscriptionPlanTier>
  period?: SubscriptionPlanPeriod
}

function filterPlans(
  plans: SubscriptionPlanCompleteFragment[],
  options: FilterPlansOptions,
) {
  const { requiresAcademic, tiers, period } = options || {}
  return plans.filter(
    (plan) =>
      (requiresAcademic === undefined ||
        plan.requiresAcademic === requiresAcademic) &&
      (tiers === undefined || tiers.has(plan.tier)) &&
      (period === undefined || period === plan.period),
  )
}

type SubscriptionPlansOptions = FilterPlansOptions & {
  filterInRequest?: boolean
}

export const useSubscriptionPlans = (
  currency: Currency,
  options?: SubscriptionPlansOptions,
) => {
  options = options || {}
  const listOptions = !options.filterInRequest
    ? undefined
    : {
        requiresAcademic: options.requiresAcademic,
        tiers: options.tiers ? Array.from(options.tiers) : undefined,
        periods: options.period ? [options.period] : undefined,
      }
  const extractOptions = options.filterInRequest ? undefined : options
  const { data, ...rest } = useSubscriptionPlansQuery({
    variables: {
      options: {
        currencies: [currency],
        ...listOptions,
      },
    },
  })
  let plans = data?.subscriptionPlans || []
  if (extractOptions) {
    plans = filterPlans(plans, extractOptions)
  }
  plans = [...plans].sort((a, b) => b.amount - a.amount)
  return { plans, ...rest }
}
