import { ApolloCache, Reference, TypePolicies } from '@apollo/client'
import { DatabaseClient } from '@/local-database/client'
import { areRefsEqual, filterRef, hasRef, RefSet } from '@/utils/apollo'
import { Fetch } from '@/utils/types'
import {
  ProjectIdentifiableFragment,
  ApiKeyIdentifiableFragment,
  NoteIdentifiableFragment,
  FolderIdentifiableFragment,
  ResourceIdentifiableFragment,
  ResourceHighlightIdentifiableFragment,
  SummaryRequestCompleteFragment,
  SectionSummaryCompleteFragment,
  StatusType,
  UserSubscriptionIdentifiableFragment,
  ResourceLocatableFragment,
  NoteLocatableFragment,
  FolderLocatableFragment,
  LicenseKeyCompleteFragment,
  AiChatIdentifiableFragment,
  AiChatMessageIdentifiableFragment,
  PaymentMethodIdentifier,
  PromptIdentifiableFragment,
} from './generated'
import { createNlpReadFunction } from './nlp'
import { createDocumentReadFunction } from './document'

export const createTypePolicies = (
  db: DatabaseClient,
  fetch: Fetch,
): TypePolicies => ({
  Query: {
    fields: {
      subscriptionPlan: {
        read: (_, { args, toReference }) => {
          return toReference({
            __typename: 'SubscriptionPlan',
            id: args?.id,
          })
        },
      },
      project: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Project',
            id: args?.id,
          })
        },
      },
      folder: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Folder',
            id: args?.id,
          })
        },
      },
      note: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Note',
            id: args?.id,
          })
        },
      },
      resource: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Resource',
            id: args?.id,
          })
        },
      },
      prompt: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Prompt',
            id: args?.id,
          })
        },
      },
      aiChat: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'AiChat',
            id: args?.id,
          })
        },
      },
      apiKey: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'ApiKey',
            pk: args?.pk,
          })
        },
      },
      activeSummaryRequest: {
        read(_, { args, toReference }) {
          const { resourceId, index } = args || {}
          if (typeof resourceId !== 'string' || !Number.isInteger(index)) {
            return null
          }
          return toReference({
            __typename: 'SummaryRequest',
            resourceId,
            index,
          })
        },
      },
      quickSummaries: {
        keyArgs: ['options', ['sortBy', 'sortDirection']],
        merge(existingList, incomingList) {
          const existingRefs: Reference[] = existingList
            ? [...existingList.items]
            : []
          const existingRefSet = new RefSet(existingRefs)
          const incomingRefs: Reference[] = incomingList.items
          for (const incomingRef of incomingRefs) {
            if (!existingRefSet.has(incomingRef)) {
              existingRefs.push(incomingRef)
            }
          }
          return {
            ...existingList,
            ...incomingList,
            items: existingRefs,
          }
        },
      },
    },
  },
  PublicUser: {
    keyFields: false,
  },
  User: {
    fields: {
      academic: {
        merge: true,
      },
    },
  },
  UserSubscription: {
    fields: {
      additionalInfo: {
        merge: true,
      },
    },
  },
  Resource: {
    fields: {
      nlp: {
        read: createNlpReadFunction(db, fetch),
      },
      document: {
        read: createDocumentReadFunction(db, fetch),
      },
    },
  },
  AiChat: {
    fields: {
      messages: {
        keyArgs: ['options', ['sortDirection']],
        merge(existingList, incomingList) {
          const existingRefs: Reference[] = existingList
            ? [...existingList.items]
            : []
          const existingRefSet = new RefSet(existingRefs)
          const incomingRefs: Reference[] = incomingList.items
          for (const incomingRef of incomingRefs) {
            if (!existingRefSet.has(incomingRef)) {
              existingRefs.push(incomingRef)
            }
          }
          return {
            ...existingList,
            ...incomingList,
            items: existingRefs,
          }
        },
      },
    },
  },
  ApiKey: {
    keyFields: ['pk'],
  },
  Section: {
    keyFields: ['index', 'resourceId'],
  },
  SectionSummary: {
    keyFields: ['index', 'resourceId', 'versionId'],
  },
  SummaryRequest: {
    keyFields: ['index', 'resourceId'],
  },
  Figure: {
    keyFields: ['index', 'resourceId'],
  },
  Summariser: {
    keyFields: ['distinctId'],
  },
})

export function onCreateSubscription<T>(
  cache: ApolloCache<T>,
  subscription: UserSubscriptionIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'User', id: subscription.userId }),
    fields: {
      everSubscribed() {
        return true
      },
      subscription(_, { toReference }) {
        return toReference(subscription)
      },
    },
  })
}

export function onDeleteSubscription<T>(
  cache: ApolloCache<T>,
  subscription: UserSubscriptionIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'User', id: subscription.userId }),
    fields: {
      subscription() {
        return null
      },
    },
  })
  cache.evict({ id: cache.identify(subscription) })
  cache.gc()
}

export function onConsumeLicenseKey<T>(
  cache: ApolloCache<T>,
  licenseKey: LicenseKeyCompleteFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'User', id: licenseKey.userId }),
    fields: {
      everSubscribed() {
        return true
      },
      licenseKey(_, { toReference }) {
        return toReference(licenseKey)
      },
    },
  })
}

export function onCreateApiKey<T>(
  cache: ApolloCache<T>,
  apiKey: ApiKeyIdentifiableFragment,
) {
  cache.modify({
    fields: {
      apiKeys(existingApiKeysList, { toReference }) {
        const existingRefs = existingApiKeysList.items
        const newRef = toReference(apiKey)
        if (
          !newRef ||
          existingRefs.some((existingRef: Reference) =>
            areRefsEqual(existingRef, newRef),
          )
        ) {
          return existingApiKeysList
        }
        return {
          ...existingApiKeysList,
          items: [newRef, ...existingRefs],
        }
      },
    },
  })
}

export function onDeleteApiKey<T>(
  cache: ApolloCache<T>,
  apiKey: ApiKeyIdentifiableFragment,
) {
  cache.modify({
    fields: {
      apiKeys: (existingApiKeysList, { readField }) => ({
        ...existingApiKeysList,
        items: existingApiKeysList.items.filter(
          (existingRef: Reference) =>
            apiKey.pk !== readField('pk', existingRef),
        ),
      }),
    },
  })
}

export function onCreateProject<T>(
  cache: ApolloCache<T>,
  project: ProjectIdentifiableFragment,
) {
  cache.modify({
    fields: {
      projects(existingList, { toReference }) {
        const existingRefs = existingList.items
        const newRef = toReference(project)
        if (
          !newRef ||
          existingRefs.some((existingRef: Reference) =>
            areRefsEqual(existingRef, newRef),
          )
        ) {
          return existingList
        }
        return {
          ...existingList,
          items: [newRef, ...existingRefs],
        }
      },
    },
  })
}

export function onDeleteProject<T>(
  cache: ApolloCache<T>,
  project: ProjectIdentifiableFragment,
) {
  cache.modify({
    fields: {
      projects: (existingList, { readField }) => ({
        ...existingList,
        items: existingList.items.filter(
          (existingRef: Reference) =>
            project.id !== readField<string>('id', existingRef),
        ),
      }),
    },
  })
}

export function onCreateFolder<T>(
  cache: ApolloCache<T>,
  folder: FolderIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Project', id: folder.projectId }),
    fields: {
      allFolders(existingList, { toReference }) {
        const existingRefs = existingList.items
        const newRef = toReference(folder)
        if (
          !newRef ||
          existingRefs.some((existingRef: Reference) =>
            areRefsEqual(existingRef, newRef),
          )
        ) {
          return existingList
        }
        return {
          ...existingList,
          items: [newRef, ...existingRefs],
        }
      },
    },
  })
}

export function onDeleteFolder<T>(
  cache: ApolloCache<T>,
  folder: FolderIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Project', id: folder.projectId }),
    fields: {
      allFolders(existingList, { readField }) {
        return {
          ...existingList,
          items: existingList.items.filter(
            (existingRef: Reference) =>
              folder.id !== readField<string>('id', existingRef),
          ),
        }
      },
    },
  })
}

export function onCreateResource<T>(
  cache: ApolloCache<T>,
  resource: ResourceIdentifiableFragment,
) {
  if (resource.projectId) {
    cache.modify({
      id: cache.identify({ __typename: 'Project', id: resource.projectId }),
      fields: {
        allResources(existingList, { toReference }) {
          const existingRefs: Reference[] = existingList.items
          const newRef = toReference(resource)
          if (!newRef || hasRef(existingRefs, newRef)) {
            return existingList
          }
          return {
            ...existingList,
            items: [newRef, ...existingRefs],
          }
        },
      },
    })
  } else {
    cache.modify({
      fields: {
        quickSummaries(existingList, { toReference }) {
          const existingRefs: Reference[] = existingList.items
          const newRef = toReference(resource)
          if (!newRef || hasRef(existingRefs, newRef)) {
            return existingList
          }
          return {
            ...existingList,
            items: [newRef, ...existingRefs],
          }
        },
      },
    })
  }
}

export function onSetResourceProject<T>(
  cache: ApolloCache<T>,
  resource: ResourceIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Project', id: resource.projectId }),
    fields: {
      allResources(existingList, { toReference }) {
        const existingRefs = existingList.items
        const newRef = toReference(resource)
        if (!newRef || hasRef(existingRefs, newRef)) {
          return existingList
        }
        return {
          ...existingList,
          items: [newRef, ...existingRefs],
        }
      },
    },
  })
  // Not removing from quickSummaries because the quick summaries history
  // also includes resources that have been added to a project
}

export function onDeleteResource<T>(
  cache: ApolloCache<T>,
  resource: ResourceIdentifiableFragment,
) {
  if (resource.projectId) {
    cache.modify({
      id: cache.identify({ __typename: 'Project', id: resource.projectId }),
      fields: {
        allResources(existingList, { toReference }) {
          const existingRefs = existingList.items
          const newRef = toReference(resource)
          if (!newRef) return existingList
          return {
            ...existingList,
            items: filterRef(existingRefs, newRef),
          }
        },
      },
    })
  }
  cache.modify({
    fields: {
      quickSummaries(existingList, { toReference }) {
        const existingRefs = existingList.items
        const newRef = toReference(resource)
        if (!newRef) return existingList
        return {
          ...existingList,
          items: filterRef(existingRefs, newRef),
        }
      },
    },
  })
}

export function onCreateNote<T>(
  cache: ApolloCache<T>,
  note: NoteIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Project', id: note.projectId }),
    fields: {
      allNotes(existingList, { toReference }) {
        const existingRefs = existingList.items
        const newRef = toReference(note)
        if (
          !newRef ||
          existingRefs.some((existingRef: Reference) =>
            areRefsEqual(existingRef, newRef),
          )
        ) {
          return existingList
        }
        return {
          ...existingList,
          items: [newRef, ...existingRefs],
        }
      },
    },
  })
}

export function onDeleteNote<T>(
  cache: ApolloCache<T>,
  note: NoteIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Project', id: note.projectId }),
    fields: {
      allNotes(existingList, { readField }) {
        return {
          ...existingList,
          items: existingList.items.filter(
            (existingRef: Reference) =>
              note.id !== readField<string>('id', existingRef),
          ),
        }
      },
    },
  })
}

export function onCreateAiChat<T>(
  cache: ApolloCache<T>,
  aiChat: AiChatIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Project', id: aiChat.projectId }),
    fields: {
      allAiChats(existingList, { toReference }) {
        const existingRefs: Reference[] = existingList.items
        const newRef = toReference(aiChat)
        if (!newRef || hasRef(existingRefs, newRef)) {
          return existingList
        }
        return {
          ...existingList,
          items: [newRef, ...existingRefs],
        }
      },
    },
  })
}

export function onDeleteAiChat<T>(
  cache: ApolloCache<T>,
  aiChat: AiChatIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Project', id: aiChat.projectId }),
    fields: {
      allAiChats(existingList, { toReference }) {
        const existingRefs: Reference[] = existingList.items
        const deletedRef = toReference(aiChat)
        if (!deletedRef) {
          return existingList
        }
        return {
          ...existingList,
          items: filterRef(existingRefs, deletedRef),
        }
      },
    },
  })
}

export function onCreateAiChatMessage<T>(
  cache: ApolloCache<T>,
  message: AiChatMessageIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'AiChat', id: message.chatId }),
    fields: {
      messages(existingList, { toReference }) {
        const existingRefs: Reference[] = existingList.items
        const newRef = toReference(message)
        if (!newRef || hasRef(existingRefs, newRef)) {
          return existingList
        }
        return {
          ...existingList,
          items: [newRef, ...existingRefs],
        }
      },
    },
  })
}

export function onDeleteAiChatMessage<T>(
  cache: ApolloCache<T>,
  message: AiChatMessageIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'AiChat', id: message.chatId }),
    fields: {
      messages(existingList, { toReference }) {
        const existingRefs: Reference[] = existingList.items
        const deletedRef = toReference(message)
        if (!deletedRef) {
          return existingList
        }
        return {
          ...existingList,
          items: filterRef(existingRefs, deletedRef),
        }
      },
    },
  })
}

export function onCreateResourceHighlight<T>(
  cache: ApolloCache<T>,
  highlight: ResourceHighlightIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Resource', id: highlight.resourceId }),
    fields: {
      highlights(existingHighlights: Reference[] | undefined, { toReference }) {
        const existingRefs = existingHighlights || []
        const newRef = toReference(highlight)
        if (
          !newRef ||
          existingRefs.some((existingRef) => areRefsEqual(existingRef, newRef))
        ) {
          return existingRefs
        }
        return [newRef, ...existingRefs]
      },
    },
  })
}

export function onDeleteResourceHighlight<T>(
  cache: ApolloCache<T>,
  highlight: ResourceHighlightIdentifiableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Resource', id: highlight.resourceId }),
    fields: {
      highlights(existingHighlights: Reference[] | undefined, { readField }) {
        if (!existingHighlights) return existingHighlights
        return existingHighlights.filter(
          (existingRef) =>
            highlight.id !== readField<string>('id', existingRef),
        )
      },
    },
  })
}

export function onSummaryRequestStatusChange<T>(
  cache: ApolloCache<T>,
  summaryRequest: SummaryRequestCompleteFragment,
) {
  const { sectionSummary } = summaryRequest
  if (summaryRequest.status === StatusType.Completed && sectionSummary) {
    cache.modify({
      id: cache.identify({
        __typename: 'Section',
        resourceId: summaryRequest.resourceId,
        index: summaryRequest.index,
      }),
      fields: {
        latestSummaryVersionId() {
          return sectionSummary.versionId
        },
        latestSectionSummary(_, { toReference }) {
          return toReference(sectionSummary)
        },
      },
    })
  }
}

export function onRestoreSectionSummary<T>(
  cache: ApolloCache<T>,
  sectionSummary: SectionSummaryCompleteFragment,
) {
  cache.modify({
    id: cache.identify({
      __typename: 'Section',
      resourceId: sectionSummary.resourceId,
      index: sectionSummary.index,
    }),
    fields: {
      latestSummaryVersionId() {
        return sectionSummary.versionId
      },
      latestSectionSummary(_, { toReference }) {
        return toReference(sectionSummary)
      },
    },
  })
}

export function onMoveResource<T>(
  cache: ApolloCache<T>,
  resource: ResourceLocatableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Project', id: resource.projectId }),
    fields: {
      allResources(existingList, { toReference }) {
        const existingRefs = existingList.items
        const newRef = toReference(resource)
        if (!newRef) return existingList
        const newRefs = [newRef, ...filterRef(existingRefs, newRef)]
        return {
          ...existingList,
          items: newRefs,
        }
      },
    },
  })
}

export function onMoveNote<T>(
  cache: ApolloCache<T>,
  note: NoteLocatableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Project', id: note.projectId }),
    fields: {
      allNotes(existingList, { toReference }) {
        const existingRefs = existingList.items
        const newRef = toReference(note)
        if (!newRef) return existingList
        const newRefs = [newRef, ...filterRef(existingRefs, newRef)]
        return {
          ...existingList,
          items: newRefs,
        }
      },
    },
  })
}

export function onMoveFolder<T>(
  cache: ApolloCache<T>,
  folder: FolderLocatableFragment,
) {
  cache.modify({
    id: cache.identify({ __typename: 'Project', id: folder.projectId }),
    fields: {
      allFolders(existingList, { toReference }) {
        const existingRefs = existingList.items
        const newRef = toReference(folder)
        if (!newRef) return existingList
        const newRefs = [newRef, ...filterRef(existingRefs, newRef)]
        return {
          ...existingList,
          items: newRefs,
        }
      },
    },
  })
}

export function onCreatePrompt<T>(
  cache: ApolloCache<T>,
  prompt: PromptIdentifiableFragment,
) {
  cache.modify({
    fields: {
      prompts(existingList, { toReference }) {
        const existingRefs: Reference[] = existingList.items
        const newRef = toReference(prompt)
        if (!newRef || hasRef(existingRefs, newRef)) {
          return existingList
        }
        return {
          ...existingList,
          items: [newRef, ...existingRefs],
        }
      },
    },
  })
}

export function onDeletePrompt<T>(
  cache: ApolloCache<T>,
  prompt: PromptIdentifiableFragment,
) {
  cache.modify({
    fields: {
      prompts: (existingList, { readField }) => ({
        ...existingList,
        items: existingList.items.filter(
          (existingRef: Reference) =>
            prompt.id !== readField<string>('id', existingRef),
        ),
      }),
    },
  })
}

export function onDeletePaymentMethod<T>(
  cache: ApolloCache<T>,
  paymentMethod: PaymentMethodIdentifier,
) {
  cache.modify({
    id: cache.identify({ __typename: 'User', id: paymentMethod.ownerId }),
    fields: {
      paymentMethods(existingList, { readField }) {
        return {
          ...existingList.filter(
            (existingRef: Reference) =>
              paymentMethod.id !== readField<string>('id', existingRef),
          ),
        }
      },
    },
  })
}

export function onSetDefaultPaymentMethod<T>(
  cache: ApolloCache<T>,
  paymentMethod: PaymentMethodIdentifier,
) {
  cache.modify({
    id: cache.identify({ __typename: 'User', id: paymentMethod.ownerId }),
    fields: {
      defaultPaymentMethodId() {
        return paymentMethod.id
      },
    },
  })
}
