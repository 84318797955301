import React from 'react'

function withRestoreSelection<T>(fn: () => T): T {
  const selection = window.getSelection()
  const savedRanges: Range[] = []
  if (selection) {
    for (let i = 0; i < selection.rangeCount; i++) {
      savedRanges.push(selection.getRangeAt(i))
    }
  }
  try {
    return fn()
  } finally {
    const selection = window.getSelection()
    if (selection) {
      selection.removeAllRanges()
      for (const range of savedRanges) {
        selection.addRange(range)
      }
    }
  }
}

// TODO: this will likely stop working at some point in the future
export const copyToClipboardWithCustomData = (
  text: string,
  ...customData: { format: string; data: string }[]
): boolean => {
  return withRestoreSelection(() => {
    let isCopied = false
    const eventListener = (event: ClipboardEvent) => {
      event.preventDefault()
      const dataTransfer = event.clipboardData
      if (!dataTransfer) {
        return
      }
      dataTransfer.setData('text/plain', text)
      for (const { format, data } of customData) {
        dataTransfer.setData(format, data)
      }
      isCopied = true
    }

    const textArea = document.createElement('textarea')
    textArea.value = text || ' '
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    document.addEventListener('copy', eventListener)

    try {
      document.execCommand('copy')
      return isCopied
    } catch (error) {
      console.error(error)
      return false
    } finally {
      document.removeEventListener('copy', eventListener)
      document.body.removeChild(textArea)
    }
  })
}

export const copyToClipboard = async (text: string) => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text)
      return true
    } catch (error) {
      return false
    }
  } else {
    const textArea = document.createElement('textarea')
    textArea.value = text
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand('copy')
      return true
    } catch (error) {
      console.error(error)
      return false
    } finally {
      document.body.removeChild(textArea)
    }
  }
}

export const css = (styleString: string): React.CSSProperties => {
  const styleObject: React.CSSProperties = {}
  const styles = styleString
    .split(';')
    .map((style) => style.trim())
    .filter((style) => !!style.length)
  for (const style of styles) {
    let [prop, value] = style.split(':')
    prop = prop.trim()
    value = value.trim()
    if (!prop || !value) continue
    const camelCaseProp = prop.replace(/-./g, (x) => x[1].toUpperCase())
    ;(styleObject as Record<string, any>)[camelCaseProp] = value
  }
  return styleObject
}

export const joinUrlWithSourceUrl = (url: string, sourceUrl?: string) => {
  if (!sourceUrl) return url
  try {
    return new URL(url, sourceUrl).href
  } catch (error) {
    console.error(error)
    return url
  }
}

// Bit random
export const IS_MAC =
  typeof window != 'undefined' &&
  /Mac|iPod|iPhone|iPad/.test(window.navigator.platform)

export function getCookie(name: string) {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
  if (match) return match[2]
  return undefined
}

export function getCurrentBaseUrl() {
  const url = new URL(window.location.href)
  return `${url.protocol}//${url.host}`
}

export function getCurrentFullPath() {
  const url = new URL(window.location.href)
  return url.pathname + url.search + url.hash
}
