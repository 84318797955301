import { useUncancelSubscriptionMutation } from '@/api'

export const useUncancelSubscription = () => {
  const [execute, result] = useUncancelSubscriptionMutation()
  const uncancelSubscription = async () => {
    const { data } = await execute()
    return data?.uncancelSubscription
  }
  return [uncancelSubscription, result] as const
}
