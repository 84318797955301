import { memo } from 'react'
import { Global } from '@mantine/core'
import { GET_SELECTION_BACKGROUND_COLOR } from './constants'
import { getLineColor } from './helpers'

export const GlobalStyles = memo(function GlobalStyles() {
  return (
    <Global
      styles={(theme) => ({
        // This exists because PDFTron injects a weirdly positioned
        // iframe that makes the body overflow
        body: {
          overflow: 'hidden',
          height: '100vh',
          maxHeight: '100vh',
          '& > iframe': {
            display: 'none',
          },
        },
        // TODO: remove !important once exposed classes after internal ones:
        // https://github.com/johnwalley/allotment/issues/366
        '.split-view-view:not(:first-of-type)::before': {
          zIndex: '70 !important',
        },
        // TODO: remove !important once exposed classes after internal ones:
        // https://github.com/johnwalley/allotment/issues/366
        '.sash': {
          zIndex: '80 !important',
        },
        ':root': {
          '--separator-border': getLineColor(theme),
          '--focus-border': theme.colors.blue[6],
          colorScheme: theme.colorScheme,
        },
        '*::selection': {
          backgroundColor: GET_SELECTION_BACKGROUND_COLOR(),
        },
      })}
    />
  )
})
