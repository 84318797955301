import { useEffect } from 'react'
import { Button, createStyles, Modal, Text } from '@mantine/core'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {
  SubscriptionStatus,
  UserSubscriptionWithScheduledUpdateFragment,
} from '@/api'
import { Callout } from '@/common'
import { useUncancelSubscription } from './useUncancelSubscription'
import { tierToDisplay } from './helpers'

dayjs.extend(relativeTime)

export type UncancelSubscriptionModalProps = {
  opened: boolean
  subscription: UserSubscriptionWithScheduledUpdateFragment
  onClose: () => void
}

export function UncancelSubscriptionModal(
  props: UncancelSubscriptionModalProps,
) {
  const { opened, subscription, onClose } = props
  useEffect(() => {
    if (!subscription.cancelAtPeriodEnd) onClose()
  }, [subscription, onClose])
  const isTrialing = subscription.status === SubscriptionStatus.Trialing
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={isTrialing ? 'Continue trial' : 'Continue subscription'}
    >
      <ModalContent subscription={subscription} onClose={onClose} />
    </Modal>
  )
}

const useStyles = createStyles((theme) => ({
  root: {
    marginTop: 2 * theme.spacing.md,
    '& > * + *': {
      marginTop: theme.spacing.xl,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing.lg,
  },
}))

type ModalContentProps = {
  subscription: UserSubscriptionWithScheduledUpdateFragment
  onClose: () => void
}

function ModalContent(props: ModalContentProps) {
  const { subscription, onClose } = props
  const { classes, theme } = useStyles()
  const [uncancelSubscription, { loading, error }] = useUncancelSubscription()
  const isTrialing = subscription.status === SubscriptionStatus.Trialing
  const displayTier = tierToDisplay[subscription.plan.tier]
  return (
    <div className={classes.root}>
      <Text size="sm">
        {`Resubscribe to ensure your Genei ${displayTier} ${
          isTrialing ? 'trial' : 'subscription'
        }`}
        {' continues past the end of the current billing period.'}
      </Text>
      <Callout size="sm" color="red">
        {`Your ${isTrialing ? 'trial' : 'subscription'} is set to end`}
        {` ${dayjs(subscription.currentPeriodEnd).fromNow()}.`}
      </Callout>
      <div className={classes.actions}>
        <Button
          data-autofocus
          color="teal"
          loading={loading}
          onClick={uncancelSubscription}
        >
          Resubscribe
        </Button>
        <Button
          onClick={onClose}
          variant="subtle"
          color={theme.colorScheme === 'dark' ? 'dark' : 'gray'}
        >
          Cancel
        </Button>
      </div>
      {error && (
        <Text color="red" size="sm">
          {error.message}
        </Text>
      )}
    </div>
  )
}
