import { forwardRef } from 'react'
import {
  Box,
  createStyles,
  DefaultProps,
  MantineNumberSize,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '@mantine/core'
import { getLineColor } from '@/styles/helpers'

interface PaperStyles {
  radius?: MantineNumberSize
  padding: MantineNumberSize
  withBorder: boolean
}

const useStyles = createStyles(
  (theme, { radius, padding, withBorder }: PaperStyles) => ({
    root: {
      ...theme.fn.focusStyles(),
      WebkitTapHighlightColor: 'transparent',
      display: 'block',
      textDecoration: 'none',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
      boxSizing: 'border-box',
      borderRadius: radius
        ? theme.fn.size({ size: radius, sizes: theme.radius })
        : 'unset',
      padding: theme.fn.size({ size: padding, sizes: theme.spacing }),
      border: withBorder ? `1px solid ${getLineColor(theme)}` : undefined,
    },
  }),
)

export interface SharedPaperProps extends DefaultProps {
  /** Predefined padding value from theme.spacing or number for padding in px */
  padding?: MantineNumberSize
  /** Predefined border-radius value from theme.radius or number for border-radius in px */
  radius?: MantineNumberSize
  /** Adds 1px border with theme.colors.gray[2] color in light color scheme and theme.colors.dark[6] in dark color scheme */
  withBorder?: boolean
}

export type PaperProps<C> = PolymorphicComponentProps<C, SharedPaperProps>
type PaperComponent = (<C = 'div'>(
  props: PaperProps<C>,
) => React.ReactElement | null) & {
  displayName?: string
}

export const Paper: PaperComponent = forwardRef(function Paper(
  props: PaperProps<'div'>,
  ref: PolymorphicRef<'div'>,
) {
  const {
    component,
    className,
    children,
    padding = 0,
    radius,
    withBorder = false,
    ...others
  } = props
  const { classes, cx } = useStyles(
    { radius, padding, withBorder },
    { name: 'GeneiPaper' },
  )

  return (
    <Box<any>
      component={component || 'div'}
      className={cx(classes.root, className)}
      ref={ref}
      {...others}
    >
      {children}
    </Box>
  )
}) as any

Paper.displayName = '@genei/Paper'
