import { useRef, useCallback, useContext, createContext } from 'react'
import { ApolloProvider as BaseApolloProvider } from '@apollo/client'
import { ErrorResponse } from '@apollo/client/link/error'
import { useSession } from '@clerk/clerk-react'
import { useMemoOne } from 'use-memo-one'
import { useValueRef } from '@/common/hooks'
import { DatabaseClient } from '@/local-database/client'
import { Fetch } from '@/utils/types'
import { ClientFactory } from './client'

type ErrorHandler = (response: ErrorResponse) => void

type GlobalErrorListenerContextValue = (handler: ErrorHandler) => () => void

const GlobalErrorListenerContext = createContext<
  GlobalErrorListenerContextValue | undefined
>(undefined)

export type ApolloProviderProps = React.PropsWithChildren<{
  graphqlEndpoint: string
  db: DatabaseClient
  fetch: Fetch
}>

export function ApolloProvider(props: ApolloProviderProps) {
  const { graphqlEndpoint, db, fetch, children } = props
  const { session } = useSession()
  const sessionRef = useValueRef(session)

  const registeredErrorListeners = useRef(new Set<ErrorHandler>())

  const registerErrorListener = useCallback((handler: ErrorHandler) => {
    registeredErrorListeners.current.add(handler)
    return () => registeredErrorListeners.current.delete(handler)
  }, [])

  const client = useMemoOne(() => {
    const onError = (response: ErrorResponse) => {
      for (const handler of registeredErrorListeners.current) {
        handler(response)
      }
    }
    return new ClientFactory(
      graphqlEndpoint,
      db,
      fetch,
      onError,
    ).createFromSessionRef(sessionRef)
  }, [graphqlEndpoint, db, fetch, sessionRef])

  return (
    <BaseApolloProvider client={client}>
      <GlobalErrorListenerContext.Provider value={registerErrorListener}>
        {children}
      </GlobalErrorListenerContext.Provider>
    </BaseApolloProvider>
  )
}

export const useRegisterGlobalErrorListener = () => {
  const context = useContext(GlobalErrorListenerContext)
  if (context === undefined) {
    throw new Error(
      `The \`useRegisterGlobalErrorListener\` hook must be used inside the <ApolloProvider> component's context`,
    )
  }
  return context
}
