import { useRef, useEffect, EffectCallback, DependencyList } from 'react'

export const useUpdateEffect = (fn: EffectCallback, deps?: DependencyList) => {
  const mounted = useRef(false)
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      return fn()
    }
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}
