import React, { useCallback, useRef, useState } from 'react'

type ImmutableRefObject<S> = {
  readonly current: S
}

export const useStateWithRef = <S>(initializer: S | (() => S)) => {
  const [state, _setState] = useState(initializer)
  const ref = useRef<S>(state)
  const setState = useCallback<typeof _setState>((action) => {
    _setState((prevState) => {
      const newState =
        typeof action === 'function'
          ? (action as (s: S) => S)(prevState)
          : action
      ref.current = newState
      return newState
    })
  }, [])
  return [state, ref, setState] as [
    S,
    ImmutableRefObject<S>,
    React.Dispatch<React.SetStateAction<S>>,
  ]
}
