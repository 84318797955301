import {
  DefaultMantineColor,
  MantineProviderProps,
  MantineThemeOverride,
  Tuple,
} from '@mantine/core'
import { typographicScale } from './typography'

type CustomColors = DefaultMantineColor | 'blue' | 'lime'

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<CustomColors, Tuple<string, 10>>
  }
}

const [xs, sm, md, lg, xl] = typographicScale.getScale(12, 5)

export const createTheme = (
  colorScheme: 'dark' | 'light',
): MantineThemeOverride => ({
  colorScheme,
  black: '#1e2124',
  fontFamily:
    'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  fontFamilyMonospace:
    'Source Code Pro, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace',
  headings: {
    fontFamily:
      'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  },
  fontSizes: { xs, sm, md, lg, xl },
  colors: {
    blue: [
      '#e1ecf6',
      '#c3daee',
      '#a4c7e5',
      '#86b4dd',
      '#68a2d4',
      '#4a8fcc',
      '#357cba',
      '#2c689c',
      '#23547e',
      '#1b3f5f',
    ],
    lime: [
      '#e5fff4',
      '#ccffe8',
      '#b3ffdd',
      '#99ffd1',
      '#80ffc6',
      '#66ffba',
      '#4dffaf',
      '#33ffa3',
      '#1aff98',
      '#00ff8c',
    ],
  },
  primaryColor: colorScheme === 'dark' ? 'indigo' : 'blue',
})

export const styles: MantineProviderProps['styles'] = {
  TextInput: (theme) => ({
    label: {
      fontSize: theme.fontSizes.xs,
    },
    error: {
      fontSize: theme.fontSizes.xs,
    },
  }),
  NumberInput: (theme) => ({
    label: {
      fontSize: theme.fontSizes.xs,
    },
    error: {
      fontSize: theme.fontSizes.xs,
    },
  }),
  DatePicker: (theme) => ({
    label: {
      fontSize: theme.fontSizes.xs,
    },
    error: {
      fontSize: theme.fontSizes.xs,
    },
  }),
  InputWrapper: (theme) => ({
    label: {
      fontSize: theme.fontSizes.xs,
    },
    description: {
      fontSize: theme.fontSizes.xs,
    },
    error: {
      fontSize: theme.fontSizes.xs,
    },
  }),
  Select: (theme) => ({
    label: {
      fontSize: theme.fontSizes.xs,
    },
    error: {
      fontSize: theme.fontSizes.xs,
    },
  }),
  Menu: (theme) => ({
    item: {
      ...theme.fn.focusStyles(),
    },
  }),
  Tooltip: (theme) => ({
    body: {
      fontSize: theme.fontSizes.xs,
    },
  }),
}

export const defaultProps: MantineProviderProps['defaultProps'] = {
  TextInput: {
    radius: 'md',
  },
  NumberInput: {
    radius: 'md',
  },
  DatePicker: {
    radius: 'md',
  },
  Input: {
    radius: 'md',
  },
  Select: {
    radius: 'md',
  },
  Button: {
    radius: 'md',
  },
  Modal: {
    radius: 'lg',
  },
  Tooltip: {
    placement: 'start',
    openDelay: 500,
  },
}
