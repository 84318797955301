import { Link } from 'react-router-dom'
import {
  ActionIcon,
  createStyles,
  Divider,
  Stack,
  useMantineColorScheme,
} from '@mantine/core'
import {
  IconHome as HomeIcon,
  IconUser as AccountIcon,
  IconHelp as HelpIcon,
  IconMessageCircle as ChatIcon,
  IconSun as LightModeIcon,
  IconMoonStars as DarkModeIcon,
} from '@tabler/icons-react'
import { Paper } from '@/common'
import { getLineColor } from '@/styles/helpers'
import ActiveProjects from './ActiveProjects'
import { track } from '@/analytics'

const useStyles = createStyles((theme) => ({
  root: {
    height: '100%',
    borderRight: `1px solid ${getLineColor(theme)}`,
  },
  stack: {
    height: '100%',
    paddingBlock: theme.spacing.xs,
    '& > *': {
      marginInline: theme.spacing.xs,
    },
  },
  divider: {
    width: '100%',
    padding: 0,
  },
  projectsWrapper: {
    flex: '1 0 0',
    height: 0,
    overflowY: 'auto',
  },
}))

export function NavBar() {
  const { classes, theme } = useStyles()
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  return (
    <Paper className={classes.root}>
      <Stack align="center" spacing="xs" className={classes.stack}>
        <ActionIcon
          title="Go to dashboard"
          color={theme.primaryColor}
          variant="filled"
          component={Link}
          to="/dashboard"
        >
          <HomeIcon size={20} />
        </ActionIcon>
        <Divider className={classes.divider} color={getLineColor(theme)} />
        <div className={classes.projectsWrapper}>
          <ActiveProjects />
        </div>
        <Divider className={classes.divider} color={getLineColor(theme)} />
        <ActionIcon
          title="Go to account"
          component={Link}
          to="/account"
          color={theme.primaryColor}
          variant="light"
        >
          <AccountIcon size={20} />
        </ActionIcon>
        <ActionIcon
          title="Guides and releases"
          component="a"
          href="https://genei.notion.site/Guides-Releases-5a4b6c7b55974a80a560991e991ca2a1"
          target="_blank"
          rel="noopener noreferrer"
          color={theme.primaryColor}
          variant="light"
        >
          <HelpIcon size={20} />
        </ActionIcon>
        <ActionIcon
          id="custom-intercom-button"
          title="Talk to us and get help with Genei"
          color={theme.primaryColor}
          variant="light"
          style={{ display: 'none' }}
        >
          <ChatIcon size={20} />
        </ActionIcon>
        <ActionIcon
          title="Toggle color scheme"
          onClick={() => {
            toggleColorScheme()
            track({
              eventName:
                colorScheme === 'light'
                  ? 'Trigger Dark Mode'
                  : 'Trigger Light Mode',
            })
          }}
          color={colorScheme === 'dark' ? 'yellow' : 'indigo'}
          variant="filled"
        >
          {colorScheme === 'dark' ? (
            <LightModeIcon size={18} />
          ) : (
            <DarkModeIcon size={18} />
          )}
        </ActionIcon>
      </Stack>
    </Paper>
  )
}

export default NavBar
