import { useEffect } from 'react'
import {
  ApolloCache,
  ApolloError,
  FieldReadFunction,
  gql,
  useApolloClient,
} from '@apollo/client'
import { Fetch, PartialRecord } from '@/utils/types'
import { DatabaseClient } from '@/local-database/client'
import * as generated from './generated'
import { Document } from './types'

type ReferenceCounts = PartialRecord<string, number>

const DOCUMENT_REFERENCES = new WeakMap<ApolloCache<any>, ReferenceCounts>()

const DocumentReferences = {
  get(cache: ApolloCache<any>, resourceId: string) {
    const counts = DOCUMENT_REFERENCES.get(cache) || {}
    return counts[resourceId] || 0
  },

  increment(cache: ApolloCache<any>, resourceId: string) {
    const counts = DOCUMENT_REFERENCES.get(cache) || {}
    const resourceCount = (counts[resourceId] || 0) + 1
    counts[resourceId] = resourceCount
    DOCUMENT_REFERENCES.set(cache, counts)
  },

  decrement(cache: ApolloCache<any>, resourceId: string) {
    const counts = DOCUMENT_REFERENCES.get(cache) || {}
    const resourceCount = (counts[resourceId] || 0) - 1
    if (resourceCount <= 0) {
      delete counts[resourceId]
    } else {
      counts[resourceId] = resourceCount
    }
    DOCUMENT_REFERENCES.set(cache, counts)
  },
}

export const useResourceDocumentQuery: typeof generated.useResourceDocumentQuery =
  (options) => {
    const id = options.variables?.id
    const client = useApolloClient()
    const result = generated.useResourceDocumentQuery(options)
    useEffect(() => {
      if (id == null) return undefined
      // when mounting / id changes we increment the ref count for the new id
      DocumentReferences.increment(client.cache, id)
      return () => {
        // when unmounting / id changes we decrement the ref count for the old id
        DocumentReferences.decrement(client.cache, id)
        // if the ref count for the old id is 0 then we evict document from the cache
        if (DocumentReferences.get(client.cache, id) === 0) {
          client.cache.evict({
            id: client.cache.identify({ __typename: 'Resource', id }),
            fieldName: 'document',
          })
        }
      }
    }, [id, client.cache])
    return result
  }

const DocumentFragment = gql`
  fragment DocumentFragment on Resource {
    document @client
  }
`

export function createDocumentReadFunction(db: DatabaseClient, fetch: Fetch) {
  const read: FieldReadFunction = (existing, { readField, cache, storage }) => {
    const __typename = readField<string>('__typename')
    const id = readField<string>('id')
    const type = readField<generated.ResourceType>('type')
    const hasDocument = readField<boolean>('hasDocument')
    const documentUrl = readField<string>('documentUrl')
    if (!__typename || !id || !type || !hasDocument || !documentUrl) return null
    // have to check reference count here because for some reason
    // the read function is called after cache.evict()
    // have to check storage.isFetching in case read function gets called multiple times
    // before we get a response
    if (!existing && DocumentReferences.get(cache, id) && !storage.isFetching) {
      ;(async () => {
        storage.isFetching = true
        try {
          let blob: Blob
          const cachedDocumentEntry = await db.document.get(id)
          if (cachedDocumentEntry) {
            blob = cachedDocumentEntry.data
          } else {
            const response = await fetch(documentUrl)
            if (!response.ok) {
              throw new Error(response.status.toString(10))
            }
            blob = await response.blob()
            // not awaiting on purpose
            db.document.create({ id, data: blob })
          }
          let document: Blob | Document
          switch (type) {
            case generated.ResourceType.Pdf:
              document = blob
              break
            case generated.ResourceType.Web:
              document = JSON.parse(await blob.text()) as Document
              break
            default:
              throw new Error(`Invalid resource type ${type}`)
          }
          // return early if the reference count is zero by this point
          if (!DocumentReferences.get(cache, id)) return
          cache.writeFragment({
            id: cache.identify({ __typename, id }),
            fragment: DocumentFragment,
            data: { document },
          })
        } catch (error) {
          // return early if the reference count is zero by this point
          if (!DocumentReferences.get(cache, id)) return
          cache.writeFragment({
            id: cache.identify({ __typename, id }),
            fragment: DocumentFragment,
            data: {
              document: new ApolloError({
                errorMessage: 'Failed to load document',
              }),
            },
          })
        }
        storage.isFetching = false
      })()
    }
    return existing
  }
  return read
}
