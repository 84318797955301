import { forwardRef } from 'react'
import { createStyles, MantineNumberSize } from '@mantine/core'
import { Expand } from '@/utils/types'

type StyleParams = {
  horizontal: 'left' | 'right'
  vertical: 'top' | 'bottom'
  horizontalOffset: MantineNumberSize
  verticalOffset: MantineNumberSize
}

const useStyles = createStyles((theme, params: StyleParams) => ({
  anchor: {
    position: 'relative',
  },
  wrapper: {
    position: 'absolute',
    zIndex: 1,
    [params.horizontal === 'left' ? 'left' : 'right']:
      typeof params.horizontalOffset === 'number'
        ? params.horizontalOffset
        : theme.spacing[params.horizontalOffset],
    [params.vertical === 'top' ? 'bottom' : 'top']:
      typeof params.verticalOffset === 'number'
        ? params.verticalOffset
        : theme.spacing[params.verticalOffset],
  },
}))

export type NewChipPopoverProps = Expand<
  React.PropsWithChildren<
    {
      opened: boolean
      horizontal?: 'left' | 'right'
      vertical?: 'top' | 'bottom'
      horizontalOffset?: MantineNumberSize
      verticalOffset?: MantineNumberSize
    } & React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  >
>

export const NewChipPopover = forwardRef(function NewChipPopover(
  props: NewChipPopoverProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    opened,
    children,
    vertical = 'top',
    horizontal = 'left',
    verticalOffset = 0,
    horizontalOffset = 0,
    ...rest
  } = props
  const { classes } = useStyles({
    vertical,
    horizontal,
    verticalOffset,
    horizontalOffset,
  })
  return (
    <div {...rest} ref={ref}>
      <div className={classes.anchor}>
        {!opened ? null : (
          <div className={classes.wrapper}>
            <NewChip />
          </div>
        )}
      </div>
      {children}
    </div>
  )
})

const useChipStyles = createStyles((theme) => ({
  chip: {
    color: theme.white,
    backgroundColor: theme.colors.red[theme.colorScheme === 'dark' ? 7 : 5],
    paddingInline: 4,
    borderRadius: theme.radius.sm,
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
  },
}))

export const NewChip = forwardRef(function NewChip(
  _,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { classes } = useChipStyles()
  return (
    <div
      className={classes.chip}
      role="tooltip"
      aria-label="New feature!"
      ref={ref}
    >
      NEW!
    </div>
  )
})
