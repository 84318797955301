import Dexie, { Table } from 'dexie'
import { DocumentData, DocumentMeta, NlpData, NlpMeta } from './types'

export class Database extends Dexie {
  documentMeta!: Table<DocumentMeta, string>
  documentData!: Table<DocumentData, string>
  nlpMeta!: Table<NlpMeta, string>
  nlpData!: Table<NlpData, string>

  constructor() {
    super('genei-db')
    this.version(1).stores({
      documentMeta: 'id, version',
      documentData: 'id',
      nlpMeta: 'id, version',
      nlpData: 'id',
    })
  }
}
