import { EffectCallback, useEffect, useRef } from 'react'

// React 18 safe
export function useEffectOnce(effect: EffectCallback) {
  const hasRun = useRef(false)
  return useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true
      return effect()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
