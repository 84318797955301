import { useEndSubscriptionTrialMutation } from '@/api'

export const useEndSubscriptionTrial = () => {
  const [execute, result] = useEndSubscriptionTrialMutation()
  const endSubscriptionTrial = async () => {
    const { data } = await execute()
    return data?.endSubscriptionTrial
  }
  return [endSubscriptionTrial, result] as const
}
