import { useEffect, useState } from 'react'
import {
  Button,
  createStyles,
  Modal,
  Text,
  UnstyledButton,
} from '@mantine/core'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { IconAlertTriangle as AlertIcon } from '@tabler/icons-react'
import { SubscriptionStatus, useUser } from '@/api'
import {
  isSubscriptionActionRequired,
  Subscription,
  ActionRequiredStatus,
} from '@/shared-billing/helpers'
import { usePrevious } from '@/common'

dayjs.extend(relativeTime)

type ActionRequiredModalProps = {
  onTakeAction: () => void
}

export function ActionRequiredModal(props: ActionRequiredModalProps) {
  const { onTakeAction } = props
  const user = useUser()
  if (user.subscription && isSubscriptionActionRequired(user.subscription)) {
    return (
      <InternalActionRequiredModal
        subscription={user.subscription}
        onTakeAction={onTakeAction}
      />
    )
  }
  return null
}

type InternalActionRequiredModalProps = {
  subscription: Subscription<ActionRequiredStatus>
  onTakeAction: () => void
}

function InternalActionRequiredModal(props: InternalActionRequiredModalProps) {
  const { subscription, onTakeAction } = props
  const location = useLocation()
  const prevLocation = usePrevious(location)
  const [opened, setOpened] = useState(true)
  const handleClose = () => setOpened(false)
  useEffect(() => {
    const wasViewingAccount = prevLocation?.pathname.startsWith('/account')
    const isViewingAccount = location.pathname.startsWith('/account')
    if (!wasViewingAccount && isViewingAccount) {
      setOpened(true)
    }
  }, [prevLocation?.pathname, location.pathname])
  return (
    <Modal
      padding="xl"
      opened={opened}
      withCloseButton={false}
      onClose={handleClose}
    >
      <ModalContent
        subscription={subscription}
        onTakeAction={onTakeAction}
        onClose={handleClose}
      />
    </Modal>
  )
}

const useStyles = createStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing.xl,
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing.xs,
    color:
      theme.colorScheme === 'dark' ? theme.colors.red[5] : theme.colors.red[7],
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing.xs,
  },
  closeButton: {
    borderRadius: theme.radius.sm,
  },
}))

type ModalContentProps = {
  subscription: Subscription<ActionRequiredStatus>
  onTakeAction: () => void
  onClose: () => void
}

function ModalContent(props: ModalContentProps) {
  const { subscription, onTakeAction, onClose } = props
  const { classes } = useStyles()
  let title: string
  let description: string
  let buttonLabel: string
  switch (subscription.status) {
    case SubscriptionStatus.Trialing:
      title =
        'Your trial is in progress, but we failed to verify your payment method.'
      description = `If you don't add a payment method, you will lose access at the end of your trial ${dayjs(
        subscription.trialEnd,
      ).fromNow()}.`
      buttonLabel = 'Add payment method'
      break
    case SubscriptionStatus.Incomplete:
      title = 'We failed to charge your payment method when you subscribed.'
      description =
        "Your subscription won't be activated until you complete the payment."
      buttonLabel = 'Complete payment'
      break
    case SubscriptionStatus.PastDue:
      title = 'You are behind on your latest payment.'
      description =
        "If you don't complete the outstanding payment, your subscription will soon expire."
      buttonLabel = 'Complete payment'
      break
  }
  const handleTakeAction = () => {
    onTakeAction()
    onClose()
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <AlertIcon />
        <Text size="md" align="center">
          {title}
        </Text>
      </div>
      <Text size="sm" align="center">
        {description}
      </Text>
      <div className={classes.buttonContainer}>
        <Button
          data-autofocus
          color="teal"
          variant="light"
          onClick={handleTakeAction}
        >
          {buttonLabel}
        </Button>
        <UnstyledButton className={classes.closeButton} onClick={onClose}>
          <Text size="xs" color="dimmed">
            Remind me later
          </Text>
        </UnstyledButton>
      </div>
    </div>
  )
}
