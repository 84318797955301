import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { createStyles } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { SubscriptionStatus, useUser } from '@/api'
import {
  NavBar,
  ActionRequiredModal,
  GeneiBetaBanner,
  OnboardingForm,
  TouchWarningModal,
  useTouchWarning,
  FreeUpgradeModal,
  TierUpgradeModal,
  MigrationModal,
} from '@/nav-layout'
import { getCurrentFullPath } from '@/utils/web'
import { IntercomTour, useIntercomTour } from '@/tours'

const useStyles = createStyles({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  view: {
    flex: '1 0 0',
    height: 0,
    display: 'flex',
  },
  nav: {
    flex: '0 0 auto',
    overflow: 'hidden',
  },
  main: {
    flex: '1 0 0',
    width: 0,
  },
})

function NavLayout() {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const handleTakeAction = () =>
    navigate(
      `/subscription/action-pending-intent?redirect-url=${encodeURIComponent(
        getCurrentFullPath(),
      )}`,
    )
  return (
    <>
      <div className={classes.root}>
        <GlobalOnboarding />
        <div className={classes.view}>
          <div className={classes.nav}>
            <NavBar />
          </div>
          <div className={classes.main}>
            <Outlet />
          </div>
        </div>
      </div>
      <ActionRequiredModal onTakeAction={handleTakeAction} />
    </>
  )
}

function GlobalOnboarding() {
  const location = useLocation()
  const isSummariseRoute = location.pathname === '/summarise'
  const [isPostOnboarding, setIsPostOnboarding] = useState(false)
  const [isPostMigration, setIsPostMigration] = useState(false)
  const quickSummaryTour = useIntercomTour(IntercomTour.QuickSummary1)
  useEffect(() => {
    if (isSummariseRoute && isPostOnboarding && isPostMigration) {
      quickSummaryTour.start()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSummariseRoute, isPostOnboarding, isPostMigration])
  return (
    <>
      <Onboarding onComplete={() => setIsPostOnboarding(true)} />
      <BetaMigration
        isPostOnboarding={isPostOnboarding}
        onComplete={() => setIsPostMigration(true)}
      />
    </>
  )
}

type BetaMigrationProps = {
  isPostOnboarding: boolean
  onComplete: () => void
}

function BetaMigration(props: BetaMigrationProps) {
  const { isPostOnboarding, onComplete } = props
  const user = useUser()
  const [showMigration, migrationHandlers] = useDisclosure(!user.hasMigrated)
  const shouldShowBanner = user.oldUserId && !user.hasMigrated
  const shouldShowMigration =
    isPostOnboarding && !!user.oldUserId && user.canMigrate && showMigration
  const isPostMigration = isPostOnboarding && !shouldShowMigration
  useEffect(() => {
    if (isPostMigration) {
      onComplete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostMigration])
  if (!user.oldUserId) return null
  return (
    <>
      {shouldShowBanner && (
        <GeneiBetaBanner
          canMigrate={user.canMigrate}
          onStartMigration={migrationHandlers.open}
        />
      )}
      <MigrationModal
        opened={shouldShowMigration}
        onClose={migrationHandlers.close}
      />
    </>
  )
}

type OnboardingProps = {
  onComplete: () => void
}

function Onboarding(props: OnboardingProps) {
  const { onComplete } = props
  const user = useUser()
  const [showTouchWarning, dismissTouchWarning] = useTouchWarning()
  const isReadyForOnboardingFlow = Boolean(
    (user.subscription &&
      // do not use `isSubscriptionActionRequired` here because
      // we want to show onboarding in spite of action required
      [SubscriptionStatus.Trialing, SubscriptionStatus.Active].includes(
        user.subscription.status,
      )) ||
      user.freeTierType,
  )
  const shouldShowOnboarding = isReadyForOnboardingFlow && !user.hasOnboarded
  const shouldShowTouchWarning =
    isReadyForOnboardingFlow && user.hasOnboarded && showTouchWarning
  const isPostOnboarding = !shouldShowOnboarding && !shouldShowTouchWarning
  useEffect(() => {
    if (isPostOnboarding) {
      onComplete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostOnboarding])
  return (
    <>
      <OnboardingForm opened={shouldShowOnboarding} />
      <TouchWarningModal
        opened={shouldShowTouchWarning}
        onClose={dismissTouchWarning}
      />
      <FreeUpgradeModal />
      <TierUpgradeModal />
    </>
  )
}

export default NavLayout
