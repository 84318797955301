import { useCallback, useEffect, useState } from 'react'
import throttle from 'lodash.throttle'

export const useScroll = <TElement extends HTMLElement, TData>(
  ref: React.RefObject<TElement>,
  initialData: TData | (() => TData),
  getData: (element: TElement) => TData,
  throttleMs?: number,
) => {
  const [data, setData] = useState<TData>(initialData)

  useEffect(() => {
    const element = ref.current
    if (!element) return
    const updateData = () => setData(getData(element))
    updateData()
    const scrollListener = !throttleMs
      ? updateData
      : throttle(updateData, throttleMs, { trailing: true })
    element.addEventListener('scroll', scrollListener)
    return () => {
      element.removeEventListener('scroll', scrollListener)
    }
  }, [ref, throttleMs, getData])

  return data
}

type ScrollPosition = {
  x: number
  y: number
}

const DEFAULT_POSITION: ScrollPosition = { x: 0, y: 0 }

export const useScrollPosition = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  throttleMs?: number,
) => {
  return useScroll(
    ref,
    DEFAULT_POSITION,
    useCallback(
      (element) => ({
        x: element.scrollLeft,
        y: element.scrollTop,
      }),
      [],
    ),
    throttleMs,
  )
}

export const useScrollAtTop = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  throttleMs?: number,
) => {
  return useScroll(
    ref,
    true,
    useCallback((element) => element.scrollTop === 0, []),
    throttleMs,
  )
}
