import {
  useOnAiChatCreateSubscription,
  useOnAiChatRenameSubscription,
  useOnAiChatDeleteSubscription,
  useOnAiChatMessageCreateSubscription,
  useOnAiChatMessageDeleteSubscription,
  useOnAiChatMessageRequestStatusChangeSubscription,
} from './hooks'
import {
  onCreateAiChat,
  onCreateAiChatMessage,
  onDeleteAiChat,
  onDeleteAiChatMessage,
} from './typePolicies'

type AiChatSubscriptionsProps = {
  skip?: boolean
}

export function AiChatSubscriptions(props: AiChatSubscriptionsProps) {
  const { skip } = props
  useOnAiChatCreateSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const aiChat = subscriptionData.data?.onAiChatCreate
      if (!aiChat) return
      onCreateAiChat(client.cache, aiChat)
    },
  })
  useOnAiChatRenameSubscription({ skip })
  useOnAiChatDeleteSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const aiChat = subscriptionData.data?.onAiChatDelete
      if (!aiChat) return
      onDeleteAiChat(client.cache, aiChat)
    },
  })
  useOnAiChatMessageCreateSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const message = subscriptionData.data?.onAiChatMessageCreate
      if (!message) return
      onCreateAiChatMessage(client.cache, message)
    },
  })
  useOnAiChatMessageDeleteSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const message = subscriptionData.data?.onAiChatMessageDelete
      if (!message) return
      onDeleteAiChatMessage(client.cache, message)
    },
  })
  useOnAiChatMessageRequestStatusChangeSubscription({ skip })
  return null
}
