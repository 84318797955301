import { useState } from 'react'
import { createStyles, Text, UnstyledButton } from '@mantine/core'
import { IconX as CloseIcon } from '@tabler/icons-react'
import config from '@/config'

type StyleParams = {
  canMigrate: boolean
}

const useStyles = createStyles((theme, params: StyleParams) => ({
  root: {
    position: 'relative',
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 30,
    width: '100%',
    backgroundColor: params.canMigrate
      ? theme.colors.blue[9]
      : theme.colors.lime[1],
    padding: `0px ${theme.spacing.xs}px`,
  },
  start: {
    color: theme.white,
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  link: {
    fontWeight: 700,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:visited': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  text: {
    marginInline: theme.spacing.xl,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.xs,
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.colors.blue[8],
    '& > svg': {
      display: 'block',
    },
  },
}))

export type BannerProps = {
  canMigrate: boolean
  onStartMigration: () => void
}

export function GeneiBetaBanner(props: BannerProps) {
  const { canMigrate, onStartMigration } = props
  const [show, setShow] = useState(true)
  const { classes } = useStyles({ canMigrate })
  if (!show) return null
  return (
    <div className={classes.root}>
      {canMigrate ? (
        <UnstyledButton className={classes.start} onClick={onStartMigration}>
          Migration ready - click here to migrate your files to Genei 1.0
        </UnstyledButton>
      ) : (
        <>
          <Text size="sm" className={classes.text}>
            Thanks for trying Genei 1.0! Click{' '}
            <a className={classes.link} href={config.betaClientBaseUrl}>
              here
            </a>{' '}
            to go back to old Genei ✨
          </Text>
          <UnstyledButton
            className={classes.closeButton}
            onClick={() => setShow(false)}
          >
            <CloseIcon size={20} />
          </UnstyledButton>
        </>
      )}
    </div>
  )
}
