import { forwardRef } from 'react'
import {
  CSSObject,
  MantineTheme,
  PolymorphicComponentProps,
  PolymorphicRef,
  SharedTextProps,
  Text,
} from '@mantine/core'

export type LinkProps<C> = PolymorphicComponentProps<C, SharedTextProps>
type LinkComponent = (<C = 'a'>(props: LinkProps<C>) => React.ReactElement) & {
  displayName?: string
}

export const Link: LinkComponent = forwardRef(function Link(
  props: LinkProps<'a'>,
  ref: PolymorphicRef<'a'>,
) {
  const { sx, children, component = 'a', ...delegated } = props
  const getSx = (theme: MantineTheme): CSSObject => {
    const externalSx = typeof sx === 'function' ? sx(theme) : sx
    return {
      ...externalSx,
      '&:hover': {
        textDecoration: 'underline',
      },
    } as CSSObject
  }

  return (
    <Text sx={getSx} component={component} {...delegated} ref={ref}>
      {children}
    </Text>
  )
}) as any
