import { useCreateSubscriptionMutation } from '@/api'
import { getCookie } from '@/utils/web'

export const useCreateSubscription = () => {
  const [execute, { data, ...result }] = useCreateSubscriptionMutation()
  const createSubscription = async (planId: string, promotionCode?: string) => {
    const { data } = await execute({
      variables: {
        input: {
          planId,
          promotionCode,
          fbp: getCookie('_fbp'),
          fbc: getCookie('_fbc'),
        },
      },
    })
    return data?.createSubscription!
  }
  const subscription = data?.createSubscription
  return [createSubscription, { ...result, subscription }] as const
}
