import { useEffect, useState } from 'react'
import { Button, ButtonProps } from '@mantine/core'
import { useSafeStateUpdate } from '../hooks'

export type ButtonWithTimerProps = {
  throttleTimeInMs: number
  startingState?: 'enabled' | 'disabled'
} & ButtonProps<'button'>

export function ButtonWithTimer(props: ButtonWithTimerProps) {
  const {
    throttleTimeInMs,
    startingState = 'enabled',
    onClick,
    children,
    ...rest
  } = props

  const [isDisabled, setIsDisabled] = useState(startingState === 'disabled')
  const safeUpdate = useSafeStateUpdate()

  useEffect(() => {
    if (startingState === 'disabled') {
      disableAndToggleAfterDelay()
    }
  }, []) // eslint-disable-line

  const throttledClickHandler: React.MouseEventHandler<HTMLButtonElement> = (
    ...args
  ) => {
    disableAndToggleAfterDelay()
    onClick?.(...args)
  }

  const disableAndToggleAfterDelay = () => {
    safeUpdate(() => setIsDisabled(true))
    setTimeout(() => safeUpdate(() => setIsDisabled(false)), throttleTimeInMs)
  }

  return (
    <Button disabled={isDisabled} onClick={throttledClickHandler} {...rest}>
      {children}
    </Button>
  )
}
