import type { TablerIconsProps } from '@tabler/icons-react'

export function ResourceLinkIcon(props: TablerIconsProps) {
  const { size = 24, color = 'currentColor', stroke = 2, ...delegated } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-resource-link"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      strokeWidth={stroke}
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...delegated}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M10 14C10.3259 14.3326 10.7148 14.5968 11.1441 14.7772C11.5734 14.9576 12.0344 15.0505 12.5 15.0505C12.9656 15.0505 13.4266 14.9576 13.8559 14.7772C14.2852 14.5968 14.6741 14.3326 15 14L19 10C19.663 9.33697 20.0355 8.43769 20.0355 7.50001C20.0355 6.56233 19.663 5.66305 19 5.00001C18.337 4.33697 17.4377 3.96448 16.5 3.96448C15.5623 3.96448 14.663 4.33697 14 5.00001L13.5 5.50001" />
      <path d="M14 9.99997C13.6741 9.66737 13.2852 9.40315 12.8559 9.22276C12.4266 9.04238 11.9656 8.94946 11.5 8.94946C11.0344 8.94946 10.5734 9.04238 10.1441 9.22276C9.71486 9.40315 9.32589 9.66737 9.00001 9.99997L5.00001 14C4.33697 14.663 3.96448 15.5623 3.96448 16.5C3.96448 17.4377 4.33697 18.3369 5.00001 19C5.66305 19.663 6.56233 20.0355 7.50001 20.0355C8.43769 20.0355 9.33697 19.663 10 19L10.5 18.5" />
      <path d="M5 9.5V8C5 6.34315 6.34315 5 8 5H10" />
      <path d="M19 14.5L19 16C19 17.6569 17.6569 19 16 19L14 19" />
    </svg>
  )
}

type AppleIconProps = Omit<TablerIconsProps, 'stroke'>

export function AppleIcon(props: AppleIconProps) {
  const { size = 24, color = 'currentColor', ...delegated } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-apple"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      {...delegated}
    >
      <path
        d="M17.6637 6.81868C17.5477 6.90869 15.4995 8.06281 15.4995 10.6291C15.4995 13.5974 18.1058 14.6475 18.1838 14.6735C18.1718 14.7375 17.7697 16.1116 16.8096 17.5118C15.9535 18.7439 15.0595 19.974 13.6993 19.974C12.3392 19.974 11.9892 19.1839 10.419 19.1839C8.88884 19.1839 8.34479 20 7.10067 20C5.85654 20 4.98845 18.8599 3.99035 17.4597C2.83424 15.8156 1.90015 13.2613 1.90015 10.8371C1.90015 6.9487 4.4284 4.88649 6.91665 4.88649C8.23878 4.88649 9.34089 5.75458 10.171 5.75458C10.961 5.75458 12.1932 4.83448 13.6973 4.83448C14.2674 4.83448 16.3156 4.88649 17.6637 6.81868ZM12.9833 3.18832C13.6053 2.45025 14.0454 1.42614 14.0454 0.40204C14.0454 0.260026 14.0334 0.116012 14.0074 0C12.9953 0.0380038 11.7911 0.674068 11.0651 1.51615C10.495 2.16422 9.96295 3.18832 9.96295 4.22642C9.96295 4.38244 9.98895 4.53845 10.001 4.58846C10.065 4.60046 10.169 4.61446 10.273 4.61446C11.1811 4.61446 12.3232 4.0064 12.9833 3.18832Z"
        fill={color}
      />
    </svg>
  )
}

type GoogleIconProps = Omit<TablerIconsProps, 'stroke' | 'color'>

export function GoogleIcon(props: GoogleIconProps) {
  const { size = 24, ...delegated } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-google"
      width={size}
      height={size}
      viewBox="0 0 550 550"
      fill="none"
      {...delegated}
    >
      <path
        d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
        fill="#4285f4"
      />
      <path
        d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
        fill="#34a853"
      />
      <path
        d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
        fill="#fbbc04"
      />
      <path
        d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
        fill="#ea4335"
      />
    </svg>
  )
}
