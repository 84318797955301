import { useEffect } from 'react'
import { KeyboardEventLike } from 'is-hotkey'

/**
 * Hook to listen to a keyboard shortcut
 * @param matchShortcut takes a keyboard event and returns true if the event matches the shortcut
 * @param callback executed when the shortcut is matched. \
 *  the listener is only attached if it not undefined \
 *  the event is default prevented unless the listener returns false
 * @param shouldPreventDefault should call preventDefault on the event @default true
 */
export const useShortcut = (
  matchShortcut: (event: KeyboardEventLike) => boolean,
  callback?: (event: KeyboardEvent) => void,
  shouldPreventDefault: boolean = true,
) => {
  const shouldListen = !!callback
  useEffect(() => {
    if (!shouldListen) return
    const keydownListener = (event: KeyboardEvent) => {
      if (matchShortcut(event)) {
        callback(event)
        if (shouldPreventDefault === true) {
          event.preventDefault()
        }
      }
    }
    document.addEventListener('keydown', keydownListener)
    return () => {
      document.removeEventListener('keydown', keydownListener)
    }
  }, [shouldListen, shouldPreventDefault, callback, matchShortcut])
}
