import { useState } from 'react'
import { Modal, Text, Button, createStyles } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flow-root',
    '& > *': {
      marginInline: 'auto',
    },
    '& > * + *': {
      marginBlockStart: theme.spacing.xl,
    },
  },
}))

export const useTouchWarning = () => {
  const [showWarning, setShowWarning] = useState(() => {
    if (localStorage.getItem('touch-modal-seen')) return false
    return navigator.maxTouchPoints > 0
  })
  const dismiss = () => {
    setShowWarning(false)
    localStorage.setItem('touch-modal-seen', 'true')
  }
  return [showWarning, dismiss] as const
}

export type TouchWarningModalProps = {
  opened: boolean
  onClose: () => void
}

export function TouchWarningModal(props: TouchWarningModalProps) {
  const { opened, onClose } = props
  const { classes } = useStyles()
  return (
    <Modal opened={opened} onClose={onClose} radius="lg">
      <div className={classes.container}>
        <Text size="sm" weight={600}>
          Please note, Genei is not currently optimized for touch devices. You
          may experience issues scrolling in PDFs.
        </Text>
        <Text size="sm" weight={600}>
          For the best experience, use a laptop or desktop computer.
        </Text>
        <Button onClick={onClose} fullWidth={false}>
          Got it!
        </Button>
      </div>
    </Modal>
  )
}
