import { Link as BaseLink, useSearchParams } from 'react-router-dom'
import { mergeSearchParams } from './Navigate'

export type LinkProps = Parameters<typeof BaseLink>[0] & {
  propagateSearchParams?: boolean
}

export function Link(props: LinkProps) {
  const { propagateSearchParams, to: _to, ...delegated } = props
  const [searchParams] = useSearchParams()
  const to = !propagateSearchParams ? _to : mergeSearchParams(_to, searchParams)
  return <BaseLink {...delegated} to={to} />
}
