import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** Represents NULL values */
  Void: any;
};

export type Academic = {
  __typename: 'Academic';
  email: Scalars['String'];
  isVerified: Scalars['Boolean'];
  verifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ActivateAcademicInput = {
  token: Scalars['String'];
};

export type ActivateAcademicStatusInput = {
  token: Scalars['String'];
};

export type AddCreditsInput = {
  credits: Scalars['Int'];
  paid?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};

export type AiChat = {
  __typename: 'AiChat';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  messages: AiChatMessageList;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type AiChatMessagesArgs = {
  options?: InputMaybe<ListAiChatMessagesOptions>;
};

export type AiChatBotMessage = AiChatMessage & {
  __typename: 'AiChatBotMessage';
  chatId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  prompt?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  references?: Maybe<Array<AiChatMessageReference>>;
  sender: Scalars['String'];
};

export type AiChatList = {
  __typename: 'AiChatList';
  cursor?: Maybe<Scalars['String']>;
  hasMore: Scalars['Boolean'];
  items: Array<AiChat>;
  limit?: Maybe<Scalars['Int']>;
};

export type AiChatMessage = {
  chatId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  sender: Scalars['String'];
};

export type AiChatMessageList = {
  __typename: 'AiChatMessageList';
  cursor?: Maybe<Scalars['String']>;
  hasMore: Scalars['Boolean'];
  items: Array<AiChatMessage>;
  limit?: Maybe<Scalars['Int']>;
};

export type AiChatMessageReference = {
  __typename: 'AiChatMessageReference';
  number: Scalars['Int'];
  resourceId: Scalars['String'];
  segments?: Maybe<Array<ParagraphSegment>>;
  spans: Array<Span>;
};

export type AiChatMessageRequest = {
  __typename: 'AiChatMessageRequest';
  chatId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  messageId: Scalars['String'];
  status: AiChatMessageRequestStatus;
  updatedAt: Scalars['DateTime'];
};

export enum AiChatMessageRequestStatus {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Superseded = 'superseded'
}

export enum AiChatSortBy {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type AiChatUserMessage = AiChatMessage & {
  __typename: 'AiChatUserMessage';
  chatId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  request?: Maybe<AiChatMessageRequest>;
  sender: Scalars['String'];
};

export type ApiKey = {
  __typename: 'ApiKey';
  createdAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  pk: Scalars['ID'];
  sk?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ApiKeyList = {
  __typename: 'ApiKeyList';
  cursor?: Maybe<Scalars['String']>;
  items: Array<ApiKey>;
};

export enum ApiKeySortBy {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type AskContentAnswer = {
  __typename: 'AskContentAnswer';
  answer: Scalars['String'];
  context: Scalars['String'];
  folderId?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  offsetEnd: Scalars['Int'];
  offsetEndInDoc: Scalars['Int'];
  offsetStart: Scalars['Int'];
  offsetStartInDoc: Scalars['Int'];
  paragraphIdx: Scalars['Int'];
  projectId?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  score: Scalars['Float'];
  sectionIdx: Scalars['Int'];
  segments?: Maybe<Array<ParagraphSegment>>;
  title?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type AskContentOutput = {
  __typename: 'AskContentOutput';
  answers?: Maybe<Array<AskContentAnswer>>;
  noAnsGap?: Maybe<Scalars['Float']>;
  query: Scalars['String'];
};

export type AskProjectContentInput = {
  folderIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  query: Scalars['String'];
  resourceIds?: InputMaybe<Array<Scalars['String']>>;
  topKReader?: InputMaybe<Scalars['Int']>;
  topKRetriever?: InputMaybe<Scalars['Int']>;
  useOdin?: InputMaybe<Scalars['Boolean']>;
};

export type AskResourceContentInput = {
  id: Scalars['String'];
  query: Scalars['String'];
  topKReader?: InputMaybe<Scalars['Int']>;
  topKRetriever?: InputMaybe<Scalars['Int']>;
  useOdin?: InputMaybe<Scalars['Boolean']>;
};

export type ChangeAssociatedCustomerInput = {
  customerId: Scalars['String'];
  id: Scalars['ID'];
};

export type ChangeSubscriptionPlanInput = {
  idempotencyKey?: InputMaybe<Scalars['String']>;
  planId: Scalars['String'];
  promotionCode?: InputMaybe<Scalars['String']>;
};

export type CompleteTourInput = {
  tourId: Scalars['Int'];
};

export type ComputeSectionSummaryInput = {
  indexes?: InputMaybe<Array<Scalars['Int']>>;
  resourceId: Scalars['String'];
};

export type ConsumeCreditsInput = {
  credits: Scalars['Int'];
  userId: Scalars['ID'];
};

export type ConsumeLicenseKeyInput = {
  id: Scalars['String'];
};

export type CountMigratedUsersOuput = {
  __typename: 'CountMigratedUsersOuput';
  count: Scalars['Int'];
};

export type CreateAiChatInput = {
  name: Scalars['String'];
  projectId: Scalars['String'];
};

export type CreateApiKeyInput = {
  expiresAt: Scalars['DateTime'];
  name: Scalars['String'];
};

export type CreateCompletionInput = {
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type CreateCompletionOutput = {
  __typename: 'CreateCompletionOutput';
  text: Scalars['String'];
};

export type CreateFolderInput = {
  name: Scalars['String'];
  parentFolderId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
};

export type CreateLicenseKeysInput = {
  customerId: Scalars['String'];
  duration: Scalars['Int'];
  quantity: Scalars['Int'];
  tier: SubscriptionPlanTier;
};

export type CreateNoteImageInput = {
  id: Scalars['ID'];
  type: NoteImageType;
};

export type CreateNoteImagePayload = {
  __typename: 'CreateNoteImagePayload';
  id: Scalars['ID'];
  putUrl: Scalars['String'];
  url: Scalars['String'];
};

export type CreateNoteInput = {
  folderId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
};

export type CreateProjectInput = {
  name: Scalars['String'];
};

export type CreatePromptInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  options: PromptOptions;
};

export type CreateResourceFromFileInput = {
  customName?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  folderId?: InputMaybe<Scalars['String']>;
  processWithOcr: Scalars['Boolean'];
  projectId?: InputMaybe<Scalars['String']>;
  type: ResourceType;
};

export type CreateResourceFromUrlInput = {
  customName?: InputMaybe<Scalars['String']>;
  folderId?: InputMaybe<Scalars['String']>;
  processWithOcr: Scalars['Boolean'];
  projectId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ResourceType>;
  url: Scalars['String'];
};

export type CreateResourceHighlightInput = {
  highlight: ResourceHighlightInput;
  resourceId: Scalars['ID'];
};

export type CreateSubscriptionInput = {
  fbc?: InputMaybe<Scalars['String']>;
  fbp?: InputMaybe<Scalars['String']>;
  idempotencyKey?: InputMaybe<Scalars['String']>;
  planId: Scalars['String'];
  promotionCode?: InputMaybe<Scalars['String']>;
};

export type Credits = {
  __typename: 'Credits';
  paid: Scalars['Int'];
  plan: Scalars['Int'];
  total: Scalars['Int'];
};

export enum Currency {
  Cad = 'cad',
  Eur = 'eur',
  Gbp = 'gbp',
  Usd = 'usd'
}

export type CustomerLicenseKeysInput = {
  customerId: Scalars['String'];
};

export type DeleteAiChatInput = {
  id: Scalars['ID'];
};

export type DeleteAiChatMessageInput = {
  id: Scalars['ID'];
};

export type DeleteApiKeyInput = {
  pk: Scalars['ID'];
};

export type DeleteFolderInput = {
  id: Scalars['ID'];
};

export type DeleteNoteInput = {
  id: Scalars['ID'];
};

export type DeletePaymentMethodInput = {
  id: Scalars['ID'];
};

export type DeleteProjectInput = {
  id: Scalars['ID'];
};

export type DeletePromptInput = {
  id: Scalars['ID'];
};

export type DeleteResourceHighlightInput = {
  id: Scalars['ID'];
};

export type DeleteResourceInput = {
  id: Scalars['ID'];
};

export type DeleteUserInput = {
  id: Scalars['ID'];
};

export type DeleteUserQueue = {
  __typename: 'DeleteUserQueue';
  items: Array<User>;
};

export type DocumentCoord = PdfCoord | WebCoord;

export type EnableMigrationForNextCohortInput = {
  count: Scalars['Int'];
};

export type EnableMigrationInput = {
  id: Scalars['ID'];
};

export type Figure = {
  __typename: 'Figure';
  bbox?: Maybe<Array<Scalars['Float']>>;
  index: Scalars['Int'];
  nodeKey?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  resourceId: Scalars['String'];
  type?: Maybe<FigureType>;
  url: Scalars['String'];
};

export type FigureInput = {
  bbox?: InputMaybe<Array<Scalars['Float']>>;
  nodeKey?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  source: Scalars['String'];
  type?: InputMaybe<FigureType>;
};

export enum FigureType {
  Figure = 'figure',
  Table = 'table'
}

export type FindUserInput = {
  email: Scalars['String'];
};

export type Folder = {
  __typename: 'Folder';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  notes: NoteList;
  ownerId: Scalars['String'];
  parentFolderId?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  resources: ResourceList;
  subfolders: FolderList;
  updatedAt: Scalars['DateTime'];
};


export type FolderNotesArgs = {
  options?: InputMaybe<ListNotesOptions>;
};


export type FolderResourcesArgs = {
  options?: InputMaybe<ListResourcesOptions>;
};


export type FolderSubfoldersArgs = {
  options?: InputMaybe<ListFoldersOptions>;
};

export type FolderList = {
  __typename: 'FolderList';
  cursor?: Maybe<Scalars['String']>;
  items: Array<Folder>;
};

export enum FolderSortBy {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type ForceSyncSubscriptionInput = {
  id: Scalars['ID'];
  subscriptionId: Scalars['String'];
};

export enum FreeTierType {
  Time = 'time',
  Usage = 'usage'
}

export enum HighlightColor {
  Blue = 'blue',
  Green = 'green',
  Orange = 'orange',
  Pink = 'pink',
  Yellow = 'yellow'
}

export type LicenseKey = {
  __typename: 'LicenseKey';
  consumedAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['ID'];
  tier: SubscriptionPlanTier;
  userId?: Maybe<Scalars['String']>;
};

export type LicenseKeyList = {
  __typename: 'LicenseKeyList';
  items: Array<LicenseKey>;
};

export type LinkAccountInput = {
  token: Scalars['String'];
};

export type ListAiChatMessagesOptions = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ListAiChatsOptions = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<AiChatSortBy>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ListApiKeysOptions = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ApiKeySortBy>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ListFoldersOptions = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<FolderSortBy>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ListNotesOptions = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<NoteSortBy>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ListProjectsOptions = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProjectSortBy>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ListResourcesOptions = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ResourceSortBy>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ListSubscriptionPlanOptions = {
  currencies?: InputMaybe<Array<Currency>>;
  periods?: InputMaybe<Array<SubscriptionPlanPeriod>>;
  requiresAcademic?: InputMaybe<Scalars['Boolean']>;
  tiers?: InputMaybe<Array<SubscriptionPlanTier>>;
};

export type MakeAdminInput = {
  id: Scalars['ID'];
};

export type MessagePayload = {
  __typename: 'MessagePayload';
  message: Scalars['String'];
};

export type MetadataSearchItem = Folder | Note | Resource;

export type MetadataSearchResult = {
  __typename: 'MetadataSearchResult';
  item: MetadataSearchItem;
  score: Scalars['Float'];
};

export enum MetadataSearchableModel {
  Folder = 'folder',
  Note = 'note',
  Resource = 'resource'
}

export type MoveFolderInput = {
  id: Scalars['ID'];
  parentFolderId?: InputMaybe<Scalars['String']>;
};

export type MoveNoteInput = {
  folderId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MoveResourceInput = {
  folderId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type Mutation = {
  __typename: 'Mutation';
  activateAcademicEmail: User;
  activateAcademicStatus: User;
  addCredits: User;
  cancelSubscription: UserSubscription;
  changeAssociatedCustomer: User;
  changeSubscriptionPlan: UserSubscription;
  completeTour: User;
  computeSectionSummary: Array<SummaryRequest>;
  consumeCredits: User;
  consumeLicenseKey: LicenseKey;
  createAiChat: AiChat;
  createApiKey: ApiKey;
  createCompletion: CreateCompletionOutput;
  createFolder: Folder;
  createLicenseKeys: LicenseKeyList;
  createNote: Note;
  createNoteImage: CreateNoteImagePayload;
  createProject: Project;
  createPrompt: Prompt;
  createResourceFromFile: Resource;
  createResourceFromUrl: Resource;
  createResourceHighlight: ResourceHighlight;
  createSetupIntent: SubscriptionPendingIntent;
  createSubscription: UserSubscription;
  deleteAiChat: AiChat;
  deleteAiChatMessage: AiChatMessage;
  deleteApiKey: ApiKey;
  deleteFolder: Folder;
  deleteNote: Note;
  deletePaymentMethod: PaymentMethodIdentifier;
  deleteProject: Project;
  deletePrompt: Prompt;
  deleteResource: Resource;
  deleteResourceHighlight: ResourceHighlight;
  deleteUser: User;
  enableMigration: User;
  enableMigrationForNextCohort: Array<User>;
  endSubscriptionTrial: UserSubscription;
  forceSyncSubscription: UserSubscription;
  linkAccount: User;
  makeAdmin: User;
  migrateBeta?: Maybe<User>;
  moveFolder: Folder;
  moveNote: Note;
  moveResource: Resource;
  notifyPdfResourceHasSections: Resource;
  notifyResourceHasDocument: Resource;
  notifyResourceHasDocumentError: Resource;
  notifyResourceHasFigures: Resource;
  notifyResourceHasFiguresError: Resource;
  notifyResourceHasMetadata: Resource;
  notifyResourceHasMetadataError: Resource;
  notifyResourceHasNlp: Resource;
  notifyResourceHasNlpError: Resource;
  notifyResourceHasSectionsError: Resource;
  notifyResourceHasUpload: Resource;
  notifySummaryRequestFailure: SummaryRequest;
  notifyWebResourceHasSections: Resource;
  paraphraseBlocks: ParaphraseBlocksOutput;
  queueDeleteUser: User;
  recomputeSectionSummary: Array<SummaryRequest>;
  requestAcademicEmailVerification: User;
  requestAcademicStatus: User;
  requestAcademicStatusForUser: User;
  resetBillingState: User;
  restoreSectionSummary: SectionSummary;
  revokeAdmin: User;
  rollbackBetaMigration?: Maybe<User>;
  sendAiChatMessage: AiChatMessage;
  setAiChatName: AiChat;
  setApiKeyName: ApiKey;
  setDefaultPaymentMethod: PaymentMethodIdentifier;
  setDefaultSummariser: User;
  setFolderName: Folder;
  setHasOnboarded: User;
  setNoteData: Note;
  setNoteName: Note;
  setProjectName: Project;
  setResourceHighlightAnnotation: ResourceHighlight;
  setResourceHighlightColor: ResourceHighlight;
  setResourceName: Resource;
  setResourceProject: Resource;
  setSectionSummaryRating: SectionSummary;
  setSubscriptionPromotion: UserSubscription;
  setUserName: User;
  summariseText: TextSummary;
  syncTraits?: Maybe<Scalars['Void']>;
  uncancelSubscription: UserSubscription;
  unqueueDeleteUser: User;
  updatePrompt: Prompt;
  updateResourceSectionSummary: Section;
};


export type MutationActivateAcademicEmailArgs = {
  input: ActivateAcademicInput;
};


export type MutationActivateAcademicStatusArgs = {
  input: ActivateAcademicStatusInput;
};


export type MutationAddCreditsArgs = {
  input: AddCreditsInput;
};


export type MutationChangeAssociatedCustomerArgs = {
  input: ChangeAssociatedCustomerInput;
};


export type MutationChangeSubscriptionPlanArgs = {
  input: ChangeSubscriptionPlanInput;
};


export type MutationCompleteTourArgs = {
  input: CompleteTourInput;
};


export type MutationComputeSectionSummaryArgs = {
  input: ComputeSectionSummaryInput;
};


export type MutationConsumeCreditsArgs = {
  input: ConsumeCreditsInput;
};


export type MutationConsumeLicenseKeyArgs = {
  input: ConsumeLicenseKeyInput;
};


export type MutationCreateAiChatArgs = {
  input: CreateAiChatInput;
};


export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};


export type MutationCreateCompletionArgs = {
  input: CreateCompletionInput;
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};


export type MutationCreateLicenseKeysArgs = {
  input: CreateLicenseKeysInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateNoteImageArgs = {
  input: CreateNoteImageInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationCreatePromptArgs = {
  input: CreatePromptInput;
};


export type MutationCreateResourceFromFileArgs = {
  input: CreateResourceFromFileInput;
};


export type MutationCreateResourceFromUrlArgs = {
  input: CreateResourceFromUrlInput;
};


export type MutationCreateResourceHighlightArgs = {
  input: CreateResourceHighlightInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationDeleteAiChatArgs = {
  input: DeleteAiChatInput;
};


export type MutationDeleteAiChatMessageArgs = {
  input: DeleteAiChatMessageInput;
};


export type MutationDeleteApiKeyArgs = {
  input: DeleteApiKeyInput;
};


export type MutationDeleteFolderArgs = {
  input: DeleteFolderInput;
};


export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};


export type MutationDeletePromptArgs = {
  input: DeletePromptInput;
};


export type MutationDeleteResourceArgs = {
  input: DeleteResourceInput;
};


export type MutationDeleteResourceHighlightArgs = {
  input: DeleteResourceHighlightInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationEnableMigrationArgs = {
  input: EnableMigrationInput;
};


export type MutationEnableMigrationForNextCohortArgs = {
  input: EnableMigrationForNextCohortInput;
};


export type MutationForceSyncSubscriptionArgs = {
  input: ForceSyncSubscriptionInput;
};


export type MutationLinkAccountArgs = {
  input: LinkAccountInput;
};


export type MutationMakeAdminArgs = {
  input: MakeAdminInput;
};


export type MutationMoveFolderArgs = {
  input: MoveFolderInput;
};


export type MutationMoveNoteArgs = {
  input: MoveNoteInput;
};


export type MutationMoveResourceArgs = {
  input: MoveResourceInput;
};


export type MutationNotifyPdfResourceHasSectionsArgs = {
  input: NotifyPdfResourceHasSectionsInput;
};


export type MutationNotifyResourceHasDocumentArgs = {
  input: NotifyResourceHasDocumentInput;
};


export type MutationNotifyResourceHasDocumentErrorArgs = {
  input: NotifyResourceHasDocumentErrorInput;
};


export type MutationNotifyResourceHasFiguresArgs = {
  input: NotifyResourceHasFiguresInput;
};


export type MutationNotifyResourceHasFiguresErrorArgs = {
  input: NotifyResourceHasFiguresErrorInput;
};


export type MutationNotifyResourceHasMetadataArgs = {
  input: NotifyResourceHasMetadataInput;
};


export type MutationNotifyResourceHasMetadataErrorArgs = {
  input: NotifyResourceHasMetadataErrorInput;
};


export type MutationNotifyResourceHasNlpArgs = {
  input: NotifyResourceHasNlpInput;
};


export type MutationNotifyResourceHasNlpErrorArgs = {
  input: NotifyResourceHasNlpErrorInput;
};


export type MutationNotifyResourceHasSectionsErrorArgs = {
  input: NotifyResourceHasSectionsErrorInput;
};


export type MutationNotifyResourceHasUploadArgs = {
  input: NotifyResourceHasUploadInput;
};


export type MutationNotifySummaryRequestFailureArgs = {
  input: NotifySummaryRequestFailureInput;
};


export type MutationNotifyWebResourceHasSectionsArgs = {
  input: NotifyWebResourceHasSectionsInput;
};


export type MutationParaphraseBlocksArgs = {
  input: ParaphraseBlocksInput;
};


export type MutationRecomputeSectionSummaryArgs = {
  input: RecomputeSectionSummaryInput;
};


export type MutationRequestAcademicEmailVerificationArgs = {
  input: RequestAcademicInput;
};


export type MutationRequestAcademicStatusArgs = {
  input: RequestAcademicStatusInput;
};


export type MutationRequestAcademicStatusForUserArgs = {
  input: RequestAcademicStatusForUserInput;
};


export type MutationResetBillingStateArgs = {
  input: ResetBillingStateInput;
};


export type MutationRestoreSectionSummaryArgs = {
  input: RestoreSectionSummaryInput;
};


export type MutationRevokeAdminArgs = {
  input: RevokedAdminInput;
};


export type MutationSendAiChatMessageArgs = {
  input: SendAiChatMessageInput;
};


export type MutationSetAiChatNameArgs = {
  input: SetAiChatNameInput;
};


export type MutationSetApiKeyNameArgs = {
  input: SetApiKeyNameInput;
};


export type MutationSetDefaultPaymentMethodArgs = {
  input: SetDefaultPaymentMethodInput;
};


export type MutationSetDefaultSummariserArgs = {
  input: SetDefaultSummariserInput;
};


export type MutationSetFolderNameArgs = {
  input: SetFolderNameInput;
};


export type MutationSetNoteDataArgs = {
  input: SetNoteDataInput;
};


export type MutationSetNoteNameArgs = {
  input: SetNoteNameInput;
};


export type MutationSetProjectNameArgs = {
  input: SetProjectNameInput;
};


export type MutationSetResourceHighlightAnnotationArgs = {
  input: SetResourceHighlightAnnotationInput;
};


export type MutationSetResourceHighlightColorArgs = {
  input: SetResourceHighlightColorInput;
};


export type MutationSetResourceNameArgs = {
  input: SetResourceNameInput;
};


export type MutationSetResourceProjectArgs = {
  input: SetResourceProjectInput;
};


export type MutationSetSectionSummaryRatingArgs = {
  input: SetSectionSummaryRatingInput;
};


export type MutationSetSubscriptionPromotionArgs = {
  input: SetSubscriptionPromotionInput;
};


export type MutationSetUserNameArgs = {
  input: SetUserNameInput;
};


export type MutationSummariseTextArgs = {
  input: SummariseTextInput;
};


export type MutationUpdatePromptArgs = {
  input: UpdatePromptInput;
};


export type MutationUpdateResourceSectionSummaryArgs = {
  input: UpdateResourceSectionSummaryInput;
};

export type Note = {
  __typename: 'Note';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSONObject']>;
  dataSource: Scalars['String'];
  folderId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NoteImagePayload = {
  __typename: 'NoteImagePayload';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export enum NoteImageType {
  Jpeg = 'jpeg',
  Jpg = 'jpg',
  Png = 'png'
}

export type NoteList = {
  __typename: 'NoteList';
  cursor?: Maybe<Scalars['String']>;
  items: Array<Note>;
};

export enum NoteSortBy {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type NotifyPdfResourceHasSectionsInput = {
  id: Scalars['ID'];
  sections: Array<PdfSectionInput>;
};

export type NotifyResourceHasDocumentErrorInput = {
  error: Scalars['String'];
  id: Scalars['ID'];
};

export type NotifyResourceHasDocumentInput = {
  id: Scalars['ID'];
};

export type NotifyResourceHasFiguresErrorInput = {
  error: Scalars['String'];
  id: Scalars['ID'];
};

export type NotifyResourceHasFiguresInput = {
  figures: Array<FigureInput>;
  id: Scalars['ID'];
};

export type NotifyResourceHasMetadataErrorInput = {
  error: Scalars['String'];
  id: Scalars['ID'];
};

export type NotifyResourceHasMetadataInput = {
  id: Scalars['ID'];
  metadata: ResourceMetadataInput;
};

export type NotifyResourceHasNlpErrorInput = {
  error: Scalars['String'];
  id: Scalars['ID'];
};

export type NotifyResourceHasNlpInput = {
  id: Scalars['ID'];
};

export type NotifyResourceHasSectionsErrorInput = {
  error: Scalars['String'];
  id: Scalars['ID'];
};

export type NotifyResourceHasUploadInput = {
  bucket: Scalars['String'];
  key: Scalars['String'];
};

export type NotifySummaryRequestFailureInput = {
  errorMessage: Scalars['String'];
  id: Scalars['String'];
};

export type NotifyWebResourceHasSectionsInput = {
  id: Scalars['ID'];
  sections: Array<WebSectionInput>;
};

export type Paragraph = {
  __typename: 'Paragraph';
  segments: Array<Maybe<ParagraphSegment>>;
  text: Scalars['String'];
};

export type ParagraphSegment = {
  __typename: 'ParagraphSegment';
  coords: Array<Maybe<DocumentCoord>>;
  offsetEnd: Scalars['Int'];
  offsetStart: Scalars['Int'];
};

export type ParaphraseBlocksInput = {
  blocks: Array<Scalars['String']>;
  projectId: Scalars['String'];
};

export type ParaphraseBlocksOutput = {
  __typename: 'ParaphraseBlocksOutput';
  blocks: Array<Scalars['String']>;
};

export type PaymentMethod = {
  __typename: 'PaymentMethod';
  brand: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['String'];
  isApplePay: Scalars['Boolean'];
  last4: Scalars['String'];
  type: Scalars['String'];
};

export type PaymentMethodIdentifier = {
  __typename: 'PaymentMethodIdentifier';
  id: Scalars['ID'];
  ownerId: Scalars['String'];
};

export type PdfCoord = {
  __typename: 'PdfCoord';
  bbox: Array<Scalars['Float']>;
  page: Scalars['Int'];
};

export type PdfCoordInput = {
  bbox: Array<Scalars['Float']>;
  page: Scalars['Int'];
};

export type PdfDocumentPageAndQuads = {
  __typename: 'PdfDocumentPageAndQuads';
  page: Scalars['Int'];
  quads: Array<PdfDocumentQuad>;
};

export type PdfDocumentPageAndQuadsInput = {
  page: Scalars['Int'];
  quads: Array<PdfDocumentQuadInput>;
};

export type PdfDocumentQuad = {
  __typename: 'PdfDocumentQuad';
  x1: Scalars['Float'];
  x2: Scalars['Float'];
  x3: Scalars['Float'];
  x4: Scalars['Float'];
  y1: Scalars['Float'];
  y2: Scalars['Float'];
  y3: Scalars['Float'];
  y4: Scalars['Float'];
};

export type PdfDocumentQuadInput = {
  x1: Scalars['Float'];
  x2: Scalars['Float'];
  x3: Scalars['Float'];
  x4: Scalars['Float'];
  y1: Scalars['Float'];
  y2: Scalars['Float'];
  y3: Scalars['Float'];
  y4: Scalars['Float'];
};

export type PdfParagraphInput = {
  segments: Array<PdfParagraphSegmentInput>;
  text: Scalars['String'];
};

export type PdfParagraphSegmentInput = {
  coords: Array<PdfCoordInput>;
  offsetEnd: Scalars['Int'];
  offsetStart: Scalars['Int'];
};

export type PdfSectionInput = {
  paragraphs: Array<PdfParagraphInput>;
  title: Scalars['String'];
};

export type Project = {
  __typename: 'Project';
  aiChats: AiChatList;
  allAiChats: AiChatList;
  allFolders: FolderList;
  allNotes: NoteList;
  allResources: ResourceList;
  createdAt: Scalars['DateTime'];
  folders: FolderList;
  id: Scalars['ID'];
  name: Scalars['String'];
  notes: NoteList;
  ownerId: Scalars['String'];
  resources: ResourceList;
  updatedAt: Scalars['DateTime'];
};


export type ProjectAiChatsArgs = {
  options?: InputMaybe<ListAiChatsOptions>;
};


export type ProjectFoldersArgs = {
  options?: InputMaybe<ListFoldersOptions>;
};


export type ProjectNotesArgs = {
  options?: InputMaybe<ListNotesOptions>;
};


export type ProjectResourcesArgs = {
  options?: InputMaybe<ListResourcesOptions>;
};

export type ProjectList = {
  __typename: 'ProjectList';
  cursor?: Maybe<Scalars['String']>;
  items: Array<Project>;
};

export enum ProjectSortBy {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type PromotionCode = {
  __typename: 'PromotionCode';
  amountOff?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  currency?: Maybe<Currency>;
  duration: PromotionDuration;
  durationInMonths?: Maybe<Scalars['Int']>;
  percentOff?: Maybe<Scalars['Int']>;
};

export type PromotionCodeInput = {
  code: Scalars['String'];
  planId: Scalars['ID'];
};

export enum PromotionDuration {
  Forever = 'forever',
  Once = 'once',
  Repeating = 'repeating'
}

export type Prompt = {
  __typename: 'Prompt';
  description: Scalars['String'];
  frequencyPenalty: Scalars['Float'];
  id: Scalars['ID'];
  maxTokens: Scalars['Int'];
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  prefix: Scalars['String'];
  presencePenalty: Scalars['Float'];
  stop: Array<Scalars['String']>;
  suffix: Scalars['String'];
  temperature: Scalars['Float'];
  topP: Scalars['Int'];
};

export type PromptList = {
  __typename: 'PromptList';
  items: Array<Prompt>;
};

export type PromptOptions = {
  description: Scalars['String'];
  frequencyPenalty: Scalars['Float'];
  maxTokens: Scalars['Int'];
  name: Scalars['String'];
  prefix: Scalars['String'];
  presencePenalty: Scalars['Float'];
  stop: Array<Scalars['String']>;
  suffix: Scalars['String'];
  temperature: Scalars['Float'];
  topP: Scalars['Int'];
};

export type PublicUser = {
  __typename: 'PublicUser';
  betaUserExists: Scalars['Boolean'];
  email: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type Quad = {
  __typename: 'Quad';
  x1: Scalars['Float'];
  x2: Scalars['Float'];
  x3: Scalars['Float'];
  x4: Scalars['Float'];
  y1: Scalars['Float'];
  y2: Scalars['Float'];
  y3: Scalars['Float'];
  y4: Scalars['Float'];
};

export type QuadsAndPage = {
  __typename: 'QuadsAndPage';
  page: Scalars['Int'];
  quads: Array<Quad>;
};

export type Query = {
  __typename: 'Query';
  activeSummaryRequest?: Maybe<SummaryRequest>;
  aiChat: AiChat;
  apiKey: ApiKey;
  apiKeys: ApiKeyList;
  askProjectContent: AskContentOutput;
  askResourceContent: AskContentOutput;
  countMigratedUsers: CountMigratedUsersOuput;
  customerLicenseKeys: LicenseKeyList;
  findUser: PublicUser;
  folder: Folder;
  getDeleteQueue: DeleteUserQueue;
  me: User;
  note: Note;
  noteImage: NoteImagePayload;
  project: Project;
  projects: ProjectList;
  promotionCode: PromotionCode;
  prompt: Prompt;
  prompts: PromptList;
  quickSummaries: ResourceList;
  resource: Resource;
  searchByName: SearchByNameOutput;
  searchProjectContent: SearchContentOutput;
  searchResource?: Maybe<SearchResource>;
  searchResourceContent: SearchContentOutput;
  subscriptionPlan: SubscriptionPlan;
  subscriptionPlans: Array<SubscriptionPlan>;
  summariseProjectContent: SummariseContentOutput;
  summariseResourceContent: SummariseContentOutput;
  summarisers: Array<Summariser>;
  user: User;
  userByEmail?: Maybe<User>;
};


export type QueryActiveSummaryRequestArgs = {
  index: Scalars['Int'];
  resourceId: Scalars['ID'];
};


export type QueryAiChatArgs = {
  id: Scalars['ID'];
};


export type QueryApiKeyArgs = {
  pk: Scalars['ID'];
};


export type QueryApiKeysArgs = {
  options?: InputMaybe<ListApiKeysOptions>;
};


export type QueryAskProjectContentArgs = {
  input: AskProjectContentInput;
};


export type QueryAskResourceContentArgs = {
  input: AskResourceContentInput;
};


export type QueryCustomerLicenseKeysArgs = {
  input: CustomerLicenseKeysInput;
};


export type QueryFindUserArgs = {
  input: FindUserInput;
};


export type QueryFolderArgs = {
  id: Scalars['ID'];
};


export type QueryNoteArgs = {
  id: Scalars['ID'];
};


export type QueryNoteImageArgs = {
  id: Scalars['ID'];
  imageId: Scalars['ID'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryProjectsArgs = {
  options?: InputMaybe<ListProjectsOptions>;
};


export type QueryPromotionCodeArgs = {
  input: PromotionCodeInput;
};


export type QueryPromptArgs = {
  id: Scalars['ID'];
};


export type QueryQuickSummariesArgs = {
  options?: InputMaybe<ListResourcesOptions>;
};


export type QueryResourceArgs = {
  id: Scalars['ID'];
};


export type QuerySearchByNameArgs = {
  input: SearchByNameInput;
};


export type QuerySearchProjectContentArgs = {
  input: SearchProjectContentInput;
};


export type QuerySearchResourceArgs = {
  input: SearchResourceInput;
};


export type QuerySearchResourceContentArgs = {
  input: SearchResourceContentInput;
};


export type QuerySubscriptionPlanArgs = {
  id: Scalars['ID'];
};


export type QuerySubscriptionPlansArgs = {
  options: ListSubscriptionPlanOptions;
};


export type QuerySummariseProjectContentArgs = {
  input: SummariseProjectContentInput;
};


export type QuerySummariseResourceContentArgs = {
  input: SummariseResourceContentInput;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};

export type RecomputeSectionSummaryInput = {
  index?: InputMaybe<Scalars['Int']>;
  resourceId: Scalars['String'];
  summariser: SummariserModel;
};

export type RequestAcademicInput = {
  email: Scalars['String'];
};

export type RequestAcademicStatusForUserInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  skipValidation?: InputMaybe<Scalars['Boolean']>;
};

export type RequestAcademicStatusInput = {
  email: Scalars['String'];
};

export type ResetBillingStateInput = {
  id: Scalars['ID'];
};

export type Resource = {
  __typename: 'Resource';
  createdAt: Scalars['DateTime'];
  customName?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['JSONObject']>;
  documentSource: Scalars['String'];
  documentUrl: Scalars['String'];
  errorDocument?: Maybe<Scalars['String']>;
  errorFigures?: Maybe<Scalars['String']>;
  errorMetadata?: Maybe<Scalars['String']>;
  errorNlp?: Maybe<Scalars['String']>;
  errorSections?: Maybe<Scalars['String']>;
  errorUpload?: Maybe<Scalars['String']>;
  figures: Array<Figure>;
  fileName?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  freeSummary: Scalars['Boolean'];
  hasDocument: Scalars['Boolean'];
  hasFigures: Scalars['Boolean'];
  hasMetadata: Scalars['Boolean'];
  hasNlp: Scalars['Boolean'];
  hasSections: Scalars['Boolean'];
  hasUpload: Scalars['Boolean'];
  highlights: Array<ResourceHighlight>;
  id: Scalars['ID'];
  inputSource: Scalars['String'];
  inputUrl: Scalars['String'];
  metadata?: Maybe<ResourceMetadata>;
  name: Scalars['String'];
  nlp?: Maybe<Scalars['JSONObject']>;
  nlpSource: Scalars['String'];
  nlpUrl: Scalars['String'];
  ownerId: Scalars['String'];
  processWithOcr: Scalars['Boolean'];
  projectId?: Maybe<Scalars['String']>;
  putInputUrl: Scalars['String'];
  section: Section;
  sectionSummaryHistory: SectionSummaryList;
  sections: Array<Section>;
  type: ResourceType;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};


export type ResourceSectionArgs = {
  index: Scalars['Int'];
};


export type ResourceSectionSummaryHistoryArgs = {
  input: SectionSummaryHistoryInput;
};

export type ResourceHighlight = {
  __typename: 'ResourceHighlight';
  annotation?: Maybe<Scalars['String']>;
  color: HighlightColor;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mustTransform: Scalars['Boolean'];
  quadsByPage?: Maybe<Array<PdfDocumentPageAndQuads>>;
  range?: Maybe<WebDocumentRange>;
  resourceId: Scalars['String'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ResourceHighlightInput = {
  color: HighlightColor;
  quadsByPage?: InputMaybe<Array<PdfDocumentPageAndQuadsInput>>;
  range?: InputMaybe<WebDocumentRangeInput>;
  text: Scalars['String'];
};

export type ResourceLink = {
  __typename: 'ResourceLink';
  quadsByPage?: Maybe<Array<QuadsAndPage>>;
  range?: Maybe<WebResourceRange>;
  resourceId: Scalars['ID'];
  type: ResourceType;
};

export type ResourceList = {
  __typename: 'ResourceList';
  cursor?: Maybe<Scalars['String']>;
  hasMore: Scalars['Boolean'];
  items: Array<Resource>;
  limit?: Maybe<Scalars['Int']>;
};

export type ResourceMetadata = {
  __typename: 'ResourceMetadata';
  abstract?: Maybe<Scalars['String']>;
  authors: Array<Scalars['String']>;
  doi?: Maybe<Scalars['String']>;
  extractedName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keywords: Array<Scalars['String']>;
  leadImageSource?: Maybe<Scalars['String']>;
  leadImageUrl?: Maybe<Scalars['String']>;
  publication?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type ResourceMetadataInput = {
  abstract?: InputMaybe<Scalars['String']>;
  authors?: InputMaybe<Array<Scalars['String']>>;
  doi?: InputMaybe<Scalars['String']>;
  extractedName?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  leadImageSource?: InputMaybe<Scalars['String']>;
  leadImageUrl?: InputMaybe<Scalars['String']>;
  publication?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export enum ResourceSortBy {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export enum ResourceType {
  Pdf = 'PDF',
  Web = 'WEB'
}

export type RestoreSectionSummaryInput = {
  index: Scalars['Int'];
  resourceId: Scalars['String'];
  versionId: Scalars['Int'];
};

export type RevokedAdminInput = {
  id: Scalars['ID'];
};

export type SearchByNameInput = {
  folderId?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Int']>;
  models?: InputMaybe<Array<MetadataSearchableModel>>;
  name: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type SearchByNameOutput = {
  __typename: 'SearchByNameOutput';
  results: Array<MetadataSearchResult>;
};

export type SearchContentOutput = {
  __typename: 'SearchContentOutput';
  query: Scalars['String'];
  results: Array<SearchContentResult>;
};

export type SearchContentResult = {
  __typename: 'SearchContentResult';
  folderId?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  highlightSegments?: Maybe<Array<SearchContentResultHighlightSegment>>;
  paragraphIdx: Scalars['Int'];
  projectId?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  sectionIdx: Scalars['Int'];
  segments?: Maybe<Array<ParagraphSegment>>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type SearchContentResultHighlightSegment = {
  __typename: 'SearchContentResultHighlightSegment';
  highlight: Scalars['Boolean'];
  text: Scalars['String'];
};

export type SearchProjectContentInput = {
  folderIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  query: Scalars['String'];
  resourceIds?: InputMaybe<Array<Scalars['String']>>;
  topKRetriever?: InputMaybe<Scalars['Int']>;
  useOdin?: InputMaybe<Scalars['Boolean']>;
};

export type SearchResource = {
  __typename: 'SearchResource';
  answers: Array<SearchResourceAnswer>;
  query: Scalars['String'];
};

export type SearchResourceAnswer = {
  __typename: 'SearchResourceAnswer';
  doc: Scalars['String'];
  link: ResourceLink;
  score: Scalars['Float'];
  sent: Scalars['String'];
  sentIdx: Scalars['Int'];
};

export type SearchResourceContentInput = {
  id: Scalars['String'];
  query: Scalars['String'];
  topKRetriever?: InputMaybe<Scalars['Int']>;
  useOdin?: InputMaybe<Scalars['Boolean']>;
};

export type SearchResourceInput = {
  id: Scalars['ID'];
  query: Scalars['String'];
  sectionIdx?: InputMaybe<Scalars['Int']>;
};

export type Section = {
  __typename: 'Section';
  index: Scalars['Int'];
  latestSectionSummary?: Maybe<SectionSummary>;
  latestSummaryVersionId?: Maybe<Scalars['Int']>;
  paragraphs: Array<Paragraph>;
  resourceId: Scalars['String'];
  title: Scalars['String'];
};

export type SectionSummary = {
  __typename: 'SectionSummary';
  contentScore: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  index: Scalars['Int'];
  paragraphs: Array<SummaryParagraph>;
  rating?: Maybe<SummaryRating>;
  request?: Maybe<SummaryRequest>;
  requestId?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  summariser?: Maybe<Summariser>;
  summariserModel: SummariserModel;
  versionId: Scalars['Int'];
};

export type SectionSummaryHistoryInput = {
  cursor?: InputMaybe<Scalars['String']>;
  index: Scalars['Int'];
};

export type SectionSummaryList = {
  __typename: 'SectionSummaryList';
  cursor?: Maybe<Scalars['String']>;
  items: Array<SectionSummary>;
};

export type SendAiChatMessageInput = {
  chatId: Scalars['ID'];
  content: Scalars['String'];
};

export type SetAiChatNameInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SetApiKeyNameInput = {
  name: Scalars['String'];
  pk: Scalars['ID'];
};

export type SetDefaultPaymentMethodInput = {
  id: Scalars['ID'];
};

export type SetDefaultSummariserInput = {
  summariser: SummariserModel;
};

export type SetFolderNameInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SetNoteDataInput = {
  data: Scalars['JSONObject'];
  id: Scalars['ID'];
};

export type SetNoteNameInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SetProjectNameInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SetResourceHighlightAnnotationInput = {
  annotation: Scalars['String'];
  id: Scalars['ID'];
};

export type SetResourceHighlightColorInput = {
  color: HighlightColor;
  id: Scalars['ID'];
};

export type SetResourceNameInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SetResourceProjectInput = {
  id: Scalars['ID'];
  projectId: Scalars['String'];
};

export type SetSectionSummaryRatingInput = {
  index: Scalars['Int'];
  rating?: InputMaybe<SummaryRating>;
  resourceId: Scalars['String'];
  versionId: Scalars['Int'];
};

export type SetSubscriptionPromotionInput = {
  promotionCode: Scalars['String'];
};

export type SetUserNameInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Span = {
  __typename: 'Span';
  end: Scalars['Int'];
  start: Scalars['Int'];
};

export type SpanInput = {
  end: Scalars['Int'];
  start: Scalars['Int'];
};

export enum StatusType {
  Completed = 'completed',
  Failed = 'failed',
  Loading = 'loading'
}

export type Subscription = {
  __typename: 'Subscription';
  onAiChatCreate?: Maybe<AiChat>;
  onAiChatDelete?: Maybe<AiChat>;
  onAiChatMessageCreate?: Maybe<AiChatMessage>;
  onAiChatMessageDelete?: Maybe<AiChatMessage>;
  onAiChatMessageRequestStatusChange?: Maybe<AiChatMessageRequest>;
  onAiChatRename?: Maybe<AiChat>;
  onCreditsChange?: Maybe<User>;
  onProjectCreate?: Maybe<Project>;
  onProjectDelete?: Maybe<Project>;
  onResourceCreate?: Maybe<Resource>;
  onResourceDelete?: Maybe<Resource>;
  onResourceDocument?: Maybe<Resource>;
  onResourceFigures?: Maybe<Resource>;
  onResourceMetadata?: Maybe<Resource>;
  onResourceNlp?: Maybe<Resource>;
  onResourceRename?: Maybe<Resource>;
  onResourceSections?: Maybe<Resource>;
  onResourceSetProject?: Maybe<Resource>;
  onResourceStateChange?: Maybe<Resource>;
  onResourceUpload?: Maybe<Resource>;
  onSubscriptionDelete?: Maybe<UserSubscription>;
  onSubscriptionUpdate?: Maybe<UserSubscription>;
  onSummaryRequestStatusChange?: Maybe<SummaryRequest>;
};

export type SubscriptionAdditionalInfo = {
  __typename: 'SubscriptionAdditionalInfo';
  pendingIntent?: Maybe<SubscriptionPendingIntent>;
  promotion?: Maybe<SubscriptionPromotion>;
  scheduledUpdate?: Maybe<SubscriptionScheduledUpdate>;
};

export type SubscriptionPendingIntent = {
  __typename: 'SubscriptionPendingIntent';
  amount: Scalars['Int'];
  clientSecret: Scalars['String'];
  currency: Currency;
  type: SubscriptionPendingIntentType;
};

export enum SubscriptionPendingIntentType {
  Payment = 'payment',
  Setup = 'setup'
}

export type SubscriptionPlan = {
  __typename: 'SubscriptionPlan';
  amount: Scalars['Int'];
  credits: Scalars['Int'];
  currency: Currency;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  period: SubscriptionPlanPeriod;
  requiresAcademic: Scalars['Boolean'];
  tier: SubscriptionPlanTier;
  trialCredits: Scalars['Int'];
  trialDays: Scalars['Int'];
  version: Scalars['Int'];
};

export enum SubscriptionPlanPeriod {
  Month = 'month',
  Year = 'year'
}

export enum SubscriptionPlanTier {
  Basic = 'basic',
  Pro = 'pro'
}

export type SubscriptionPromotion = {
  __typename: 'SubscriptionPromotion';
  amountOff?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  duration: PromotionDuration;
  durationInMonths?: Maybe<Scalars['Int']>;
  percentOff?: Maybe<Scalars['Int']>;
};

export type SubscriptionScheduledUpdate = {
  __typename: 'SubscriptionScheduledUpdate';
  id: Scalars['ID'];
  plan: SubscriptionPlan;
  startsAt: Scalars['DateTime'];
};

export enum SubscriptionStatus {
  Active = 'active',
  Incomplete = 'incomplete',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing'
}

export type SummariseContentDocument = {
  __typename: 'SummariseContentDocument';
  folderId?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  paragraphIdx: Scalars['Int'];
  projectId?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  sectionIdx: Scalars['Int'];
  segments?: Maybe<Array<ParagraphSegment>>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type SummariseContentOutput = {
  __typename: 'SummariseContentOutput';
  documents?: Maybe<Array<SummariseContentDocument>>;
  query: Scalars['String'];
  summary?: Maybe<SummariseContentSummary>;
};

export type SummariseContentSummary = {
  __typename: 'SummariseContentSummary';
  contentScore: Scalars['Int'];
  context: Scalars['String'];
  text: Scalars['String'];
  wordUsage: Scalars['Int'];
};

export type SummariseProjectContentInput = {
  folderIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  query: Scalars['String'];
  resourceIds?: InputMaybe<Array<Scalars['String']>>;
  topKRetriever?: InputMaybe<Scalars['Int']>;
  useOdin?: InputMaybe<Scalars['Boolean']>;
};

export type SummariseResourceContentInput = {
  id: Scalars['String'];
  query: Scalars['String'];
  topKRetriever?: InputMaybe<Scalars['Int']>;
  useOdin?: InputMaybe<Scalars['Boolean']>;
};

export type SummariseTextInput = {
  text: Scalars['String'];
};

export type Summariser = {
  __typename: 'Summariser';
  consumesCredits?: Maybe<Scalars['Boolean']>;
  distinctId: Scalars['ID'];
  experimentalGroup: Scalars['Int'];
  id: SummariserModel;
  name: Scalars['String'];
  requiresTier: SubscriptionPlanTier;
};

export enum SummariserModel {
  BasicLong = 'basic_long',
  BasicShort = 'basic_short',
  BasicStandard = 'basic_standard',
  Gpt3 = 'gpt3'
}

export type SummaryParagraph = {
  __typename: 'SummaryParagraph';
  span: Array<Span>;
  text: Scalars['String'];
};

export type SummaryParagraphInput = {
  span: Array<SpanInput>;
  text: Scalars['String'];
};

export enum SummaryRating {
  Down = 'down',
  Up = 'up'
}

export type SummaryRequest = {
  __typename: 'SummaryRequest';
  createdAt: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  index: Scalars['Int'];
  resourceId: Scalars['String'];
  sectionSummary?: Maybe<SectionSummary>;
  status: StatusType;
  summariser: SummariserModel;
  updatedAt: Scalars['DateTime'];
};

export type TextSummary = {
  __typename: 'TextSummary';
  summary: Scalars['String'];
  text: Scalars['String'];
};

export type UpdatePromptInput = {
  id: Scalars['ID'];
  options: PromptOptions;
};

export type UpdateResourceSectionSummaryInput = {
  contentScore: Scalars['Int'];
  id: Scalars['String'];
  index: Scalars['Int'];
  paragraphs: Array<SummaryParagraphInput>;
  resourceId: Scalars['String'];
};

export type User = {
  __typename: 'User';
  academic?: Maybe<Academic>;
  canMigrate: Scalars['Boolean'];
  completedTours: Array<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  credits: Credits;
  currency: Currency;
  customer: Scalars['JSONObject'];
  customerId: Scalars['String'];
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  defaultSummariser: SummariserModel;
  deleteAfter?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  everSubscribed: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  freeSummaryCount: Scalars['Int'];
  freeTierType?: Maybe<FreeTierType>;
  hasMigrated: Scalars['Boolean'];
  hasOnboarded: Scalars['Boolean'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  licenseKey?: Maybe<LicenseKey>;
  lifetimeSubscriptionTier?: Maybe<SubscriptionPlanTier>;
  oldUserId?: Maybe<Scalars['ID']>;
  paymentMethods: Array<PaymentMethod>;
  roles: Array<UserRole>;
  shouldDelete: Scalars['Boolean'];
  subscription?: Maybe<UserSubscription>;
  updatedAt: Scalars['DateTime'];
};

export enum UserRole {
  Admin = 'admin',
  Superadmin = 'superadmin'
}

export type UserSubscription = {
  __typename: 'UserSubscription';
  additionalInfo: SubscriptionAdditionalInfo;
  cancelAt?: Maybe<Scalars['DateTime']>;
  cancelAtPeriodEnd: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  currentPeriodEnd: Scalars['DateTime'];
  id: Scalars['ID'];
  plan: SubscriptionPlan;
  planId: Scalars['String'];
  setupCompleted: Scalars['Boolean'];
  status: SubscriptionStatus;
  trialEnd?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type WebCoord = {
  __typename: 'WebCoord';
  nodeKey: Scalars['Int'];
  nodeOffsetEnd: Scalars['Int'];
  nodeOffsetStart: Scalars['Int'];
};

export type WebCoordInput = {
  nodeKey: Scalars['Int'];
  nodeOffsetEnd: Scalars['Int'];
  nodeOffsetStart: Scalars['Int'];
};

export type WebDocumentPoint = {
  __typename: 'WebDocumentPoint';
  key: Scalars['Int'];
  offset: Scalars['Int'];
};

export type WebDocumentPointInput = {
  key: Scalars['Int'];
  offset: Scalars['Int'];
};

export type WebDocumentRange = {
  __typename: 'WebDocumentRange';
  end: WebDocumentPoint;
  start: WebDocumentPoint;
};

export type WebDocumentRangeInput = {
  end: WebDocumentPointInput;
  start: WebDocumentPointInput;
};

export type WebParagraphInput = {
  segments: Array<WebParagraphSegmentInput>;
  text: Scalars['String'];
};

export type WebParagraphSegmentInput = {
  coords: Array<WebCoordInput>;
  offsetEnd: Scalars['Int'];
  offsetStart: Scalars['Int'];
};

export type WebResourcePoint = {
  __typename: 'WebResourcePoint';
  key: Scalars['Int'];
  offset: Scalars['Int'];
};

export type WebResourceRange = {
  __typename: 'WebResourceRange';
  end: WebResourcePoint;
  start: WebResourcePoint;
};

export type WebSectionInput = {
  paragraphs: Array<WebParagraphInput>;
  title: Scalars['String'];
};

export type PromotionCodeCompleteFragment = { __typename: 'PromotionCode', amountOff?: number | null, code: string, currency?: Currency | null, duration: PromotionDuration, durationInMonths?: number | null, percentOff?: number | null };

export type SubscriptionPlanIdentifiableFragment = { __typename: 'SubscriptionPlan', id: string };

export type SubscriptionPlanCompleteFragment = { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string };

export type SubscriptionPendingIntentCompleteFragment = { __typename: 'SubscriptionPendingIntent', amount: number, clientSecret: string, currency: Currency, type: SubscriptionPendingIntentType };

export type SubscriptionScheduledUpdateCompleteFragment = { __typename: 'SubscriptionScheduledUpdate', id: string, startsAt: any, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } };

export type SubscriptionPromotionCompleteFragment = { __typename: 'SubscriptionPromotion', amountOff?: number | null, currency?: Currency | null, duration: PromotionDuration, durationInMonths?: number | null, percentOff?: number | null };

export type UserSubscriptionIdentifiableFragment = { __typename: 'UserSubscription', id: string, userId: string };

export type UserSubscriptionCompleteFragment = { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } };

export type UserSubscriptionWithPendingIntentFragment = { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', pendingIntent?: { __typename: 'SubscriptionPendingIntent', amount: number, clientSecret: string, currency: Currency, type: SubscriptionPendingIntentType } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } };

export type UserSubscriptionWithScheduledUpdateFragment = { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', scheduledUpdate?: { __typename: 'SubscriptionScheduledUpdate', id: string, startsAt: any, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } };

export type UserSubscriptionWithPromotionFragment = { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', promotion?: { __typename: 'SubscriptionPromotion', amountOff?: number | null, currency?: Currency | null, duration: PromotionDuration, durationInMonths?: number | null, percentOff?: number | null } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } };

export type UserSubscriptionWithScheduledUpdateAndPromotionFragment = { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', scheduledUpdate?: { __typename: 'SubscriptionScheduledUpdate', id: string, startsAt: any, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, promotion?: { __typename: 'SubscriptionPromotion', amountOff?: number | null, currency?: Currency | null, duration: PromotionDuration, durationInMonths?: number | null, percentOff?: number | null } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } };

export type UserSubscriptionWithAllAdditionalInfoFragment = { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', pendingIntent?: { __typename: 'SubscriptionPendingIntent', amount: number, clientSecret: string, currency: Currency, type: SubscriptionPendingIntentType } | null, scheduledUpdate?: { __typename: 'SubscriptionScheduledUpdate', id: string, startsAt: any, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, promotion?: { __typename: 'SubscriptionPromotion', amountOff?: number | null, currency?: Currency | null, duration: PromotionDuration, durationInMonths?: number | null, percentOff?: number | null } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } };

export type UserIdentifiableFragment = { __typename: 'User', id: string };

export type CreditsCompleteFragment = { __typename: 'Credits', total: number, paid: number, plan: number };

export type LicenseKeyCompleteFragment = { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier };

export type UserCreditsFragment = { __typename: 'User', id: string, credits: { __typename: 'Credits', total: number, paid: number, plan: number } };

export type AcademicCompleteFragment = { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null };

export type UserCompleteWithoutSubscriptionFragment = { __typename: 'User', createdAt: any, updatedAt: any, roles: Array<UserRole>, email: string, firstName?: string | null, lastName?: string | null, oldUserId?: string | null, hasMigrated: boolean, canMigrate: boolean, defaultSummariser: SummariserModel, completedTours: Array<number>, customerId: string, currency: Currency, everSubscribed: boolean, hasOnboarded: boolean, lifetimeSubscriptionTier?: SubscriptionPlanTier | null, freeTierType?: FreeTierType | null, freeSummaryCount: number, shouldDelete: boolean, deleteAfter?: any | null, id: string, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null, licenseKey?: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } | null };

export type PaymentMethodsCompleteFragment = { __typename: 'User', id: string, defaultPaymentMethodId?: string | null, paymentMethods: Array<{ __typename: 'PaymentMethod', brand: string, expMonth: number, expYear: number, id: string, last4: string, type: string, isApplePay: boolean }> };

export type UserCompleteFragment = { __typename: 'User', createdAt: any, updatedAt: any, roles: Array<UserRole>, email: string, firstName?: string | null, lastName?: string | null, oldUserId?: string | null, hasMigrated: boolean, canMigrate: boolean, defaultSummariser: SummariserModel, completedTours: Array<number>, customerId: string, currency: Currency, everSubscribed: boolean, hasOnboarded: boolean, lifetimeSubscriptionTier?: SubscriptionPlanTier | null, freeTierType?: FreeTierType | null, freeSummaryCount: number, shouldDelete: boolean, deleteAfter?: any | null, id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null, licenseKey?: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } | null };

export type ApiKeyIdentifiableFragment = { __typename: 'ApiKey', pk: string };

export type ApiKeyCompleteFragment = { __typename: 'ApiKey', pk: string, createdAt: any, updatedAt: any, expiresAt: any, ownerId: string, name: string };

export type ResourceHighlightIdentifiableFragment = { __typename: 'ResourceHighlight', id: string, resourceId: string };

export type ResourceHighlightAnnotationFragment = { __typename: 'ResourceHighlight', updatedAt: any, annotation?: string | null, id: string, resourceId: string };

export type ResourceHighlightColorFragment = { __typename: 'ResourceHighlight', updatedAt: any, color: HighlightColor, id: string, resourceId: string };

export type ResourceHighlightCompleteFragment = { __typename: 'ResourceHighlight', createdAt: any, updatedAt: any, text: string, color: HighlightColor, mustTransform: boolean, annotation?: string | null, id: string, resourceId: string, quadsByPage?: Array<{ __typename: 'PdfDocumentPageAndQuads', page: number, quads: Array<{ __typename: 'PdfDocumentQuad', x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number }> }> | null, range?: { __typename: 'WebDocumentRange', start: { __typename: 'WebDocumentPoint', key: number, offset: number }, end: { __typename: 'WebDocumentPoint', key: number, offset: number } } | null };

export type ResourceMetadataCompleteFragment = { __typename: 'ResourceMetadata', abstract?: string | null, authors: Array<string>, doi?: string | null, extractedName?: string | null, id: string, keywords: Array<string>, leadImageSource?: string | null, leadImageUrl?: string | null, publication?: string | null, publishedAt?: string | null, resourceId: string, url?: string | null };

export type FigureCompleteFragment = { __typename: 'Figure', bbox?: Array<number> | null, index: number, nodeKey?: number | null, page?: number | null, resourceId: string, type?: FigureType | null, url: string };

export type SummariserCompleteFragment = { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier };

export type SectionSummaryIdentifiableFragment = { __typename: 'SectionSummary', resourceId: string, index: number, versionId: number };

export type SectionSummaryCompleteFragment = { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null };

export type SummaryRequestCompleteFragment = { __typename: 'SummaryRequest', id: string, resourceId: string, index: number, createdAt: any, updatedAt: any, errorMessage?: string | null, status: StatusType, summariser: SummariserModel, sectionSummary?: { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null } | null };

export type ParagraphSegmentCompleteFragment = { __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> };

export type SectionCompleteFragment = { __typename: 'Section', resourceId: string, index: number, latestSummaryVersionId?: number | null, title: string, paragraphs: Array<{ __typename: 'Paragraph', text: string, segments: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> } | null> }>, latestSectionSummary?: { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null } | null };

export type ResourceIdentifiableFragment = { __typename: 'Resource', id: string, projectId?: string | null, type: ResourceType };

export type ResourceLocatableFragment = { __typename: 'Resource', folderId?: string | null, id: string, projectId?: string | null, type: ResourceType };

export type ResourceNameFragment = { __typename: 'Resource', id: string, name: string, customName?: string | null };

export type ResourcePreviewFragment = { __typename: 'Resource', name: string, id: string, projectId?: string | null, type: ResourceType };

export type ResourceHighlightsFragment = { __typename: 'Resource', id: string, projectId?: string | null, type: ResourceType, highlights: Array<{ __typename: 'ResourceHighlight', createdAt: any, updatedAt: any, text: string, color: HighlightColor, mustTransform: boolean, annotation?: string | null, id: string, resourceId: string, quadsByPage?: Array<{ __typename: 'PdfDocumentPageAndQuads', page: number, quads: Array<{ __typename: 'PdfDocumentQuad', x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number }> }> | null, range?: { __typename: 'WebDocumentRange', start: { __typename: 'WebDocumentPoint', key: number, offset: number }, end: { __typename: 'WebDocumentPoint', key: number, offset: number } } | null }> };

export type ResourceUploadStateFragment = { __typename: 'Resource', hasUpload: boolean, errorUpload?: string | null, id: string, projectId?: string | null, type: ResourceType };

export type ResourceDocumentStateFragment = { __typename: 'Resource', hasDocument: boolean, errorDocument?: string | null, documentUrl: string, id: string, projectId?: string | null, type: ResourceType };

export type ResourceMetadataStateFragment = { __typename: 'Resource', hasMetadata: boolean, errorMetadata?: string | null, name: string, customName?: string | null, freeSummary: boolean, id: string, projectId?: string | null, type: ResourceType, metadata?: { __typename: 'ResourceMetadata', abstract?: string | null, authors: Array<string>, doi?: string | null, extractedName?: string | null, id: string, keywords: Array<string>, leadImageSource?: string | null, leadImageUrl?: string | null, publication?: string | null, publishedAt?: string | null, resourceId: string, url?: string | null } | null };

export type ResourceFiguresStateFragment = { __typename: 'Resource', hasFigures: boolean, errorFigures?: string | null, id: string, projectId?: string | null, type: ResourceType, figures: Array<{ __typename: 'Figure', bbox?: Array<number> | null, index: number, nodeKey?: number | null, page?: number | null, resourceId: string, type?: FigureType | null, url: string }> };

export type ResourceSectionsStateFragment = { __typename: 'Resource', hasSections: boolean, errorSections?: string | null, id: string, projectId?: string | null, type: ResourceType, sections: Array<{ __typename: 'Section', resourceId: string, index: number, latestSummaryVersionId?: number | null, title: string, paragraphs: Array<{ __typename: 'Paragraph', text: string, segments: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> } | null> }>, latestSectionSummary?: { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null } | null }> };

export type ResourceNlpStateFragment = { __typename: 'Resource', hasNlp: boolean, errorNlp?: string | null, nlpUrl: string, id: string, projectId?: string | null, type: ResourceType };

export type ResourceNlpFragment = { __typename: 'Resource', nlp?: any | null, hasNlp: boolean, errorNlp?: string | null, nlpUrl: string, id: string, projectId?: string | null, type: ResourceType };

export type ResourceDocumentFragment = { __typename: 'Resource', document?: any | null, hasDocument: boolean, errorDocument?: string | null, documentUrl: string, id: string, projectId?: string | null, type: ResourceType };

export type ResourceMetaWithoutProjectIdFragment = { __typename: 'Resource', id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null };

export type ResourceQuickSummaryTableItemFragment = { __typename: 'Resource', id: string, ownerId: string, projectId?: string | null, createdAt: any, updatedAt: any, type: ResourceType, name: string, fileName?: string | null, url?: string | null };

export type ResourceMetaFragment = { __typename: 'Resource', projectId?: string | null, id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null };

export type ResourceMetaWithNullableProjectIdFragment = { __typename: 'Resource', id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null, nullableProjectId?: string | null };

export type FolderIdentifiableFragment = { __typename: 'Folder', id: string, projectId: string };

export type FolderLocatableFragment = { __typename: 'Folder', parentFolderId?: string | null, id: string, projectId: string };

export type FolderNameFragment = { __typename: 'Folder', id: string, name: string };

export type FolderMetaFragment = { __typename: 'Folder', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, parentFolderId?: string | null, name: string };

export type NoteIdentifiableFragment = { __typename: 'Note', id: string, projectId: string };

export type NoteLocatableFragment = { __typename: 'Note', folderId?: string | null, id: string, projectId: string };

export type NoteNameFragment = { __typename: 'Note', id: string, name: string };

export type NoteMetaFragment = { __typename: 'Note', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string };

export type NoteCompleteFragment = { __typename: 'Note', data?: any | null, id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string };

export type ProjectIdentifiableFragment = { __typename: 'Project', id: string };

export type ProjectNameFragment = { __typename: 'Project', id: string, name: string };

export type ProjectCompleteFragment = { __typename: 'Project', id: string, createdAt: any, updatedAt: any, ownerId: string, name: string };

export type ProjectWithAllItemMetasFragment = { __typename: 'Project', id: string, createdAt: any, updatedAt: any, ownerId: string, name: string, allResources: { __typename: 'ResourceList', items: Array<{ __typename: 'Resource', projectId?: string | null, id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null }> }, allNotes: { __typename: 'NoteList', items: Array<{ __typename: 'Note', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string }> }, allFolders: { __typename: 'FolderList', items: Array<{ __typename: 'Folder', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, parentFolderId?: string | null, name: string }> } };

export type MetadataSearchResultCompleteFragment = { __typename: 'MetadataSearchResult', score: number, item: { __typename: 'Folder', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, parentFolderId?: string | null, name: string } | { __typename: 'Note', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string } | { __typename: 'Resource', id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null, nullableProjectId?: string | null } };

export type SearchContentResultCompleteFragment = { __typename: 'SearchContentResult', folderId?: string | null, header?: string | null, paragraphIdx: number, projectId?: string | null, resourceId: string, sectionIdx: number, text: string, title?: string | null, userId: string, highlightSegments?: Array<{ __typename: 'SearchContentResultHighlightSegment', highlight: boolean, text: string }> | null, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null };

export type AskContentAnswerCompleteFragment = { __typename: 'AskContentAnswer', answer: string, context: string, folderId?: string | null, header?: string | null, offsetEnd: number, offsetEndInDoc: number, offsetStart: number, offsetStartInDoc: number, paragraphIdx: number, projectId?: string | null, resourceId: string, score: number, sectionIdx: number, title?: string | null, userId: string, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null };

export type SummariseContentDocumentCompleteFragment = { __typename: 'SummariseContentDocument', folderId?: string | null, header?: string | null, paragraphIdx: number, projectId?: string | null, resourceId: string, sectionIdx: number, text: string, title?: string | null, userId: string, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null };

export type SearchResourceCompleteFragment = { __typename: 'SearchResource', query: string, answers: Array<{ __typename: 'SearchResourceAnswer', link: { __typename: 'ResourceLink', type: ResourceType, resourceId: string, range?: { __typename: 'WebResourceRange', start: { __typename: 'WebResourcePoint', key: number, offset: number }, end: { __typename: 'WebResourcePoint', key: number, offset: number } } | null, quadsByPage?: Array<{ __typename: 'QuadsAndPage', page: number, quads: Array<{ __typename: 'Quad', x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number }> }> | null } }> };

export type PromptIdentifiableFragment = { __typename: 'Prompt', id: string, ownerId?: string | null };

export type PromptMetaFragment = { __typename: 'Prompt', name: string, description: string, id: string, ownerId?: string | null };

export type PromptOptionsFragment = { __typename: 'Prompt', prefix: string, suffix: string, frequencyPenalty: number, maxTokens: number, presencePenalty: number, stop: Array<string>, temperature: number, topP: number };

export type PromptCompleteFragment = { __typename: 'Prompt', name: string, description: string, prefix: string, suffix: string, frequencyPenalty: number, maxTokens: number, presencePenalty: number, stop: Array<string>, temperature: number, topP: number, id: string, ownerId?: string | null };

export type AiChatIdentifiableFragment = { __typename: 'AiChat', id: string, projectId: string };

export type AiChatMetaFragment = { __typename: 'AiChat', createdAt: any, updatedAt: any, name: string, id: string, projectId: string };

export type AiChatMessageRequestIdentifiableFragment = { __typename: 'AiChatMessageRequest', id: string, messageId: string };

export type AiChatMessageRequestCompleteFragment = { __typename: 'AiChatMessageRequest', createdAt: any, updatedAt: any, status: AiChatMessageRequestStatus, errorMessage?: string | null, id: string, messageId: string };

export type AiChatMessageReferenceCompleteFragment = { __typename: 'AiChatMessageReference', number: number, resourceId: string, spans: Array<{ __typename: 'Span', start: number, end: number }>, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null };

type AiChatMessageIdentifiable_AiChatBotMessage_Fragment = { __typename: 'AiChatBotMessage', id: string, chatId: string };

type AiChatMessageIdentifiable_AiChatUserMessage_Fragment = { __typename: 'AiChatUserMessage', id: string, chatId: string };

export type AiChatMessageIdentifiableFragment = AiChatMessageIdentifiable_AiChatBotMessage_Fragment | AiChatMessageIdentifiable_AiChatUserMessage_Fragment;

type AiChatMessageComplete_AiChatBotMessage_Fragment = { __typename: 'AiChatBotMessage', query?: string | null, prompt?: string | null, createdAt: any, sender: string, content: string, id: string, chatId: string, references?: Array<{ __typename: 'AiChatMessageReference', number: number, resourceId: string, spans: Array<{ __typename: 'Span', start: number, end: number }>, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null }> | null };

type AiChatMessageComplete_AiChatUserMessage_Fragment = { __typename: 'AiChatUserMessage', createdAt: any, sender: string, content: string, id: string, chatId: string, request?: { __typename: 'AiChatMessageRequest', createdAt: any, updatedAt: any, status: AiChatMessageRequestStatus, errorMessage?: string | null, id: string, messageId: string } | null };

export type AiChatMessageCompleteFragment = AiChatMessageComplete_AiChatBotMessage_Fragment | AiChatMessageComplete_AiChatUserMessage_Fragment;

export type AddCreditsMutationVariables = Exact<{
  input: AddCreditsInput;
}>;


export type AddCreditsMutation = { __typename: 'Mutation', addCredits: { __typename: 'User', id: string, credits: { __typename: 'Credits', total: number, paid: number, plan: number } } };

export type ResetBillingStateMutationVariables = Exact<{
  input: ResetBillingStateInput;
}>;


export type ResetBillingStateMutation = { __typename: 'Mutation', resetBillingState: { __typename: 'User', createdAt: any, updatedAt: any, roles: Array<UserRole>, email: string, firstName?: string | null, lastName?: string | null, oldUserId?: string | null, hasMigrated: boolean, canMigrate: boolean, defaultSummariser: SummariserModel, completedTours: Array<number>, customerId: string, currency: Currency, everSubscribed: boolean, hasOnboarded: boolean, lifetimeSubscriptionTier?: SubscriptionPlanTier | null, freeTierType?: FreeTierType | null, freeSummaryCount: number, shouldDelete: boolean, deleteAfter?: any | null, id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null, licenseKey?: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } | null } };

export type ChangeAssociatedCustomerMutationVariables = Exact<{
  input: ChangeAssociatedCustomerInput;
}>;


export type ChangeAssociatedCustomerMutation = { __typename: 'Mutation', changeAssociatedCustomer: { __typename: 'User', customerId: string, id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null } };

export type ForceSyncSubscriptionMutationVariables = Exact<{
  input: ForceSyncSubscriptionInput;
}>;


export type ForceSyncSubscriptionMutation = { __typename: 'Mutation', forceSyncSubscription: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } };

export type EnableMigrationMutationVariables = Exact<{
  input: EnableMigrationInput;
}>;


export type EnableMigrationMutation = { __typename: 'Mutation', enableMigration: { __typename: 'User', canMigrate: boolean, id: string } };

export type EnableMigrationForNextCohortMutationVariables = Exact<{
  input: EnableMigrationForNextCohortInput;
}>;


export type EnableMigrationForNextCohortMutation = { __typename: 'Mutation', enableMigrationForNextCohort: Array<{ __typename: 'User', email: string, canMigrate: boolean, id: string }> };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename: 'Mutation', createSubscription: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', pendingIntent?: { __typename: 'SubscriptionPendingIntent', amount: number, clientSecret: string, currency: Currency, type: SubscriptionPendingIntentType } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } };

export type ChangeSubscriptionPlanMutationVariables = Exact<{
  input: ChangeSubscriptionPlanInput;
}>;


export type ChangeSubscriptionPlanMutation = { __typename: 'Mutation', changeSubscriptionPlan: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', pendingIntent?: { __typename: 'SubscriptionPendingIntent', amount: number, clientSecret: string, currency: Currency, type: SubscriptionPendingIntentType } | null, scheduledUpdate?: { __typename: 'SubscriptionScheduledUpdate', id: string, startsAt: any, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, promotion?: { __typename: 'SubscriptionPromotion', amountOff?: number | null, currency?: Currency | null, duration: PromotionDuration, durationInMonths?: number | null, percentOff?: number | null } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } };

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscriptionMutation = { __typename: 'Mutation', cancelSubscription: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', scheduledUpdate?: { __typename: 'SubscriptionScheduledUpdate', id: string, startsAt: any, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } };

export type UncancelSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type UncancelSubscriptionMutation = { __typename: 'Mutation', uncancelSubscription: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } };

export type EndSubscriptionTrialMutationVariables = Exact<{ [key: string]: never; }>;


export type EndSubscriptionTrialMutation = { __typename: 'Mutation', endSubscriptionTrial: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', pendingIntent?: { __typename: 'SubscriptionPendingIntent', amount: number, clientSecret: string, currency: Currency, type: SubscriptionPendingIntentType } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } };

export type SetSubscriptionPromotionMutationVariables = Exact<{
  input: SetSubscriptionPromotionInput;
}>;


export type SetSubscriptionPromotionMutation = { __typename: 'Mutation', setSubscriptionPromotion: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', promotion?: { __typename: 'SubscriptionPromotion', amountOff?: number | null, currency?: Currency | null, duration: PromotionDuration, durationInMonths?: number | null, percentOff?: number | null } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } };

export type SetUserNameMutationVariables = Exact<{
  input: SetUserNameInput;
}>;


export type SetUserNameMutation = { __typename: 'Mutation', setUserName: { __typename: 'User', createdAt: any, updatedAt: any, roles: Array<UserRole>, email: string, firstName?: string | null, lastName?: string | null, oldUserId?: string | null, hasMigrated: boolean, canMigrate: boolean, defaultSummariser: SummariserModel, completedTours: Array<number>, customerId: string, currency: Currency, everSubscribed: boolean, hasOnboarded: boolean, lifetimeSubscriptionTier?: SubscriptionPlanTier | null, freeTierType?: FreeTierType | null, freeSummaryCount: number, shouldDelete: boolean, deleteAfter?: any | null, id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null, licenseKey?: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } | null } };

export type CompleteTourMutationVariables = Exact<{
  input: CompleteTourInput;
}>;


export type CompleteTourMutation = { __typename: 'Mutation', completeTour: { __typename: 'User', completedTours: Array<number>, id: string } };

export type CreateApiKeyMutationVariables = Exact<{
  input: CreateApiKeyInput;
}>;


export type CreateApiKeyMutation = { __typename: 'Mutation', createApiKey: { __typename: 'ApiKey', sk?: string | null, pk: string, createdAt: any, updatedAt: any, expiresAt: any, ownerId: string, name: string } };

export type SetApiKeyNameMutationVariables = Exact<{
  input: SetApiKeyNameInput;
}>;


export type SetApiKeyNameMutation = { __typename: 'Mutation', setApiKeyName: { __typename: 'ApiKey', pk: string, createdAt: any, updatedAt: any, expiresAt: any, ownerId: string, name: string } };

export type DeleteApiKeyMutationVariables = Exact<{
  input: DeleteApiKeyInput;
}>;


export type DeleteApiKeyMutation = { __typename: 'Mutation', deleteApiKey: { __typename: 'ApiKey', pk: string, createdAt: any, updatedAt: any, expiresAt: any, ownerId: string, name: string } };

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;


export type CreateProjectMutation = { __typename: 'Mutation', createProject: { __typename: 'Project', id: string, createdAt: any, updatedAt: any, ownerId: string, name: string } };

export type SetProjectNameMutationVariables = Exact<{
  input: SetProjectNameInput;
}>;


export type SetProjectNameMutation = { __typename: 'Mutation', setProjectName: { __typename: 'Project', id: string, name: string } };

export type DeleteProjectMutationVariables = Exact<{
  input: DeleteProjectInput;
}>;


export type DeleteProjectMutation = { __typename: 'Mutation', deleteProject: { __typename: 'Project', id: string, createdAt: any, updatedAt: any, ownerId: string, name: string } };

export type CreateFolderMutationVariables = Exact<{
  input: CreateFolderInput;
}>;


export type CreateFolderMutation = { __typename: 'Mutation', createFolder: { __typename: 'Folder', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, parentFolderId?: string | null, name: string } };

export type SetFolderNameMutationVariables = Exact<{
  input: SetFolderNameInput;
}>;


export type SetFolderNameMutation = { __typename: 'Mutation', setFolderName: { __typename: 'Folder', id: string, name: string } };

export type DeleteFolderMutationVariables = Exact<{
  input: DeleteFolderInput;
}>;


export type DeleteFolderMutation = { __typename: 'Mutation', deleteFolder: { __typename: 'Folder', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, parentFolderId?: string | null, name: string } };

export type CreateNoteMutationVariables = Exact<{
  input: CreateNoteInput;
}>;


export type CreateNoteMutation = { __typename: 'Mutation', createNote: { __typename: 'Note', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string } };

export type SetNoteNameMutationVariables = Exact<{
  input: SetNoteNameInput;
}>;


export type SetNoteNameMutation = { __typename: 'Mutation', setNoteName: { __typename: 'Note', id: string, name: string } };

export type SetNoteDataMutationVariables = Exact<{
  input: SetNoteDataInput;
}>;


export type SetNoteDataMutation = { __typename: 'Mutation', setNoteData: { __typename: 'Note', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string } };

export type DeleteNoteMutationVariables = Exact<{
  input: DeleteNoteInput;
}>;


export type DeleteNoteMutation = { __typename: 'Mutation', deleteNote: { __typename: 'Note', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string } };

export type CreateNoteImageMutationVariables = Exact<{
  input: CreateNoteImageInput;
}>;


export type CreateNoteImageMutation = { __typename: 'Mutation', createNoteImage: { __typename: 'CreateNoteImagePayload', id: string, url: string, putUrl: string } };

export type CreateResourceFromFileMutationVariables = Exact<{
  input: CreateResourceFromFileInput;
}>;


export type CreateResourceFromFileMutation = { __typename: 'Mutation', createResourceFromFile: { __typename: 'Resource', putInputUrl: string, processWithOcr: boolean, projectId?: string | null, id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null } };

export type CreateResourceFromUrlMutationVariables = Exact<{
  input: CreateResourceFromUrlInput;
}>;


export type CreateResourceFromUrlMutation = { __typename: 'Mutation', createResourceFromUrl: { __typename: 'Resource', projectId?: string | null, id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null } };

export type SetResourceProjectMutationVariables = Exact<{
  input: SetResourceProjectInput;
}>;


export type SetResourceProjectMutation = { __typename: 'Mutation', setResourceProject: { __typename: 'Resource', id: string, projectId?: string | null, type: ResourceType } };

export type SetResourceNameMutationVariables = Exact<{
  input: SetResourceNameInput;
}>;


export type SetResourceNameMutation = { __typename: 'Mutation', setResourceName: { __typename: 'Resource', id: string, name: string, customName?: string | null } };

export type DeleteResourceMutationVariables = Exact<{
  input: DeleteResourceInput;
}>;


export type DeleteResourceMutation = { __typename: 'Mutation', deleteResource: { __typename: 'Resource', id: string, projectId?: string | null, type: ResourceType } };

export type CreateResourceHighlightMutationVariables = Exact<{
  input: CreateResourceHighlightInput;
}>;


export type CreateResourceHighlightMutation = { __typename: 'Mutation', createResourceHighlight: { __typename: 'ResourceHighlight', createdAt: any, updatedAt: any, text: string, color: HighlightColor, mustTransform: boolean, annotation?: string | null, id: string, resourceId: string, quadsByPage?: Array<{ __typename: 'PdfDocumentPageAndQuads', page: number, quads: Array<{ __typename: 'PdfDocumentQuad', x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number }> }> | null, range?: { __typename: 'WebDocumentRange', start: { __typename: 'WebDocumentPoint', key: number, offset: number }, end: { __typename: 'WebDocumentPoint', key: number, offset: number } } | null } };

export type SetResourceHighlightColorMutationVariables = Exact<{
  input: SetResourceHighlightColorInput;
}>;


export type SetResourceHighlightColorMutation = { __typename: 'Mutation', setResourceHighlightColor: { __typename: 'ResourceHighlight', updatedAt: any, color: HighlightColor, id: string, resourceId: string } };

export type SetResourceHighlightAnnotationMutationVariables = Exact<{
  input: SetResourceHighlightAnnotationInput;
}>;


export type SetResourceHighlightAnnotationMutation = { __typename: 'Mutation', setResourceHighlightAnnotation: { __typename: 'ResourceHighlight', updatedAt: any, annotation?: string | null, id: string, resourceId: string } };

export type DeleteResourceHighlightMutationVariables = Exact<{
  input: DeleteResourceHighlightInput;
}>;


export type DeleteResourceHighlightMutation = { __typename: 'Mutation', deleteResourceHighlight: { __typename: 'ResourceHighlight', id: string, resourceId: string } };

export type ComputeSectionSummaryMutationVariables = Exact<{
  input: ComputeSectionSummaryInput;
}>;


export type ComputeSectionSummaryMutation = { __typename: 'Mutation', computeSectionSummary: Array<{ __typename: 'SummaryRequest', id: string, resourceId: string, index: number, createdAt: any, updatedAt: any, errorMessage?: string | null, status: StatusType, summariser: SummariserModel, sectionSummary?: { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null } | null }> };

export type RecomputeSectionSummaryMutationVariables = Exact<{
  input: RecomputeSectionSummaryInput;
}>;


export type RecomputeSectionSummaryMutation = { __typename: 'Mutation', recomputeSectionSummary: Array<{ __typename: 'SummaryRequest', id: string, resourceId: string, index: number, createdAt: any, updatedAt: any, errorMessage?: string | null, status: StatusType, summariser: SummariserModel, sectionSummary?: { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null } | null }> };

export type RestoreSectionSummaryMutationVariables = Exact<{
  input: RestoreSectionSummaryInput;
}>;


export type RestoreSectionSummaryMutation = { __typename: 'Mutation', restoreSectionSummary: { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null } };

export type LinkAccountMutationVariables = Exact<{
  input: LinkAccountInput;
}>;


export type LinkAccountMutation = { __typename: 'Mutation', linkAccount: { __typename: 'User', createdAt: any, updatedAt: any, roles: Array<UserRole>, email: string, firstName?: string | null, lastName?: string | null, oldUserId?: string | null, hasMigrated: boolean, canMigrate: boolean, defaultSummariser: SummariserModel, completedTours: Array<number>, customerId: string, currency: Currency, everSubscribed: boolean, hasOnboarded: boolean, lifetimeSubscriptionTier?: SubscriptionPlanTier | null, freeTierType?: FreeTierType | null, freeSummaryCount: number, shouldDelete: boolean, deleteAfter?: any | null, id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null, licenseKey?: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } | null } };

export type MoveResourceMutationVariables = Exact<{
  input: MoveResourceInput;
}>;


export type MoveResourceMutation = { __typename: 'Mutation', moveResource: { __typename: 'Resource', folderId?: string | null, id: string, projectId?: string | null, type: ResourceType } };

export type MoveNoteMutationVariables = Exact<{
  input: MoveNoteInput;
}>;


export type MoveNoteMutation = { __typename: 'Mutation', moveNote: { __typename: 'Note', folderId?: string | null, id: string, projectId: string } };

export type MoveFolderMutationVariables = Exact<{
  input: MoveFolderInput;
}>;


export type MoveFolderMutation = { __typename: 'Mutation', moveFolder: { __typename: 'Folder', parentFolderId?: string | null, id: string, projectId: string } };

export type RequestAcademicStatusForUserMutationVariables = Exact<{
  input: RequestAcademicStatusForUserInput;
}>;


export type RequestAcademicStatusForUserMutation = { __typename: 'Mutation', requestAcademicStatusForUser: { __typename: 'User', id: string, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null } };

export type RequestAcademicStatusMutationVariables = Exact<{
  input: RequestAcademicStatusInput;
}>;


export type RequestAcademicStatusMutation = { __typename: 'Mutation', requestAcademicStatus: { __typename: 'User', id: string, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null } };

export type ActivateAcademicStatusMutationVariables = Exact<{
  input: ActivateAcademicStatusInput;
}>;


export type ActivateAcademicStatusMutation = { __typename: 'Mutation', activateAcademicStatus: { __typename: 'User', id: string, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null } };

export type SetHasOnboardedMutationVariables = Exact<{ [key: string]: never; }>;


export type SetHasOnboardedMutation = { __typename: 'Mutation', setHasOnboarded: { __typename: 'User', hasOnboarded: boolean, id: string } };

export type SetSectionSummaryRatingMutationVariables = Exact<{
  input: SetSectionSummaryRatingInput;
}>;


export type SetSectionSummaryRatingMutation = { __typename: 'Mutation', setSectionSummaryRating: { __typename: 'SectionSummary', rating?: SummaryRating | null, resourceId: string, index: number, versionId: number } };

export type SyncTraitsMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncTraitsMutation = { __typename: 'Mutation', syncTraits?: any | null };

export type SetDefaultSummariserMutationVariables = Exact<{
  input: SetDefaultSummariserInput;
}>;


export type SetDefaultSummariserMutation = { __typename: 'Mutation', setDefaultSummariser: { __typename: 'User', defaultSummariser: SummariserModel, id: string } };

export type SummariseTextMutationVariables = Exact<{
  input: SummariseTextInput;
}>;


export type SummariseTextMutation = { __typename: 'Mutation', summariseText: { __typename: 'TextSummary', text: string, summary: string } };

export type ParaphraseBlocksMutationVariables = Exact<{
  input: ParaphraseBlocksInput;
}>;


export type ParaphraseBlocksMutation = { __typename: 'Mutation', paraphraseBlocks: { __typename: 'ParaphraseBlocksOutput', blocks: Array<string> } };

export type ConsumeLicenseKeyMutationVariables = Exact<{
  input: ConsumeLicenseKeyInput;
}>;


export type ConsumeLicenseKeyMutation = { __typename: 'Mutation', consumeLicenseKey: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } };

export type CreateLicenseKeysMutationVariables = Exact<{
  input: CreateLicenseKeysInput;
}>;


export type CreateLicenseKeysMutation = { __typename: 'Mutation', createLicenseKeys: { __typename: 'LicenseKeyList', items: Array<{ __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier }> } };

export type MigrateBetaMutationVariables = Exact<{ [key: string]: never; }>;


export type MigrateBetaMutation = { __typename: 'Mutation', migrateBeta?: { __typename: 'User', hasMigrated: boolean, id: string } | null };

export type RollbackBetaMigrationMutationVariables = Exact<{ [key: string]: never; }>;


export type RollbackBetaMigrationMutation = { __typename: 'Mutation', rollbackBetaMigration?: { __typename: 'User', hasMigrated: boolean, id: string } | null };

export type CreatePromptMutationVariables = Exact<{
  input: CreatePromptInput;
}>;


export type CreatePromptMutation = { __typename: 'Mutation', createPrompt: { __typename: 'Prompt', name: string, description: string, prefix: string, suffix: string, frequencyPenalty: number, maxTokens: number, presencePenalty: number, stop: Array<string>, temperature: number, topP: number, id: string, ownerId?: string | null } };

export type UpdatePromptMutationVariables = Exact<{
  input: UpdatePromptInput;
}>;


export type UpdatePromptMutation = { __typename: 'Mutation', updatePrompt: { __typename: 'Prompt', name: string, description: string, prefix: string, suffix: string, frequencyPenalty: number, maxTokens: number, presencePenalty: number, stop: Array<string>, temperature: number, topP: number, id: string, ownerId?: string | null } };

export type DeletePromptMutationVariables = Exact<{
  input: DeletePromptInput;
}>;


export type DeletePromptMutation = { __typename: 'Mutation', deletePrompt: { __typename: 'Prompt', id: string, ownerId?: string | null } };

export type CreateCompletionMutationVariables = Exact<{
  input: CreateCompletionInput;
}>;


export type CreateCompletionMutation = { __typename: 'Mutation', createCompletion: { __typename: 'CreateCompletionOutput', text: string } };

export type CreateAiChatMutationVariables = Exact<{
  input: CreateAiChatInput;
}>;


export type CreateAiChatMutation = { __typename: 'Mutation', createAiChat: { __typename: 'AiChat', createdAt: any, updatedAt: any, name: string, id: string, projectId: string } };

export type SetAiChatNameMutationVariables = Exact<{
  input: SetAiChatNameInput;
}>;


export type SetAiChatNameMutation = { __typename: 'Mutation', setAiChatName: { __typename: 'AiChat', createdAt: any, updatedAt: any, name: string, id: string, projectId: string } };

export type DeleteAiChatMutationVariables = Exact<{
  input: DeleteAiChatInput;
}>;


export type DeleteAiChatMutation = { __typename: 'Mutation', deleteAiChat: { __typename: 'AiChat', id: string, projectId: string } };

export type SendAiChatMessageMutationVariables = Exact<{
  input: SendAiChatMessageInput;
}>;


export type SendAiChatMessageMutation = { __typename: 'Mutation', sendAiChatMessage: { __typename: 'AiChatBotMessage', query?: string | null, prompt?: string | null, createdAt: any, sender: string, content: string, id: string, chatId: string, references?: Array<{ __typename: 'AiChatMessageReference', number: number, resourceId: string, spans: Array<{ __typename: 'Span', start: number, end: number }>, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null }> | null } | { __typename: 'AiChatUserMessage', createdAt: any, sender: string, content: string, id: string, chatId: string, request?: { __typename: 'AiChatMessageRequest', createdAt: any, updatedAt: any, status: AiChatMessageRequestStatus, errorMessage?: string | null, id: string, messageId: string } | null } };

export type DeleteAiChatMessageMutationVariables = Exact<{
  input: DeleteAiChatMessageInput;
}>;


export type DeleteAiChatMessageMutation = { __typename: 'Mutation', deleteAiChatMessage: { __typename: 'AiChatBotMessage', id: string, chatId: string } | { __typename: 'AiChatUserMessage', id: string, chatId: string } };

export type DeletePaymentMethodMutationVariables = Exact<{
  input: DeletePaymentMethodInput;
}>;


export type DeletePaymentMethodMutation = { __typename: 'Mutation', deletePaymentMethod: { __typename: 'PaymentMethodIdentifier', id: string, ownerId: string } };

export type SetDefaultPaymentMethodMutationVariables = Exact<{
  input: SetDefaultPaymentMethodInput;
}>;


export type SetDefaultPaymentMethodMutation = { __typename: 'Mutation', setDefaultPaymentMethod: { __typename: 'PaymentMethodIdentifier', id: string, ownerId: string } };

export type CreateSetupIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSetupIntentMutation = { __typename: 'Mutation', createSetupIntent: { __typename: 'SubscriptionPendingIntent', amount: number, clientSecret: string, currency: Currency, type: SubscriptionPendingIntentType } };

export type QueueDeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type QueueDeleteUserMutation = { __typename: 'Mutation', queueDeleteUser: { __typename: 'User', createdAt: any, updatedAt: any, roles: Array<UserRole>, email: string, firstName?: string | null, lastName?: string | null, oldUserId?: string | null, hasMigrated: boolean, canMigrate: boolean, defaultSummariser: SummariserModel, completedTours: Array<number>, customerId: string, currency: Currency, everSubscribed: boolean, hasOnboarded: boolean, lifetimeSubscriptionTier?: SubscriptionPlanTier | null, freeTierType?: FreeTierType | null, freeSummaryCount: number, shouldDelete: boolean, deleteAfter?: any | null, id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null, licenseKey?: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } | null } };

export type UnqueueDeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type UnqueueDeleteUserMutation = { __typename: 'Mutation', unqueueDeleteUser: { __typename: 'User', createdAt: any, updatedAt: any, roles: Array<UserRole>, email: string, firstName?: string | null, lastName?: string | null, oldUserId?: string | null, hasMigrated: boolean, canMigrate: boolean, defaultSummariser: SummariserModel, completedTours: Array<number>, customerId: string, currency: Currency, everSubscribed: boolean, hasOnboarded: boolean, lifetimeSubscriptionTier?: SubscriptionPlanTier | null, freeTierType?: FreeTierType | null, freeSummaryCount: number, shouldDelete: boolean, deleteAfter?: any | null, id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null, licenseKey?: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } | null } };

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = { __typename: 'Mutation', deleteUser: { __typename: 'User', createdAt: any, updatedAt: any, roles: Array<UserRole>, email: string, firstName?: string | null, lastName?: string | null, oldUserId?: string | null, hasMigrated: boolean, canMigrate: boolean, defaultSummariser: SummariserModel, completedTours: Array<number>, customerId: string, currency: Currency, everSubscribed: boolean, hasOnboarded: boolean, lifetimeSubscriptionTier?: SubscriptionPlanTier | null, freeTierType?: FreeTierType | null, freeSummaryCount: number, shouldDelete: boolean, deleteAfter?: any | null, id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null, licenseKey?: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } | null } };

export type PromotionCodeQueryVariables = Exact<{
  input: PromotionCodeInput;
}>;


export type PromotionCodeQuery = { __typename: 'Query', promotionCode: { __typename: 'PromotionCode', amountOff?: number | null, code: string, currency?: Currency | null, duration: PromotionDuration, durationInMonths?: number | null, percentOff?: number | null } };

export type SubscriptionPlansQueryVariables = Exact<{
  options: ListSubscriptionPlanOptions;
}>;


export type SubscriptionPlansQuery = { __typename: 'Query', subscriptionPlans: Array<{ __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string }> };

export type SubscriptionPlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SubscriptionPlanQuery = { __typename: 'Query', subscriptionPlan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename: 'Query', me: { __typename: 'User', createdAt: any, updatedAt: any, roles: Array<UserRole>, email: string, firstName?: string | null, lastName?: string | null, oldUserId?: string | null, hasMigrated: boolean, canMigrate: boolean, defaultSummariser: SummariserModel, completedTours: Array<number>, customerId: string, currency: Currency, everSubscribed: boolean, hasOnboarded: boolean, lifetimeSubscriptionTier?: SubscriptionPlanTier | null, freeTierType?: FreeTierType | null, freeSummaryCount: number, shouldDelete: boolean, deleteAfter?: any | null, id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null, licenseKey?: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } | null } };

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserByEmailQuery = { __typename: 'Query', userByEmail?: { __typename: 'User', createdAt: any, updatedAt: any, roles: Array<UserRole>, email: string, firstName?: string | null, lastName?: string | null, oldUserId?: string | null, hasMigrated: boolean, canMigrate: boolean, defaultSummariser: SummariserModel, completedTours: Array<number>, customerId: string, currency: Currency, everSubscribed: boolean, hasOnboarded: boolean, lifetimeSubscriptionTier?: SubscriptionPlanTier | null, freeTierType?: FreeTierType | null, freeSummaryCount: number, shouldDelete: boolean, deleteAfter?: any | null, id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, academic?: { __typename: 'Academic', email: string, isVerified: boolean, verifiedAt?: any | null } | null, licenseKey?: { __typename: 'LicenseKey', id: string, duration: number, consumedAt?: any | null, userId?: string | null, tier: SubscriptionPlanTier } | null } | null };

export type SubscriptionWithScheduledUpdateAndPromotionQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionWithScheduledUpdateAndPromotionQuery = { __typename: 'Query', me: { __typename: 'User', id: string, credits: { __typename: 'Credits', total: number, paid: number, plan: number }, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', scheduledUpdate?: { __typename: 'SubscriptionScheduledUpdate', id: string, startsAt: any, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null, promotion?: { __typename: 'SubscriptionPromotion', amountOff?: number | null, currency?: Currency | null, duration: PromotionDuration, durationInMonths?: number | null, percentOff?: number | null } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null } };

export type SubscriptionWithPendingIntentQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionWithPendingIntentQuery = { __typename: 'Query', me: { __typename: 'User', id: string, subscription?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, additionalInfo: { __typename: 'SubscriptionAdditionalInfo', pendingIntent?: { __typename: 'SubscriptionPendingIntent', amount: number, clientSecret: string, currency: Currency, type: SubscriptionPendingIntentType } | null }, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null } };

export type ApiKeyQueryVariables = Exact<{
  pk: Scalars['ID'];
}>;


export type ApiKeyQuery = { __typename: 'Query', apiKey: { __typename: 'ApiKey', pk: string, createdAt: any, updatedAt: any, expiresAt: any, ownerId: string, name: string } };

export type ApiKeysQueryVariables = Exact<{
  options?: InputMaybe<ListApiKeysOptions>;
}>;


export type ApiKeysQuery = { __typename: 'Query', apiKeys: { __typename: 'ApiKeyList', cursor?: string | null, items: Array<{ __typename: 'ApiKey', pk: string, createdAt: any, updatedAt: any, expiresAt: any, ownerId: string, name: string }> } };

export type ProjectsQueryVariables = Exact<{
  options?: InputMaybe<ListProjectsOptions>;
}>;


export type ProjectsQuery = { __typename: 'Query', projects: { __typename: 'ProjectList', cursor?: string | null, items: Array<{ __typename: 'Project', id: string, createdAt: any, updatedAt: any, ownerId: string, name: string }> } };

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectQuery = { __typename: 'Query', project: { __typename: 'Project', id: string, createdAt: any, updatedAt: any, ownerId: string, name: string } };

export type ProjectMenuQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectMenuQuery = { __typename: 'Query', project: { __typename: 'Project', id: string, createdAt: any, updatedAt: any, ownerId: string, name: string, allResources: { __typename: 'ResourceList', items: Array<{ __typename: 'Resource', projectId?: string | null, id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null }> }, allNotes: { __typename: 'NoteList', items: Array<{ __typename: 'Note', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string }> }, allFolders: { __typename: 'FolderList', items: Array<{ __typename: 'Folder', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, parentFolderId?: string | null, name: string }> } } };

export type NoteMetaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NoteMetaQuery = { __typename: 'Query', note: { __typename: 'Note', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string } };

export type NoteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NoteQuery = { __typename: 'Query', note: { __typename: 'Note', data?: any | null, id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string } };

export type FolderMetaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FolderMetaQuery = { __typename: 'Query', folder: { __typename: 'Folder', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, parentFolderId?: string | null, name: string } };

export type ResourceMetaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourceMetaQuery = { __typename: 'Query', resource: { __typename: 'Resource', projectId?: string | null, id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null } };

export type NoteImageQueryVariables = Exact<{
  id: Scalars['ID'];
  imageId: Scalars['ID'];
}>;


export type NoteImageQuery = { __typename: 'Query', noteImage: { __typename: 'NoteImagePayload', id: string, url: string } };

export type ResourceHighlightsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourceHighlightsQuery = { __typename: 'Query', resource: { __typename: 'Resource', id: string, projectId?: string | null, type: ResourceType, highlights: Array<{ __typename: 'ResourceHighlight', createdAt: any, updatedAt: any, text: string, color: HighlightColor, mustTransform: boolean, annotation?: string | null, id: string, resourceId: string, quadsByPage?: Array<{ __typename: 'PdfDocumentPageAndQuads', page: number, quads: Array<{ __typename: 'PdfDocumentQuad', x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number }> }> | null, range?: { __typename: 'WebDocumentRange', start: { __typename: 'WebDocumentPoint', key: number, offset: number }, end: { __typename: 'WebDocumentPoint', key: number, offset: number } } | null }> } };

export type ResourcePreviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourcePreviewQuery = { __typename: 'Query', resource: { __typename: 'Resource', name: string, id: string, projectId?: string | null, type: ResourceType } };

export type ResourceOverviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourceOverviewQuery = { __typename: 'Query', resource: { __typename: 'Resource', hasMetadata: boolean, errorMetadata?: string | null, name: string, customName?: string | null, freeSummary: boolean, hasFigures: boolean, errorFigures?: string | null, id: string, projectId?: string | null, type: ResourceType, metadata?: { __typename: 'ResourceMetadata', abstract?: string | null, authors: Array<string>, doi?: string | null, extractedName?: string | null, id: string, keywords: Array<string>, leadImageSource?: string | null, leadImageUrl?: string | null, publication?: string | null, publishedAt?: string | null, resourceId: string, url?: string | null } | null, figures: Array<{ __typename: 'Figure', bbox?: Array<number> | null, index: number, nodeKey?: number | null, page?: number | null, resourceId: string, type?: FigureType | null, url: string }> } };

export type ResourceSectionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourceSectionsQuery = { __typename: 'Query', resource: { __typename: 'Resource', hasSections: boolean, errorSections?: string | null, id: string, projectId?: string | null, type: ResourceType, sections: Array<{ __typename: 'Section', resourceId: string, index: number, latestSummaryVersionId?: number | null, title: string, paragraphs: Array<{ __typename: 'Paragraph', text: string, segments: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> } | null> }>, latestSectionSummary?: { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null } | null }> } };

export type ResourceNlpQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourceNlpQuery = { __typename: 'Query', resource: { __typename: 'Resource', nlp?: any | null, hasNlp: boolean, errorNlp?: string | null, nlpUrl: string, id: string, projectId?: string | null, type: ResourceType } };

export type ResourceDocumentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourceDocumentQuery = { __typename: 'Query', resource: { __typename: 'Resource', document?: any | null, hasDocument: boolean, errorDocument?: string | null, documentUrl: string, id: string, projectId?: string | null, type: ResourceType } };

export type ResourcePdfDownloadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourcePdfDownloadQuery = { __typename: 'Query', resource: { __typename: 'Resource', hasDocument: boolean, errorDocument?: string | null, documentUrl: string, id: string, projectId?: string | null, type: ResourceType, highlights: Array<{ __typename: 'ResourceHighlight', createdAt: any, updatedAt: any, text: string, color: HighlightColor, mustTransform: boolean, annotation?: string | null, id: string, resourceId: string, quadsByPage?: Array<{ __typename: 'PdfDocumentPageAndQuads', page: number, quads: Array<{ __typename: 'PdfDocumentQuad', x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number }> }> | null, range?: { __typename: 'WebDocumentRange', start: { __typename: 'WebDocumentPoint', key: number, offset: number }, end: { __typename: 'WebDocumentPoint', key: number, offset: number } } | null }> } };

export type SectionSummaryHistoryQueryVariables = Exact<{
  id: Scalars['ID'];
  input: SectionSummaryHistoryInput;
}>;


export type SectionSummaryHistoryQuery = { __typename: 'Query', resource: { __typename: 'Resource', id: string, projectId?: string | null, type: ResourceType, sectionSummaryHistory: { __typename: 'SectionSummaryList', items: Array<{ __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null }> } } };

export type ActiveSummaryRequestQueryVariables = Exact<{
  resourceId: Scalars['ID'];
  index: Scalars['Int'];
}>;


export type ActiveSummaryRequestQuery = { __typename: 'Query', activeSummaryRequest?: { __typename: 'SummaryRequest', id: string, resourceId: string, index: number, createdAt: any, updatedAt: any, errorMessage?: string | null, status: StatusType, summariser: SummariserModel, sectionSummary?: { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null } | null } | null };

export type FindUserQueryVariables = Exact<{
  input: FindUserInput;
}>;


export type FindUserQuery = { __typename: 'Query', findUser: { __typename: 'PublicUser', email: string, id?: string | null, betaUserExists: boolean } };

export type SearchByNameQueryVariables = Exact<{
  input: SearchByNameInput;
}>;


export type SearchByNameQuery = { __typename: 'Query', searchByName: { __typename: 'SearchByNameOutput', results: Array<{ __typename: 'MetadataSearchResult', score: number, item: { __typename: 'Folder', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, parentFolderId?: string | null, name: string } | { __typename: 'Note', id: string, createdAt: any, updatedAt: any, ownerId: string, projectId: string, folderId?: string | null, name: string } | { __typename: 'Resource', id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null, nullableProjectId?: string | null } }> } };

export type SearchProjectContentQueryVariables = Exact<{
  input: SearchProjectContentInput;
}>;


export type SearchProjectContentQuery = { __typename: 'Query', searchProjectContent: { __typename: 'SearchContentOutput', query: string, results: Array<{ __typename: 'SearchContentResult', folderId?: string | null, header?: string | null, paragraphIdx: number, projectId?: string | null, resourceId: string, sectionIdx: number, text: string, title?: string | null, userId: string, highlightSegments?: Array<{ __typename: 'SearchContentResultHighlightSegment', highlight: boolean, text: string }> | null, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null }> } };

export type SearchResourceContentQueryVariables = Exact<{
  input: SearchResourceContentInput;
}>;


export type SearchResourceContentQuery = { __typename: 'Query', searchResourceContent: { __typename: 'SearchContentOutput', query: string, results: Array<{ __typename: 'SearchContentResult', folderId?: string | null, header?: string | null, paragraphIdx: number, projectId?: string | null, resourceId: string, sectionIdx: number, text: string, title?: string | null, userId: string, highlightSegments?: Array<{ __typename: 'SearchContentResultHighlightSegment', highlight: boolean, text: string }> | null, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null }> } };

export type AskProjectContentQueryVariables = Exact<{
  input: AskProjectContentInput;
}>;


export type AskProjectContentQuery = { __typename: 'Query', askProjectContent: { __typename: 'AskContentOutput', query: string, noAnsGap?: number | null, answers?: Array<{ __typename: 'AskContentAnswer', answer: string, context: string, folderId?: string | null, header?: string | null, offsetEnd: number, offsetEndInDoc: number, offsetStart: number, offsetStartInDoc: number, paragraphIdx: number, projectId?: string | null, resourceId: string, score: number, sectionIdx: number, title?: string | null, userId: string, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null }> | null } };

export type AskResourceContentQueryVariables = Exact<{
  input: AskResourceContentInput;
}>;


export type AskResourceContentQuery = { __typename: 'Query', askResourceContent: { __typename: 'AskContentOutput', query: string, noAnsGap?: number | null, answers?: Array<{ __typename: 'AskContentAnswer', answer: string, context: string, folderId?: string | null, header?: string | null, offsetEnd: number, offsetEndInDoc: number, offsetStart: number, offsetStartInDoc: number, paragraphIdx: number, projectId?: string | null, resourceId: string, score: number, sectionIdx: number, title?: string | null, userId: string, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null }> | null } };

export type SummariseProjectContentQueryVariables = Exact<{
  input: SummariseProjectContentInput;
}>;


export type SummariseProjectContentQuery = { __typename: 'Query', summariseProjectContent: { __typename: 'SummariseContentOutput', query: string, summary?: { __typename: 'SummariseContentSummary', contentScore: number, context: string, text: string, wordUsage: number } | null, documents?: Array<{ __typename: 'SummariseContentDocument', folderId?: string | null, header?: string | null, paragraphIdx: number, projectId?: string | null, resourceId: string, sectionIdx: number, text: string, title?: string | null, userId: string, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null }> | null } };

export type SummariseResourceContentQueryVariables = Exact<{
  input: SummariseResourceContentInput;
}>;


export type SummariseResourceContentQuery = { __typename: 'Query', summariseResourceContent: { __typename: 'SummariseContentOutput', query: string, summary?: { __typename: 'SummariseContentSummary', contentScore: number, context: string, text: string, wordUsage: number } | null, documents?: Array<{ __typename: 'SummariseContentDocument', folderId?: string | null, header?: string | null, paragraphIdx: number, projectId?: string | null, resourceId: string, sectionIdx: number, text: string, title?: string | null, userId: string, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null }> | null } };

export type SearchResourceQueryVariables = Exact<{
  input: SearchResourceInput;
}>;


export type SearchResourceQuery = { __typename: 'Query', searchResource?: { __typename: 'SearchResource', query: string, answers: Array<{ __typename: 'SearchResourceAnswer', link: { __typename: 'ResourceLink', type: ResourceType, resourceId: string, range?: { __typename: 'WebResourceRange', start: { __typename: 'WebResourcePoint', key: number, offset: number }, end: { __typename: 'WebResourcePoint', key: number, offset: number } } | null, quadsByPage?: Array<{ __typename: 'QuadsAndPage', page: number, quads: Array<{ __typename: 'Quad', x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number }> }> | null } }> } | null };

export type QuickSummariesQueryVariables = Exact<{
  options?: InputMaybe<ListResourcesOptions>;
}>;


export type QuickSummariesQuery = { __typename: 'Query', quickSummaries: { __typename: 'ResourceList', cursor?: string | null, hasMore: boolean, items: Array<{ __typename: 'Resource', id: string, ownerId: string, projectId?: string | null, createdAt: any, updatedAt: any, type: ResourceType, name: string, fileName?: string | null, url?: string | null }> } };

export type SummarisersQueryVariables = Exact<{ [key: string]: never; }>;


export type SummarisersQuery = { __typename: 'Query', summarisers: Array<{ __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier }> };

export type PromptsQueryVariables = Exact<{ [key: string]: never; }>;


export type PromptsQuery = { __typename: 'Query', prompts: { __typename: 'PromptList', items: Array<{ __typename: 'Prompt', name: string, description: string, prefix: string, suffix: string, frequencyPenalty: number, maxTokens: number, presencePenalty: number, stop: Array<string>, temperature: number, topP: number, id: string, ownerId?: string | null }> } };

export type PromptQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PromptQuery = { __typename: 'Query', prompt: { __typename: 'Prompt', name: string, description: string, prefix: string, suffix: string, frequencyPenalty: number, maxTokens: number, presencePenalty: number, stop: Array<string>, temperature: number, topP: number, id: string, ownerId?: string | null } };

export type ResourceNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourceNameQuery = { __typename: 'Query', resource: { __typename: 'Resource', id: string, type: ResourceType, name: string } };

export type FolderNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FolderNameQuery = { __typename: 'Query', folder: { __typename: 'Folder', id: string, name: string } };

export type AiChatMetaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AiChatMetaQuery = { __typename: 'Query', aiChat: { __typename: 'AiChat', createdAt: any, updatedAt: any, name: string, id: string, projectId: string } };

export type AiChatsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type AiChatsQuery = { __typename: 'Query', project: { __typename: 'Project', allAiChats: { __typename: 'AiChatList', items: Array<{ __typename: 'AiChat', createdAt: any, updatedAt: any, name: string, id: string, projectId: string }> } } };

export type AiChatMessagesQueryVariables = Exact<{
  id: Scalars['ID'];
  options?: InputMaybe<ListAiChatMessagesOptions>;
}>;


export type AiChatMessagesQuery = { __typename: 'Query', aiChat: { __typename: 'AiChat', id: string, projectId: string, messages: { __typename: 'AiChatMessageList', cursor?: string | null, hasMore: boolean, limit?: number | null, items: Array<{ __typename: 'AiChatBotMessage', query?: string | null, prompt?: string | null, createdAt: any, sender: string, content: string, id: string, chatId: string, references?: Array<{ __typename: 'AiChatMessageReference', number: number, resourceId: string, spans: Array<{ __typename: 'Span', start: number, end: number }>, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null }> | null } | { __typename: 'AiChatUserMessage', createdAt: any, sender: string, content: string, id: string, chatId: string, request?: { __typename: 'AiChatMessageRequest', createdAt: any, updatedAt: any, status: AiChatMessageRequestStatus, errorMessage?: string | null, id: string, messageId: string } | null }> } } };

export type CountMigratedUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type CountMigratedUsersQuery = { __typename: 'Query', countMigratedUsers: { __typename: 'CountMigratedUsersOuput', count: number } };

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentMethodsQuery = { __typename: 'Query', me: { __typename: 'User', id: string, defaultPaymentMethodId?: string | null, paymentMethods: Array<{ __typename: 'PaymentMethod', brand: string, expMonth: number, expYear: number, id: string, last4: string, type: string, isApplePay: boolean }> } };

export type OnSubscriptionUpdateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnSubscriptionUpdateSubscription = { __typename: 'Subscription', onSubscriptionUpdate?: { __typename: 'UserSubscription', cancelAt?: any | null, cancelAtPeriodEnd: boolean, createdAt: any, currentPeriodEnd: any, planId: string, setupCompleted: boolean, status: SubscriptionStatus, trialEnd?: any | null, id: string, userId: string, plan: { __typename: 'SubscriptionPlan', amount: number, currency: Currency, isActive: boolean, name: string, period: SubscriptionPlanPeriod, requiresAcademic: boolean, tier: SubscriptionPlanTier, version: number, trialDays: number, credits: number, trialCredits: number, id: string } } | null };

export type OnSubscriptionDeleteSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnSubscriptionDeleteSubscription = { __typename: 'Subscription', onSubscriptionDelete?: { __typename: 'UserSubscription', id: string, userId: string } | null };

export type OnProjectCreateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnProjectCreateSubscription = { __typename: 'Subscription', onProjectCreate?: { __typename: 'Project', id: string, createdAt: any, updatedAt: any, ownerId: string, name: string } | null };

export type OnProjectDeleteSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnProjectDeleteSubscription = { __typename: 'Subscription', onProjectDelete?: { __typename: 'Project', id: string, createdAt: any, updatedAt: any, ownerId: string, name: string } | null };

export type OnResourceCreateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnResourceCreateSubscription = { __typename: 'Subscription', onResourceCreate?: { __typename: 'Resource', projectId?: string | null, id: string, createdAt: any, updatedAt: any, ownerId: string, folderId?: string | null, type: ResourceType, name: string, hasDocument: boolean, hasFigures: boolean, hasMetadata: boolean, hasNlp: boolean, hasSections: boolean, hasUpload: boolean, errorDocument?: string | null, errorFigures?: string | null, errorMetadata?: string | null, errorNlp?: string | null, errorSections?: string | null, errorUpload?: string | null } | null };

export type OnResourceSetProjectSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnResourceSetProjectSubscription = { __typename: 'Subscription', onResourceSetProject?: { __typename: 'Resource', id: string, projectId?: string | null, type: ResourceType } | null };

export type OnResourceDeleteSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnResourceDeleteSubscription = { __typename: 'Subscription', onResourceDelete?: { __typename: 'Resource', id: string, projectId?: string | null, type: ResourceType } | null };

export type OnResourceDocumentSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnResourceDocumentSubscription = { __typename: 'Subscription', onResourceDocument?: { __typename: 'Resource', hasDocument: boolean, errorDocument?: string | null, documentUrl: string, id: string, projectId?: string | null, type: ResourceType } | null };

export type OnResourceMetadataSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnResourceMetadataSubscription = { __typename: 'Subscription', onResourceMetadata?: { __typename: 'Resource', hasMetadata: boolean, errorMetadata?: string | null, name: string, customName?: string | null, freeSummary: boolean, id: string, projectId?: string | null, type: ResourceType, metadata?: { __typename: 'ResourceMetadata', abstract?: string | null, authors: Array<string>, doi?: string | null, extractedName?: string | null, id: string, keywords: Array<string>, leadImageSource?: string | null, leadImageUrl?: string | null, publication?: string | null, publishedAt?: string | null, resourceId: string, url?: string | null } | null } | null };

export type OnResourceFiguresSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnResourceFiguresSubscription = { __typename: 'Subscription', onResourceFigures?: { __typename: 'Resource', hasFigures: boolean, errorFigures?: string | null, id: string, projectId?: string | null, type: ResourceType, figures: Array<{ __typename: 'Figure', bbox?: Array<number> | null, index: number, nodeKey?: number | null, page?: number | null, resourceId: string, type?: FigureType | null, url: string }> } | null };

export type OnResourceSectionsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnResourceSectionsSubscription = { __typename: 'Subscription', onResourceSections?: { __typename: 'Resource', hasSections: boolean, errorSections?: string | null, id: string, projectId?: string | null, type: ResourceType, sections: Array<{ __typename: 'Section', resourceId: string, index: number, latestSummaryVersionId?: number | null, title: string, paragraphs: Array<{ __typename: 'Paragraph', text: string, segments: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> } | null> }>, latestSectionSummary?: { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null } | null }> } | null };

export type OnResourceNlpSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnResourceNlpSubscription = { __typename: 'Subscription', onResourceNlp?: { __typename: 'Resource', hasNlp: boolean, errorNlp?: string | null, nlpUrl: string, id: string, projectId?: string | null, type: ResourceType } | null };

export type OnResourceRenameSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnResourceRenameSubscription = { __typename: 'Subscription', onResourceRename?: { __typename: 'Resource', id: string, name: string, customName?: string | null } | null };

export type OnSummaryRequestStatusChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnSummaryRequestStatusChangeSubscription = { __typename: 'Subscription', onSummaryRequestStatusChange?: { __typename: 'SummaryRequest', id: string, resourceId: string, index: number, createdAt: any, updatedAt: any, errorMessage?: string | null, status: StatusType, summariser: SummariserModel, sectionSummary?: { __typename: 'SectionSummary', contentScore: number, createdAt: any, summariserModel: SummariserModel, requestId?: string | null, rating?: SummaryRating | null, resourceId: string, index: number, versionId: number, paragraphs: Array<{ __typename: 'SummaryParagraph', text: string, span: Array<{ __typename: 'Span', start: number, end: number }> }>, summariser?: { __typename: 'Summariser', consumesCredits?: boolean | null, distinctId: string, experimentalGroup: number, id: SummariserModel, name: string, requiresTier: SubscriptionPlanTier } | null } | null } | null };

export type OnCreditsChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCreditsChangeSubscription = { __typename: 'Subscription', onCreditsChange?: { __typename: 'User', id: string, credits: { __typename: 'Credits', total: number, paid: number, plan: number } } | null };

export type OnAiChatCreateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAiChatCreateSubscription = { __typename: 'Subscription', onAiChatCreate?: { __typename: 'AiChat', createdAt: any, updatedAt: any, name: string, id: string, projectId: string } | null };

export type OnAiChatRenameSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAiChatRenameSubscription = { __typename: 'Subscription', onAiChatRename?: { __typename: 'AiChat', createdAt: any, updatedAt: any, name: string, id: string, projectId: string } | null };

export type OnAiChatDeleteSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAiChatDeleteSubscription = { __typename: 'Subscription', onAiChatDelete?: { __typename: 'AiChat', id: string, projectId: string } | null };

export type OnAiChatMessageCreateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAiChatMessageCreateSubscription = { __typename: 'Subscription', onAiChatMessageCreate?: { __typename: 'AiChatBotMessage', query?: string | null, prompt?: string | null, createdAt: any, sender: string, content: string, id: string, chatId: string, references?: Array<{ __typename: 'AiChatMessageReference', number: number, resourceId: string, spans: Array<{ __typename: 'Span', start: number, end: number }>, segments?: Array<{ __typename: 'ParagraphSegment', offsetStart: number, offsetEnd: number, coords: Array<{ __typename: 'PdfCoord', page: number, bbox: Array<number> } | { __typename: 'WebCoord', nodeKey: number, nodeOffsetStart: number, nodeOffsetEnd: number } | null> }> | null }> | null } | { __typename: 'AiChatUserMessage', createdAt: any, sender: string, content: string, id: string, chatId: string, request?: { __typename: 'AiChatMessageRequest', createdAt: any, updatedAt: any, status: AiChatMessageRequestStatus, errorMessage?: string | null, id: string, messageId: string } | null } | null };

export type OnAiChatMessageDeleteSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAiChatMessageDeleteSubscription = { __typename: 'Subscription', onAiChatMessageDelete?: { __typename: 'AiChatBotMessage', id: string, chatId: string } | { __typename: 'AiChatUserMessage', id: string, chatId: string } | null };

export type OnAiChatMessageRequestStatusChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAiChatMessageRequestStatusChangeSubscription = { __typename: 'Subscription', onAiChatMessageRequestStatusChange?: { __typename: 'AiChatMessageRequest', createdAt: any, updatedAt: any, status: AiChatMessageRequestStatus, errorMessage?: string | null, id: string, messageId: string } | null };

export const PromotionCodeCompleteFragmentDoc = gql`
    fragment PromotionCodeComplete on PromotionCode {
  amountOff
  code
  currency
  duration
  durationInMonths
  percentOff
}
    `;
export const UserSubscriptionIdentifiableFragmentDoc = gql`
    fragment UserSubscriptionIdentifiable on UserSubscription {
  id
  userId
}
    `;
export const SubscriptionPlanIdentifiableFragmentDoc = gql`
    fragment SubscriptionPlanIdentifiable on SubscriptionPlan {
  id
}
    `;
export const SubscriptionPlanCompleteFragmentDoc = gql`
    fragment SubscriptionPlanComplete on SubscriptionPlan {
  ...SubscriptionPlanIdentifiable
  amount
  currency
  isActive
  name
  period
  requiresAcademic
  tier
  version
  trialDays
  credits
  trialCredits
}
    ${SubscriptionPlanIdentifiableFragmentDoc}`;
export const UserSubscriptionCompleteFragmentDoc = gql`
    fragment UserSubscriptionComplete on UserSubscription {
  ...UserSubscriptionIdentifiable
  cancelAt
  cancelAtPeriodEnd
  createdAt
  currentPeriodEnd
  plan {
    ...SubscriptionPlanComplete
  }
  planId
  setupCompleted
  status
  trialEnd
}
    ${UserSubscriptionIdentifiableFragmentDoc}
${SubscriptionPlanCompleteFragmentDoc}`;
export const SubscriptionPendingIntentCompleteFragmentDoc = gql`
    fragment SubscriptionPendingIntentComplete on SubscriptionPendingIntent {
  amount
  clientSecret
  currency
  type
}
    `;
export const UserSubscriptionWithPendingIntentFragmentDoc = gql`
    fragment UserSubscriptionWithPendingIntent on UserSubscription {
  ...UserSubscriptionComplete
  additionalInfo {
    pendingIntent {
      ...SubscriptionPendingIntentComplete
    }
  }
}
    ${UserSubscriptionCompleteFragmentDoc}
${SubscriptionPendingIntentCompleteFragmentDoc}`;
export const SubscriptionScheduledUpdateCompleteFragmentDoc = gql`
    fragment SubscriptionScheduledUpdateComplete on SubscriptionScheduledUpdate {
  id
  plan {
    ...SubscriptionPlanComplete
  }
  startsAt
}
    ${SubscriptionPlanCompleteFragmentDoc}`;
export const UserSubscriptionWithScheduledUpdateFragmentDoc = gql`
    fragment UserSubscriptionWithScheduledUpdate on UserSubscription {
  ...UserSubscriptionComplete
  additionalInfo {
    scheduledUpdate {
      ...SubscriptionScheduledUpdateComplete
    }
  }
}
    ${UserSubscriptionCompleteFragmentDoc}
${SubscriptionScheduledUpdateCompleteFragmentDoc}`;
export const SubscriptionPromotionCompleteFragmentDoc = gql`
    fragment SubscriptionPromotionComplete on SubscriptionPromotion {
  amountOff
  currency
  duration
  durationInMonths
  percentOff
}
    `;
export const UserSubscriptionWithPromotionFragmentDoc = gql`
    fragment UserSubscriptionWithPromotion on UserSubscription {
  ...UserSubscriptionComplete
  additionalInfo {
    promotion {
      ...SubscriptionPromotionComplete
    }
  }
}
    ${UserSubscriptionCompleteFragmentDoc}
${SubscriptionPromotionCompleteFragmentDoc}`;
export const UserSubscriptionWithScheduledUpdateAndPromotionFragmentDoc = gql`
    fragment UserSubscriptionWithScheduledUpdateAndPromotion on UserSubscription {
  ...UserSubscriptionComplete
  additionalInfo {
    scheduledUpdate {
      ...SubscriptionScheduledUpdateComplete
    }
    promotion {
      ...SubscriptionPromotionComplete
    }
  }
}
    ${UserSubscriptionCompleteFragmentDoc}
${SubscriptionScheduledUpdateCompleteFragmentDoc}
${SubscriptionPromotionCompleteFragmentDoc}`;
export const UserSubscriptionWithAllAdditionalInfoFragmentDoc = gql`
    fragment UserSubscriptionWithAllAdditionalInfo on UserSubscription {
  ...UserSubscriptionComplete
  additionalInfo {
    pendingIntent {
      ...SubscriptionPendingIntentComplete
    }
    scheduledUpdate {
      ...SubscriptionScheduledUpdateComplete
    }
    promotion {
      ...SubscriptionPromotionComplete
    }
  }
}
    ${UserSubscriptionCompleteFragmentDoc}
${SubscriptionPendingIntentCompleteFragmentDoc}
${SubscriptionScheduledUpdateCompleteFragmentDoc}
${SubscriptionPromotionCompleteFragmentDoc}`;
export const UserIdentifiableFragmentDoc = gql`
    fragment UserIdentifiable on User {
  id
}
    `;
export const CreditsCompleteFragmentDoc = gql`
    fragment CreditsComplete on Credits {
  total
  paid
  plan
}
    `;
export const UserCreditsFragmentDoc = gql`
    fragment UserCredits on User {
  ...UserIdentifiable
  credits {
    ...CreditsComplete
  }
}
    ${UserIdentifiableFragmentDoc}
${CreditsCompleteFragmentDoc}`;
export const PaymentMethodsCompleteFragmentDoc = gql`
    fragment PaymentMethodsComplete on User {
  id
  defaultPaymentMethodId
  paymentMethods {
    brand
    expMonth
    expYear
    id
    last4
    type
    isApplePay
  }
}
    `;
export const AcademicCompleteFragmentDoc = gql`
    fragment AcademicComplete on Academic {
  email
  isVerified
  verifiedAt
}
    `;
export const LicenseKeyCompleteFragmentDoc = gql`
    fragment licenseKeyComplete on LicenseKey {
  id
  duration
  consumedAt
  userId
  tier
}
    `;
export const UserCompleteWithoutSubscriptionFragmentDoc = gql`
    fragment UserCompleteWithoutSubscription on User {
  ...UserIdentifiable
  createdAt
  updatedAt
  roles
  email
  firstName
  lastName
  oldUserId
  hasMigrated
  canMigrate
  defaultSummariser
  completedTours
  customerId
  currency
  everSubscribed
  hasOnboarded
  lifetimeSubscriptionTier
  freeTierType
  freeSummaryCount
  shouldDelete
  deleteAfter
  credits {
    ...CreditsComplete
  }
  academic {
    ...AcademicComplete
  }
  licenseKey {
    ...licenseKeyComplete
  }
}
    ${UserIdentifiableFragmentDoc}
${CreditsCompleteFragmentDoc}
${AcademicCompleteFragmentDoc}
${LicenseKeyCompleteFragmentDoc}`;
export const UserCompleteFragmentDoc = gql`
    fragment UserComplete on User {
  ...UserCompleteWithoutSubscription
  subscription {
    ...UserSubscriptionComplete
  }
}
    ${UserCompleteWithoutSubscriptionFragmentDoc}
${UserSubscriptionCompleteFragmentDoc}`;
export const ApiKeyIdentifiableFragmentDoc = gql`
    fragment ApiKeyIdentifiable on ApiKey {
  pk
}
    `;
export const ApiKeyCompleteFragmentDoc = gql`
    fragment ApiKeyComplete on ApiKey {
  pk
  createdAt
  updatedAt
  expiresAt
  ownerId
  name
}
    `;
export const ResourceHighlightIdentifiableFragmentDoc = gql`
    fragment ResourceHighlightIdentifiable on ResourceHighlight {
  id
  resourceId
}
    `;
export const ResourceHighlightAnnotationFragmentDoc = gql`
    fragment ResourceHighlightAnnotation on ResourceHighlight {
  ...ResourceHighlightIdentifiable
  updatedAt
  annotation
}
    ${ResourceHighlightIdentifiableFragmentDoc}`;
export const ResourceHighlightColorFragmentDoc = gql`
    fragment ResourceHighlightColor on ResourceHighlight {
  ...ResourceHighlightIdentifiable
  updatedAt
  color
}
    ${ResourceHighlightIdentifiableFragmentDoc}`;
export const SectionSummaryIdentifiableFragmentDoc = gql`
    fragment SectionSummaryIdentifiable on SectionSummary {
  resourceId
  index
  versionId
}
    `;
export const SummariserCompleteFragmentDoc = gql`
    fragment SummariserComplete on Summariser {
  consumesCredits
  distinctId
  experimentalGroup
  id
  name
  requiresTier
}
    `;
export const SectionSummaryCompleteFragmentDoc = gql`
    fragment SectionSummaryComplete on SectionSummary {
  ...SectionSummaryIdentifiable
  paragraphs {
    text
    span {
      start
      end
    }
  }
  contentScore
  createdAt
  summariserModel
  summariser {
    ...SummariserComplete
  }
  requestId
  rating
}
    ${SectionSummaryIdentifiableFragmentDoc}
${SummariserCompleteFragmentDoc}`;
export const SummaryRequestCompleteFragmentDoc = gql`
    fragment SummaryRequestComplete on SummaryRequest {
  id
  resourceId
  index
  createdAt
  updatedAt
  errorMessage
  status
  summariser
  sectionSummary {
    ...SectionSummaryComplete
  }
}
    ${SectionSummaryCompleteFragmentDoc}`;
export const ResourceIdentifiableFragmentDoc = gql`
    fragment ResourceIdentifiable on Resource {
  id
  projectId
  type
}
    `;
export const ResourceLocatableFragmentDoc = gql`
    fragment ResourceLocatable on Resource {
  ...ResourceIdentifiable
  folderId
}
    ${ResourceIdentifiableFragmentDoc}`;
export const ResourceNameFragmentDoc = gql`
    fragment ResourceName on Resource {
  id
  name
  customName
}
    `;
export const ResourcePreviewFragmentDoc = gql`
    fragment ResourcePreview on Resource {
  ...ResourceIdentifiable
  name
}
    ${ResourceIdentifiableFragmentDoc}`;
export const ResourceHighlightCompleteFragmentDoc = gql`
    fragment ResourceHighlightComplete on ResourceHighlight {
  ...ResourceHighlightIdentifiable
  createdAt
  updatedAt
  text
  color
  quadsByPage {
    page
    quads {
      x1
      y1
      x2
      y2
      x3
      y3
      x4
      y4
    }
  }
  range {
    start {
      key
      offset
    }
    end {
      key
      offset
    }
  }
  mustTransform
  annotation
}
    ${ResourceHighlightIdentifiableFragmentDoc}`;
export const ResourceHighlightsFragmentDoc = gql`
    fragment ResourceHighlights on Resource {
  ...ResourceIdentifiable
  highlights {
    ...ResourceHighlightComplete
  }
}
    ${ResourceIdentifiableFragmentDoc}
${ResourceHighlightCompleteFragmentDoc}`;
export const ResourceUploadStateFragmentDoc = gql`
    fragment ResourceUploadState on Resource {
  ...ResourceIdentifiable
  hasUpload
  errorUpload
}
    ${ResourceIdentifiableFragmentDoc}`;
export const ResourceMetadataCompleteFragmentDoc = gql`
    fragment ResourceMetadataComplete on ResourceMetadata {
  abstract
  authors
  doi
  extractedName
  id
  keywords
  leadImageSource
  leadImageUrl
  publication
  publishedAt
  resourceId
  url
}
    `;
export const ResourceMetadataStateFragmentDoc = gql`
    fragment ResourceMetadataState on Resource {
  ...ResourceIdentifiable
  hasMetadata
  errorMetadata
  name
  customName
  freeSummary
  metadata {
    ...ResourceMetadataComplete
  }
}
    ${ResourceIdentifiableFragmentDoc}
${ResourceMetadataCompleteFragmentDoc}`;
export const FigureCompleteFragmentDoc = gql`
    fragment FigureComplete on Figure {
  bbox
  index
  nodeKey
  page
  resourceId
  type
  url
}
    `;
export const ResourceFiguresStateFragmentDoc = gql`
    fragment ResourceFiguresState on Resource {
  ...ResourceIdentifiable
  hasFigures
  errorFigures
  figures {
    ...FigureComplete
  }
}
    ${ResourceIdentifiableFragmentDoc}
${FigureCompleteFragmentDoc}`;
export const ParagraphSegmentCompleteFragmentDoc = gql`
    fragment ParagraphSegmentComplete on ParagraphSegment {
  offsetStart
  offsetEnd
  coords {
    ... on PdfCoord {
      page
      bbox
    }
    ... on WebCoord {
      nodeKey
      nodeOffsetStart
      nodeOffsetEnd
    }
  }
}
    `;
export const SectionCompleteFragmentDoc = gql`
    fragment SectionComplete on Section {
  resourceId
  index
  latestSummaryVersionId
  title
  paragraphs {
    text
    segments {
      ...ParagraphSegmentComplete
    }
  }
  latestSectionSummary {
    ...SectionSummaryComplete
  }
}
    ${ParagraphSegmentCompleteFragmentDoc}
${SectionSummaryCompleteFragmentDoc}`;
export const ResourceSectionsStateFragmentDoc = gql`
    fragment ResourceSectionsState on Resource {
  ...ResourceIdentifiable
  hasSections
  errorSections
  sections {
    ...SectionComplete
  }
}
    ${ResourceIdentifiableFragmentDoc}
${SectionCompleteFragmentDoc}`;
export const ResourceNlpStateFragmentDoc = gql`
    fragment ResourceNlpState on Resource {
  ...ResourceIdentifiable
  hasNlp
  errorNlp
  nlpUrl
}
    ${ResourceIdentifiableFragmentDoc}`;
export const ResourceNlpFragmentDoc = gql`
    fragment ResourceNlp on Resource {
  ...ResourceNlpState
  nlp @client
}
    ${ResourceNlpStateFragmentDoc}`;
export const ResourceDocumentStateFragmentDoc = gql`
    fragment ResourceDocumentState on Resource {
  ...ResourceIdentifiable
  hasDocument
  errorDocument
  documentUrl
}
    ${ResourceIdentifiableFragmentDoc}`;
export const ResourceDocumentFragmentDoc = gql`
    fragment ResourceDocument on Resource {
  ...ResourceDocumentState
  document @client
}
    ${ResourceDocumentStateFragmentDoc}`;
export const ResourceQuickSummaryTableItemFragmentDoc = gql`
    fragment ResourceQuickSummaryTableItem on Resource {
  id
  ownerId
  projectId
  createdAt
  updatedAt
  type
  name
  fileName
  url
}
    `;
export const FolderIdentifiableFragmentDoc = gql`
    fragment FolderIdentifiable on Folder {
  id
  projectId
}
    `;
export const FolderLocatableFragmentDoc = gql`
    fragment FolderLocatable on Folder {
  ...FolderIdentifiable
  parentFolderId
}
    ${FolderIdentifiableFragmentDoc}`;
export const FolderNameFragmentDoc = gql`
    fragment FolderName on Folder {
  id
  name
}
    `;
export const NoteIdentifiableFragmentDoc = gql`
    fragment NoteIdentifiable on Note {
  id
  projectId
}
    `;
export const NoteLocatableFragmentDoc = gql`
    fragment NoteLocatable on Note {
  ...NoteIdentifiable
  folderId
}
    ${NoteIdentifiableFragmentDoc}`;
export const NoteNameFragmentDoc = gql`
    fragment NoteName on Note {
  id
  name
}
    `;
export const NoteMetaFragmentDoc = gql`
    fragment NoteMeta on Note {
  id
  createdAt
  updatedAt
  ownerId
  projectId
  folderId
  name
}
    `;
export const NoteCompleteFragmentDoc = gql`
    fragment NoteComplete on Note {
  ...NoteMeta
  data
}
    ${NoteMetaFragmentDoc}`;
export const ProjectIdentifiableFragmentDoc = gql`
    fragment ProjectIdentifiable on Project {
  id
}
    `;
export const ProjectNameFragmentDoc = gql`
    fragment ProjectName on Project {
  id
  name
}
    `;
export const ProjectCompleteFragmentDoc = gql`
    fragment ProjectComplete on Project {
  id
  createdAt
  updatedAt
  ownerId
  name
}
    `;
export const ResourceMetaWithoutProjectIdFragmentDoc = gql`
    fragment ResourceMetaWithoutProjectId on Resource {
  id
  createdAt
  updatedAt
  ownerId
  folderId
  type
  name
  hasDocument
  hasFigures
  hasMetadata
  hasNlp
  hasSections
  hasUpload
  errorDocument
  errorFigures
  errorMetadata
  errorNlp
  errorSections
  errorUpload
}
    `;
export const ResourceMetaFragmentDoc = gql`
    fragment ResourceMeta on Resource {
  ...ResourceMetaWithoutProjectId
  projectId
}
    ${ResourceMetaWithoutProjectIdFragmentDoc}`;
export const FolderMetaFragmentDoc = gql`
    fragment FolderMeta on Folder {
  id
  createdAt
  updatedAt
  ownerId
  projectId
  parentFolderId
  name
}
    `;
export const ProjectWithAllItemMetasFragmentDoc = gql`
    fragment ProjectWithAllItemMetas on Project {
  ...ProjectComplete
  allResources {
    items {
      ...ResourceMeta
    }
  }
  allNotes {
    items {
      ...NoteMeta
    }
  }
  allFolders {
    items {
      ...FolderMeta
    }
  }
}
    ${ProjectCompleteFragmentDoc}
${ResourceMetaFragmentDoc}
${NoteMetaFragmentDoc}
${FolderMetaFragmentDoc}`;
export const ResourceMetaWithNullableProjectIdFragmentDoc = gql`
    fragment ResourceMetaWithNullableProjectId on Resource {
  ...ResourceMetaWithoutProjectId
  nullableProjectId: projectId
}
    ${ResourceMetaWithoutProjectIdFragmentDoc}`;
export const MetadataSearchResultCompleteFragmentDoc = gql`
    fragment MetadataSearchResultComplete on MetadataSearchResult {
  score
  item {
    ... on Folder {
      ...FolderMeta
    }
    ... on Note {
      ...NoteMeta
    }
    ... on Resource {
      ...ResourceMetaWithNullableProjectId
    }
  }
}
    ${FolderMetaFragmentDoc}
${NoteMetaFragmentDoc}
${ResourceMetaWithNullableProjectIdFragmentDoc}`;
export const SearchContentResultCompleteFragmentDoc = gql`
    fragment SearchContentResultComplete on SearchContentResult {
  folderId
  header
  highlightSegments {
    highlight
    text
  }
  paragraphIdx
  projectId
  resourceId
  sectionIdx
  segments {
    ...ParagraphSegmentComplete
  }
  text
  title
  userId
}
    ${ParagraphSegmentCompleteFragmentDoc}`;
export const AskContentAnswerCompleteFragmentDoc = gql`
    fragment AskContentAnswerComplete on AskContentAnswer {
  answer
  context
  folderId
  header
  offsetEnd
  offsetEndInDoc
  offsetStart
  offsetStartInDoc
  paragraphIdx
  projectId
  resourceId
  score
  sectionIdx
  segments {
    ...ParagraphSegmentComplete
  }
  title
  userId
}
    ${ParagraphSegmentCompleteFragmentDoc}`;
export const SummariseContentDocumentCompleteFragmentDoc = gql`
    fragment SummariseContentDocumentComplete on SummariseContentDocument {
  folderId
  header
  paragraphIdx
  projectId
  resourceId
  sectionIdx
  segments {
    ...ParagraphSegmentComplete
  }
  text
  title
  userId
}
    ${ParagraphSegmentCompleteFragmentDoc}`;
export const SearchResourceCompleteFragmentDoc = gql`
    fragment SearchResourceComplete on SearchResource {
  query
  answers {
    link {
      type
      resourceId
      range {
        start {
          key
          offset
        }
        end {
          key
          offset
        }
      }
      quadsByPage {
        page
        quads {
          x1
          y1
          x2
          y2
          x3
          y3
          x4
          y4
        }
      }
    }
  }
}
    `;
export const PromptIdentifiableFragmentDoc = gql`
    fragment PromptIdentifiable on Prompt {
  id
  ownerId
}
    `;
export const PromptMetaFragmentDoc = gql`
    fragment PromptMeta on Prompt {
  ...PromptIdentifiable
  name
  description
}
    ${PromptIdentifiableFragmentDoc}`;
export const PromptOptionsFragmentDoc = gql`
    fragment PromptOptions on Prompt {
  prefix
  suffix
  frequencyPenalty
  maxTokens
  presencePenalty
  stop
  temperature
  topP
}
    `;
export const PromptCompleteFragmentDoc = gql`
    fragment PromptComplete on Prompt {
  ...PromptMeta
  ...PromptOptions
}
    ${PromptMetaFragmentDoc}
${PromptOptionsFragmentDoc}`;
export const AiChatIdentifiableFragmentDoc = gql`
    fragment AiChatIdentifiable on AiChat {
  id
  projectId
}
    `;
export const AiChatMetaFragmentDoc = gql`
    fragment AiChatMeta on AiChat {
  ...AiChatIdentifiable
  createdAt
  updatedAt
  name
}
    ${AiChatIdentifiableFragmentDoc}`;
export const AiChatMessageIdentifiableFragmentDoc = gql`
    fragment AiChatMessageIdentifiable on AiChatMessage {
  id
  chatId
}
    `;
export const AiChatMessageRequestIdentifiableFragmentDoc = gql`
    fragment AiChatMessageRequestIdentifiable on AiChatMessageRequest {
  id
  messageId
}
    `;
export const AiChatMessageRequestCompleteFragmentDoc = gql`
    fragment AiChatMessageRequestComplete on AiChatMessageRequest {
  ...AiChatMessageRequestIdentifiable
  createdAt
  updatedAt
  status
  errorMessage
}
    ${AiChatMessageRequestIdentifiableFragmentDoc}`;
export const AiChatMessageReferenceCompleteFragmentDoc = gql`
    fragment AiChatMessageReferenceComplete on AiChatMessageReference {
  number
  spans {
    start
    end
  }
  resourceId
  segments {
    ...ParagraphSegmentComplete
  }
}
    ${ParagraphSegmentCompleteFragmentDoc}`;
export const AiChatMessageCompleteFragmentDoc = gql`
    fragment AiChatMessageComplete on AiChatMessage {
  ...AiChatMessageIdentifiable
  createdAt
  sender
  content
  ... on AiChatUserMessage {
    request {
      ...AiChatMessageRequestComplete
    }
  }
  ... on AiChatBotMessage {
    query
    prompt
    references {
      ...AiChatMessageReferenceComplete
    }
  }
}
    ${AiChatMessageIdentifiableFragmentDoc}
${AiChatMessageRequestCompleteFragmentDoc}
${AiChatMessageReferenceCompleteFragmentDoc}`;
export const AddCreditsDocument = gql`
    mutation AddCredits($input: AddCreditsInput!) {
  addCredits(input: $input) {
    ...UserCredits
  }
}
    ${UserCreditsFragmentDoc}`;
export type AddCreditsMutationFn = Apollo.MutationFunction<AddCreditsMutation, AddCreditsMutationVariables>;

/**
 * __useAddCreditsMutation__
 *
 * To run a mutation, you first call `useAddCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCreditsMutation, { data, loading, error }] = useAddCreditsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCreditsMutation(baseOptions?: Apollo.MutationHookOptions<AddCreditsMutation, AddCreditsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCreditsMutation, AddCreditsMutationVariables>(AddCreditsDocument, options);
      }
export type AddCreditsMutationHookResult = ReturnType<typeof useAddCreditsMutation>;
export type AddCreditsMutationResult = Apollo.MutationResult<AddCreditsMutation>;
export type AddCreditsMutationOptions = Apollo.BaseMutationOptions<AddCreditsMutation, AddCreditsMutationVariables>;
export const ResetBillingStateDocument = gql`
    mutation ResetBillingState($input: ResetBillingStateInput!) {
  resetBillingState(input: $input) {
    ...UserComplete
  }
}
    ${UserCompleteFragmentDoc}`;
export type ResetBillingStateMutationFn = Apollo.MutationFunction<ResetBillingStateMutation, ResetBillingStateMutationVariables>;

/**
 * __useResetBillingStateMutation__
 *
 * To run a mutation, you first call `useResetBillingStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetBillingStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetBillingStateMutation, { data, loading, error }] = useResetBillingStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetBillingStateMutation(baseOptions?: Apollo.MutationHookOptions<ResetBillingStateMutation, ResetBillingStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetBillingStateMutation, ResetBillingStateMutationVariables>(ResetBillingStateDocument, options);
      }
export type ResetBillingStateMutationHookResult = ReturnType<typeof useResetBillingStateMutation>;
export type ResetBillingStateMutationResult = Apollo.MutationResult<ResetBillingStateMutation>;
export type ResetBillingStateMutationOptions = Apollo.BaseMutationOptions<ResetBillingStateMutation, ResetBillingStateMutationVariables>;
export const ChangeAssociatedCustomerDocument = gql`
    mutation ChangeAssociatedCustomer($input: ChangeAssociatedCustomerInput!) {
  changeAssociatedCustomer(input: $input) {
    ...UserIdentifiable
    customerId
    subscription {
      ...UserSubscriptionComplete
    }
  }
}
    ${UserIdentifiableFragmentDoc}
${UserSubscriptionCompleteFragmentDoc}`;
export type ChangeAssociatedCustomerMutationFn = Apollo.MutationFunction<ChangeAssociatedCustomerMutation, ChangeAssociatedCustomerMutationVariables>;

/**
 * __useChangeAssociatedCustomerMutation__
 *
 * To run a mutation, you first call `useChangeAssociatedCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAssociatedCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAssociatedCustomerMutation, { data, loading, error }] = useChangeAssociatedCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeAssociatedCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAssociatedCustomerMutation, ChangeAssociatedCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAssociatedCustomerMutation, ChangeAssociatedCustomerMutationVariables>(ChangeAssociatedCustomerDocument, options);
      }
export type ChangeAssociatedCustomerMutationHookResult = ReturnType<typeof useChangeAssociatedCustomerMutation>;
export type ChangeAssociatedCustomerMutationResult = Apollo.MutationResult<ChangeAssociatedCustomerMutation>;
export type ChangeAssociatedCustomerMutationOptions = Apollo.BaseMutationOptions<ChangeAssociatedCustomerMutation, ChangeAssociatedCustomerMutationVariables>;
export const ForceSyncSubscriptionDocument = gql`
    mutation ForceSyncSubscription($input: ForceSyncSubscriptionInput!) {
  forceSyncSubscription(input: $input) {
    ...UserSubscriptionComplete
  }
}
    ${UserSubscriptionCompleteFragmentDoc}`;
export type ForceSyncSubscriptionMutationFn = Apollo.MutationFunction<ForceSyncSubscriptionMutation, ForceSyncSubscriptionMutationVariables>;

/**
 * __useForceSyncSubscriptionMutation__
 *
 * To run a mutation, you first call `useForceSyncSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceSyncSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceSyncSubscriptionMutation, { data, loading, error }] = useForceSyncSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForceSyncSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ForceSyncSubscriptionMutation, ForceSyncSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForceSyncSubscriptionMutation, ForceSyncSubscriptionMutationVariables>(ForceSyncSubscriptionDocument, options);
      }
export type ForceSyncSubscriptionMutationHookResult = ReturnType<typeof useForceSyncSubscriptionMutation>;
export type ForceSyncSubscriptionMutationResult = Apollo.MutationResult<ForceSyncSubscriptionMutation>;
export type ForceSyncSubscriptionMutationOptions = Apollo.BaseMutationOptions<ForceSyncSubscriptionMutation, ForceSyncSubscriptionMutationVariables>;
export const EnableMigrationDocument = gql`
    mutation EnableMigration($input: EnableMigrationInput!) {
  enableMigration(input: $input) {
    ...UserIdentifiable
    canMigrate
  }
}
    ${UserIdentifiableFragmentDoc}`;
export type EnableMigrationMutationFn = Apollo.MutationFunction<EnableMigrationMutation, EnableMigrationMutationVariables>;

/**
 * __useEnableMigrationMutation__
 *
 * To run a mutation, you first call `useEnableMigrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableMigrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableMigrationMutation, { data, loading, error }] = useEnableMigrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableMigrationMutation(baseOptions?: Apollo.MutationHookOptions<EnableMigrationMutation, EnableMigrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableMigrationMutation, EnableMigrationMutationVariables>(EnableMigrationDocument, options);
      }
export type EnableMigrationMutationHookResult = ReturnType<typeof useEnableMigrationMutation>;
export type EnableMigrationMutationResult = Apollo.MutationResult<EnableMigrationMutation>;
export type EnableMigrationMutationOptions = Apollo.BaseMutationOptions<EnableMigrationMutation, EnableMigrationMutationVariables>;
export const EnableMigrationForNextCohortDocument = gql`
    mutation EnableMigrationForNextCohort($input: EnableMigrationForNextCohortInput!) {
  enableMigrationForNextCohort(input: $input) {
    ...UserIdentifiable
    email
    canMigrate
  }
}
    ${UserIdentifiableFragmentDoc}`;
export type EnableMigrationForNextCohortMutationFn = Apollo.MutationFunction<EnableMigrationForNextCohortMutation, EnableMigrationForNextCohortMutationVariables>;

/**
 * __useEnableMigrationForNextCohortMutation__
 *
 * To run a mutation, you first call `useEnableMigrationForNextCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableMigrationForNextCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableMigrationForNextCohortMutation, { data, loading, error }] = useEnableMigrationForNextCohortMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableMigrationForNextCohortMutation(baseOptions?: Apollo.MutationHookOptions<EnableMigrationForNextCohortMutation, EnableMigrationForNextCohortMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableMigrationForNextCohortMutation, EnableMigrationForNextCohortMutationVariables>(EnableMigrationForNextCohortDocument, options);
      }
export type EnableMigrationForNextCohortMutationHookResult = ReturnType<typeof useEnableMigrationForNextCohortMutation>;
export type EnableMigrationForNextCohortMutationResult = Apollo.MutationResult<EnableMigrationForNextCohortMutation>;
export type EnableMigrationForNextCohortMutationOptions = Apollo.BaseMutationOptions<EnableMigrationForNextCohortMutation, EnableMigrationForNextCohortMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    ...UserSubscriptionWithPendingIntent
  }
}
    ${UserSubscriptionWithPendingIntentFragmentDoc}`;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const ChangeSubscriptionPlanDocument = gql`
    mutation ChangeSubscriptionPlan($input: ChangeSubscriptionPlanInput!) {
  changeSubscriptionPlan(input: $input) {
    ...UserSubscriptionWithAllAdditionalInfo
  }
}
    ${UserSubscriptionWithAllAdditionalInfoFragmentDoc}`;
export type ChangeSubscriptionPlanMutationFn = Apollo.MutationFunction<ChangeSubscriptionPlanMutation, ChangeSubscriptionPlanMutationVariables>;

/**
 * __useChangeSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useChangeSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubscriptionPlanMutation, { data, loading, error }] = useChangeSubscriptionPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSubscriptionPlanMutation, ChangeSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSubscriptionPlanMutation, ChangeSubscriptionPlanMutationVariables>(ChangeSubscriptionPlanDocument, options);
      }
export type ChangeSubscriptionPlanMutationHookResult = ReturnType<typeof useChangeSubscriptionPlanMutation>;
export type ChangeSubscriptionPlanMutationResult = Apollo.MutationResult<ChangeSubscriptionPlanMutation>;
export type ChangeSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<ChangeSubscriptionPlanMutation, ChangeSubscriptionPlanMutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription {
  cancelSubscription {
    ...UserSubscriptionWithScheduledUpdate
  }
}
    ${UserSubscriptionWithScheduledUpdateFragmentDoc}`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const UncancelSubscriptionDocument = gql`
    mutation UncancelSubscription {
  uncancelSubscription {
    ...UserSubscriptionComplete
  }
}
    ${UserSubscriptionCompleteFragmentDoc}`;
export type UncancelSubscriptionMutationFn = Apollo.MutationFunction<UncancelSubscriptionMutation, UncancelSubscriptionMutationVariables>;

/**
 * __useUncancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useUncancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUncancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uncancelSubscriptionMutation, { data, loading, error }] = useUncancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useUncancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UncancelSubscriptionMutation, UncancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UncancelSubscriptionMutation, UncancelSubscriptionMutationVariables>(UncancelSubscriptionDocument, options);
      }
export type UncancelSubscriptionMutationHookResult = ReturnType<typeof useUncancelSubscriptionMutation>;
export type UncancelSubscriptionMutationResult = Apollo.MutationResult<UncancelSubscriptionMutation>;
export type UncancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<UncancelSubscriptionMutation, UncancelSubscriptionMutationVariables>;
export const EndSubscriptionTrialDocument = gql`
    mutation EndSubscriptionTrial {
  endSubscriptionTrial {
    ...UserSubscriptionWithPendingIntent
  }
}
    ${UserSubscriptionWithPendingIntentFragmentDoc}`;
export type EndSubscriptionTrialMutationFn = Apollo.MutationFunction<EndSubscriptionTrialMutation, EndSubscriptionTrialMutationVariables>;

/**
 * __useEndSubscriptionTrialMutation__
 *
 * To run a mutation, you first call `useEndSubscriptionTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndSubscriptionTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endSubscriptionTrialMutation, { data, loading, error }] = useEndSubscriptionTrialMutation({
 *   variables: {
 *   },
 * });
 */
export function useEndSubscriptionTrialMutation(baseOptions?: Apollo.MutationHookOptions<EndSubscriptionTrialMutation, EndSubscriptionTrialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndSubscriptionTrialMutation, EndSubscriptionTrialMutationVariables>(EndSubscriptionTrialDocument, options);
      }
export type EndSubscriptionTrialMutationHookResult = ReturnType<typeof useEndSubscriptionTrialMutation>;
export type EndSubscriptionTrialMutationResult = Apollo.MutationResult<EndSubscriptionTrialMutation>;
export type EndSubscriptionTrialMutationOptions = Apollo.BaseMutationOptions<EndSubscriptionTrialMutation, EndSubscriptionTrialMutationVariables>;
export const SetSubscriptionPromotionDocument = gql`
    mutation SetSubscriptionPromotion($input: SetSubscriptionPromotionInput!) {
  setSubscriptionPromotion(input: $input) {
    ...UserSubscriptionWithPromotion
  }
}
    ${UserSubscriptionWithPromotionFragmentDoc}`;
export type SetSubscriptionPromotionMutationFn = Apollo.MutationFunction<SetSubscriptionPromotionMutation, SetSubscriptionPromotionMutationVariables>;

/**
 * __useSetSubscriptionPromotionMutation__
 *
 * To run a mutation, you first call `useSetSubscriptionPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubscriptionPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubscriptionPromotionMutation, { data, loading, error }] = useSetSubscriptionPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSubscriptionPromotionMutation(baseOptions?: Apollo.MutationHookOptions<SetSubscriptionPromotionMutation, SetSubscriptionPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSubscriptionPromotionMutation, SetSubscriptionPromotionMutationVariables>(SetSubscriptionPromotionDocument, options);
      }
export type SetSubscriptionPromotionMutationHookResult = ReturnType<typeof useSetSubscriptionPromotionMutation>;
export type SetSubscriptionPromotionMutationResult = Apollo.MutationResult<SetSubscriptionPromotionMutation>;
export type SetSubscriptionPromotionMutationOptions = Apollo.BaseMutationOptions<SetSubscriptionPromotionMutation, SetSubscriptionPromotionMutationVariables>;
export const SetUserNameDocument = gql`
    mutation SetUserName($input: SetUserNameInput!) {
  setUserName(input: $input) {
    ...UserComplete
  }
}
    ${UserCompleteFragmentDoc}`;
export type SetUserNameMutationFn = Apollo.MutationFunction<SetUserNameMutation, SetUserNameMutationVariables>;

/**
 * __useSetUserNameMutation__
 *
 * To run a mutation, you first call `useSetUserNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserNameMutation, { data, loading, error }] = useSetUserNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserNameMutation(baseOptions?: Apollo.MutationHookOptions<SetUserNameMutation, SetUserNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserNameMutation, SetUserNameMutationVariables>(SetUserNameDocument, options);
      }
export type SetUserNameMutationHookResult = ReturnType<typeof useSetUserNameMutation>;
export type SetUserNameMutationResult = Apollo.MutationResult<SetUserNameMutation>;
export type SetUserNameMutationOptions = Apollo.BaseMutationOptions<SetUserNameMutation, SetUserNameMutationVariables>;
export const CompleteTourDocument = gql`
    mutation CompleteTour($input: CompleteTourInput!) {
  completeTour(input: $input) {
    ...UserIdentifiable
    completedTours
  }
}
    ${UserIdentifiableFragmentDoc}`;
export type CompleteTourMutationFn = Apollo.MutationFunction<CompleteTourMutation, CompleteTourMutationVariables>;

/**
 * __useCompleteTourMutation__
 *
 * To run a mutation, you first call `useCompleteTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTourMutation, { data, loading, error }] = useCompleteTourMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteTourMutation(baseOptions?: Apollo.MutationHookOptions<CompleteTourMutation, CompleteTourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteTourMutation, CompleteTourMutationVariables>(CompleteTourDocument, options);
      }
export type CompleteTourMutationHookResult = ReturnType<typeof useCompleteTourMutation>;
export type CompleteTourMutationResult = Apollo.MutationResult<CompleteTourMutation>;
export type CompleteTourMutationOptions = Apollo.BaseMutationOptions<CompleteTourMutation, CompleteTourMutationVariables>;
export const CreateApiKeyDocument = gql`
    mutation CreateApiKey($input: CreateApiKeyInput!) {
  createApiKey(input: $input) {
    ...ApiKeyComplete
    sk
  }
}
    ${ApiKeyCompleteFragmentDoc}`;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const SetApiKeyNameDocument = gql`
    mutation SetApiKeyName($input: SetApiKeyNameInput!) {
  setApiKeyName(input: $input) {
    ...ApiKeyComplete
  }
}
    ${ApiKeyCompleteFragmentDoc}`;
export type SetApiKeyNameMutationFn = Apollo.MutationFunction<SetApiKeyNameMutation, SetApiKeyNameMutationVariables>;

/**
 * __useSetApiKeyNameMutation__
 *
 * To run a mutation, you first call `useSetApiKeyNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApiKeyNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApiKeyNameMutation, { data, loading, error }] = useSetApiKeyNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetApiKeyNameMutation(baseOptions?: Apollo.MutationHookOptions<SetApiKeyNameMutation, SetApiKeyNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetApiKeyNameMutation, SetApiKeyNameMutationVariables>(SetApiKeyNameDocument, options);
      }
export type SetApiKeyNameMutationHookResult = ReturnType<typeof useSetApiKeyNameMutation>;
export type SetApiKeyNameMutationResult = Apollo.MutationResult<SetApiKeyNameMutation>;
export type SetApiKeyNameMutationOptions = Apollo.BaseMutationOptions<SetApiKeyNameMutation, SetApiKeyNameMutationVariables>;
export const DeleteApiKeyDocument = gql`
    mutation DeleteApiKey($input: DeleteApiKeyInput!) {
  deleteApiKey(input: $input) {
    ...ApiKeyComplete
  }
}
    ${ApiKeyCompleteFragmentDoc}`;
export type DeleteApiKeyMutationFn = Apollo.MutationFunction<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(DeleteApiKeyDocument, options);
      }
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = Apollo.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    ...ProjectComplete
  }
}
    ${ProjectCompleteFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const SetProjectNameDocument = gql`
    mutation SetProjectName($input: SetProjectNameInput!) {
  setProjectName(input: $input) {
    ...ProjectName
  }
}
    ${ProjectNameFragmentDoc}`;
export type SetProjectNameMutationFn = Apollo.MutationFunction<SetProjectNameMutation, SetProjectNameMutationVariables>;

/**
 * __useSetProjectNameMutation__
 *
 * To run a mutation, you first call `useSetProjectNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectNameMutation, { data, loading, error }] = useSetProjectNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetProjectNameMutation(baseOptions?: Apollo.MutationHookOptions<SetProjectNameMutation, SetProjectNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProjectNameMutation, SetProjectNameMutationVariables>(SetProjectNameDocument, options);
      }
export type SetProjectNameMutationHookResult = ReturnType<typeof useSetProjectNameMutation>;
export type SetProjectNameMutationResult = Apollo.MutationResult<SetProjectNameMutation>;
export type SetProjectNameMutationOptions = Apollo.BaseMutationOptions<SetProjectNameMutation, SetProjectNameMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($input: DeleteProjectInput!) {
  deleteProject(input: $input) {
    ...ProjectComplete
  }
}
    ${ProjectCompleteFragmentDoc}`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const CreateFolderDocument = gql`
    mutation CreateFolder($input: CreateFolderInput!) {
  createFolder(input: $input) {
    ...FolderMeta
  }
}
    ${FolderMetaFragmentDoc}`;
export type CreateFolderMutationFn = Apollo.MutationFunction<CreateFolderMutation, CreateFolderMutationVariables>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument, options);
      }
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<CreateFolderMutation, CreateFolderMutationVariables>;
export const SetFolderNameDocument = gql`
    mutation SetFolderName($input: SetFolderNameInput!) {
  setFolderName(input: $input) {
    ...FolderName
  }
}
    ${FolderNameFragmentDoc}`;
export type SetFolderNameMutationFn = Apollo.MutationFunction<SetFolderNameMutation, SetFolderNameMutationVariables>;

/**
 * __useSetFolderNameMutation__
 *
 * To run a mutation, you first call `useSetFolderNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFolderNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFolderNameMutation, { data, loading, error }] = useSetFolderNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFolderNameMutation(baseOptions?: Apollo.MutationHookOptions<SetFolderNameMutation, SetFolderNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFolderNameMutation, SetFolderNameMutationVariables>(SetFolderNameDocument, options);
      }
export type SetFolderNameMutationHookResult = ReturnType<typeof useSetFolderNameMutation>;
export type SetFolderNameMutationResult = Apollo.MutationResult<SetFolderNameMutation>;
export type SetFolderNameMutationOptions = Apollo.BaseMutationOptions<SetFolderNameMutation, SetFolderNameMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($input: DeleteFolderInput!) {
  deleteFolder(input: $input) {
    ...FolderMeta
  }
}
    ${FolderMetaFragmentDoc}`;
export type DeleteFolderMutationFn = Apollo.MutationFunction<DeleteFolderMutation, DeleteFolderMutationVariables>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument, options);
      }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = Apollo.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const CreateNoteDocument = gql`
    mutation CreateNote($input: CreateNoteInput!) {
  createNote(input: $input) {
    ...NoteMeta
  }
}
    ${NoteMetaFragmentDoc}`;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const SetNoteNameDocument = gql`
    mutation SetNoteName($input: SetNoteNameInput!) {
  setNoteName(input: $input) {
    ...NoteName
  }
}
    ${NoteNameFragmentDoc}`;
export type SetNoteNameMutationFn = Apollo.MutationFunction<SetNoteNameMutation, SetNoteNameMutationVariables>;

/**
 * __useSetNoteNameMutation__
 *
 * To run a mutation, you first call `useSetNoteNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNoteNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNoteNameMutation, { data, loading, error }] = useSetNoteNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetNoteNameMutation(baseOptions?: Apollo.MutationHookOptions<SetNoteNameMutation, SetNoteNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNoteNameMutation, SetNoteNameMutationVariables>(SetNoteNameDocument, options);
      }
export type SetNoteNameMutationHookResult = ReturnType<typeof useSetNoteNameMutation>;
export type SetNoteNameMutationResult = Apollo.MutationResult<SetNoteNameMutation>;
export type SetNoteNameMutationOptions = Apollo.BaseMutationOptions<SetNoteNameMutation, SetNoteNameMutationVariables>;
export const SetNoteDataDocument = gql`
    mutation SetNoteData($input: SetNoteDataInput!) {
  setNoteData(input: $input) {
    ...NoteMeta
  }
}
    ${NoteMetaFragmentDoc}`;
export type SetNoteDataMutationFn = Apollo.MutationFunction<SetNoteDataMutation, SetNoteDataMutationVariables>;

/**
 * __useSetNoteDataMutation__
 *
 * To run a mutation, you first call `useSetNoteDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNoteDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNoteDataMutation, { data, loading, error }] = useSetNoteDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetNoteDataMutation(baseOptions?: Apollo.MutationHookOptions<SetNoteDataMutation, SetNoteDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNoteDataMutation, SetNoteDataMutationVariables>(SetNoteDataDocument, options);
      }
export type SetNoteDataMutationHookResult = ReturnType<typeof useSetNoteDataMutation>;
export type SetNoteDataMutationResult = Apollo.MutationResult<SetNoteDataMutation>;
export type SetNoteDataMutationOptions = Apollo.BaseMutationOptions<SetNoteDataMutation, SetNoteDataMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation DeleteNote($input: DeleteNoteInput!) {
  deleteNote(input: $input) {
    ...NoteMeta
  }
}
    ${NoteMetaFragmentDoc}`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const CreateNoteImageDocument = gql`
    mutation CreateNoteImage($input: CreateNoteImageInput!) {
  createNoteImage(input: $input) {
    id
    url
    putUrl
  }
}
    `;
export type CreateNoteImageMutationFn = Apollo.MutationFunction<CreateNoteImageMutation, CreateNoteImageMutationVariables>;

/**
 * __useCreateNoteImageMutation__
 *
 * To run a mutation, you first call `useCreateNoteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteImageMutation, { data, loading, error }] = useCreateNoteImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteImageMutation, CreateNoteImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteImageMutation, CreateNoteImageMutationVariables>(CreateNoteImageDocument, options);
      }
export type CreateNoteImageMutationHookResult = ReturnType<typeof useCreateNoteImageMutation>;
export type CreateNoteImageMutationResult = Apollo.MutationResult<CreateNoteImageMutation>;
export type CreateNoteImageMutationOptions = Apollo.BaseMutationOptions<CreateNoteImageMutation, CreateNoteImageMutationVariables>;
export const CreateResourceFromFileDocument = gql`
    mutation CreateResourceFromFile($input: CreateResourceFromFileInput!) {
  createResourceFromFile(input: $input) {
    ...ResourceMeta
    putInputUrl
    processWithOcr
  }
}
    ${ResourceMetaFragmentDoc}`;
export type CreateResourceFromFileMutationFn = Apollo.MutationFunction<CreateResourceFromFileMutation, CreateResourceFromFileMutationVariables>;

/**
 * __useCreateResourceFromFileMutation__
 *
 * To run a mutation, you first call `useCreateResourceFromFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceFromFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceFromFileMutation, { data, loading, error }] = useCreateResourceFromFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceFromFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceFromFileMutation, CreateResourceFromFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceFromFileMutation, CreateResourceFromFileMutationVariables>(CreateResourceFromFileDocument, options);
      }
export type CreateResourceFromFileMutationHookResult = ReturnType<typeof useCreateResourceFromFileMutation>;
export type CreateResourceFromFileMutationResult = Apollo.MutationResult<CreateResourceFromFileMutation>;
export type CreateResourceFromFileMutationOptions = Apollo.BaseMutationOptions<CreateResourceFromFileMutation, CreateResourceFromFileMutationVariables>;
export const CreateResourceFromUrlDocument = gql`
    mutation CreateResourceFromUrl($input: CreateResourceFromUrlInput!) {
  createResourceFromUrl(input: $input) {
    ...ResourceMeta
  }
}
    ${ResourceMetaFragmentDoc}`;
export type CreateResourceFromUrlMutationFn = Apollo.MutationFunction<CreateResourceFromUrlMutation, CreateResourceFromUrlMutationVariables>;

/**
 * __useCreateResourceFromUrlMutation__
 *
 * To run a mutation, you first call `useCreateResourceFromUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceFromUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceFromUrlMutation, { data, loading, error }] = useCreateResourceFromUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceFromUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceFromUrlMutation, CreateResourceFromUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceFromUrlMutation, CreateResourceFromUrlMutationVariables>(CreateResourceFromUrlDocument, options);
      }
export type CreateResourceFromUrlMutationHookResult = ReturnType<typeof useCreateResourceFromUrlMutation>;
export type CreateResourceFromUrlMutationResult = Apollo.MutationResult<CreateResourceFromUrlMutation>;
export type CreateResourceFromUrlMutationOptions = Apollo.BaseMutationOptions<CreateResourceFromUrlMutation, CreateResourceFromUrlMutationVariables>;
export const SetResourceProjectDocument = gql`
    mutation SetResourceProject($input: SetResourceProjectInput!) {
  setResourceProject(input: $input) {
    ...ResourceIdentifiable
  }
}
    ${ResourceIdentifiableFragmentDoc}`;
export type SetResourceProjectMutationFn = Apollo.MutationFunction<SetResourceProjectMutation, SetResourceProjectMutationVariables>;

/**
 * __useSetResourceProjectMutation__
 *
 * To run a mutation, you first call `useSetResourceProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetResourceProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setResourceProjectMutation, { data, loading, error }] = useSetResourceProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetResourceProjectMutation(baseOptions?: Apollo.MutationHookOptions<SetResourceProjectMutation, SetResourceProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetResourceProjectMutation, SetResourceProjectMutationVariables>(SetResourceProjectDocument, options);
      }
export type SetResourceProjectMutationHookResult = ReturnType<typeof useSetResourceProjectMutation>;
export type SetResourceProjectMutationResult = Apollo.MutationResult<SetResourceProjectMutation>;
export type SetResourceProjectMutationOptions = Apollo.BaseMutationOptions<SetResourceProjectMutation, SetResourceProjectMutationVariables>;
export const SetResourceNameDocument = gql`
    mutation SetResourceName($input: SetResourceNameInput!) {
  setResourceName(input: $input) {
    ...ResourceName
  }
}
    ${ResourceNameFragmentDoc}`;
export type SetResourceNameMutationFn = Apollo.MutationFunction<SetResourceNameMutation, SetResourceNameMutationVariables>;

/**
 * __useSetResourceNameMutation__
 *
 * To run a mutation, you first call `useSetResourceNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetResourceNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setResourceNameMutation, { data, loading, error }] = useSetResourceNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetResourceNameMutation(baseOptions?: Apollo.MutationHookOptions<SetResourceNameMutation, SetResourceNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetResourceNameMutation, SetResourceNameMutationVariables>(SetResourceNameDocument, options);
      }
export type SetResourceNameMutationHookResult = ReturnType<typeof useSetResourceNameMutation>;
export type SetResourceNameMutationResult = Apollo.MutationResult<SetResourceNameMutation>;
export type SetResourceNameMutationOptions = Apollo.BaseMutationOptions<SetResourceNameMutation, SetResourceNameMutationVariables>;
export const DeleteResourceDocument = gql`
    mutation DeleteResource($input: DeleteResourceInput!) {
  deleteResource(input: $input) {
    ...ResourceIdentifiable
  }
}
    ${ResourceIdentifiableFragmentDoc}`;
export type DeleteResourceMutationFn = Apollo.MutationFunction<DeleteResourceMutation, DeleteResourceMutationVariables>;

/**
 * __useDeleteResourceMutation__
 *
 * To run a mutation, you first call `useDeleteResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceMutation, { data, loading, error }] = useDeleteResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResourceMutation, DeleteResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResourceMutation, DeleteResourceMutationVariables>(DeleteResourceDocument, options);
      }
export type DeleteResourceMutationHookResult = ReturnType<typeof useDeleteResourceMutation>;
export type DeleteResourceMutationResult = Apollo.MutationResult<DeleteResourceMutation>;
export type DeleteResourceMutationOptions = Apollo.BaseMutationOptions<DeleteResourceMutation, DeleteResourceMutationVariables>;
export const CreateResourceHighlightDocument = gql`
    mutation CreateResourceHighlight($input: CreateResourceHighlightInput!) {
  createResourceHighlight(input: $input) {
    ...ResourceHighlightComplete
  }
}
    ${ResourceHighlightCompleteFragmentDoc}`;
export type CreateResourceHighlightMutationFn = Apollo.MutationFunction<CreateResourceHighlightMutation, CreateResourceHighlightMutationVariables>;

/**
 * __useCreateResourceHighlightMutation__
 *
 * To run a mutation, you first call `useCreateResourceHighlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceHighlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceHighlightMutation, { data, loading, error }] = useCreateResourceHighlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceHighlightMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceHighlightMutation, CreateResourceHighlightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceHighlightMutation, CreateResourceHighlightMutationVariables>(CreateResourceHighlightDocument, options);
      }
export type CreateResourceHighlightMutationHookResult = ReturnType<typeof useCreateResourceHighlightMutation>;
export type CreateResourceHighlightMutationResult = Apollo.MutationResult<CreateResourceHighlightMutation>;
export type CreateResourceHighlightMutationOptions = Apollo.BaseMutationOptions<CreateResourceHighlightMutation, CreateResourceHighlightMutationVariables>;
export const SetResourceHighlightColorDocument = gql`
    mutation SetResourceHighlightColor($input: SetResourceHighlightColorInput!) {
  setResourceHighlightColor(input: $input) {
    ...ResourceHighlightColor
  }
}
    ${ResourceHighlightColorFragmentDoc}`;
export type SetResourceHighlightColorMutationFn = Apollo.MutationFunction<SetResourceHighlightColorMutation, SetResourceHighlightColorMutationVariables>;

/**
 * __useSetResourceHighlightColorMutation__
 *
 * To run a mutation, you first call `useSetResourceHighlightColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetResourceHighlightColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setResourceHighlightColorMutation, { data, loading, error }] = useSetResourceHighlightColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetResourceHighlightColorMutation(baseOptions?: Apollo.MutationHookOptions<SetResourceHighlightColorMutation, SetResourceHighlightColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetResourceHighlightColorMutation, SetResourceHighlightColorMutationVariables>(SetResourceHighlightColorDocument, options);
      }
export type SetResourceHighlightColorMutationHookResult = ReturnType<typeof useSetResourceHighlightColorMutation>;
export type SetResourceHighlightColorMutationResult = Apollo.MutationResult<SetResourceHighlightColorMutation>;
export type SetResourceHighlightColorMutationOptions = Apollo.BaseMutationOptions<SetResourceHighlightColorMutation, SetResourceHighlightColorMutationVariables>;
export const SetResourceHighlightAnnotationDocument = gql`
    mutation SetResourceHighlightAnnotation($input: SetResourceHighlightAnnotationInput!) {
  setResourceHighlightAnnotation(input: $input) {
    ...ResourceHighlightAnnotation
  }
}
    ${ResourceHighlightAnnotationFragmentDoc}`;
export type SetResourceHighlightAnnotationMutationFn = Apollo.MutationFunction<SetResourceHighlightAnnotationMutation, SetResourceHighlightAnnotationMutationVariables>;

/**
 * __useSetResourceHighlightAnnotationMutation__
 *
 * To run a mutation, you first call `useSetResourceHighlightAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetResourceHighlightAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setResourceHighlightAnnotationMutation, { data, loading, error }] = useSetResourceHighlightAnnotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetResourceHighlightAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<SetResourceHighlightAnnotationMutation, SetResourceHighlightAnnotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetResourceHighlightAnnotationMutation, SetResourceHighlightAnnotationMutationVariables>(SetResourceHighlightAnnotationDocument, options);
      }
export type SetResourceHighlightAnnotationMutationHookResult = ReturnType<typeof useSetResourceHighlightAnnotationMutation>;
export type SetResourceHighlightAnnotationMutationResult = Apollo.MutationResult<SetResourceHighlightAnnotationMutation>;
export type SetResourceHighlightAnnotationMutationOptions = Apollo.BaseMutationOptions<SetResourceHighlightAnnotationMutation, SetResourceHighlightAnnotationMutationVariables>;
export const DeleteResourceHighlightDocument = gql`
    mutation DeleteResourceHighlight($input: DeleteResourceHighlightInput!) {
  deleteResourceHighlight(input: $input) {
    ...ResourceHighlightIdentifiable
  }
}
    ${ResourceHighlightIdentifiableFragmentDoc}`;
export type DeleteResourceHighlightMutationFn = Apollo.MutationFunction<DeleteResourceHighlightMutation, DeleteResourceHighlightMutationVariables>;

/**
 * __useDeleteResourceHighlightMutation__
 *
 * To run a mutation, you first call `useDeleteResourceHighlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceHighlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceHighlightMutation, { data, loading, error }] = useDeleteResourceHighlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResourceHighlightMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResourceHighlightMutation, DeleteResourceHighlightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResourceHighlightMutation, DeleteResourceHighlightMutationVariables>(DeleteResourceHighlightDocument, options);
      }
export type DeleteResourceHighlightMutationHookResult = ReturnType<typeof useDeleteResourceHighlightMutation>;
export type DeleteResourceHighlightMutationResult = Apollo.MutationResult<DeleteResourceHighlightMutation>;
export type DeleteResourceHighlightMutationOptions = Apollo.BaseMutationOptions<DeleteResourceHighlightMutation, DeleteResourceHighlightMutationVariables>;
export const ComputeSectionSummaryDocument = gql`
    mutation ComputeSectionSummary($input: ComputeSectionSummaryInput!) {
  computeSectionSummary(input: $input) {
    ...SummaryRequestComplete
  }
}
    ${SummaryRequestCompleteFragmentDoc}`;
export type ComputeSectionSummaryMutationFn = Apollo.MutationFunction<ComputeSectionSummaryMutation, ComputeSectionSummaryMutationVariables>;

/**
 * __useComputeSectionSummaryMutation__
 *
 * To run a mutation, you first call `useComputeSectionSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useComputeSectionSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [computeSectionSummaryMutation, { data, loading, error }] = useComputeSectionSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useComputeSectionSummaryMutation(baseOptions?: Apollo.MutationHookOptions<ComputeSectionSummaryMutation, ComputeSectionSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ComputeSectionSummaryMutation, ComputeSectionSummaryMutationVariables>(ComputeSectionSummaryDocument, options);
      }
export type ComputeSectionSummaryMutationHookResult = ReturnType<typeof useComputeSectionSummaryMutation>;
export type ComputeSectionSummaryMutationResult = Apollo.MutationResult<ComputeSectionSummaryMutation>;
export type ComputeSectionSummaryMutationOptions = Apollo.BaseMutationOptions<ComputeSectionSummaryMutation, ComputeSectionSummaryMutationVariables>;
export const RecomputeSectionSummaryDocument = gql`
    mutation RecomputeSectionSummary($input: RecomputeSectionSummaryInput!) {
  recomputeSectionSummary(input: $input) {
    ...SummaryRequestComplete
  }
}
    ${SummaryRequestCompleteFragmentDoc}`;
export type RecomputeSectionSummaryMutationFn = Apollo.MutationFunction<RecomputeSectionSummaryMutation, RecomputeSectionSummaryMutationVariables>;

/**
 * __useRecomputeSectionSummaryMutation__
 *
 * To run a mutation, you first call `useRecomputeSectionSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecomputeSectionSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recomputeSectionSummaryMutation, { data, loading, error }] = useRecomputeSectionSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecomputeSectionSummaryMutation(baseOptions?: Apollo.MutationHookOptions<RecomputeSectionSummaryMutation, RecomputeSectionSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecomputeSectionSummaryMutation, RecomputeSectionSummaryMutationVariables>(RecomputeSectionSummaryDocument, options);
      }
export type RecomputeSectionSummaryMutationHookResult = ReturnType<typeof useRecomputeSectionSummaryMutation>;
export type RecomputeSectionSummaryMutationResult = Apollo.MutationResult<RecomputeSectionSummaryMutation>;
export type RecomputeSectionSummaryMutationOptions = Apollo.BaseMutationOptions<RecomputeSectionSummaryMutation, RecomputeSectionSummaryMutationVariables>;
export const RestoreSectionSummaryDocument = gql`
    mutation RestoreSectionSummary($input: RestoreSectionSummaryInput!) {
  restoreSectionSummary(input: $input) {
    ...SectionSummaryComplete
  }
}
    ${SectionSummaryCompleteFragmentDoc}`;
export type RestoreSectionSummaryMutationFn = Apollo.MutationFunction<RestoreSectionSummaryMutation, RestoreSectionSummaryMutationVariables>;

/**
 * __useRestoreSectionSummaryMutation__
 *
 * To run a mutation, you first call `useRestoreSectionSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSectionSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSectionSummaryMutation, { data, loading, error }] = useRestoreSectionSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreSectionSummaryMutation(baseOptions?: Apollo.MutationHookOptions<RestoreSectionSummaryMutation, RestoreSectionSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreSectionSummaryMutation, RestoreSectionSummaryMutationVariables>(RestoreSectionSummaryDocument, options);
      }
export type RestoreSectionSummaryMutationHookResult = ReturnType<typeof useRestoreSectionSummaryMutation>;
export type RestoreSectionSummaryMutationResult = Apollo.MutationResult<RestoreSectionSummaryMutation>;
export type RestoreSectionSummaryMutationOptions = Apollo.BaseMutationOptions<RestoreSectionSummaryMutation, RestoreSectionSummaryMutationVariables>;
export const LinkAccountDocument = gql`
    mutation LinkAccount($input: LinkAccountInput!) {
  linkAccount(input: $input) {
    ...UserComplete
  }
}
    ${UserCompleteFragmentDoc}`;
export type LinkAccountMutationFn = Apollo.MutationFunction<LinkAccountMutation, LinkAccountMutationVariables>;

/**
 * __useLinkAccountMutation__
 *
 * To run a mutation, you first call `useLinkAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkAccountMutation, { data, loading, error }] = useLinkAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkAccountMutation(baseOptions?: Apollo.MutationHookOptions<LinkAccountMutation, LinkAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkAccountMutation, LinkAccountMutationVariables>(LinkAccountDocument, options);
      }
export type LinkAccountMutationHookResult = ReturnType<typeof useLinkAccountMutation>;
export type LinkAccountMutationResult = Apollo.MutationResult<LinkAccountMutation>;
export type LinkAccountMutationOptions = Apollo.BaseMutationOptions<LinkAccountMutation, LinkAccountMutationVariables>;
export const MoveResourceDocument = gql`
    mutation MoveResource($input: MoveResourceInput!) {
  moveResource(input: $input) {
    ...ResourceLocatable
  }
}
    ${ResourceLocatableFragmentDoc}`;
export type MoveResourceMutationFn = Apollo.MutationFunction<MoveResourceMutation, MoveResourceMutationVariables>;

/**
 * __useMoveResourceMutation__
 *
 * To run a mutation, you first call `useMoveResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveResourceMutation, { data, loading, error }] = useMoveResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveResourceMutation(baseOptions?: Apollo.MutationHookOptions<MoveResourceMutation, MoveResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveResourceMutation, MoveResourceMutationVariables>(MoveResourceDocument, options);
      }
export type MoveResourceMutationHookResult = ReturnType<typeof useMoveResourceMutation>;
export type MoveResourceMutationResult = Apollo.MutationResult<MoveResourceMutation>;
export type MoveResourceMutationOptions = Apollo.BaseMutationOptions<MoveResourceMutation, MoveResourceMutationVariables>;
export const MoveNoteDocument = gql`
    mutation MoveNote($input: MoveNoteInput!) {
  moveNote(input: $input) {
    ...NoteLocatable
  }
}
    ${NoteLocatableFragmentDoc}`;
export type MoveNoteMutationFn = Apollo.MutationFunction<MoveNoteMutation, MoveNoteMutationVariables>;

/**
 * __useMoveNoteMutation__
 *
 * To run a mutation, you first call `useMoveNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveNoteMutation, { data, loading, error }] = useMoveNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveNoteMutation(baseOptions?: Apollo.MutationHookOptions<MoveNoteMutation, MoveNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveNoteMutation, MoveNoteMutationVariables>(MoveNoteDocument, options);
      }
export type MoveNoteMutationHookResult = ReturnType<typeof useMoveNoteMutation>;
export type MoveNoteMutationResult = Apollo.MutationResult<MoveNoteMutation>;
export type MoveNoteMutationOptions = Apollo.BaseMutationOptions<MoveNoteMutation, MoveNoteMutationVariables>;
export const MoveFolderDocument = gql`
    mutation MoveFolder($input: MoveFolderInput!) {
  moveFolder(input: $input) {
    ...FolderLocatable
  }
}
    ${FolderLocatableFragmentDoc}`;
export type MoveFolderMutationFn = Apollo.MutationFunction<MoveFolderMutation, MoveFolderMutationVariables>;

/**
 * __useMoveFolderMutation__
 *
 * To run a mutation, you first call `useMoveFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveFolderMutation, { data, loading, error }] = useMoveFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveFolderMutation(baseOptions?: Apollo.MutationHookOptions<MoveFolderMutation, MoveFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveFolderMutation, MoveFolderMutationVariables>(MoveFolderDocument, options);
      }
export type MoveFolderMutationHookResult = ReturnType<typeof useMoveFolderMutation>;
export type MoveFolderMutationResult = Apollo.MutationResult<MoveFolderMutation>;
export type MoveFolderMutationOptions = Apollo.BaseMutationOptions<MoveFolderMutation, MoveFolderMutationVariables>;
export const RequestAcademicStatusForUserDocument = gql`
    mutation RequestAcademicStatusForUser($input: RequestAcademicStatusForUserInput!) {
  requestAcademicStatusForUser(input: $input) {
    ...UserIdentifiable
    academic {
      ...AcademicComplete
    }
  }
}
    ${UserIdentifiableFragmentDoc}
${AcademicCompleteFragmentDoc}`;
export type RequestAcademicStatusForUserMutationFn = Apollo.MutationFunction<RequestAcademicStatusForUserMutation, RequestAcademicStatusForUserMutationVariables>;

/**
 * __useRequestAcademicStatusForUserMutation__
 *
 * To run a mutation, you first call `useRequestAcademicStatusForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAcademicStatusForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAcademicStatusForUserMutation, { data, loading, error }] = useRequestAcademicStatusForUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestAcademicStatusForUserMutation(baseOptions?: Apollo.MutationHookOptions<RequestAcademicStatusForUserMutation, RequestAcademicStatusForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAcademicStatusForUserMutation, RequestAcademicStatusForUserMutationVariables>(RequestAcademicStatusForUserDocument, options);
      }
export type RequestAcademicStatusForUserMutationHookResult = ReturnType<typeof useRequestAcademicStatusForUserMutation>;
export type RequestAcademicStatusForUserMutationResult = Apollo.MutationResult<RequestAcademicStatusForUserMutation>;
export type RequestAcademicStatusForUserMutationOptions = Apollo.BaseMutationOptions<RequestAcademicStatusForUserMutation, RequestAcademicStatusForUserMutationVariables>;
export const RequestAcademicStatusDocument = gql`
    mutation RequestAcademicStatus($input: RequestAcademicStatusInput!) {
  requestAcademicStatus(input: $input) {
    ...UserIdentifiable
    academic {
      ...AcademicComplete
    }
  }
}
    ${UserIdentifiableFragmentDoc}
${AcademicCompleteFragmentDoc}`;
export type RequestAcademicStatusMutationFn = Apollo.MutationFunction<RequestAcademicStatusMutation, RequestAcademicStatusMutationVariables>;

/**
 * __useRequestAcademicStatusMutation__
 *
 * To run a mutation, you first call `useRequestAcademicStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAcademicStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAcademicStatusMutation, { data, loading, error }] = useRequestAcademicStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestAcademicStatusMutation(baseOptions?: Apollo.MutationHookOptions<RequestAcademicStatusMutation, RequestAcademicStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAcademicStatusMutation, RequestAcademicStatusMutationVariables>(RequestAcademicStatusDocument, options);
      }
export type RequestAcademicStatusMutationHookResult = ReturnType<typeof useRequestAcademicStatusMutation>;
export type RequestAcademicStatusMutationResult = Apollo.MutationResult<RequestAcademicStatusMutation>;
export type RequestAcademicStatusMutationOptions = Apollo.BaseMutationOptions<RequestAcademicStatusMutation, RequestAcademicStatusMutationVariables>;
export const ActivateAcademicStatusDocument = gql`
    mutation ActivateAcademicStatus($input: ActivateAcademicStatusInput!) {
  activateAcademicStatus(input: $input) {
    ...UserIdentifiable
    academic {
      ...AcademicComplete
    }
  }
}
    ${UserIdentifiableFragmentDoc}
${AcademicCompleteFragmentDoc}`;
export type ActivateAcademicStatusMutationFn = Apollo.MutationFunction<ActivateAcademicStatusMutation, ActivateAcademicStatusMutationVariables>;

/**
 * __useActivateAcademicStatusMutation__
 *
 * To run a mutation, you first call `useActivateAcademicStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAcademicStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAcademicStatusMutation, { data, loading, error }] = useActivateAcademicStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateAcademicStatusMutation(baseOptions?: Apollo.MutationHookOptions<ActivateAcademicStatusMutation, ActivateAcademicStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateAcademicStatusMutation, ActivateAcademicStatusMutationVariables>(ActivateAcademicStatusDocument, options);
      }
export type ActivateAcademicStatusMutationHookResult = ReturnType<typeof useActivateAcademicStatusMutation>;
export type ActivateAcademicStatusMutationResult = Apollo.MutationResult<ActivateAcademicStatusMutation>;
export type ActivateAcademicStatusMutationOptions = Apollo.BaseMutationOptions<ActivateAcademicStatusMutation, ActivateAcademicStatusMutationVariables>;
export const SetHasOnboardedDocument = gql`
    mutation SetHasOnboarded {
  setHasOnboarded {
    ...UserIdentifiable
    hasOnboarded
  }
}
    ${UserIdentifiableFragmentDoc}`;
export type SetHasOnboardedMutationFn = Apollo.MutationFunction<SetHasOnboardedMutation, SetHasOnboardedMutationVariables>;

/**
 * __useSetHasOnboardedMutation__
 *
 * To run a mutation, you first call `useSetHasOnboardedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHasOnboardedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHasOnboardedMutation, { data, loading, error }] = useSetHasOnboardedMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetHasOnboardedMutation(baseOptions?: Apollo.MutationHookOptions<SetHasOnboardedMutation, SetHasOnboardedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetHasOnboardedMutation, SetHasOnboardedMutationVariables>(SetHasOnboardedDocument, options);
      }
export type SetHasOnboardedMutationHookResult = ReturnType<typeof useSetHasOnboardedMutation>;
export type SetHasOnboardedMutationResult = Apollo.MutationResult<SetHasOnboardedMutation>;
export type SetHasOnboardedMutationOptions = Apollo.BaseMutationOptions<SetHasOnboardedMutation, SetHasOnboardedMutationVariables>;
export const SetSectionSummaryRatingDocument = gql`
    mutation SetSectionSummaryRating($input: SetSectionSummaryRatingInput!) {
  setSectionSummaryRating(input: $input) {
    ...SectionSummaryIdentifiable
    rating
  }
}
    ${SectionSummaryIdentifiableFragmentDoc}`;
export type SetSectionSummaryRatingMutationFn = Apollo.MutationFunction<SetSectionSummaryRatingMutation, SetSectionSummaryRatingMutationVariables>;

/**
 * __useSetSectionSummaryRatingMutation__
 *
 * To run a mutation, you first call `useSetSectionSummaryRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSectionSummaryRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSectionSummaryRatingMutation, { data, loading, error }] = useSetSectionSummaryRatingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSectionSummaryRatingMutation(baseOptions?: Apollo.MutationHookOptions<SetSectionSummaryRatingMutation, SetSectionSummaryRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSectionSummaryRatingMutation, SetSectionSummaryRatingMutationVariables>(SetSectionSummaryRatingDocument, options);
      }
export type SetSectionSummaryRatingMutationHookResult = ReturnType<typeof useSetSectionSummaryRatingMutation>;
export type SetSectionSummaryRatingMutationResult = Apollo.MutationResult<SetSectionSummaryRatingMutation>;
export type SetSectionSummaryRatingMutationOptions = Apollo.BaseMutationOptions<SetSectionSummaryRatingMutation, SetSectionSummaryRatingMutationVariables>;
export const SyncTraitsDocument = gql`
    mutation SyncTraits {
  syncTraits
}
    `;
export type SyncTraitsMutationFn = Apollo.MutationFunction<SyncTraitsMutation, SyncTraitsMutationVariables>;

/**
 * __useSyncTraitsMutation__
 *
 * To run a mutation, you first call `useSyncTraitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncTraitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncTraitsMutation, { data, loading, error }] = useSyncTraitsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncTraitsMutation(baseOptions?: Apollo.MutationHookOptions<SyncTraitsMutation, SyncTraitsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncTraitsMutation, SyncTraitsMutationVariables>(SyncTraitsDocument, options);
      }
export type SyncTraitsMutationHookResult = ReturnType<typeof useSyncTraitsMutation>;
export type SyncTraitsMutationResult = Apollo.MutationResult<SyncTraitsMutation>;
export type SyncTraitsMutationOptions = Apollo.BaseMutationOptions<SyncTraitsMutation, SyncTraitsMutationVariables>;
export const SetDefaultSummariserDocument = gql`
    mutation SetDefaultSummariser($input: SetDefaultSummariserInput!) {
  setDefaultSummariser(input: $input) {
    ...UserIdentifiable
    defaultSummariser
  }
}
    ${UserIdentifiableFragmentDoc}`;
export type SetDefaultSummariserMutationFn = Apollo.MutationFunction<SetDefaultSummariserMutation, SetDefaultSummariserMutationVariables>;

/**
 * __useSetDefaultSummariserMutation__
 *
 * To run a mutation, you first call `useSetDefaultSummariserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultSummariserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultSummariserMutation, { data, loading, error }] = useSetDefaultSummariserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDefaultSummariserMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultSummariserMutation, SetDefaultSummariserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultSummariserMutation, SetDefaultSummariserMutationVariables>(SetDefaultSummariserDocument, options);
      }
export type SetDefaultSummariserMutationHookResult = ReturnType<typeof useSetDefaultSummariserMutation>;
export type SetDefaultSummariserMutationResult = Apollo.MutationResult<SetDefaultSummariserMutation>;
export type SetDefaultSummariserMutationOptions = Apollo.BaseMutationOptions<SetDefaultSummariserMutation, SetDefaultSummariserMutationVariables>;
export const SummariseTextDocument = gql`
    mutation SummariseText($input: SummariseTextInput!) {
  summariseText(input: $input) {
    text
    summary
  }
}
    `;
export type SummariseTextMutationFn = Apollo.MutationFunction<SummariseTextMutation, SummariseTextMutationVariables>;

/**
 * __useSummariseTextMutation__
 *
 * To run a mutation, you first call `useSummariseTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSummariseTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [summariseTextMutation, { data, loading, error }] = useSummariseTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSummariseTextMutation(baseOptions?: Apollo.MutationHookOptions<SummariseTextMutation, SummariseTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SummariseTextMutation, SummariseTextMutationVariables>(SummariseTextDocument, options);
      }
export type SummariseTextMutationHookResult = ReturnType<typeof useSummariseTextMutation>;
export type SummariseTextMutationResult = Apollo.MutationResult<SummariseTextMutation>;
export type SummariseTextMutationOptions = Apollo.BaseMutationOptions<SummariseTextMutation, SummariseTextMutationVariables>;
export const ParaphraseBlocksDocument = gql`
    mutation ParaphraseBlocks($input: ParaphraseBlocksInput!) {
  paraphraseBlocks(input: $input) {
    blocks
  }
}
    `;
export type ParaphraseBlocksMutationFn = Apollo.MutationFunction<ParaphraseBlocksMutation, ParaphraseBlocksMutationVariables>;

/**
 * __useParaphraseBlocksMutation__
 *
 * To run a mutation, you first call `useParaphraseBlocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParaphraseBlocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paraphraseBlocksMutation, { data, loading, error }] = useParaphraseBlocksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParaphraseBlocksMutation(baseOptions?: Apollo.MutationHookOptions<ParaphraseBlocksMutation, ParaphraseBlocksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParaphraseBlocksMutation, ParaphraseBlocksMutationVariables>(ParaphraseBlocksDocument, options);
      }
export type ParaphraseBlocksMutationHookResult = ReturnType<typeof useParaphraseBlocksMutation>;
export type ParaphraseBlocksMutationResult = Apollo.MutationResult<ParaphraseBlocksMutation>;
export type ParaphraseBlocksMutationOptions = Apollo.BaseMutationOptions<ParaphraseBlocksMutation, ParaphraseBlocksMutationVariables>;
export const ConsumeLicenseKeyDocument = gql`
    mutation ConsumeLicenseKey($input: ConsumeLicenseKeyInput!) {
  consumeLicenseKey(input: $input) {
    ...licenseKeyComplete
  }
}
    ${LicenseKeyCompleteFragmentDoc}`;
export type ConsumeLicenseKeyMutationFn = Apollo.MutationFunction<ConsumeLicenseKeyMutation, ConsumeLicenseKeyMutationVariables>;

/**
 * __useConsumeLicenseKeyMutation__
 *
 * To run a mutation, you first call `useConsumeLicenseKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumeLicenseKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumeLicenseKeyMutation, { data, loading, error }] = useConsumeLicenseKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumeLicenseKeyMutation(baseOptions?: Apollo.MutationHookOptions<ConsumeLicenseKeyMutation, ConsumeLicenseKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsumeLicenseKeyMutation, ConsumeLicenseKeyMutationVariables>(ConsumeLicenseKeyDocument, options);
      }
export type ConsumeLicenseKeyMutationHookResult = ReturnType<typeof useConsumeLicenseKeyMutation>;
export type ConsumeLicenseKeyMutationResult = Apollo.MutationResult<ConsumeLicenseKeyMutation>;
export type ConsumeLicenseKeyMutationOptions = Apollo.BaseMutationOptions<ConsumeLicenseKeyMutation, ConsumeLicenseKeyMutationVariables>;
export const CreateLicenseKeysDocument = gql`
    mutation CreateLicenseKeys($input: CreateLicenseKeysInput!) {
  createLicenseKeys(input: $input) {
    items {
      ...licenseKeyComplete
    }
  }
}
    ${LicenseKeyCompleteFragmentDoc}`;
export type CreateLicenseKeysMutationFn = Apollo.MutationFunction<CreateLicenseKeysMutation, CreateLicenseKeysMutationVariables>;

/**
 * __useCreateLicenseKeysMutation__
 *
 * To run a mutation, you first call `useCreateLicenseKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicenseKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicenseKeysMutation, { data, loading, error }] = useCreateLicenseKeysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLicenseKeysMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicenseKeysMutation, CreateLicenseKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicenseKeysMutation, CreateLicenseKeysMutationVariables>(CreateLicenseKeysDocument, options);
      }
export type CreateLicenseKeysMutationHookResult = ReturnType<typeof useCreateLicenseKeysMutation>;
export type CreateLicenseKeysMutationResult = Apollo.MutationResult<CreateLicenseKeysMutation>;
export type CreateLicenseKeysMutationOptions = Apollo.BaseMutationOptions<CreateLicenseKeysMutation, CreateLicenseKeysMutationVariables>;
export const MigrateBetaDocument = gql`
    mutation MigrateBeta {
  migrateBeta {
    ...UserIdentifiable
    hasMigrated
  }
}
    ${UserIdentifiableFragmentDoc}`;
export type MigrateBetaMutationFn = Apollo.MutationFunction<MigrateBetaMutation, MigrateBetaMutationVariables>;

/**
 * __useMigrateBetaMutation__
 *
 * To run a mutation, you first call `useMigrateBetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateBetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateBetaMutation, { data, loading, error }] = useMigrateBetaMutation({
 *   variables: {
 *   },
 * });
 */
export function useMigrateBetaMutation(baseOptions?: Apollo.MutationHookOptions<MigrateBetaMutation, MigrateBetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MigrateBetaMutation, MigrateBetaMutationVariables>(MigrateBetaDocument, options);
      }
export type MigrateBetaMutationHookResult = ReturnType<typeof useMigrateBetaMutation>;
export type MigrateBetaMutationResult = Apollo.MutationResult<MigrateBetaMutation>;
export type MigrateBetaMutationOptions = Apollo.BaseMutationOptions<MigrateBetaMutation, MigrateBetaMutationVariables>;
export const RollbackBetaMigrationDocument = gql`
    mutation RollbackBetaMigration {
  rollbackBetaMigration {
    ...UserIdentifiable
    hasMigrated
  }
}
    ${UserIdentifiableFragmentDoc}`;
export type RollbackBetaMigrationMutationFn = Apollo.MutationFunction<RollbackBetaMigrationMutation, RollbackBetaMigrationMutationVariables>;

/**
 * __useRollbackBetaMigrationMutation__
 *
 * To run a mutation, you first call `useRollbackBetaMigrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRollbackBetaMigrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rollbackBetaMigrationMutation, { data, loading, error }] = useRollbackBetaMigrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRollbackBetaMigrationMutation(baseOptions?: Apollo.MutationHookOptions<RollbackBetaMigrationMutation, RollbackBetaMigrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RollbackBetaMigrationMutation, RollbackBetaMigrationMutationVariables>(RollbackBetaMigrationDocument, options);
      }
export type RollbackBetaMigrationMutationHookResult = ReturnType<typeof useRollbackBetaMigrationMutation>;
export type RollbackBetaMigrationMutationResult = Apollo.MutationResult<RollbackBetaMigrationMutation>;
export type RollbackBetaMigrationMutationOptions = Apollo.BaseMutationOptions<RollbackBetaMigrationMutation, RollbackBetaMigrationMutationVariables>;
export const CreatePromptDocument = gql`
    mutation CreatePrompt($input: CreatePromptInput!) {
  createPrompt(input: $input) {
    ...PromptComplete
  }
}
    ${PromptCompleteFragmentDoc}`;
export type CreatePromptMutationFn = Apollo.MutationFunction<CreatePromptMutation, CreatePromptMutationVariables>;

/**
 * __useCreatePromptMutation__
 *
 * To run a mutation, you first call `useCreatePromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromptMutation, { data, loading, error }] = useCreatePromptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromptMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromptMutation, CreatePromptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePromptMutation, CreatePromptMutationVariables>(CreatePromptDocument, options);
      }
export type CreatePromptMutationHookResult = ReturnType<typeof useCreatePromptMutation>;
export type CreatePromptMutationResult = Apollo.MutationResult<CreatePromptMutation>;
export type CreatePromptMutationOptions = Apollo.BaseMutationOptions<CreatePromptMutation, CreatePromptMutationVariables>;
export const UpdatePromptDocument = gql`
    mutation UpdatePrompt($input: UpdatePromptInput!) {
  updatePrompt(input: $input) {
    ...PromptComplete
  }
}
    ${PromptCompleteFragmentDoc}`;
export type UpdatePromptMutationFn = Apollo.MutationFunction<UpdatePromptMutation, UpdatePromptMutationVariables>;

/**
 * __useUpdatePromptMutation__
 *
 * To run a mutation, you first call `useUpdatePromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromptMutation, { data, loading, error }] = useUpdatePromptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromptMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromptMutation, UpdatePromptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePromptMutation, UpdatePromptMutationVariables>(UpdatePromptDocument, options);
      }
export type UpdatePromptMutationHookResult = ReturnType<typeof useUpdatePromptMutation>;
export type UpdatePromptMutationResult = Apollo.MutationResult<UpdatePromptMutation>;
export type UpdatePromptMutationOptions = Apollo.BaseMutationOptions<UpdatePromptMutation, UpdatePromptMutationVariables>;
export const DeletePromptDocument = gql`
    mutation DeletePrompt($input: DeletePromptInput!) {
  deletePrompt(input: $input) {
    ...PromptIdentifiable
  }
}
    ${PromptIdentifiableFragmentDoc}`;
export type DeletePromptMutationFn = Apollo.MutationFunction<DeletePromptMutation, DeletePromptMutationVariables>;

/**
 * __useDeletePromptMutation__
 *
 * To run a mutation, you first call `useDeletePromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromptMutation, { data, loading, error }] = useDeletePromptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePromptMutation(baseOptions?: Apollo.MutationHookOptions<DeletePromptMutation, DeletePromptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePromptMutation, DeletePromptMutationVariables>(DeletePromptDocument, options);
      }
export type DeletePromptMutationHookResult = ReturnType<typeof useDeletePromptMutation>;
export type DeletePromptMutationResult = Apollo.MutationResult<DeletePromptMutation>;
export type DeletePromptMutationOptions = Apollo.BaseMutationOptions<DeletePromptMutation, DeletePromptMutationVariables>;
export const CreateCompletionDocument = gql`
    mutation CreateCompletion($input: CreateCompletionInput!) {
  createCompletion(input: $input) {
    text
  }
}
    `;
export type CreateCompletionMutationFn = Apollo.MutationFunction<CreateCompletionMutation, CreateCompletionMutationVariables>;

/**
 * __useCreateCompletionMutation__
 *
 * To run a mutation, you first call `useCreateCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompletionMutation, { data, loading, error }] = useCreateCompletionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompletionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompletionMutation, CreateCompletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompletionMutation, CreateCompletionMutationVariables>(CreateCompletionDocument, options);
      }
export type CreateCompletionMutationHookResult = ReturnType<typeof useCreateCompletionMutation>;
export type CreateCompletionMutationResult = Apollo.MutationResult<CreateCompletionMutation>;
export type CreateCompletionMutationOptions = Apollo.BaseMutationOptions<CreateCompletionMutation, CreateCompletionMutationVariables>;
export const CreateAiChatDocument = gql`
    mutation CreateAiChat($input: CreateAiChatInput!) {
  createAiChat(input: $input) {
    ...AiChatMeta
  }
}
    ${AiChatMetaFragmentDoc}`;
export type CreateAiChatMutationFn = Apollo.MutationFunction<CreateAiChatMutation, CreateAiChatMutationVariables>;

/**
 * __useCreateAiChatMutation__
 *
 * To run a mutation, you first call `useCreateAiChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiChatMutation, { data, loading, error }] = useCreateAiChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAiChatMutation(baseOptions?: Apollo.MutationHookOptions<CreateAiChatMutation, CreateAiChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAiChatMutation, CreateAiChatMutationVariables>(CreateAiChatDocument, options);
      }
export type CreateAiChatMutationHookResult = ReturnType<typeof useCreateAiChatMutation>;
export type CreateAiChatMutationResult = Apollo.MutationResult<CreateAiChatMutation>;
export type CreateAiChatMutationOptions = Apollo.BaseMutationOptions<CreateAiChatMutation, CreateAiChatMutationVariables>;
export const SetAiChatNameDocument = gql`
    mutation SetAiChatName($input: SetAiChatNameInput!) {
  setAiChatName(input: $input) {
    ...AiChatMeta
  }
}
    ${AiChatMetaFragmentDoc}`;
export type SetAiChatNameMutationFn = Apollo.MutationFunction<SetAiChatNameMutation, SetAiChatNameMutationVariables>;

/**
 * __useSetAiChatNameMutation__
 *
 * To run a mutation, you first call `useSetAiChatNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAiChatNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAiChatNameMutation, { data, loading, error }] = useSetAiChatNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAiChatNameMutation(baseOptions?: Apollo.MutationHookOptions<SetAiChatNameMutation, SetAiChatNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAiChatNameMutation, SetAiChatNameMutationVariables>(SetAiChatNameDocument, options);
      }
export type SetAiChatNameMutationHookResult = ReturnType<typeof useSetAiChatNameMutation>;
export type SetAiChatNameMutationResult = Apollo.MutationResult<SetAiChatNameMutation>;
export type SetAiChatNameMutationOptions = Apollo.BaseMutationOptions<SetAiChatNameMutation, SetAiChatNameMutationVariables>;
export const DeleteAiChatDocument = gql`
    mutation DeleteAiChat($input: DeleteAiChatInput!) {
  deleteAiChat(input: $input) {
    ...AiChatIdentifiable
  }
}
    ${AiChatIdentifiableFragmentDoc}`;
export type DeleteAiChatMutationFn = Apollo.MutationFunction<DeleteAiChatMutation, DeleteAiChatMutationVariables>;

/**
 * __useDeleteAiChatMutation__
 *
 * To run a mutation, you first call `useDeleteAiChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAiChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAiChatMutation, { data, loading, error }] = useDeleteAiChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAiChatMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAiChatMutation, DeleteAiChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAiChatMutation, DeleteAiChatMutationVariables>(DeleteAiChatDocument, options);
      }
export type DeleteAiChatMutationHookResult = ReturnType<typeof useDeleteAiChatMutation>;
export type DeleteAiChatMutationResult = Apollo.MutationResult<DeleteAiChatMutation>;
export type DeleteAiChatMutationOptions = Apollo.BaseMutationOptions<DeleteAiChatMutation, DeleteAiChatMutationVariables>;
export const SendAiChatMessageDocument = gql`
    mutation SendAiChatMessage($input: SendAiChatMessageInput!) {
  sendAiChatMessage(input: $input) {
    ...AiChatMessageComplete
  }
}
    ${AiChatMessageCompleteFragmentDoc}`;
export type SendAiChatMessageMutationFn = Apollo.MutationFunction<SendAiChatMessageMutation, SendAiChatMessageMutationVariables>;

/**
 * __useSendAiChatMessageMutation__
 *
 * To run a mutation, you first call `useSendAiChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAiChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAiChatMessageMutation, { data, loading, error }] = useSendAiChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAiChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendAiChatMessageMutation, SendAiChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAiChatMessageMutation, SendAiChatMessageMutationVariables>(SendAiChatMessageDocument, options);
      }
export type SendAiChatMessageMutationHookResult = ReturnType<typeof useSendAiChatMessageMutation>;
export type SendAiChatMessageMutationResult = Apollo.MutationResult<SendAiChatMessageMutation>;
export type SendAiChatMessageMutationOptions = Apollo.BaseMutationOptions<SendAiChatMessageMutation, SendAiChatMessageMutationVariables>;
export const DeleteAiChatMessageDocument = gql`
    mutation DeleteAiChatMessage($input: DeleteAiChatMessageInput!) {
  deleteAiChatMessage(input: $input) {
    ...AiChatMessageIdentifiable
  }
}
    ${AiChatMessageIdentifiableFragmentDoc}`;
export type DeleteAiChatMessageMutationFn = Apollo.MutationFunction<DeleteAiChatMessageMutation, DeleteAiChatMessageMutationVariables>;

/**
 * __useDeleteAiChatMessageMutation__
 *
 * To run a mutation, you first call `useDeleteAiChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAiChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAiChatMessageMutation, { data, loading, error }] = useDeleteAiChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAiChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAiChatMessageMutation, DeleteAiChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAiChatMessageMutation, DeleteAiChatMessageMutationVariables>(DeleteAiChatMessageDocument, options);
      }
export type DeleteAiChatMessageMutationHookResult = ReturnType<typeof useDeleteAiChatMessageMutation>;
export type DeleteAiChatMessageMutationResult = Apollo.MutationResult<DeleteAiChatMessageMutation>;
export type DeleteAiChatMessageMutationOptions = Apollo.BaseMutationOptions<DeleteAiChatMessageMutation, DeleteAiChatMessageMutationVariables>;
export const DeletePaymentMethodDocument = gql`
    mutation DeletePaymentMethod($input: DeletePaymentMethodInput!) {
  deletePaymentMethod(input: $input) {
    id
    ownerId
  }
}
    `;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(DeletePaymentMethodDocument, options);
      }
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export const SetDefaultPaymentMethodDocument = gql`
    mutation SetDefaultPaymentMethod($input: SetDefaultPaymentMethodInput!) {
  setDefaultPaymentMethod(input: $input) {
    id
    ownerId
  }
}
    `;
export type SetDefaultPaymentMethodMutationFn = Apollo.MutationFunction<SetDefaultPaymentMethodMutation, SetDefaultPaymentMethodMutationVariables>;

/**
 * __useSetDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultPaymentMethodMutation, { data, loading, error }] = useSetDefaultPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDefaultPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultPaymentMethodMutation, SetDefaultPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultPaymentMethodMutation, SetDefaultPaymentMethodMutationVariables>(SetDefaultPaymentMethodDocument, options);
      }
export type SetDefaultPaymentMethodMutationHookResult = ReturnType<typeof useSetDefaultPaymentMethodMutation>;
export type SetDefaultPaymentMethodMutationResult = Apollo.MutationResult<SetDefaultPaymentMethodMutation>;
export type SetDefaultPaymentMethodMutationOptions = Apollo.BaseMutationOptions<SetDefaultPaymentMethodMutation, SetDefaultPaymentMethodMutationVariables>;
export const CreateSetupIntentDocument = gql`
    mutation CreateSetupIntent {
  createSetupIntent {
    ...SubscriptionPendingIntentComplete
  }
}
    ${SubscriptionPendingIntentCompleteFragmentDoc}`;
export type CreateSetupIntentMutationFn = Apollo.MutationFunction<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>;

/**
 * __useCreateSetupIntentMutation__
 *
 * To run a mutation, you first call `useCreateSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSetupIntentMutation, { data, loading, error }] = useCreateSetupIntentMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSetupIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>(CreateSetupIntentDocument, options);
      }
export type CreateSetupIntentMutationHookResult = ReturnType<typeof useCreateSetupIntentMutation>;
export type CreateSetupIntentMutationResult = Apollo.MutationResult<CreateSetupIntentMutation>;
export type CreateSetupIntentMutationOptions = Apollo.BaseMutationOptions<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>;
export const QueueDeleteUserDocument = gql`
    mutation QueueDeleteUser {
  queueDeleteUser {
    ...UserComplete
  }
}
    ${UserCompleteFragmentDoc}`;
export type QueueDeleteUserMutationFn = Apollo.MutationFunction<QueueDeleteUserMutation, QueueDeleteUserMutationVariables>;

/**
 * __useQueueDeleteUserMutation__
 *
 * To run a mutation, you first call `useQueueDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQueueDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [queueDeleteUserMutation, { data, loading, error }] = useQueueDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useQueueDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<QueueDeleteUserMutation, QueueDeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QueueDeleteUserMutation, QueueDeleteUserMutationVariables>(QueueDeleteUserDocument, options);
      }
export type QueueDeleteUserMutationHookResult = ReturnType<typeof useQueueDeleteUserMutation>;
export type QueueDeleteUserMutationResult = Apollo.MutationResult<QueueDeleteUserMutation>;
export type QueueDeleteUserMutationOptions = Apollo.BaseMutationOptions<QueueDeleteUserMutation, QueueDeleteUserMutationVariables>;
export const UnqueueDeleteUserDocument = gql`
    mutation UnqueueDeleteUser {
  unqueueDeleteUser {
    ...UserComplete
  }
}
    ${UserCompleteFragmentDoc}`;
export type UnqueueDeleteUserMutationFn = Apollo.MutationFunction<UnqueueDeleteUserMutation, UnqueueDeleteUserMutationVariables>;

/**
 * __useUnqueueDeleteUserMutation__
 *
 * To run a mutation, you first call `useUnqueueDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnqueueDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unqueueDeleteUserMutation, { data, loading, error }] = useUnqueueDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnqueueDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<UnqueueDeleteUserMutation, UnqueueDeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnqueueDeleteUserMutation, UnqueueDeleteUserMutationVariables>(UnqueueDeleteUserDocument, options);
      }
export type UnqueueDeleteUserMutationHookResult = ReturnType<typeof useUnqueueDeleteUserMutation>;
export type UnqueueDeleteUserMutationResult = Apollo.MutationResult<UnqueueDeleteUserMutation>;
export type UnqueueDeleteUserMutationOptions = Apollo.BaseMutationOptions<UnqueueDeleteUserMutation, UnqueueDeleteUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    ...UserComplete
  }
}
    ${UserCompleteFragmentDoc}`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const PromotionCodeDocument = gql`
    query PromotionCode($input: PromotionCodeInput!) {
  promotionCode(input: $input) {
    ...PromotionCodeComplete
  }
}
    ${PromotionCodeCompleteFragmentDoc}`;

/**
 * __usePromotionCodeQuery__
 *
 * To run a query within a React component, call `usePromotionCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionCodeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromotionCodeQuery(baseOptions: Apollo.QueryHookOptions<PromotionCodeQuery, PromotionCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionCodeQuery, PromotionCodeQueryVariables>(PromotionCodeDocument, options);
      }
export function usePromotionCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionCodeQuery, PromotionCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionCodeQuery, PromotionCodeQueryVariables>(PromotionCodeDocument, options);
        }
export type PromotionCodeQueryHookResult = ReturnType<typeof usePromotionCodeQuery>;
export type PromotionCodeLazyQueryHookResult = ReturnType<typeof usePromotionCodeLazyQuery>;
export type PromotionCodeQueryResult = Apollo.QueryResult<PromotionCodeQuery, PromotionCodeQueryVariables>;
export const SubscriptionPlansDocument = gql`
    query SubscriptionPlans($options: ListSubscriptionPlanOptions!) {
  subscriptionPlans(options: $options) {
    ...SubscriptionPlanComplete
  }
}
    ${SubscriptionPlanCompleteFragmentDoc}`;

/**
 * __useSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSubscriptionPlansQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, options);
      }
export function useSubscriptionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, options);
        }
export type SubscriptionPlansQueryHookResult = ReturnType<typeof useSubscriptionPlansQuery>;
export type SubscriptionPlansLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansLazyQuery>;
export type SubscriptionPlansQueryResult = Apollo.QueryResult<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>;
export const SubscriptionPlanDocument = gql`
    query SubscriptionPlan($id: ID!) {
  subscriptionPlan(id: $id) {
    ...SubscriptionPlanComplete
  }
}
    ${SubscriptionPlanCompleteFragmentDoc}`;

/**
 * __useSubscriptionPlanQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscriptionPlanQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(SubscriptionPlanDocument, options);
      }
export function useSubscriptionPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(SubscriptionPlanDocument, options);
        }
export type SubscriptionPlanQueryHookResult = ReturnType<typeof useSubscriptionPlanQuery>;
export type SubscriptionPlanLazyQueryHookResult = ReturnType<typeof useSubscriptionPlanLazyQuery>;
export type SubscriptionPlanQueryResult = Apollo.QueryResult<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...UserComplete
  }
}
    ${UserCompleteFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserByEmailDocument = gql`
    query UserByEmail($email: String!) {
  userByEmail(email: $email) {
    ...UserComplete
  }
}
    ${UserCompleteFragmentDoc}`;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
      }
export function useUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
        }
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>;
export type UserByEmailLazyQueryHookResult = ReturnType<typeof useUserByEmailLazyQuery>;
export type UserByEmailQueryResult = Apollo.QueryResult<UserByEmailQuery, UserByEmailQueryVariables>;
export const SubscriptionWithScheduledUpdateAndPromotionDocument = gql`
    query SubscriptionWithScheduledUpdateAndPromotion {
  me {
    ...UserIdentifiable
    credits {
      ...CreditsComplete
    }
    subscription {
      ...UserSubscriptionWithScheduledUpdateAndPromotion
    }
  }
}
    ${UserIdentifiableFragmentDoc}
${CreditsCompleteFragmentDoc}
${UserSubscriptionWithScheduledUpdateAndPromotionFragmentDoc}`;

/**
 * __useSubscriptionWithScheduledUpdateAndPromotionQuery__
 *
 * To run a query within a React component, call `useSubscriptionWithScheduledUpdateAndPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionWithScheduledUpdateAndPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionWithScheduledUpdateAndPromotionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionWithScheduledUpdateAndPromotionQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionWithScheduledUpdateAndPromotionQuery, SubscriptionWithScheduledUpdateAndPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionWithScheduledUpdateAndPromotionQuery, SubscriptionWithScheduledUpdateAndPromotionQueryVariables>(SubscriptionWithScheduledUpdateAndPromotionDocument, options);
      }
export function useSubscriptionWithScheduledUpdateAndPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionWithScheduledUpdateAndPromotionQuery, SubscriptionWithScheduledUpdateAndPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionWithScheduledUpdateAndPromotionQuery, SubscriptionWithScheduledUpdateAndPromotionQueryVariables>(SubscriptionWithScheduledUpdateAndPromotionDocument, options);
        }
export type SubscriptionWithScheduledUpdateAndPromotionQueryHookResult = ReturnType<typeof useSubscriptionWithScheduledUpdateAndPromotionQuery>;
export type SubscriptionWithScheduledUpdateAndPromotionLazyQueryHookResult = ReturnType<typeof useSubscriptionWithScheduledUpdateAndPromotionLazyQuery>;
export type SubscriptionWithScheduledUpdateAndPromotionQueryResult = Apollo.QueryResult<SubscriptionWithScheduledUpdateAndPromotionQuery, SubscriptionWithScheduledUpdateAndPromotionQueryVariables>;
export const SubscriptionWithPendingIntentDocument = gql`
    query SubscriptionWithPendingIntent {
  me {
    ...UserIdentifiable
    subscription {
      ...UserSubscriptionWithPendingIntent
    }
  }
}
    ${UserIdentifiableFragmentDoc}
${UserSubscriptionWithPendingIntentFragmentDoc}`;

/**
 * __useSubscriptionWithPendingIntentQuery__
 *
 * To run a query within a React component, call `useSubscriptionWithPendingIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionWithPendingIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionWithPendingIntentQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionWithPendingIntentQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionWithPendingIntentQuery, SubscriptionWithPendingIntentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionWithPendingIntentQuery, SubscriptionWithPendingIntentQueryVariables>(SubscriptionWithPendingIntentDocument, options);
      }
export function useSubscriptionWithPendingIntentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionWithPendingIntentQuery, SubscriptionWithPendingIntentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionWithPendingIntentQuery, SubscriptionWithPendingIntentQueryVariables>(SubscriptionWithPendingIntentDocument, options);
        }
export type SubscriptionWithPendingIntentQueryHookResult = ReturnType<typeof useSubscriptionWithPendingIntentQuery>;
export type SubscriptionWithPendingIntentLazyQueryHookResult = ReturnType<typeof useSubscriptionWithPendingIntentLazyQuery>;
export type SubscriptionWithPendingIntentQueryResult = Apollo.QueryResult<SubscriptionWithPendingIntentQuery, SubscriptionWithPendingIntentQueryVariables>;
export const ApiKeyDocument = gql`
    query ApiKey($pk: ID!) {
  apiKey(pk: $pk) {
    ...ApiKeyComplete
  }
}
    ${ApiKeyCompleteFragmentDoc}`;

/**
 * __useApiKeyQuery__
 *
 * To run a query within a React component, call `useApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeyQuery({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useApiKeyQuery(baseOptions: Apollo.QueryHookOptions<ApiKeyQuery, ApiKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiKeyQuery, ApiKeyQueryVariables>(ApiKeyDocument, options);
      }
export function useApiKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiKeyQuery, ApiKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiKeyQuery, ApiKeyQueryVariables>(ApiKeyDocument, options);
        }
export type ApiKeyQueryHookResult = ReturnType<typeof useApiKeyQuery>;
export type ApiKeyLazyQueryHookResult = ReturnType<typeof useApiKeyLazyQuery>;
export type ApiKeyQueryResult = Apollo.QueryResult<ApiKeyQuery, ApiKeyQueryVariables>;
export const ApiKeysDocument = gql`
    query ApiKeys($options: ListApiKeysOptions) {
  apiKeys(options: $options) {
    items {
      ...ApiKeyComplete
    }
    cursor
  }
}
    ${ApiKeyCompleteFragmentDoc}`;

/**
 * __useApiKeysQuery__
 *
 * To run a query within a React component, call `useApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useApiKeysQuery(baseOptions?: Apollo.QueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
      }
export function useApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
        }
export type ApiKeysQueryHookResult = ReturnType<typeof useApiKeysQuery>;
export type ApiKeysLazyQueryHookResult = ReturnType<typeof useApiKeysLazyQuery>;
export type ApiKeysQueryResult = Apollo.QueryResult<ApiKeysQuery, ApiKeysQueryVariables>;
export const ProjectsDocument = gql`
    query Projects($options: ListProjectsOptions) {
  projects(options: $options) {
    items {
      ...ProjectComplete
    }
    cursor
  }
}
    ${ProjectCompleteFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const ProjectDocument = gql`
    query Project($id: ID!) {
  project(id: $id) {
    ...ProjectComplete
  }
}
    ${ProjectCompleteFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectMenuDocument = gql`
    query ProjectMenu($id: ID!) {
  project(id: $id) {
    ...ProjectWithAllItemMetas
  }
}
    ${ProjectWithAllItemMetasFragmentDoc}`;

/**
 * __useProjectMenuQuery__
 *
 * To run a query within a React component, call `useProjectMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMenuQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectMenuQuery(baseOptions: Apollo.QueryHookOptions<ProjectMenuQuery, ProjectMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectMenuQuery, ProjectMenuQueryVariables>(ProjectMenuDocument, options);
      }
export function useProjectMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectMenuQuery, ProjectMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectMenuQuery, ProjectMenuQueryVariables>(ProjectMenuDocument, options);
        }
export type ProjectMenuQueryHookResult = ReturnType<typeof useProjectMenuQuery>;
export type ProjectMenuLazyQueryHookResult = ReturnType<typeof useProjectMenuLazyQuery>;
export type ProjectMenuQueryResult = Apollo.QueryResult<ProjectMenuQuery, ProjectMenuQueryVariables>;
export const NoteMetaDocument = gql`
    query NoteMeta($id: ID!) {
  note(id: $id) {
    ...NoteMeta
  }
}
    ${NoteMetaFragmentDoc}`;

/**
 * __useNoteMetaQuery__
 *
 * To run a query within a React component, call `useNoteMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteMetaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteMetaQuery(baseOptions: Apollo.QueryHookOptions<NoteMetaQuery, NoteMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteMetaQuery, NoteMetaQueryVariables>(NoteMetaDocument, options);
      }
export function useNoteMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteMetaQuery, NoteMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteMetaQuery, NoteMetaQueryVariables>(NoteMetaDocument, options);
        }
export type NoteMetaQueryHookResult = ReturnType<typeof useNoteMetaQuery>;
export type NoteMetaLazyQueryHookResult = ReturnType<typeof useNoteMetaLazyQuery>;
export type NoteMetaQueryResult = Apollo.QueryResult<NoteMetaQuery, NoteMetaQueryVariables>;
export const NoteDocument = gql`
    query Note($id: ID!) {
  note(id: $id) {
    ...NoteComplete
  }
}
    ${NoteCompleteFragmentDoc}`;

/**
 * __useNoteQuery__
 *
 * To run a query within a React component, call `useNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteQuery(baseOptions: Apollo.QueryHookOptions<NoteQuery, NoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
      }
export function useNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteQuery, NoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
        }
export type NoteQueryHookResult = ReturnType<typeof useNoteQuery>;
export type NoteLazyQueryHookResult = ReturnType<typeof useNoteLazyQuery>;
export type NoteQueryResult = Apollo.QueryResult<NoteQuery, NoteQueryVariables>;
export const FolderMetaDocument = gql`
    query FolderMeta($id: ID!) {
  folder(id: $id) {
    ...FolderMeta
  }
}
    ${FolderMetaFragmentDoc}`;

/**
 * __useFolderMetaQuery__
 *
 * To run a query within a React component, call `useFolderMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderMetaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFolderMetaQuery(baseOptions: Apollo.QueryHookOptions<FolderMetaQuery, FolderMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FolderMetaQuery, FolderMetaQueryVariables>(FolderMetaDocument, options);
      }
export function useFolderMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolderMetaQuery, FolderMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FolderMetaQuery, FolderMetaQueryVariables>(FolderMetaDocument, options);
        }
export type FolderMetaQueryHookResult = ReturnType<typeof useFolderMetaQuery>;
export type FolderMetaLazyQueryHookResult = ReturnType<typeof useFolderMetaLazyQuery>;
export type FolderMetaQueryResult = Apollo.QueryResult<FolderMetaQuery, FolderMetaQueryVariables>;
export const ResourceMetaDocument = gql`
    query ResourceMeta($id: ID!) {
  resource(id: $id) {
    ...ResourceMeta
  }
}
    ${ResourceMetaFragmentDoc}`;

/**
 * __useResourceMetaQuery__
 *
 * To run a query within a React component, call `useResourceMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceMetaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceMetaQuery(baseOptions: Apollo.QueryHookOptions<ResourceMetaQuery, ResourceMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceMetaQuery, ResourceMetaQueryVariables>(ResourceMetaDocument, options);
      }
export function useResourceMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceMetaQuery, ResourceMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceMetaQuery, ResourceMetaQueryVariables>(ResourceMetaDocument, options);
        }
export type ResourceMetaQueryHookResult = ReturnType<typeof useResourceMetaQuery>;
export type ResourceMetaLazyQueryHookResult = ReturnType<typeof useResourceMetaLazyQuery>;
export type ResourceMetaQueryResult = Apollo.QueryResult<ResourceMetaQuery, ResourceMetaQueryVariables>;
export const NoteImageDocument = gql`
    query NoteImage($id: ID!, $imageId: ID!) {
  noteImage(id: $id, imageId: $imageId) {
    id
    url
  }
}
    `;

/**
 * __useNoteImageQuery__
 *
 * To run a query within a React component, call `useNoteImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useNoteImageQuery(baseOptions: Apollo.QueryHookOptions<NoteImageQuery, NoteImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteImageQuery, NoteImageQueryVariables>(NoteImageDocument, options);
      }
export function useNoteImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteImageQuery, NoteImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteImageQuery, NoteImageQueryVariables>(NoteImageDocument, options);
        }
export type NoteImageQueryHookResult = ReturnType<typeof useNoteImageQuery>;
export type NoteImageLazyQueryHookResult = ReturnType<typeof useNoteImageLazyQuery>;
export type NoteImageQueryResult = Apollo.QueryResult<NoteImageQuery, NoteImageQueryVariables>;
export const ResourceHighlightsDocument = gql`
    query ResourceHighlights($id: ID!) {
  resource(id: $id) {
    ...ResourceHighlights
  }
}
    ${ResourceHighlightsFragmentDoc}`;

/**
 * __useResourceHighlightsQuery__
 *
 * To run a query within a React component, call `useResourceHighlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceHighlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceHighlightsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceHighlightsQuery(baseOptions: Apollo.QueryHookOptions<ResourceHighlightsQuery, ResourceHighlightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceHighlightsQuery, ResourceHighlightsQueryVariables>(ResourceHighlightsDocument, options);
      }
export function useResourceHighlightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceHighlightsQuery, ResourceHighlightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceHighlightsQuery, ResourceHighlightsQueryVariables>(ResourceHighlightsDocument, options);
        }
export type ResourceHighlightsQueryHookResult = ReturnType<typeof useResourceHighlightsQuery>;
export type ResourceHighlightsLazyQueryHookResult = ReturnType<typeof useResourceHighlightsLazyQuery>;
export type ResourceHighlightsQueryResult = Apollo.QueryResult<ResourceHighlightsQuery, ResourceHighlightsQueryVariables>;
export const ResourcePreviewDocument = gql`
    query ResourcePreview($id: ID!) {
  resource(id: $id) {
    ...ResourcePreview
  }
}
    ${ResourcePreviewFragmentDoc}`;

/**
 * __useResourcePreviewQuery__
 *
 * To run a query within a React component, call `useResourcePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourcePreviewQuery(baseOptions: Apollo.QueryHookOptions<ResourcePreviewQuery, ResourcePreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcePreviewQuery, ResourcePreviewQueryVariables>(ResourcePreviewDocument, options);
      }
export function useResourcePreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcePreviewQuery, ResourcePreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcePreviewQuery, ResourcePreviewQueryVariables>(ResourcePreviewDocument, options);
        }
export type ResourcePreviewQueryHookResult = ReturnType<typeof useResourcePreviewQuery>;
export type ResourcePreviewLazyQueryHookResult = ReturnType<typeof useResourcePreviewLazyQuery>;
export type ResourcePreviewQueryResult = Apollo.QueryResult<ResourcePreviewQuery, ResourcePreviewQueryVariables>;
export const ResourceOverviewDocument = gql`
    query ResourceOverview($id: ID!) {
  resource(id: $id) {
    ...ResourceMetadataState
    ...ResourceFiguresState
  }
}
    ${ResourceMetadataStateFragmentDoc}
${ResourceFiguresStateFragmentDoc}`;

/**
 * __useResourceOverviewQuery__
 *
 * To run a query within a React component, call `useResourceOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceOverviewQuery(baseOptions: Apollo.QueryHookOptions<ResourceOverviewQuery, ResourceOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceOverviewQuery, ResourceOverviewQueryVariables>(ResourceOverviewDocument, options);
      }
export function useResourceOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceOverviewQuery, ResourceOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceOverviewQuery, ResourceOverviewQueryVariables>(ResourceOverviewDocument, options);
        }
export type ResourceOverviewQueryHookResult = ReturnType<typeof useResourceOverviewQuery>;
export type ResourceOverviewLazyQueryHookResult = ReturnType<typeof useResourceOverviewLazyQuery>;
export type ResourceOverviewQueryResult = Apollo.QueryResult<ResourceOverviewQuery, ResourceOverviewQueryVariables>;
export const ResourceSectionsDocument = gql`
    query ResourceSections($id: ID!) {
  resource(id: $id) {
    ...ResourceSectionsState
  }
}
    ${ResourceSectionsStateFragmentDoc}`;

/**
 * __useResourceSectionsQuery__
 *
 * To run a query within a React component, call `useResourceSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceSectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceSectionsQuery(baseOptions: Apollo.QueryHookOptions<ResourceSectionsQuery, ResourceSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceSectionsQuery, ResourceSectionsQueryVariables>(ResourceSectionsDocument, options);
      }
export function useResourceSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceSectionsQuery, ResourceSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceSectionsQuery, ResourceSectionsQueryVariables>(ResourceSectionsDocument, options);
        }
export type ResourceSectionsQueryHookResult = ReturnType<typeof useResourceSectionsQuery>;
export type ResourceSectionsLazyQueryHookResult = ReturnType<typeof useResourceSectionsLazyQuery>;
export type ResourceSectionsQueryResult = Apollo.QueryResult<ResourceSectionsQuery, ResourceSectionsQueryVariables>;
export const ResourceNlpDocument = gql`
    query ResourceNlp($id: ID!) {
  resource(id: $id) {
    ...ResourceNlp
  }
}
    ${ResourceNlpFragmentDoc}`;

/**
 * __useResourceNlpQuery__
 *
 * To run a query within a React component, call `useResourceNlpQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceNlpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceNlpQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceNlpQuery(baseOptions: Apollo.QueryHookOptions<ResourceNlpQuery, ResourceNlpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceNlpQuery, ResourceNlpQueryVariables>(ResourceNlpDocument, options);
      }
export function useResourceNlpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceNlpQuery, ResourceNlpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceNlpQuery, ResourceNlpQueryVariables>(ResourceNlpDocument, options);
        }
export type ResourceNlpQueryHookResult = ReturnType<typeof useResourceNlpQuery>;
export type ResourceNlpLazyQueryHookResult = ReturnType<typeof useResourceNlpLazyQuery>;
export type ResourceNlpQueryResult = Apollo.QueryResult<ResourceNlpQuery, ResourceNlpQueryVariables>;
export const ResourceDocumentDocument = gql`
    query ResourceDocument($id: ID!) {
  resource(id: $id) {
    ...ResourceDocument
  }
}
    ${ResourceDocumentFragmentDoc}`;

/**
 * __useResourceDocumentQuery__
 *
 * To run a query within a React component, call `useResourceDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceDocumentQuery(baseOptions: Apollo.QueryHookOptions<ResourceDocumentQuery, ResourceDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceDocumentQuery, ResourceDocumentQueryVariables>(ResourceDocumentDocument, options);
      }
export function useResourceDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceDocumentQuery, ResourceDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceDocumentQuery, ResourceDocumentQueryVariables>(ResourceDocumentDocument, options);
        }
export type ResourceDocumentQueryHookResult = ReturnType<typeof useResourceDocumentQuery>;
export type ResourceDocumentLazyQueryHookResult = ReturnType<typeof useResourceDocumentLazyQuery>;
export type ResourceDocumentQueryResult = Apollo.QueryResult<ResourceDocumentQuery, ResourceDocumentQueryVariables>;
export const ResourcePdfDownloadDocument = gql`
    query ResourcePdfDownload($id: ID!) {
  resource(id: $id) {
    ...ResourceDocumentState
    ...ResourceHighlights
  }
}
    ${ResourceDocumentStateFragmentDoc}
${ResourceHighlightsFragmentDoc}`;

/**
 * __useResourcePdfDownloadQuery__
 *
 * To run a query within a React component, call `useResourcePdfDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePdfDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePdfDownloadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourcePdfDownloadQuery(baseOptions: Apollo.QueryHookOptions<ResourcePdfDownloadQuery, ResourcePdfDownloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcePdfDownloadQuery, ResourcePdfDownloadQueryVariables>(ResourcePdfDownloadDocument, options);
      }
export function useResourcePdfDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcePdfDownloadQuery, ResourcePdfDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcePdfDownloadQuery, ResourcePdfDownloadQueryVariables>(ResourcePdfDownloadDocument, options);
        }
export type ResourcePdfDownloadQueryHookResult = ReturnType<typeof useResourcePdfDownloadQuery>;
export type ResourcePdfDownloadLazyQueryHookResult = ReturnType<typeof useResourcePdfDownloadLazyQuery>;
export type ResourcePdfDownloadQueryResult = Apollo.QueryResult<ResourcePdfDownloadQuery, ResourcePdfDownloadQueryVariables>;
export const SectionSummaryHistoryDocument = gql`
    query SectionSummaryHistory($id: ID!, $input: SectionSummaryHistoryInput!) {
  resource(id: $id) {
    ...ResourceIdentifiable
    sectionSummaryHistory(input: $input) {
      items {
        ...SectionSummaryComplete
      }
    }
  }
}
    ${ResourceIdentifiableFragmentDoc}
${SectionSummaryCompleteFragmentDoc}`;

/**
 * __useSectionSummaryHistoryQuery__
 *
 * To run a query within a React component, call `useSectionSummaryHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionSummaryHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionSummaryHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSectionSummaryHistoryQuery(baseOptions: Apollo.QueryHookOptions<SectionSummaryHistoryQuery, SectionSummaryHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SectionSummaryHistoryQuery, SectionSummaryHistoryQueryVariables>(SectionSummaryHistoryDocument, options);
      }
export function useSectionSummaryHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SectionSummaryHistoryQuery, SectionSummaryHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SectionSummaryHistoryQuery, SectionSummaryHistoryQueryVariables>(SectionSummaryHistoryDocument, options);
        }
export type SectionSummaryHistoryQueryHookResult = ReturnType<typeof useSectionSummaryHistoryQuery>;
export type SectionSummaryHistoryLazyQueryHookResult = ReturnType<typeof useSectionSummaryHistoryLazyQuery>;
export type SectionSummaryHistoryQueryResult = Apollo.QueryResult<SectionSummaryHistoryQuery, SectionSummaryHistoryQueryVariables>;
export const ActiveSummaryRequestDocument = gql`
    query ActiveSummaryRequest($resourceId: ID!, $index: Int!) {
  activeSummaryRequest(resourceId: $resourceId, index: $index) @client {
    ...SummaryRequestComplete
  }
}
    ${SummaryRequestCompleteFragmentDoc}`;

/**
 * __useActiveSummaryRequestQuery__
 *
 * To run a query within a React component, call `useActiveSummaryRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveSummaryRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveSummaryRequestQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useActiveSummaryRequestQuery(baseOptions: Apollo.QueryHookOptions<ActiveSummaryRequestQuery, ActiveSummaryRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveSummaryRequestQuery, ActiveSummaryRequestQueryVariables>(ActiveSummaryRequestDocument, options);
      }
export function useActiveSummaryRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveSummaryRequestQuery, ActiveSummaryRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveSummaryRequestQuery, ActiveSummaryRequestQueryVariables>(ActiveSummaryRequestDocument, options);
        }
export type ActiveSummaryRequestQueryHookResult = ReturnType<typeof useActiveSummaryRequestQuery>;
export type ActiveSummaryRequestLazyQueryHookResult = ReturnType<typeof useActiveSummaryRequestLazyQuery>;
export type ActiveSummaryRequestQueryResult = Apollo.QueryResult<ActiveSummaryRequestQuery, ActiveSummaryRequestQueryVariables>;
export const FindUserDocument = gql`
    query FindUser($input: FindUserInput!) {
  findUser(input: $input) {
    email
    id
    betaUserExists
  }
}
    `;

/**
 * __useFindUserQuery__
 *
 * To run a query within a React component, call `useFindUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindUserQuery(baseOptions: Apollo.QueryHookOptions<FindUserQuery, FindUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUserQuery, FindUserQueryVariables>(FindUserDocument, options);
      }
export function useFindUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserQuery, FindUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUserQuery, FindUserQueryVariables>(FindUserDocument, options);
        }
export type FindUserQueryHookResult = ReturnType<typeof useFindUserQuery>;
export type FindUserLazyQueryHookResult = ReturnType<typeof useFindUserLazyQuery>;
export type FindUserQueryResult = Apollo.QueryResult<FindUserQuery, FindUserQueryVariables>;
export const SearchByNameDocument = gql`
    query SearchByName($input: SearchByNameInput!) {
  searchByName(input: $input) {
    results {
      ...MetadataSearchResultComplete
    }
  }
}
    ${MetadataSearchResultCompleteFragmentDoc}`;

/**
 * __useSearchByNameQuery__
 *
 * To run a query within a React component, call `useSearchByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchByNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchByNameQuery(baseOptions: Apollo.QueryHookOptions<SearchByNameQuery, SearchByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchByNameQuery, SearchByNameQueryVariables>(SearchByNameDocument, options);
      }
export function useSearchByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchByNameQuery, SearchByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchByNameQuery, SearchByNameQueryVariables>(SearchByNameDocument, options);
        }
export type SearchByNameQueryHookResult = ReturnType<typeof useSearchByNameQuery>;
export type SearchByNameLazyQueryHookResult = ReturnType<typeof useSearchByNameLazyQuery>;
export type SearchByNameQueryResult = Apollo.QueryResult<SearchByNameQuery, SearchByNameQueryVariables>;
export const SearchProjectContentDocument = gql`
    query SearchProjectContent($input: SearchProjectContentInput!) {
  searchProjectContent(input: $input) {
    query
    results {
      ...SearchContentResultComplete
    }
  }
}
    ${SearchContentResultCompleteFragmentDoc}`;

/**
 * __useSearchProjectContentQuery__
 *
 * To run a query within a React component, call `useSearchProjectContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProjectContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProjectContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchProjectContentQuery(baseOptions: Apollo.QueryHookOptions<SearchProjectContentQuery, SearchProjectContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProjectContentQuery, SearchProjectContentQueryVariables>(SearchProjectContentDocument, options);
      }
export function useSearchProjectContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProjectContentQuery, SearchProjectContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProjectContentQuery, SearchProjectContentQueryVariables>(SearchProjectContentDocument, options);
        }
export type SearchProjectContentQueryHookResult = ReturnType<typeof useSearchProjectContentQuery>;
export type SearchProjectContentLazyQueryHookResult = ReturnType<typeof useSearchProjectContentLazyQuery>;
export type SearchProjectContentQueryResult = Apollo.QueryResult<SearchProjectContentQuery, SearchProjectContentQueryVariables>;
export const SearchResourceContentDocument = gql`
    query SearchResourceContent($input: SearchResourceContentInput!) {
  searchResourceContent(input: $input) {
    query
    results {
      ...SearchContentResultComplete
    }
  }
}
    ${SearchContentResultCompleteFragmentDoc}`;

/**
 * __useSearchResourceContentQuery__
 *
 * To run a query within a React component, call `useSearchResourceContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResourceContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResourceContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchResourceContentQuery(baseOptions: Apollo.QueryHookOptions<SearchResourceContentQuery, SearchResourceContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchResourceContentQuery, SearchResourceContentQueryVariables>(SearchResourceContentDocument, options);
      }
export function useSearchResourceContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchResourceContentQuery, SearchResourceContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchResourceContentQuery, SearchResourceContentQueryVariables>(SearchResourceContentDocument, options);
        }
export type SearchResourceContentQueryHookResult = ReturnType<typeof useSearchResourceContentQuery>;
export type SearchResourceContentLazyQueryHookResult = ReturnType<typeof useSearchResourceContentLazyQuery>;
export type SearchResourceContentQueryResult = Apollo.QueryResult<SearchResourceContentQuery, SearchResourceContentQueryVariables>;
export const AskProjectContentDocument = gql`
    query AskProjectContent($input: AskProjectContentInput!) {
  askProjectContent(input: $input) {
    query
    noAnsGap
    answers {
      ...AskContentAnswerComplete
    }
  }
}
    ${AskContentAnswerCompleteFragmentDoc}`;

/**
 * __useAskProjectContentQuery__
 *
 * To run a query within a React component, call `useAskProjectContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAskProjectContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAskProjectContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAskProjectContentQuery(baseOptions: Apollo.QueryHookOptions<AskProjectContentQuery, AskProjectContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AskProjectContentQuery, AskProjectContentQueryVariables>(AskProjectContentDocument, options);
      }
export function useAskProjectContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AskProjectContentQuery, AskProjectContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AskProjectContentQuery, AskProjectContentQueryVariables>(AskProjectContentDocument, options);
        }
export type AskProjectContentQueryHookResult = ReturnType<typeof useAskProjectContentQuery>;
export type AskProjectContentLazyQueryHookResult = ReturnType<typeof useAskProjectContentLazyQuery>;
export type AskProjectContentQueryResult = Apollo.QueryResult<AskProjectContentQuery, AskProjectContentQueryVariables>;
export const AskResourceContentDocument = gql`
    query AskResourceContent($input: AskResourceContentInput!) {
  askResourceContent(input: $input) {
    query
    noAnsGap
    answers {
      ...AskContentAnswerComplete
    }
  }
}
    ${AskContentAnswerCompleteFragmentDoc}`;

/**
 * __useAskResourceContentQuery__
 *
 * To run a query within a React component, call `useAskResourceContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAskResourceContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAskResourceContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAskResourceContentQuery(baseOptions: Apollo.QueryHookOptions<AskResourceContentQuery, AskResourceContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AskResourceContentQuery, AskResourceContentQueryVariables>(AskResourceContentDocument, options);
      }
export function useAskResourceContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AskResourceContentQuery, AskResourceContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AskResourceContentQuery, AskResourceContentQueryVariables>(AskResourceContentDocument, options);
        }
export type AskResourceContentQueryHookResult = ReturnType<typeof useAskResourceContentQuery>;
export type AskResourceContentLazyQueryHookResult = ReturnType<typeof useAskResourceContentLazyQuery>;
export type AskResourceContentQueryResult = Apollo.QueryResult<AskResourceContentQuery, AskResourceContentQueryVariables>;
export const SummariseProjectContentDocument = gql`
    query SummariseProjectContent($input: SummariseProjectContentInput!) {
  summariseProjectContent(input: $input) {
    query
    summary {
      contentScore
      context
      text
      wordUsage
    }
    documents {
      ...SummariseContentDocumentComplete
    }
  }
}
    ${SummariseContentDocumentCompleteFragmentDoc}`;

/**
 * __useSummariseProjectContentQuery__
 *
 * To run a query within a React component, call `useSummariseProjectContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummariseProjectContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummariseProjectContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSummariseProjectContentQuery(baseOptions: Apollo.QueryHookOptions<SummariseProjectContentQuery, SummariseProjectContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummariseProjectContentQuery, SummariseProjectContentQueryVariables>(SummariseProjectContentDocument, options);
      }
export function useSummariseProjectContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummariseProjectContentQuery, SummariseProjectContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummariseProjectContentQuery, SummariseProjectContentQueryVariables>(SummariseProjectContentDocument, options);
        }
export type SummariseProjectContentQueryHookResult = ReturnType<typeof useSummariseProjectContentQuery>;
export type SummariseProjectContentLazyQueryHookResult = ReturnType<typeof useSummariseProjectContentLazyQuery>;
export type SummariseProjectContentQueryResult = Apollo.QueryResult<SummariseProjectContentQuery, SummariseProjectContentQueryVariables>;
export const SummariseResourceContentDocument = gql`
    query SummariseResourceContent($input: SummariseResourceContentInput!) {
  summariseResourceContent(input: $input) {
    query
    summary {
      contentScore
      context
      text
      wordUsage
    }
    documents {
      ...SummariseContentDocumentComplete
    }
  }
}
    ${SummariseContentDocumentCompleteFragmentDoc}`;

/**
 * __useSummariseResourceContentQuery__
 *
 * To run a query within a React component, call `useSummariseResourceContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummariseResourceContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummariseResourceContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSummariseResourceContentQuery(baseOptions: Apollo.QueryHookOptions<SummariseResourceContentQuery, SummariseResourceContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummariseResourceContentQuery, SummariseResourceContentQueryVariables>(SummariseResourceContentDocument, options);
      }
export function useSummariseResourceContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummariseResourceContentQuery, SummariseResourceContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummariseResourceContentQuery, SummariseResourceContentQueryVariables>(SummariseResourceContentDocument, options);
        }
export type SummariseResourceContentQueryHookResult = ReturnType<typeof useSummariseResourceContentQuery>;
export type SummariseResourceContentLazyQueryHookResult = ReturnType<typeof useSummariseResourceContentLazyQuery>;
export type SummariseResourceContentQueryResult = Apollo.QueryResult<SummariseResourceContentQuery, SummariseResourceContentQueryVariables>;
export const SearchResourceDocument = gql`
    query searchResource($input: SearchResourceInput!) {
  searchResource(input: $input) {
    ...SearchResourceComplete
  }
}
    ${SearchResourceCompleteFragmentDoc}`;

/**
 * __useSearchResourceQuery__
 *
 * To run a query within a React component, call `useSearchResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResourceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchResourceQuery(baseOptions: Apollo.QueryHookOptions<SearchResourceQuery, SearchResourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchResourceQuery, SearchResourceQueryVariables>(SearchResourceDocument, options);
      }
export function useSearchResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchResourceQuery, SearchResourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchResourceQuery, SearchResourceQueryVariables>(SearchResourceDocument, options);
        }
export type SearchResourceQueryHookResult = ReturnType<typeof useSearchResourceQuery>;
export type SearchResourceLazyQueryHookResult = ReturnType<typeof useSearchResourceLazyQuery>;
export type SearchResourceQueryResult = Apollo.QueryResult<SearchResourceQuery, SearchResourceQueryVariables>;
export const QuickSummariesDocument = gql`
    query QuickSummaries($options: ListResourcesOptions) {
  quickSummaries(options: $options) {
    items {
      ...ResourceQuickSummaryTableItem
    }
    cursor
    hasMore
  }
}
    ${ResourceQuickSummaryTableItemFragmentDoc}`;

/**
 * __useQuickSummariesQuery__
 *
 * To run a query within a React component, call `useQuickSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSummariesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useQuickSummariesQuery(baseOptions?: Apollo.QueryHookOptions<QuickSummariesQuery, QuickSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickSummariesQuery, QuickSummariesQueryVariables>(QuickSummariesDocument, options);
      }
export function useQuickSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickSummariesQuery, QuickSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickSummariesQuery, QuickSummariesQueryVariables>(QuickSummariesDocument, options);
        }
export type QuickSummariesQueryHookResult = ReturnType<typeof useQuickSummariesQuery>;
export type QuickSummariesLazyQueryHookResult = ReturnType<typeof useQuickSummariesLazyQuery>;
export type QuickSummariesQueryResult = Apollo.QueryResult<QuickSummariesQuery, QuickSummariesQueryVariables>;
export const SummarisersDocument = gql`
    query Summarisers {
  summarisers {
    ...SummariserComplete
  }
}
    ${SummariserCompleteFragmentDoc}`;

/**
 * __useSummarisersQuery__
 *
 * To run a query within a React component, call `useSummarisersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarisersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarisersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSummarisersQuery(baseOptions?: Apollo.QueryHookOptions<SummarisersQuery, SummarisersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarisersQuery, SummarisersQueryVariables>(SummarisersDocument, options);
      }
export function useSummarisersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarisersQuery, SummarisersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarisersQuery, SummarisersQueryVariables>(SummarisersDocument, options);
        }
export type SummarisersQueryHookResult = ReturnType<typeof useSummarisersQuery>;
export type SummarisersLazyQueryHookResult = ReturnType<typeof useSummarisersLazyQuery>;
export type SummarisersQueryResult = Apollo.QueryResult<SummarisersQuery, SummarisersQueryVariables>;
export const PromptsDocument = gql`
    query Prompts {
  prompts {
    items {
      ...PromptComplete
    }
  }
}
    ${PromptCompleteFragmentDoc}`;

/**
 * __usePromptsQuery__
 *
 * To run a query within a React component, call `usePromptsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromptsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromptsQuery(baseOptions?: Apollo.QueryHookOptions<PromptsQuery, PromptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromptsQuery, PromptsQueryVariables>(PromptsDocument, options);
      }
export function usePromptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromptsQuery, PromptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromptsQuery, PromptsQueryVariables>(PromptsDocument, options);
        }
export type PromptsQueryHookResult = ReturnType<typeof usePromptsQuery>;
export type PromptsLazyQueryHookResult = ReturnType<typeof usePromptsLazyQuery>;
export type PromptsQueryResult = Apollo.QueryResult<PromptsQuery, PromptsQueryVariables>;
export const PromptDocument = gql`
    query Prompt($id: ID!) {
  prompt(id: $id) {
    ...PromptComplete
  }
}
    ${PromptCompleteFragmentDoc}`;

/**
 * __usePromptQuery__
 *
 * To run a query within a React component, call `usePromptQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePromptQuery(baseOptions: Apollo.QueryHookOptions<PromptQuery, PromptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromptQuery, PromptQueryVariables>(PromptDocument, options);
      }
export function usePromptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromptQuery, PromptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromptQuery, PromptQueryVariables>(PromptDocument, options);
        }
export type PromptQueryHookResult = ReturnType<typeof usePromptQuery>;
export type PromptLazyQueryHookResult = ReturnType<typeof usePromptLazyQuery>;
export type PromptQueryResult = Apollo.QueryResult<PromptQuery, PromptQueryVariables>;
export const ResourceNameDocument = gql`
    query ResourceName($id: ID!) {
  resource(id: $id) {
    id
    type
    name
  }
}
    `;

/**
 * __useResourceNameQuery__
 *
 * To run a query within a React component, call `useResourceNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceNameQuery(baseOptions: Apollo.QueryHookOptions<ResourceNameQuery, ResourceNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceNameQuery, ResourceNameQueryVariables>(ResourceNameDocument, options);
      }
export function useResourceNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceNameQuery, ResourceNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceNameQuery, ResourceNameQueryVariables>(ResourceNameDocument, options);
        }
export type ResourceNameQueryHookResult = ReturnType<typeof useResourceNameQuery>;
export type ResourceNameLazyQueryHookResult = ReturnType<typeof useResourceNameLazyQuery>;
export type ResourceNameQueryResult = Apollo.QueryResult<ResourceNameQuery, ResourceNameQueryVariables>;
export const FolderNameDocument = gql`
    query FolderName($id: ID!) {
  folder(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useFolderNameQuery__
 *
 * To run a query within a React component, call `useFolderNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFolderNameQuery(baseOptions: Apollo.QueryHookOptions<FolderNameQuery, FolderNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FolderNameQuery, FolderNameQueryVariables>(FolderNameDocument, options);
      }
export function useFolderNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolderNameQuery, FolderNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FolderNameQuery, FolderNameQueryVariables>(FolderNameDocument, options);
        }
export type FolderNameQueryHookResult = ReturnType<typeof useFolderNameQuery>;
export type FolderNameLazyQueryHookResult = ReturnType<typeof useFolderNameLazyQuery>;
export type FolderNameQueryResult = Apollo.QueryResult<FolderNameQuery, FolderNameQueryVariables>;
export const AiChatMetaDocument = gql`
    query AiChatMeta($id: ID!) {
  aiChat(id: $id) {
    ...AiChatMeta
  }
}
    ${AiChatMetaFragmentDoc}`;

/**
 * __useAiChatMetaQuery__
 *
 * To run a query within a React component, call `useAiChatMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiChatMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiChatMetaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAiChatMetaQuery(baseOptions: Apollo.QueryHookOptions<AiChatMetaQuery, AiChatMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiChatMetaQuery, AiChatMetaQueryVariables>(AiChatMetaDocument, options);
      }
export function useAiChatMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiChatMetaQuery, AiChatMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiChatMetaQuery, AiChatMetaQueryVariables>(AiChatMetaDocument, options);
        }
export type AiChatMetaQueryHookResult = ReturnType<typeof useAiChatMetaQuery>;
export type AiChatMetaLazyQueryHookResult = ReturnType<typeof useAiChatMetaLazyQuery>;
export type AiChatMetaQueryResult = Apollo.QueryResult<AiChatMetaQuery, AiChatMetaQueryVariables>;
export const AiChatsDocument = gql`
    query AiChats($projectId: ID!) {
  project(id: $projectId) {
    allAiChats {
      items {
        ...AiChatMeta
      }
    }
  }
}
    ${AiChatMetaFragmentDoc}`;

/**
 * __useAiChatsQuery__
 *
 * To run a query within a React component, call `useAiChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiChatsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAiChatsQuery(baseOptions: Apollo.QueryHookOptions<AiChatsQuery, AiChatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiChatsQuery, AiChatsQueryVariables>(AiChatsDocument, options);
      }
export function useAiChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiChatsQuery, AiChatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiChatsQuery, AiChatsQueryVariables>(AiChatsDocument, options);
        }
export type AiChatsQueryHookResult = ReturnType<typeof useAiChatsQuery>;
export type AiChatsLazyQueryHookResult = ReturnType<typeof useAiChatsLazyQuery>;
export type AiChatsQueryResult = Apollo.QueryResult<AiChatsQuery, AiChatsQueryVariables>;
export const AiChatMessagesDocument = gql`
    query AiChatMessages($id: ID!, $options: ListAiChatMessagesOptions) {
  aiChat(id: $id) {
    ...AiChatIdentifiable
    messages(options: $options) {
      cursor
      hasMore
      limit
      items {
        ...AiChatMessageComplete
      }
    }
  }
}
    ${AiChatIdentifiableFragmentDoc}
${AiChatMessageCompleteFragmentDoc}`;

/**
 * __useAiChatMessagesQuery__
 *
 * To run a query within a React component, call `useAiChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiChatMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAiChatMessagesQuery(baseOptions: Apollo.QueryHookOptions<AiChatMessagesQuery, AiChatMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiChatMessagesQuery, AiChatMessagesQueryVariables>(AiChatMessagesDocument, options);
      }
export function useAiChatMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiChatMessagesQuery, AiChatMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiChatMessagesQuery, AiChatMessagesQueryVariables>(AiChatMessagesDocument, options);
        }
export type AiChatMessagesQueryHookResult = ReturnType<typeof useAiChatMessagesQuery>;
export type AiChatMessagesLazyQueryHookResult = ReturnType<typeof useAiChatMessagesLazyQuery>;
export type AiChatMessagesQueryResult = Apollo.QueryResult<AiChatMessagesQuery, AiChatMessagesQueryVariables>;
export const CountMigratedUsersDocument = gql`
    query CountMigratedUsers {
  countMigratedUsers {
    count
  }
}
    `;

/**
 * __useCountMigratedUsersQuery__
 *
 * To run a query within a React component, call `useCountMigratedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountMigratedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountMigratedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountMigratedUsersQuery(baseOptions?: Apollo.QueryHookOptions<CountMigratedUsersQuery, CountMigratedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountMigratedUsersQuery, CountMigratedUsersQueryVariables>(CountMigratedUsersDocument, options);
      }
export function useCountMigratedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountMigratedUsersQuery, CountMigratedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountMigratedUsersQuery, CountMigratedUsersQueryVariables>(CountMigratedUsersDocument, options);
        }
export type CountMigratedUsersQueryHookResult = ReturnType<typeof useCountMigratedUsersQuery>;
export type CountMigratedUsersLazyQueryHookResult = ReturnType<typeof useCountMigratedUsersLazyQuery>;
export type CountMigratedUsersQueryResult = Apollo.QueryResult<CountMigratedUsersQuery, CountMigratedUsersQueryVariables>;
export const PaymentMethodsDocument = gql`
    query PaymentMethods {
  me {
    ...PaymentMethodsComplete
  }
}
    ${PaymentMethodsCompleteFragmentDoc}`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
      }
export function usePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
        }
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>;
export const OnSubscriptionUpdateDocument = gql`
    subscription OnSubscriptionUpdate {
  onSubscriptionUpdate {
    ...UserSubscriptionComplete
  }
}
    ${UserSubscriptionCompleteFragmentDoc}`;

/**
 * __useOnSubscriptionUpdateSubscription__
 *
 * To run a query within a React component, call `useOnSubscriptionUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSubscriptionUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSubscriptionUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnSubscriptionUpdateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnSubscriptionUpdateSubscription, OnSubscriptionUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnSubscriptionUpdateSubscription, OnSubscriptionUpdateSubscriptionVariables>(OnSubscriptionUpdateDocument, options);
      }
export type OnSubscriptionUpdateSubscriptionHookResult = ReturnType<typeof useOnSubscriptionUpdateSubscription>;
export type OnSubscriptionUpdateSubscriptionResult = Apollo.SubscriptionResult<OnSubscriptionUpdateSubscription>;
export const OnSubscriptionDeleteDocument = gql`
    subscription OnSubscriptionDelete {
  onSubscriptionDelete {
    ...UserSubscriptionIdentifiable
  }
}
    ${UserSubscriptionIdentifiableFragmentDoc}`;

/**
 * __useOnSubscriptionDeleteSubscription__
 *
 * To run a query within a React component, call `useOnSubscriptionDeleteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSubscriptionDeleteSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSubscriptionDeleteSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnSubscriptionDeleteSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnSubscriptionDeleteSubscription, OnSubscriptionDeleteSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnSubscriptionDeleteSubscription, OnSubscriptionDeleteSubscriptionVariables>(OnSubscriptionDeleteDocument, options);
      }
export type OnSubscriptionDeleteSubscriptionHookResult = ReturnType<typeof useOnSubscriptionDeleteSubscription>;
export type OnSubscriptionDeleteSubscriptionResult = Apollo.SubscriptionResult<OnSubscriptionDeleteSubscription>;
export const OnProjectCreateDocument = gql`
    subscription OnProjectCreate {
  onProjectCreate {
    ...ProjectComplete
  }
}
    ${ProjectCompleteFragmentDoc}`;

/**
 * __useOnProjectCreateSubscription__
 *
 * To run a query within a React component, call `useOnProjectCreateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnProjectCreateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProjectCreateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnProjectCreateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnProjectCreateSubscription, OnProjectCreateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnProjectCreateSubscription, OnProjectCreateSubscriptionVariables>(OnProjectCreateDocument, options);
      }
export type OnProjectCreateSubscriptionHookResult = ReturnType<typeof useOnProjectCreateSubscription>;
export type OnProjectCreateSubscriptionResult = Apollo.SubscriptionResult<OnProjectCreateSubscription>;
export const OnProjectDeleteDocument = gql`
    subscription OnProjectDelete {
  onProjectDelete {
    ...ProjectComplete
  }
}
    ${ProjectCompleteFragmentDoc}`;

/**
 * __useOnProjectDeleteSubscription__
 *
 * To run a query within a React component, call `useOnProjectDeleteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnProjectDeleteSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProjectDeleteSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnProjectDeleteSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnProjectDeleteSubscription, OnProjectDeleteSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnProjectDeleteSubscription, OnProjectDeleteSubscriptionVariables>(OnProjectDeleteDocument, options);
      }
export type OnProjectDeleteSubscriptionHookResult = ReturnType<typeof useOnProjectDeleteSubscription>;
export type OnProjectDeleteSubscriptionResult = Apollo.SubscriptionResult<OnProjectDeleteSubscription>;
export const OnResourceCreateDocument = gql`
    subscription OnResourceCreate {
  onResourceCreate {
    ...ResourceMeta
  }
}
    ${ResourceMetaFragmentDoc}`;

/**
 * __useOnResourceCreateSubscription__
 *
 * To run a query within a React component, call `useOnResourceCreateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResourceCreateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResourceCreateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnResourceCreateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnResourceCreateSubscription, OnResourceCreateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResourceCreateSubscription, OnResourceCreateSubscriptionVariables>(OnResourceCreateDocument, options);
      }
export type OnResourceCreateSubscriptionHookResult = ReturnType<typeof useOnResourceCreateSubscription>;
export type OnResourceCreateSubscriptionResult = Apollo.SubscriptionResult<OnResourceCreateSubscription>;
export const OnResourceSetProjectDocument = gql`
    subscription OnResourceSetProject {
  onResourceSetProject {
    ...ResourceIdentifiable
  }
}
    ${ResourceIdentifiableFragmentDoc}`;

/**
 * __useOnResourceSetProjectSubscription__
 *
 * To run a query within a React component, call `useOnResourceSetProjectSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResourceSetProjectSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResourceSetProjectSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnResourceSetProjectSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnResourceSetProjectSubscription, OnResourceSetProjectSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResourceSetProjectSubscription, OnResourceSetProjectSubscriptionVariables>(OnResourceSetProjectDocument, options);
      }
export type OnResourceSetProjectSubscriptionHookResult = ReturnType<typeof useOnResourceSetProjectSubscription>;
export type OnResourceSetProjectSubscriptionResult = Apollo.SubscriptionResult<OnResourceSetProjectSubscription>;
export const OnResourceDeleteDocument = gql`
    subscription OnResourceDelete {
  onResourceDelete {
    ...ResourceIdentifiable
  }
}
    ${ResourceIdentifiableFragmentDoc}`;

/**
 * __useOnResourceDeleteSubscription__
 *
 * To run a query within a React component, call `useOnResourceDeleteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResourceDeleteSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResourceDeleteSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnResourceDeleteSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnResourceDeleteSubscription, OnResourceDeleteSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResourceDeleteSubscription, OnResourceDeleteSubscriptionVariables>(OnResourceDeleteDocument, options);
      }
export type OnResourceDeleteSubscriptionHookResult = ReturnType<typeof useOnResourceDeleteSubscription>;
export type OnResourceDeleteSubscriptionResult = Apollo.SubscriptionResult<OnResourceDeleteSubscription>;
export const OnResourceDocumentDocument = gql`
    subscription OnResourceDocument {
  onResourceDocument {
    ...ResourceDocumentState
  }
}
    ${ResourceDocumentStateFragmentDoc}`;

/**
 * __useOnResourceDocumentSubscription__
 *
 * To run a query within a React component, call `useOnResourceDocumentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResourceDocumentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResourceDocumentSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnResourceDocumentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnResourceDocumentSubscription, OnResourceDocumentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResourceDocumentSubscription, OnResourceDocumentSubscriptionVariables>(OnResourceDocumentDocument, options);
      }
export type OnResourceDocumentSubscriptionHookResult = ReturnType<typeof useOnResourceDocumentSubscription>;
export type OnResourceDocumentSubscriptionResult = Apollo.SubscriptionResult<OnResourceDocumentSubscription>;
export const OnResourceMetadataDocument = gql`
    subscription OnResourceMetadata {
  onResourceMetadata {
    ...ResourceMetadataState
  }
}
    ${ResourceMetadataStateFragmentDoc}`;

/**
 * __useOnResourceMetadataSubscription__
 *
 * To run a query within a React component, call `useOnResourceMetadataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResourceMetadataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResourceMetadataSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnResourceMetadataSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnResourceMetadataSubscription, OnResourceMetadataSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResourceMetadataSubscription, OnResourceMetadataSubscriptionVariables>(OnResourceMetadataDocument, options);
      }
export type OnResourceMetadataSubscriptionHookResult = ReturnType<typeof useOnResourceMetadataSubscription>;
export type OnResourceMetadataSubscriptionResult = Apollo.SubscriptionResult<OnResourceMetadataSubscription>;
export const OnResourceFiguresDocument = gql`
    subscription OnResourceFigures {
  onResourceFigures {
    ...ResourceFiguresState
  }
}
    ${ResourceFiguresStateFragmentDoc}`;

/**
 * __useOnResourceFiguresSubscription__
 *
 * To run a query within a React component, call `useOnResourceFiguresSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResourceFiguresSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResourceFiguresSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnResourceFiguresSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnResourceFiguresSubscription, OnResourceFiguresSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResourceFiguresSubscription, OnResourceFiguresSubscriptionVariables>(OnResourceFiguresDocument, options);
      }
export type OnResourceFiguresSubscriptionHookResult = ReturnType<typeof useOnResourceFiguresSubscription>;
export type OnResourceFiguresSubscriptionResult = Apollo.SubscriptionResult<OnResourceFiguresSubscription>;
export const OnResourceSectionsDocument = gql`
    subscription OnResourceSections {
  onResourceSections {
    ...ResourceSectionsState
  }
}
    ${ResourceSectionsStateFragmentDoc}`;

/**
 * __useOnResourceSectionsSubscription__
 *
 * To run a query within a React component, call `useOnResourceSectionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResourceSectionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResourceSectionsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnResourceSectionsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnResourceSectionsSubscription, OnResourceSectionsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResourceSectionsSubscription, OnResourceSectionsSubscriptionVariables>(OnResourceSectionsDocument, options);
      }
export type OnResourceSectionsSubscriptionHookResult = ReturnType<typeof useOnResourceSectionsSubscription>;
export type OnResourceSectionsSubscriptionResult = Apollo.SubscriptionResult<OnResourceSectionsSubscription>;
export const OnResourceNlpDocument = gql`
    subscription OnResourceNlp {
  onResourceNlp {
    ...ResourceNlpState
  }
}
    ${ResourceNlpStateFragmentDoc}`;

/**
 * __useOnResourceNlpSubscription__
 *
 * To run a query within a React component, call `useOnResourceNlpSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResourceNlpSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResourceNlpSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnResourceNlpSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnResourceNlpSubscription, OnResourceNlpSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResourceNlpSubscription, OnResourceNlpSubscriptionVariables>(OnResourceNlpDocument, options);
      }
export type OnResourceNlpSubscriptionHookResult = ReturnType<typeof useOnResourceNlpSubscription>;
export type OnResourceNlpSubscriptionResult = Apollo.SubscriptionResult<OnResourceNlpSubscription>;
export const OnResourceRenameDocument = gql`
    subscription OnResourceRename {
  onResourceRename {
    ...ResourceName
  }
}
    ${ResourceNameFragmentDoc}`;

/**
 * __useOnResourceRenameSubscription__
 *
 * To run a query within a React component, call `useOnResourceRenameSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResourceRenameSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResourceRenameSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnResourceRenameSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnResourceRenameSubscription, OnResourceRenameSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResourceRenameSubscription, OnResourceRenameSubscriptionVariables>(OnResourceRenameDocument, options);
      }
export type OnResourceRenameSubscriptionHookResult = ReturnType<typeof useOnResourceRenameSubscription>;
export type OnResourceRenameSubscriptionResult = Apollo.SubscriptionResult<OnResourceRenameSubscription>;
export const OnSummaryRequestStatusChangeDocument = gql`
    subscription OnSummaryRequestStatusChange {
  onSummaryRequestStatusChange {
    ...SummaryRequestComplete
  }
}
    ${SummaryRequestCompleteFragmentDoc}`;

/**
 * __useOnSummaryRequestStatusChangeSubscription__
 *
 * To run a query within a React component, call `useOnSummaryRequestStatusChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSummaryRequestStatusChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSummaryRequestStatusChangeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnSummaryRequestStatusChangeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnSummaryRequestStatusChangeSubscription, OnSummaryRequestStatusChangeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnSummaryRequestStatusChangeSubscription, OnSummaryRequestStatusChangeSubscriptionVariables>(OnSummaryRequestStatusChangeDocument, options);
      }
export type OnSummaryRequestStatusChangeSubscriptionHookResult = ReturnType<typeof useOnSummaryRequestStatusChangeSubscription>;
export type OnSummaryRequestStatusChangeSubscriptionResult = Apollo.SubscriptionResult<OnSummaryRequestStatusChangeSubscription>;
export const OnCreditsChangeDocument = gql`
    subscription OnCreditsChange {
  onCreditsChange {
    ...UserCredits
  }
}
    ${UserCreditsFragmentDoc}`;

/**
 * __useOnCreditsChangeSubscription__
 *
 * To run a query within a React component, call `useOnCreditsChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreditsChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreditsChangeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreditsChangeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreditsChangeSubscription, OnCreditsChangeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreditsChangeSubscription, OnCreditsChangeSubscriptionVariables>(OnCreditsChangeDocument, options);
      }
export type OnCreditsChangeSubscriptionHookResult = ReturnType<typeof useOnCreditsChangeSubscription>;
export type OnCreditsChangeSubscriptionResult = Apollo.SubscriptionResult<OnCreditsChangeSubscription>;
export const OnAiChatCreateDocument = gql`
    subscription OnAiChatCreate {
  onAiChatCreate {
    ...AiChatMeta
  }
}
    ${AiChatMetaFragmentDoc}`;

/**
 * __useOnAiChatCreateSubscription__
 *
 * To run a query within a React component, call `useOnAiChatCreateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAiChatCreateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAiChatCreateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAiChatCreateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnAiChatCreateSubscription, OnAiChatCreateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAiChatCreateSubscription, OnAiChatCreateSubscriptionVariables>(OnAiChatCreateDocument, options);
      }
export type OnAiChatCreateSubscriptionHookResult = ReturnType<typeof useOnAiChatCreateSubscription>;
export type OnAiChatCreateSubscriptionResult = Apollo.SubscriptionResult<OnAiChatCreateSubscription>;
export const OnAiChatRenameDocument = gql`
    subscription OnAiChatRename {
  onAiChatRename {
    ...AiChatMeta
  }
}
    ${AiChatMetaFragmentDoc}`;

/**
 * __useOnAiChatRenameSubscription__
 *
 * To run a query within a React component, call `useOnAiChatRenameSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAiChatRenameSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAiChatRenameSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAiChatRenameSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnAiChatRenameSubscription, OnAiChatRenameSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAiChatRenameSubscription, OnAiChatRenameSubscriptionVariables>(OnAiChatRenameDocument, options);
      }
export type OnAiChatRenameSubscriptionHookResult = ReturnType<typeof useOnAiChatRenameSubscription>;
export type OnAiChatRenameSubscriptionResult = Apollo.SubscriptionResult<OnAiChatRenameSubscription>;
export const OnAiChatDeleteDocument = gql`
    subscription OnAiChatDelete {
  onAiChatDelete {
    ...AiChatIdentifiable
  }
}
    ${AiChatIdentifiableFragmentDoc}`;

/**
 * __useOnAiChatDeleteSubscription__
 *
 * To run a query within a React component, call `useOnAiChatDeleteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAiChatDeleteSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAiChatDeleteSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAiChatDeleteSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnAiChatDeleteSubscription, OnAiChatDeleteSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAiChatDeleteSubscription, OnAiChatDeleteSubscriptionVariables>(OnAiChatDeleteDocument, options);
      }
export type OnAiChatDeleteSubscriptionHookResult = ReturnType<typeof useOnAiChatDeleteSubscription>;
export type OnAiChatDeleteSubscriptionResult = Apollo.SubscriptionResult<OnAiChatDeleteSubscription>;
export const OnAiChatMessageCreateDocument = gql`
    subscription OnAiChatMessageCreate {
  onAiChatMessageCreate {
    ...AiChatMessageComplete
  }
}
    ${AiChatMessageCompleteFragmentDoc}`;

/**
 * __useOnAiChatMessageCreateSubscription__
 *
 * To run a query within a React component, call `useOnAiChatMessageCreateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAiChatMessageCreateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAiChatMessageCreateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAiChatMessageCreateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnAiChatMessageCreateSubscription, OnAiChatMessageCreateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAiChatMessageCreateSubscription, OnAiChatMessageCreateSubscriptionVariables>(OnAiChatMessageCreateDocument, options);
      }
export type OnAiChatMessageCreateSubscriptionHookResult = ReturnType<typeof useOnAiChatMessageCreateSubscription>;
export type OnAiChatMessageCreateSubscriptionResult = Apollo.SubscriptionResult<OnAiChatMessageCreateSubscription>;
export const OnAiChatMessageDeleteDocument = gql`
    subscription OnAiChatMessageDelete {
  onAiChatMessageDelete {
    ...AiChatMessageIdentifiable
  }
}
    ${AiChatMessageIdentifiableFragmentDoc}`;

/**
 * __useOnAiChatMessageDeleteSubscription__
 *
 * To run a query within a React component, call `useOnAiChatMessageDeleteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAiChatMessageDeleteSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAiChatMessageDeleteSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAiChatMessageDeleteSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnAiChatMessageDeleteSubscription, OnAiChatMessageDeleteSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAiChatMessageDeleteSubscription, OnAiChatMessageDeleteSubscriptionVariables>(OnAiChatMessageDeleteDocument, options);
      }
export type OnAiChatMessageDeleteSubscriptionHookResult = ReturnType<typeof useOnAiChatMessageDeleteSubscription>;
export type OnAiChatMessageDeleteSubscriptionResult = Apollo.SubscriptionResult<OnAiChatMessageDeleteSubscription>;
export const OnAiChatMessageRequestStatusChangeDocument = gql`
    subscription OnAiChatMessageRequestStatusChange {
  onAiChatMessageRequestStatusChange {
    ...AiChatMessageRequestComplete
  }
}
    ${AiChatMessageRequestCompleteFragmentDoc}`;

/**
 * __useOnAiChatMessageRequestStatusChangeSubscription__
 *
 * To run a query within a React component, call `useOnAiChatMessageRequestStatusChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAiChatMessageRequestStatusChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAiChatMessageRequestStatusChangeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAiChatMessageRequestStatusChangeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnAiChatMessageRequestStatusChangeSubscription, OnAiChatMessageRequestStatusChangeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAiChatMessageRequestStatusChangeSubscription, OnAiChatMessageRequestStatusChangeSubscriptionVariables>(OnAiChatMessageRequestStatusChangeDocument, options);
      }
export type OnAiChatMessageRequestStatusChangeSubscriptionHookResult = ReturnType<typeof useOnAiChatMessageRequestStatusChangeSubscription>;
export type OnAiChatMessageRequestStatusChangeSubscriptionResult = Apollo.SubscriptionResult<OnAiChatMessageRequestStatusChangeSubscription>;