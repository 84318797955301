import { forwardRef, Fragment, useMemo } from 'react'
import {
  PolymorphicComponentProps,
  PolymorphicRef,
  SharedTextProps,
  Text,
} from '@mantine/core'
import { Expand } from '@/utils/types'

type SharedErrorMessageProps = Omit<SharedTextProps, 'children'> & {
  supportEmail?: string
  error: Error | string
}

type PolymorphicComponentPropsWithoutChildren<C, Props = {}> = Expand<
  Omit<PolymorphicComponentProps<C, Props>, 'children'>
>

export type ErrorMessageProps<C> = PolymorphicComponentPropsWithoutChildren<
  C,
  SharedErrorMessageProps
>

type ErrorMessageComponent = (<C = 'div'>(
  props: ErrorMessageProps<C>,
) => React.ReactElement) & {
  displayName?: string
}

type ErrorMessageSlice =
  | { type: 'text'; value: string }
  | { type: 'link'; value: string; href: string }

const SUPPORT_EMAIL = 'support@genei.io'

export const ErrorMessage: ErrorMessageComponent = forwardRef(
  function ErrorMessage(
    props: ErrorMessageProps<'div'>,
    ref: PolymorphicRef<'div'>,
  ) {
    const {
      component,
      color = 'red',
      supportEmail = SUPPORT_EMAIL,
      error,
      ...delegated
    } = props

    const errorMessage = typeof error === 'string' ? error : error.message

    const slicedMessage = useMemo<ErrorMessageSlice[]>(() => {
      const indexOfSupportEmail = errorMessage.indexOf(supportEmail)
      return indexOfSupportEmail !== -1
        ? [
            {
              type: 'text',
              value: errorMessage.slice(0, indexOfSupportEmail),
            },
            {
              type: 'link',
              value: supportEmail,
              href: `mailto:${supportEmail}`,
            },
            {
              type: 'text',
              value: errorMessage.slice(
                indexOfSupportEmail + supportEmail.length,
              ),
            },
          ]
        : [{ type: 'text', value: errorMessage }]
    }, [errorMessage, supportEmail])

    return (
      <Text
        ref={ref}
        component={component || 'div'}
        color={color}
        {...delegated}
      >
        {slicedMessage.map((slice, index) =>
          slice.type === 'text' ? (
            <Fragment key={index}>{slice.value}</Fragment>
          ) : (
            <Text
              key={index}
              component="a"
              variant="link"
              href={slice.href}
              inline
              inherit
            >
              {slice.value}
            </Text>
          ),
        )}
      </Text>
    )
  },
) as any

ErrorMessage.displayName = '@genei/ErrorMessage'
