import { useState } from 'react'
import {
  Button,
  Divider,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { PaymentMethod, Stripe } from '@stripe/stripe-js'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Currency, SubscriptionPendingIntent, useMaybeUser } from '@/api'
import { typographicScale } from '@/styles/typography'
import { Elements } from './Elements'
import { PaymentRequestButton } from './PaymentRequestButton'
import { CardInput } from './CardInput'

export type PaymentMethodHandler = (
  paymentMethod: PaymentMethod,
  confirmPendingIntent: (
    pendingIntent: SubscriptionPendingIntent,
  ) => Promise<void>,
) => Promise<void>

export type PaymentFormProps = {
  paymentLabel: string
  paymentAmount: number
  currency: Currency
  buttonLabel: string
  loading?: boolean
  error?: Error | undefined
  onPaymentMethod: PaymentMethodHandler
}

export function PaymentForm(props: PaymentFormProps) {
  return (
    <Elements>
      <InternalPaymentForm {...props} />
    </Elements>
  )
}

function InternalPaymentForm(props: PaymentFormProps) {
  const {
    paymentLabel,
    paymentAmount,
    currency,
    buttonLabel,
    onPaymentMethod,
  } = props
  const theme = useMantineTheme()
  const [paymentRequestButtonVisible, setPaymentRequestButtonVisible] =
    useState(false)
  const [_loading, setLoading] = useState(false)
  const [_error, setError] = useState<Error | undefined>(undefined)
  const [cardErrorMessage, setCardErrorMessage] = useState<string | undefined>(
    undefined,
  )
  const loading = props.loading || _loading
  const error = props.error || _error
  const { user } = useMaybeUser()
  const stripe = useStripe()
  const elements = useElements()
  const form = useForm({
    initialValues: {
      name:
        user && user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : '',
      email: user && user.email ? user.email : '',
    },
    validate: {
      name: (value) => (value.trim().length ? null : 'Please enter your name'),
      email: (value) =>
        value.trim().length ? null : 'Please enter your billing email',
    },
  })
  const handlePaymentMethod = async (
    paymentMethod: PaymentMethod,
    stripe: Stripe,
  ) => {
    const confirmPendingIntent = async (
      pendingIntent: SubscriptionPendingIntent,
    ) => {
      const { type, clientSecret } = pendingIntent
      switch (type) {
        case 'payment': {
          const { error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
          })
          if (error) throw error
          break
        }
        case 'setup': {
          const { error } = await stripe.confirmCardSetup(clientSecret, {
            payment_method: paymentMethod.id,
          })
          if (error) throw error
          break
        }
        default: {
          throw new Error('Invalid intent type. Please contact support.')
        }
      }
    }
    await onPaymentMethod(paymentMethod, confirmPendingIntent)
  }
  const handlePaymentRequestPaymentMethod = async (
    paymentMethod: PaymentMethod,
    stripe: Stripe,
  ) => {
    try {
      setLoading(true)
      setError(undefined)
      await handlePaymentMethod(paymentMethod, stripe)
    } catch (error) {
      setError(error as Error)
      // TODO: maybe rethrow here? Payment request button always completes with success now
    } finally {
      setLoading(false)
    }
  }
  const handleSubmit = form.onSubmit(async (data) => {
    try {
      setLoading(true)
      setError(undefined)
      const { name, email } = data
      if (!stripe || !elements) return
      const cardElement = elements.getElement(CardElement)
      if (!cardElement) return
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: { name, email },
      })
      if (error) {
        setError(new Error(error.message))
        return
      }
      await handlePaymentMethod(paymentMethod, stripe)
    } catch (error) {
      setError(error as Error)
    } finally {
      setLoading(false)
    }
  })
  return (
    <Stack spacing="lg">
      <PaymentRequestButton
        label={paymentLabel}
        amount={paymentAmount}
        currency={currency}
        onPaymentMethod={handlePaymentRequestPaymentMethod}
        onReady={() => setPaymentRequestButtonVisible(true)}
      />
      {paymentRequestButtonVisible && (
        <Divider label="Or enter your card details" labelPosition="center" />
      )}
      <form onSubmit={handleSubmit}>
        <Stack spacing="xs">
          <TextInput
            data-autofocus
            autoFocus
            required
            id="billing-name"
            label="Name"
            placeholder="Enter your name..."
            {...form.getInputProps('name')}
          />
          <TextInput
            required
            id="billing-email"
            label="Email"
            placeholder="Enter your billing email..."
            {...form.getInputProps('email')}
          />
          <CardInput
            required
            id="billing-card"
            label="Card details"
            error={cardErrorMessage}
            onChange={(event) => {
              setCardErrorMessage(event.error ? event.error.message : undefined)
            }}
          />
          <Text
            color="dimmed"
            my="md"
            sx={{ fontSize: typographicScale.getPrev(theme.fontSizes.xs) }}
          >
            By providing your card information, you allow Genie Technology
            Limited to charge your card for future payments in accordance with
            their terms.
          </Text>
          <Button type="submit" color="blue" loading={loading}>
            {buttonLabel}
          </Button>
          {error && (
            <Text color="red" size="sm" mt="md">
              {error.message}
            </Text>
          )}
        </Stack>
      </form>
    </Stack>
  )
}
