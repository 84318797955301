import { useEffect } from 'react'
import { Button, createStyles, Modal, Text } from '@mantine/core'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {
  SubscriptionStatus,
  UserSubscriptionWithScheduledUpdateFragment,
} from '@/api'
import { Callout } from '@/common'
import { useCancelSubscription } from './useCancelSubscription'
import { tierToDisplay } from './helpers'

dayjs.extend(relativeTime)

export type CancelSubscriptionModalProps = {
  opened: boolean
  subscription: UserSubscriptionWithScheduledUpdateFragment
  onCancel: () => void
  onClose: () => void
}

export function CancelSubscriptionModal(props: CancelSubscriptionModalProps) {
  const { opened, subscription, onCancel, onClose } = props
  useEffect(() => {
    if (subscription.cancelAtPeriodEnd) onClose()
  }, [subscription, onClose])
  const isTrialing = subscription.status === SubscriptionStatus.Trialing
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={isTrialing ? 'Cancel Trial' : 'Cancel Subscription'}
    >
      <ModalContent
        subscription={subscription}
        onCancel={onCancel}
        onClose={onClose}
      />
    </Modal>
  )
}

const useStyles = createStyles((theme) => ({
  root: {
    marginTop: 2 * theme.spacing.md,
    '& > * + *': {
      marginTop: theme.spacing.xl,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing.lg,
  },
}))

type ModalContentProps = {
  subscription: UserSubscriptionWithScheduledUpdateFragment
  onCancel: () => void
  onClose: () => void
}

function ModalContent(props: ModalContentProps) {
  const { subscription, onCancel, onClose } = props
  const { classes, theme } = useStyles()
  const [cancelSubscription, { loading, error }] = useCancelSubscription()
  const isTrialing = subscription.status === SubscriptionStatus.Trialing
  const displayTier = tierToDisplay[subscription.plan.tier]
  const handleCancel = async () => {
    try {
      await cancelSubscription()
      onCancel()
      onClose()
    } catch {}
  }
  return (
    <div className={classes.root}>
      <Text size="sm">
        {`Are you sure you want to cancel and lose all the benefits of Genei ${displayTier}?`}
      </Text>
      <Callout size="sm" color="red">
        {`Your ${isTrialing ? 'trial' : 'subscription'} will automatically end`}
        {` ${dayjs(
          subscription.currentPeriodEnd,
        ).fromNow()} after which you will be moved to the free tier.`}
        {' Unless you resubscribe, no further charges will be made.'}
      </Callout>
      <div className={classes.actions}>
        <Button color="red" loading={loading} onClick={handleCancel}>
          Cancel subscription
        </Button>
        <Button
          data-autofocus
          onClick={onClose}
          variant="subtle"
          color={theme.colorScheme === 'dark' ? 'dark' : 'gray'}
        >
          Nevermind
        </Button>
      </div>
      {error && (
        <Text color="red" size="sm">
          {error.message}
        </Text>
      )}
    </div>
  )
}
