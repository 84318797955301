/**
 * Styles derived from https://github.com/mantinedev/mantine/blob/4.2.12/src/mantine-core/src/components/Input/Input.styles.ts
 */
import { useState } from 'react'
import {
  createStyles,
  Input,
  InputVariant,
  InputWrapper,
  MantineNumberSize,
  MantineSize,
  useMantineTheme,
} from '@mantine/core'
import { CardElement, CardElementProps } from '@stripe/react-stripe-js'

const useStyles = createStyles((theme) => ({
  input: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& > *': {
      width: '100%',
    },
  },
  defaultVariantFocused: {
    outline: 'none',
    borderColor:
      theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 8 : 5],
  },
  filledVariantFocused: {
    outline: 'none',
    borderColor: `${
      theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 8 : 5]
    } !important`,
  },
  unstyledVariantFocused: {
    outline: 'none',
    borderColor: 'transparent',
  },
}))

type CardInputProps = CardElementProps & {
  required?: boolean
  label?: string
  error?: React.ReactNode
  radius?: MantineNumberSize
  size?: MantineSize
  variant?: InputVariant
}

export function CardInput(props: CardInputProps) {
  const {
    required,
    label,
    error,
    radius,
    size = 'sm',
    variant,
    onFocus,
    onBlur,
    ...elementProps
  } = props
  const [isFocused, setIsFocused] = useState(false)
  const { classes } = useStyles()
  const handleFocus = () => {
    setIsFocused(true)
    onFocus?.()
  }
  const handleBlur = () => {
    setIsFocused(false)
    onBlur?.()
  }
  return (
    <InputWrapper required={required} label={label} error={error}>
      <Input
        radius={radius}
        size={size}
        variant={variant}
        invalid={!!error}
        component={WrappedCardElement}
        classNames={{
          input: classes.input,
          defaultVariant: isFocused ? classes.defaultVariantFocused : undefined,
          filledVariant: isFocused ? classes.filledVariantFocused : undefined,
          unstyledVariant: isFocused
            ? classes.unstyledVariantFocused
            : undefined,
        }}
        cardFontSize={size}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...elementProps}
      />
    </InputWrapper>
  )
}

function WrappedCardElement(
  props: CardElementProps & { cardFontSize: MantineSize },
) {
  const {
    id,
    className,
    cardFontSize,
    options,
    onFocus,
    onBlur,
    onChange,
    onReady,
    onEscape,
  } = props
  const theme = useMantineTheme()
  return (
    <div className={className}>
      <CardElement
        id={id}
        options={{
          ...options,
          style: {
            ...options?.style,
            base: {
              fontFamily: theme.fontFamily,
              fontSize: `${theme.fontSizes[cardFontSize]}px`,
              fontWeight: 400,
              color:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[0]
                  : theme.black,
              '::placeholder': {
                color:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[3]
                    : theme.colors.gray[5],
                ...options?.style?.base?.['::placeholder'],
              },
              ...options?.style?.base,
            },
            invalid: {
              color: theme.colors.red[theme.colorScheme === 'dark' ? 6 : 7],
              iconColor: theme.colors.red[theme.colorScheme === 'dark' ? 6 : 7],
            },
          },
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        onReady={onReady}
        onEscape={onEscape}
      />
    </div>
  )
}
