import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  createContext as createSelectableContext,
  useContextSelector,
} from 'use-context-selector'
import { SerializedEditorState } from 'lexical'
import { PartialRecord } from '@/utils/types'
import { useBeforeUnload } from '@/common'

type NoteSaveStateGetterSetterContextValue = {
  getIsDebouncing: (noteId: string) => boolean
  setIsDebouncing: (noteId: string, isDebouncing: boolean) => void
  getActiveSaveLoop: (noteId: string) => Promise<boolean> | null
  setActiveSaveLoop: (noteId: string, saveLoop: Promise<boolean> | null) => void
  getQueuedState: (noteId: string) => SerializedEditorState | null
  setQueuedState: (
    noteId: string,
    queuedState: SerializedEditorState | null,
  ) => void
  getHasSavingErrored: (noteId: string) => boolean
  setHasSavingErrored: (noteId: string, isSaving: boolean) => void
  getErrorNotificationId: (noteId: string) => string | null
  setErrorNotificationId: (
    noteId: string,
    notificationId: string | null,
  ) => void
  getShowSuccessNotification: (noteId: string) => boolean
  setShowSuccessNotification: (
    noteId: string,
    showSuccessNotification: boolean,
  ) => void
}

type NoteSaveStateContextValue = {
  isSavingByNoteId: PartialRecord<string, boolean>
  hasSavingErroredByNoteId: PartialRecord<string, boolean>
}

const NoteSaveStateGetterSetterContext =
  createContext<NoteSaveStateGetterSetterContextValue | null>(null)
const NoteSaveStateContext =
  createSelectableContext<NoteSaveStateContextValue | null>(null)

type NoteSaveStateProviderProps = React.PropsWithChildren<{}>

// TODO: no need for all the callbacks just put it in one big memo
export function NoteSaveStateProvider(props: NoteSaveStateProviderProps) {
  const { children } = props

  const [isDebouncingByNoteId, setIsDebouncingByNoteId] = useState<
    PartialRecord<string, boolean>
  >({})
  const [activeSaveLoopByNoteId, setActiveSaveLoopByNoteId] = useState<
    PartialRecord<string, Promise<boolean> | null>
  >({})
  const [hasSavingErroredByNoteId, setHasSavingErroredByNoteId] = useState<
    PartialRecord<string, boolean>
  >({})
  const isDebouncingByNoteIdRef = useRef<PartialRecord<string, boolean>>({})
  const activeSaveLoopByNoteIdRef = useRef<
    PartialRecord<string, Promise<boolean> | null>
  >({})
  const queuedStateByNoteIdRef = useRef<
    PartialRecord<string, SerializedEditorState>
  >({})
  const hasSavingErroredByNoteIdRef = useRef<PartialRecord<string, boolean>>({})
  const errorNotificationIdByNoteIdRef = useRef<PartialRecord<string, string>>(
    {},
  )
  const showSuccessNotificationByNoteIdRef = useRef<
    PartialRecord<string, boolean>
  >({})

  const isSavingByNoteId = useMemo(() => {
    const noteIds = new Set([
      ...Object.keys(activeSaveLoopByNoteId),
      ...Object.keys(isDebouncingByNoteId),
    ])
    const isSavingByNoteId: PartialRecord<string, boolean> = {}
    for (const noteId of noteIds) {
      isSavingByNoteId[noteId] =
        Boolean(activeSaveLoopByNoteId[noteId]) || isDebouncingByNoteId[noteId]
    }
    return isSavingByNoteId
  }, [activeSaveLoopByNoteId, isDebouncingByNoteId])

  useBeforeUnload((event) => {
    const isSomeSaveLoopActive = Object.values(
      activeSaveLoopByNoteIdRef.current,
    ).some((loop) => Boolean(loop))
    const isSomeDebouncing = Object.values(
      isDebouncingByNoteIdRef.current,
    ).includes(true)
    const isSomeErrored = Object.values(
      hasSavingErroredByNoteIdRef.current,
    ).includes(true)
    if (isSomeDebouncing || isSomeSaveLoopActive || isSomeErrored) {
      event.preventDefault()
    }
  })

  const getIsDebouncing = useCallback((noteId: string) => {
    return isDebouncingByNoteIdRef.current[noteId] ?? false
  }, [])

  const setIsDebouncing = useCallback(
    (noteId: string, isDebouncing: boolean) => {
      if (isDebouncing) {
        isDebouncingByNoteIdRef.current[noteId] = true
        setIsDebouncingByNoteId((current) => {
          if (current[noteId] === true) return current
          return { ...current, [noteId]: true }
        })
      } else {
        delete isDebouncingByNoteIdRef.current[noteId]
        setIsDebouncingByNoteId((current) => {
          if (!current.hasOwnProperty(noteId)) return current
          const { [noteId]: _, ...next } = current
          return next
        })
      }
    },
    [],
  )

  const getActiveSaveLoop = useCallback((noteId: string) => {
    return activeSaveLoopByNoteIdRef.current[noteId] ?? null
  }, [])

  const setActiveSaveLoop = useCallback(
    (noteId: string, saveLoop: Promise<boolean> | null) => {
      if (saveLoop) {
        activeSaveLoopByNoteIdRef.current[noteId] = saveLoop
        setActiveSaveLoopByNoteId((current) => {
          if (current[noteId] === saveLoop) return current
          return { ...current, [noteId]: saveLoop }
        })
      } else {
        delete activeSaveLoopByNoteIdRef.current[noteId]
        setActiveSaveLoopByNoteId((current) => {
          if (!current.hasOwnProperty(noteId)) return current
          const { [noteId]: _, ...next } = current
          return next
        })
      }
    },
    [],
  )

  const getQueuedState = useCallback((noteId: string) => {
    return queuedStateByNoteIdRef.current[noteId] || null
  }, [])

  const setQueuedState = useCallback(
    (noteId: string, state: SerializedEditorState | null) => {
      if (state !== null) {
        queuedStateByNoteIdRef.current[noteId] = state
      } else {
        delete queuedStateByNoteIdRef.current[noteId]
      }
    },
    [],
  )

  const getHasSavingErrored = useCallback((noteId: string) => {
    return hasSavingErroredByNoteIdRef.current[noteId] ?? false
  }, [])

  const setHasSavingErrored = useCallback(
    (noteId: string, hasErrored: boolean) => {
      if (hasErrored) {
        hasSavingErroredByNoteIdRef.current[noteId] = true
        setHasSavingErroredByNoteId((current) => {
          if (current[noteId] === true) return current
          return { ...current, [noteId]: true }
        })
      } else {
        delete hasSavingErroredByNoteIdRef.current[noteId]
        setHasSavingErroredByNoteId((current) => {
          if (!current.hasOwnProperty(noteId)) return current
          const { [noteId]: _, ...next } = current
          return next
        })
      }
    },
    [],
  )

  const getErrorNotificationId = useCallback((noteId: string) => {
    return errorNotificationIdByNoteIdRef.current[noteId] ?? null
  }, [])

  const setErrorNotificationId = useCallback(
    (noteId: string, notificationId: string | null) => {
      if (notificationId !== null) {
        errorNotificationIdByNoteIdRef.current[noteId] = notificationId
      } else {
        delete errorNotificationIdByNoteIdRef.current[noteId]
      }
    },
    [],
  )

  const getShowSuccessNotification = useCallback((noteId: string) => {
    return showSuccessNotificationByNoteIdRef.current[noteId] ?? false
  }, [])

  const setShowSuccessNotification = useCallback(
    (noteId: string, show: boolean) => {
      if (show === true) {
        showSuccessNotificationByNoteIdRef.current[noteId] = true
      } else {
        delete showSuccessNotificationByNoteIdRef.current[noteId]
      }
    },
    [],
  )

  const getterSetterValue = useMemo<NoteSaveStateGetterSetterContextValue>(
    () => ({
      getIsDebouncing,
      setIsDebouncing,
      getActiveSaveLoop,
      setActiveSaveLoop,
      getQueuedState,
      setQueuedState,
      getHasSavingErrored,
      setHasSavingErrored,
      getErrorNotificationId,
      setErrorNotificationId,
      getShowSuccessNotification,
      setShowSuccessNotification,
    }),
    [
      getIsDebouncing,
      setIsDebouncing,
      getActiveSaveLoop,
      setActiveSaveLoop,
      getQueuedState,
      setQueuedState,
      getHasSavingErrored,
      setHasSavingErrored,
      getErrorNotificationId,
      setErrorNotificationId,
      getShowSuccessNotification,
      setShowSuccessNotification,
    ],
  )

  const value = useMemo<NoteSaveStateContextValue>(
    () => ({
      isSavingByNoteId,
      hasSavingErroredByNoteId,
    }),
    [isSavingByNoteId, hasSavingErroredByNoteId],
  )

  return (
    <NoteSaveStateGetterSetterContext.Provider value={getterSetterValue}>
      <NoteSaveStateContext.Provider value={value}>
        {children}
      </NoteSaveStateContext.Provider>
    </NoteSaveStateGetterSetterContext.Provider>
  )
}

export const useNoteSaveStateGettersAndSetters = () => {
  const context = useContext(NoteSaveStateGetterSetterContext)
  if (context === null) {
    throw new Error(
      `The \`useNoteSaveStateGettersAndSetters\` hook must be used inside the <NoteSaveStateProvider> component's context`,
    )
  }
  return context
}

export const useIsNoteSaving = (noteId: string) => {
  return useContextSelector(
    NoteSaveStateContext,
    useCallback(
      (context) => {
        if (context === null) {
          throw new Error(
            `The \`useIsNoteSaving\` hook must be used inside the <NoteSaveStateProvider> component's context`,
          )
        }
        return !!context.isSavingByNoteId[noteId]
      },
      [noteId],
    ),
  )
}

export const useHasNoteSavingErrored = (noteId: string) => {
  return useContextSelector(
    NoteSaveStateContext,
    useCallback(
      (context) => {
        if (context === null) {
          throw new Error(
            `The \`useHasNoteSavingErrored\` hook must be used inside the <NoteSaveStateProvider> component's context`,
          )
        }
        return !!context.hasSavingErroredByNoteId[noteId]
      },
      [noteId],
    ),
  )
}

export const useSavingNotes = () => {
  return useContextSelector(
    NoteSaveStateContext,
    useCallback((context) => {
      if (context === null) {
        throw new Error(
          `The \`useSavingNotes\` hook must be used inside the <NoteSaveStateProvider> component's context`,
        )
      }
      return Object.keys(context.isSavingByNoteId)
    }, []),
  )
}

export const useSavingErroredNotes = () => {
  return useContextSelector(
    NoteSaveStateContext,
    useCallback((context) => {
      if (context === null) {
        throw new Error(
          `The \`useSavingErroredNotes\` hook must be used inside the <NoteSaveStateProvider> component's context`,
        )
      }
      return Object.keys(context.hasSavingErroredByNoteId)
    }, []),
  )
}
