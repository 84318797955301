import {
  compareHotkey,
  HotKey,
  KeyboardEventLike,
  parseHotkey,
} from 'is-hotkey'
import { IS_MAC } from './web'

const META_KEY = IS_MAC ? '⌘' : 'win'
const CTRL_KEY = 'ctrl'
const ALT_KEY = IS_MAC ? '⌥' : 'alt'
const SHIFT_KEY = '⇧'

const CODES: Record<string, number> = {
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
  control: 17,
  alt: 18,
  pause: 19,
  capslock: 20,
  escape: 27,
  ' ': 32,
  pageup: 33,
  pagedown: 34,
  end: 35,
  home: 36,
  arrowleft: 37,
  arrowup: 38,
  arrowright: 39,
  arrowdown: 40,
  insert: 45,
  delete: 46,
  meta: 91,
  numlock: 144,
  scrolllock: 145,
  ';': 186,
  '=': 187,
  ',': 188,
  '-': 189,
  '.': 190,
  '/': 191,
  '`': 192,
  '[': 219,
  '\\': 220,
  ']': 221,
  "'": 222,
}

for (var f = 1; f < 20; f++) {
  CODES['f' + f.toString()] = 111 + f
}

const KEY_REPRESENTATIONS: Record<string, string> = {
  escape: 'esc',
  ' ': 'space',
  enter: '↵',
  backspace: '⌫',
  arrowleft: '←',
  arrowup: '↑',
  arrowright: '→',
  arrowdown: '↓',
}

const INVERSE_CODES: Record<number, string> = {}
for (const key in CODES) {
  INVERSE_CODES[CODES[key]] = KEY_REPRESENTATIONS[key] || key
}

export default function toKeyArray(hotkey: HotKey): string[] {
  const keys = []
  if (hotkey.ctrlKey) keys.push(CTRL_KEY)
  if (hotkey.metaKey) keys.push(META_KEY)
  if (hotkey.altKey) keys.push(ALT_KEY)
  if (hotkey.shiftKey) keys.push(SHIFT_KEY)
  if (hotkey.which != null) {
    if (hotkey.which in INVERSE_CODES) {
      keys.push(INVERSE_CODES[hotkey.which])
    } else {
      keys.push(String.fromCharCode(hotkey.which))
    }
  }
  return keys
}

const isHotkey = (hotkeyStr: string) => {
  const hotkey = parseHotkey(hotkeyStr)
  const keys = toKeyArray(hotkey)
  const match = (event: KeyboardEventLike) => compareHotkey(hotkey, event)
  match.keys = keys
  return match
}

export const isEsc = isHotkey('esc')
export const isReturn = isHotkey('return')
export const isTab = isHotkey('tab')
export const isUp = isHotkey('up')
export const isDown = isHotkey('down')
export const isLeft = isHotkey('left')
export const isRight = isHotkey('right')
export const isCtrlMetaLeft = isHotkey('ctrl+meta+left')
export const isCtrlMetaRight = isHotkey('ctrl+meta+right')
export const isCtrlAltLeft = isHotkey('ctrl+alt+left')
export const isCtrlAltRight = isHotkey('ctrl+alt+right')
export const isMod = isHotkey('mod')
export const isModC = isHotkey('mod+c')
export const isModK = isHotkey('mod+k')
export const isModD = isHotkey('mod+d')
export const isModH = isHotkey('mod+h')
export const isModL = isHotkey('mod+l')
export const isModS = isHotkey('mod+s')
export const isModF = isHotkey('mod+f')
export const isModBackslash = isHotkey('mod+\\')
export const isModShiftC = isHotkey('mod+shift+c')
export const isModShiftD = isHotkey('mod+shift+d')
export const isModReturn = isHotkey('mod+return')
export const isShiftReturn = isHotkey('shift+return')
