import { useSubscriptionWithPendingIntentQuery } from '@/api'

export const useSubscriptionWithPendingIntent = () => {
  const { data, ...rest } = useSubscriptionWithPendingIntentQuery({
    // always request from network so we get the latest
    // state of the subscription and pending intent
    fetchPolicy: 'network-only',
  })
  const subscription = data?.me.subscription
  return { subscription, ...rest }
}
