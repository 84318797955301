
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AiChatMessage": [
      "AiChatBotMessage",
      "AiChatUserMessage"
    ],
    "DocumentCoord": [
      "PdfCoord",
      "WebCoord"
    ],
    "MetadataSearchItem": [
      "Folder",
      "Note",
      "Resource"
    ]
  }
};
      export default result;
    