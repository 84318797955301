import { Database } from './database'
import { NlpEntry, NlpData, NlpMeta } from './types'

export class NlpClient {
  private readonly db: Database

  constructor(db: Database) {
    this.db = db
  }

  public async get(id: string): Promise<NlpEntry | null> {
    try {
      const nlpEntry = await this.db.transaction(
        'r',
        this.db.nlpMeta,
        this.db.nlpData,
        async () => {
          const [nlpMeta, nlpData] = await Promise.all([
            this.db.nlpMeta.get(id),
            this.db.nlpData.get(id),
          ])
          if (!nlpMeta || !nlpData) return null
          const nlp = { ...nlpMeta, ...nlpData }
          return nlp
        },
      )
      return nlpEntry
    } catch {
      throw new Error('Failed to get nlp')
    }
  }

  public async getMeta(id: string): Promise<NlpMeta | null> {
    try {
      const nlpMeta = await this.db.nlpMeta.get(id)
      return nlpMeta || null
    } catch {
      throw new Error('Failed to get nlp')
    }
  }

  public async list(
    offset: number = 0,
    limit: number = 20,
  ): Promise<[NlpMeta[], number | undefined]> {
    const nlptMetas = await this.db.nlpMeta
      .orderBy('id')
      .offset(offset)
      .limit(limit)
      .toArray()
    const newOffset = offset + nlptMetas.length
    const noMoreItems = nlptMetas.length < limit
    return [nlptMetas, noMoreItems ? undefined : newOffset]
  }

  public async create(input: NlpEntry): Promise<NlpEntry> {
    const { id, data, version } = input
    const nlpMeta: NlpMeta = { id, version }
    const nlpData: NlpData = { id, data }
    const nlpEntry = { ...nlpMeta, ...nlpData }
    try {
      await this.db.transaction(
        'rw',
        this.db.nlpMeta,
        this.db.nlpData,
        async () => {
          await Promise.all([
            this.db.nlpMeta.add(nlpMeta),
            this.db.nlpData.add(nlpData),
          ])
        },
      )
      return nlpEntry
    } catch {
      throw new Error('Failed to create nlp')
    }
  }

  public async delete(id: string): Promise<void> {
    try {
      await this.db.transaction(
        'rw',
        this.db.nlpMeta,
        this.db.nlpData,
        async () => {
          await Promise.all([
            this.db.nlpMeta.delete(id),
            this.db.nlpData.delete(id),
          ])
        },
      )
    } catch {
      throw new Error('Failed to delete nlp')
    }
  }
}
