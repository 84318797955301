import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Modal,
  createStyles,
  Stack,
  Text,
  Chips,
  Chip,
  Button,
  TextInput,
} from '@mantine/core'
import { useUser, useSetHasOnboardedMutation } from '@/api'
import { track } from '@/analytics'
import { shuffleArray } from '@/utils/common'
import { showNotification } from '@mantine/notifications'

const values = [
  'Facebook',
  'Instagram',
  'TikTok',
  'Youtube',
  'LinkedIn',
  'Twitter',
  'Spotify',
  'Google',
  'Influencer',
  'Recommendation/ friend',
  'Product hunt',
  'Reddit',
  'Quora',
  'Snapchat',
]
shuffleArray(values)

const useStyles = createStyles({
  modal: {
    width: 700,
  },
  body: {
    height: 500,
  },
})

type OnboardingFormProps = {
  opened: boolean
}

export const OnboardingForm = (props: OnboardingFormProps) => {
  const { opened } = props
  const { classes } = useStyles()

  return (
    <Modal
      padding="xl"
      opened={opened}
      classNames={{ modal: classes.modal, body: classes.body }}
      withCloseButton={false}
      onClose={() => {}}
    >
      <ModalContent />
    </Modal>
  )
}

const useContentStyles = createStyles({
  root: {
    height: '100%',
  },
  input: {
    width: '100%',
  },
})

const ModalContent = () => {
  const { classes } = useContentStyles()
  const user = useUser()
  const navigate = useNavigate()
  const [setHasOnboarding] = useSetHasOnboardedMutation()
  const [input, setInput] = useState('')
  const [selected, setSelected] = useState<string>()

  const handleSubmit = () => {
    if (!selected) {
      showNotification({
        message: 'Please select an option before continuing.',
        color: 'red',
      })
      return
    }
    track({
      eventName: 'User Onboarded',
      properties: {
        source: selected,
        id: user.id,
        email: user.email,
        tier: user.subscription?.plan.tier,
        period: user.subscription?.plan.period,
        // freeTierType: user.freeTierType,
        academic: user.academic,
        hasLicenseKey: !!user.licenseKey,
        freeTierType: user.freeTierType,
      },
    })
    setHasOnboarding()
    navigate('/summarise')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setInput(event.target.value)

  const filteredResults = useMemo(() => {
    const results = values.filter((value) =>
      value.toLowerCase().startsWith(input.toLowerCase()),
    )
    if (input.length) results.push(`Other answer: "${input}"`)
    return results
  }, [input])

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    setSelected(target.value)
  }

  return (
    <Stack p="xl" spacing="xl" align="center" className={classes.root}>
      <Text align="center" weight={500}>
        Thanks for signing up to Genei! We'll now show you how to start using
        the platform ✌️
      </Text>
      <Text align="center" size="sm" weight={500}>
        But before we do, please let us know how you heard about Genei...
      </Text>
      <TextInput
        px="xl"
        pt="md"
        placeholder="Begin typing your answer..."
        value={input}
        onChange={handleChange}
        className={classes.input}
      />
      <Chips
        position="center"
        px="xl"
        pb="md"
        value={selected}
        onClick={handleClick}
      >
        {filteredResults.map((value) => (
          <Chip key={value} value={value}>
            {value}
          </Chip>
        ))}
      </Chips>
      <Button size="md" onClick={handleSubmit}>
        Submit
      </Button>
    </Stack>
  )
}
