import {
  Currency,
  PromotionDuration,
  SubscriptionPlanCompleteFragment,
  SubscriptionPlanPeriod,
  SubscriptionPlanTier,
  SubscriptionPromotionCompleteFragment,
  SubscriptionStatus,
  UserSubscriptionCompleteFragment,
} from '@/api'
import { WithoutTypename } from '@/utils/types'

export type Subscription<T extends SubscriptionStatus = SubscriptionStatus> =
  Omit<UserSubscriptionCompleteFragment, 'status'> & { status: T }

const TrialingStatuses = [SubscriptionStatus.Trialing] as const
export type TrialingStatus = typeof TrialingStatuses[number]

export function isSubscriptionTrialing(
  subscription: Subscription,
): subscription is Subscription<TrialingStatus> {
  const { status } = subscription
  return TrialingStatuses.includes(status as TrialingStatus)
}

const ActiveStatuses = [
  SubscriptionStatus.Active,
  SubscriptionStatus.Trialing,
  SubscriptionStatus.PastDue,
] as const
export type ActiveStatus = typeof ActiveStatuses[number]

export function isSubscriptionActive(
  subscription: Subscription,
): subscription is Subscription<ActiveStatus> {
  const { status } = subscription
  return ActiveStatuses.includes(status as ActiveStatus)
}

const PastDueStatuses = [SubscriptionStatus.PastDue] as const
export type PastDueStatus = typeof PastDueStatuses[number]

export function isSubscriptionPastDue(
  subscription: Subscription,
): subscription is Subscription<PastDueStatus> {
  const { status } = subscription
  return PastDueStatuses.includes(status as PastDueStatus)
}

const ActionRequiredStatuses = [
  SubscriptionStatus.Incomplete,
  SubscriptionStatus.PastDue,
  SubscriptionStatus.Trialing,
] as const
export type ActionRequiredStatus = typeof ActionRequiredStatuses[number]

export function isSubscriptionActionRequired(
  subscription: Subscription,
): subscription is Subscription<ActionRequiredStatus> {
  const { status } = subscription
  if (
    subscription.status === SubscriptionStatus.Trialing &&
    subscription.setupCompleted
  )
    return false
  return ActionRequiredStatuses.includes(status as ActionRequiredStatus)
}

const SubscriptionPlanTierHierarchy: Record<
  SubscriptionPlanTier,
  Set<SubscriptionPlanTier>
> = {
  [SubscriptionPlanTier.Basic]: new Set([SubscriptionPlanTier.Basic]),
  [SubscriptionPlanTier.Pro]: new Set([
    SubscriptionPlanTier.Basic,
    SubscriptionPlanTier.Pro,
  ]),
}

export function encompassesSubscriptionPlanTier(
  actualTier: SubscriptionPlanTier,
  targetTier: SubscriptionPlanTier,
): boolean {
  return SubscriptionPlanTierHierarchy[actualTier].has(targetTier)
}

const SubscriptionPlanTierMap: Record<SubscriptionPlanTier, true> = {
  [SubscriptionPlanTier.Basic]: true,
  [SubscriptionPlanTier.Pro]: true,
}

export function isSubscriptionPlanTier(
  tier: unknown,
): tier is SubscriptionPlanTier {
  return (
    SubscriptionPlanTierMap.hasOwnProperty(tier as SubscriptionPlanTier) &&
    SubscriptionPlanTierMap[tier as SubscriptionPlanTier] === true
  )
}

const InverseSubscriptionPlanTierHierarchy: Record<
  SubscriptionPlanTier,
  Set<SubscriptionPlanTier>
> = {
  [SubscriptionPlanTier.Basic]: new Set([
    SubscriptionPlanTier.Basic,
    SubscriptionPlanTier.Pro,
  ]),
  [SubscriptionPlanTier.Pro]: new Set([SubscriptionPlanTier.Pro]),
}

export function getEncompassingSubscriptionPlanTiers(
  requiredTier: SubscriptionPlanTier,
): Set<SubscriptionPlanTier> {
  return InverseSubscriptionPlanTierHierarchy[requiredTier]
}

type PeriodDisplayVariants = {
  normalSuffixedCap: string
  normalSuffixed: string
  normal: string
  slash: string
  slashCap: string
}

export const periodToDiplay: Record<
  SubscriptionPlanPeriod,
  PeriodDisplayVariants
> = {
  [SubscriptionPlanPeriod.Month]: {
    normalSuffixedCap: 'Monthly',
    normalSuffixed: 'monthly',
    normal: 'month',
    slash: '/ month',
    slashCap: '/ Month',
  },
  [SubscriptionPlanPeriod.Year]: {
    normalSuffixedCap: 'Yearly',
    normalSuffixed: 'yearly',
    normal: 'year',
    slash: '/ year',
    slashCap: '/ Year',
  },
}

export const periodToDivisor: Record<SubscriptionPlanPeriod, number> = {
  [SubscriptionPlanPeriod.Month]: 1,
  [SubscriptionPlanPeriod.Year]: 12,
}

export const currencyToDisplay: Record<Currency, string> = {
  [Currency.Cad]: 'CA$',
  [Currency.Usd]: 'US$',
  [Currency.Gbp]: 'GB£',
  [Currency.Eur]: '€',
}

export const currencyToShortDisplay: Record<Currency, string> = {
  [Currency.Cad]: '$',
  [Currency.Usd]: '$',
  [Currency.Gbp]: '£',
  [Currency.Eur]: '€',
}

export const tierToDisplay: Record<SubscriptionPlanTier, string> = {
  [SubscriptionPlanTier.Basic]: 'Basic',
  [SubscriptionPlanTier.Pro]: 'Pro',
}

export function toDemicalString(amount: number) {
  const decimalAmount = Math.round(amount) / 100
  return decimalAmount.toFixed(2)
}

export function getPromotionCodeDisplay(
  promotionCode: WithoutTypename<SubscriptionPromotionCompleteFragment>,
) {
  let prefix = ''
  switch (promotionCode.duration) {
    case PromotionDuration.Once:
      prefix = '1x '
      break
    case PromotionDuration.Repeating:
      prefix = `${promotionCode.durationInMonths!} months `
      break
    case PromotionDuration.Forever:
      prefix = 'Lifetime '
      break
    default:
      prefix = ''
      break
  }
  if (promotionCode.amountOff) {
    const displayCurrency = currencyToShortDisplay[promotionCode.currency!]
    const decimalAmount = toDemicalString(promotionCode.amountOff)
    return `${prefix}${displayCurrency}${decimalAmount} off`
  }
  return `${prefix}${promotionCode.percentOff!}% off`
}

export function getPromotionCodeAmountOff(
  plan: SubscriptionPlanCompleteFragment,
  promotionCode: WithoutTypename<SubscriptionPromotionCompleteFragment>,
) {
  if (promotionCode.amountOff) return promotionCode.amountOff
  return Math.floor((plan.amount * promotionCode.percentOff!) / 100)
}

export function getPromotionCodeAmountOffDisplay(
  plan: SubscriptionPlanCompleteFragment,
  promotionCode: WithoutTypename<SubscriptionPromotionCompleteFragment>,
) {
  const displayCurrency = currencyToShortDisplay[plan.currency]
  const amountOff = getPromotionCodeAmountOff(plan, promotionCode)
  const amountOffDecmial = toDemicalString(amountOff)
  return `-${displayCurrency}${amountOffDecmial}`
}

export function getMonthlyPrice(plan: SubscriptionPlanCompleteFragment) {
  const monthlyAmount = Math.round(plan.amount / periodToDivisor[plan.period])
  return toDemicalString(monthlyAmount)
}

export function shouldScheduleUpdate(
  subscription: UserSubscriptionCompleteFragment,
  plan: SubscriptionPlanCompleteFragment,
) {
  return (
    // on a pro plan
    subscription.plan.tier === SubscriptionPlanTier.Pro &&
    // not trialing
    subscription.status !== SubscriptionStatus.Trialing &&
    // either changing to a non pro plan
    (plan.tier !== SubscriptionPlanTier.Pro ||
      // or changing to a pro plan with a different billing period
      subscription.plan.period !== plan.period)
  )
}
