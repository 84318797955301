import { forwardRef } from 'react'
import {
  Box,
  createStyles,
  MantineColor,
  MantineNumberSize,
  MantineSize,
} from '@mantine/core'

export interface MenuItemStylesParams {
  radius: MantineNumberSize
  color?: MantineColor
}

const useStyles = createStyles(
  (theme, { radius, color }: MenuItemStylesParams) => ({
    item: {
      ...theme.fn.fontStyles(),
      WebkitTapHighlightColor: 'transparent',
      fontSize: theme.fontSizes.sm,
      border: 0,
      backgroundColor: 'transparent',
      outline: 0,
      width: '100%',
      textAlign: 'left',
      display: 'inline-block',
      textDecoration: 'none',
      boxSizing: 'border-box',
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      cursor: 'pointer',
      borderRadius: theme.fn.size({ size: radius, sizes: theme.radius }),
      color: color
        ? theme.fn.themeColor(color, theme.colorScheme === 'dark' ? 5 : 7)
        : theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.black,
      '&:disabled': {
        color:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[3]
            : theme.colors.gray[5],
        pointerEvents: 'none',
      },
    },

    itemSelected: {
      backgroundColor: color
        ? theme.fn.variant({ variant: 'light', color }).background
        : theme.colorScheme === 'dark'
        ? theme.fn.rgba(theme.colors.dark[3], 0.35)
        : theme.colors.gray[0],
    },

    itemHovered: theme.fn.hover({
      backgroundColor: color
        ? theme.fn.variant({ variant: 'light', color }).background
        : theme.colorScheme === 'dark'
        ? theme.fn.rgba(theme.colors.dark[3], 0.35)
        : theme.colors.gray[0],
    }),

    itemInner: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },

    itemBody: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
    },

    itemIcon: {
      marginRight: theme.spacing.xs,
      '& *': {
        display: 'block',
      },
    },

    itemLabel: {
      lineHeight: 1,
    },
  }),
)

export type MenuItemProps = {
  icon?: React.ReactNode
  color?: MantineColor
  radius?: MantineSize
  rightSection?: React.ReactNode
  selected?: boolean
  disabled?: boolean
} & React.ComponentPropsWithoutRef<'button'>

export const MenuItem = forwardRef(function MenuItem(
  props: MenuItemProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const {
    icon,
    color,
    radius,
    rightSection,
    selected,
    disabled,
    children,
    className,
    ...delegated
  } = props
  const { classes, cx } = useStyles({ color, radius: radius || 'md' })
  return (
    <Box
      component="button"
      type="button"
      role="menuitem"
      className={cx(
        classes.item,
        classes.itemHovered,
        { [classes.itemSelected]: selected },
        className,
      )}
      {...delegated}
      ref={ref}
    >
      <div className={classes.itemInner}>
        {icon && <div className={classes.itemIcon}>{icon}</div>}
        <div className={classes.itemBody}>
          <div className={classes.itemLabel}>{children}</div>
          {rightSection}
        </div>
      </div>
    </Box>
  )
})
