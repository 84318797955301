import { useCancelSubscriptionMutation } from '@/api'

export const useCancelSubscription = () => {
  const [execute, result] = useCancelSubscriptionMutation()
  const cancelSubscription = async () => {
    const { data } = await execute()
    return data?.cancelSubscription
  }
  return [cancelSubscription, result] as const
}
