import { StripeElementsOptions } from '@stripe/stripe-js'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { stripePromise } from './stripePromise'

type ElementsProps = React.PropsWithChildren<{
  options?: StripeElementsOptions
}>

export function Elements(props: ElementsProps) {
  const { options, children } = props
  return (
    <StripeElements
      stripe={stripePromise}
      options={{
        ...options,
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap',
          },
          ...(options?.fonts || []),
        ],
      }}
    >
      {children}
    </StripeElements>
  )
}
