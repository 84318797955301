import {
  Button,
  createStyles,
  MantineTheme,
  Paper,
  Skeleton,
  Text,
} from '@mantine/core'
import {
  IconCheck as IncludedIcon,
  IconCheck as CurrentPlanIcon,
  IconRefresh as ChangePlanIcon,
  IconArrowUp as UpgradePlanIcon,
  IconArrowDown as DowngradePlanIcon,
  IconCalendarTime as ScheduledPlanIcon,
  IconArrowBackUp as RevertToPlanIcon,
  IconX as ExcludedIcon,
  IconArrowNarrowRight as StartNowIcon,
} from '@tabler/icons-react'
import getSymbolFromCurrency from 'currency-symbol-map'
import {
  SubscriptionPlanCompleteFragment,
  SubscriptionPlanPeriod,
  UserSubscriptionWithScheduledUpdateFragment,
  UserCompleteWithoutSubscriptionFragment,
  SubscriptionStatus,
  SubscriptionPlanTier,
  FreeTierType,
} from '@/api'
import { typographicScale } from '@/styles/typography'
import { focusStyles } from '@/styles/helpers'
import { getPlanDetails } from './constants'
import {
  getMonthlyPrice,
  isSubscriptionActionRequired,
  periodToDiplay,
} from './helpers'
import { getTrialDays } from '@/utils/common'

const CARD_RADIUS = 'md'
const CARD_WIDTH = 265
const CARD_MIN_HEIGHT = 475

type ButtonColor = 'white' | 'teal' | 'red' | 'dark'

type StyleParams = {
  buttonColor: (theme: MantineTheme) => ButtonColor
  background: (theme: MantineTheme) => string
  color: (theme: MantineTheme) => string
}

function getButtonColor(theme: MantineTheme, color: ButtonColor) {
  switch (color) {
    case 'white':
      return theme.white
    default:
      return theme.colors[color][theme.colorScheme === 'dark' ? 6 : 4]
  }
}

const useStyles = createStyles((theme, params: StyleParams, getRef) => ({
  root: {
    width: CARD_WIDTH,
    minHeight: CARD_MIN_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.lg,
    padding: theme.spacing.lg,
    background: params.background(theme),
    color: params.color(theme),
    '& > *': {
      flex: '0 0 auto',
    },
  },
  name: {
    margin: 0,
    fontWeight: 400,
  },
  priceContainer: {
    marginBlockEnd: theme.spacing.xs,
  },
  price: {
    fontWeight: 700,
    fontSize: typographicScale.getNext(theme.fontSizes.xl),
  },
  perMonth: {
    display: 'inline-block',
    fontWeight: 400,
    verticalAlign: 'super',
    marginInlineStart: '5px',
    transform: 'translateY(-4px)',
  },
  asterix: {
    fontSize: typographicScale.getPrev(theme.fontSizes.xs),
    fontStyle: 'italic',
  },
  description: {
    minHeight: 38,
  },
  features: {
    marginInlineStart: theme.spacing.xs,
    height: 152,
  },
  feature: {
    lineHeight: 1.8,
  },
  includedIcon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.green[6]
        : theme.colors.green[4],
    verticalAlign: 'middle',
    marginRight: theme.spacing.xs,
    transform: 'translateY(-0.1em)',
  },
  excludedIcon: {
    color:
      theme.colorScheme === 'dark' ? theme.colors.red[8] : theme.colors.red[6],
    verticalAlign: 'middle',
    marginRight: theme.spacing.xs,
    transform: 'translateY(-0.1em)',
  },
  buttonContainer: {
    marginBlockStart: 'auto',
    marginInline: 'auto',
  },
  button: {
    fontWeight: 600,
    color: getButtonColor(theme, params.buttonColor(theme)),
    border: `1.5px solid ${getButtonColor(theme, params.buttonColor(theme))}`,
    ...theme.fn.hover({
      backgroundColor: theme.fn.rgba(
        getButtonColor(theme, params.buttonColor(theme)),
        0.1,
      ),
    }),
    ...focusStyles(
      theme,
      2,
      2,
      getButtonColor(theme, params.buttonColor(theme)),
    ),
    [`&:not(.${getRef('loading')}):disabled`]: {
      fontWeight: 600,
      backgroundColor: 'transparent',
      opacity: 0.75,
      color: getButtonColor(theme, params.buttonColor(theme)),
      border: `1.5px solid ${getButtonColor(theme, params.buttonColor(theme))}`,
    },
  },
}))

type ClickAction = 'subscribe' | 'change-plan'

type PlanCardProps = {
  plan: SubscriptionPlanCompleteFragment
  user: UserCompleteWithoutSubscriptionFragment
  subscription?: UserSubscriptionWithScheduledUpdateFragment | null
  onClick?: (
    event: React.MouseEvent,
    action: ClickAction,
    plan: SubscriptionPlanCompleteFragment,
  ) => void
}

export function PlanCard(props: PlanCardProps) {
  const { plan, user, subscription, onClick } = props
  const details = getPlanDetails(plan.tier)
  const cardState = getPlanCardState(plan, user, subscription)
  const { classes } = useStyles({
    background: details.background,
    buttonColor: () => cardState.buttonColor,
    color: (theme) => theme.white,
  })
  const currencySymbol = getSymbolFromCurrency(plan.currency.toUpperCase())
  const displayPeriod = periodToDiplay[plan.period]
  const monthlyPrice = getMonthlyPrice(plan)
  const handleClick = (event: React.MouseEvent) => {
    if (onClick) {
      if (cardState.buttonAction === undefined) return
      onClick(event, cardState.buttonAction, plan)
    }
  }
  return (
    <Paper className={classes.root} radius={CARD_RADIUS} withBorder>
      <Text className={classes.name} component="h3" size="lg">
        {details.shortName}
      </Text>
      <div className={classes.priceContainer}>
        <Text className={classes.price}>
          {currencySymbol}
          {monthlyPrice}
          <Text className={classes.perMonth} component="span" size="xs">
            / Month
            {plan.period !== SubscriptionPlanPeriod.Month && '*'}
          </Text>
        </Text>
        {plan.period !== SubscriptionPlanPeriod.Month && (
          <Text className={classes.asterix} inline>
            * billed {displayPeriod.normalSuffixed}
          </Text>
        )}
      </div>
      <Text size="xs" className={classes.description}>
        {details.description}
      </Text>
      <div className={classes.features}>
        {details.features.map((feature, index) => (
          <Text key={index} className={classes.feature} size="xs">
            {feature.included ? (
              <IncludedIcon size="1.5em" className={classes.includedIcon} />
            ) : (
              <ExcludedIcon size="1.5em" className={classes.excludedIcon} />
            )}
            {feature.label}
          </Text>
        ))}
      </div>
      <div className={classes.buttonContainer}>
        {/* TODO: Make button state dependent on subscription */}
        <Button
          variant="outline"
          className={classes.button}
          leftIcon={
            cardState.buttonIconPosition !== 'right' && cardState.buttonIcon
          }
          rightIcon={
            cardState.buttonIconPosition === 'right' && cardState.buttonIcon
          }
          disabled={cardState.buttonAction === undefined}
          title={cardState.disabledReason}
          onClick={handleClick}
        >
          {cardState.buttonLabel}
        </Button>
      </div>
    </Paper>
  )
}

type PlanCardStateBase = {
  buttonLabel: string
  buttonColor: ButtonColor
  buttonIcon?: React.ReactNode
  buttonIconPosition?: 'left' | 'right'
}

type PlanCardStateEnabled = PlanCardStateBase & {
  buttonAction: ClickAction
  disabledReason?: undefined
}

type PlanCardStateDisabled = PlanCardStateBase & {
  buttonAction: undefined
  disabledReason?: string
}

type PlanCardState = PlanCardStateEnabled | PlanCardStateDisabled

type FreePlanCardState = Omit<PlanCardState, 'buttonColor'> & {
  buttonColor?: ButtonColor
}

function getFreePlanCardState(
  subscription?: UserSubscriptionWithScheduledUpdateFragment | null,
): FreePlanCardState {
  if (!subscription) {
    return {
      buttonLabel: 'Current plan',
      buttonIcon: <CurrentPlanIcon size={20} />,
      buttonAction: undefined,
    }
  }
  if (subscription.cancelAtPeriodEnd)
    return {
      buttonLabel: 'Change scheduled',
      buttonIcon: <ScheduledPlanIcon size={20} />,
      buttonAction: undefined,
    }
  return {
    buttonLabel: 'Downgrade',
    buttonColor: 'red',
    buttonIcon: <DowngradePlanIcon size={20} />,
    buttonAction: 'change-plan' as PlanCardState['buttonAction'],
  }
}

function getPlanCardState(
  plan: SubscriptionPlanCompleteFragment,
  user: UserCompleteWithoutSubscriptionFragment,
  subscription?: UserSubscriptionWithScheduledUpdateFragment | null,
): PlanCardState {
  if (!subscription) {
    if (!user.everSubscribed) {
      return {
        buttonLabel: `Start ${getTrialDays(
          plan.trialDays,
          user.freeTierType,
        )} day trial`,
        buttonColor: 'white',
        buttonIcon: <StartNowIcon size={20} stroke={1.7} />,
        buttonAction: 'subscribe',
        buttonIconPosition: 'right',
      }
    }
    return {
      buttonLabel: 'Start now',
      buttonColor: 'white',
      buttonIcon: <StartNowIcon size={20} stroke={1.7} />,
      buttonAction: 'subscribe',
      buttonIconPosition: 'right',
    }
  }
  const { scheduledUpdate } = subscription.additionalInfo
  if (subscription.plan.id === plan.id) {
    if (!scheduledUpdate) {
      return {
        buttonLabel: 'Current plan',
        buttonColor: 'white',
        buttonIcon: <CurrentPlanIcon size={20} />,
        buttonAction: undefined,
      }
    }
    return {
      buttonLabel: 'Revert to plan',
      buttonColor: 'teal',
      buttonIcon: <RevertToPlanIcon size={20} />,
      buttonAction: 'change-plan',
    }
  }
  if (scheduledUpdate && scheduledUpdate.plan.id === plan.id) {
    return {
      buttonLabel: 'Change scheduled',
      buttonColor: 'white',
      buttonIcon: <ScheduledPlanIcon size={20} />,
      buttonAction: undefined,
    }
  }
  const state: PlanCardState = {
    buttonLabel: 'Change plan',
    buttonColor: 'teal',
    buttonIcon: <ChangePlanIcon size={20} />,
    buttonAction: 'change-plan' as PlanCardState['buttonAction'],
  }
  const relevantPlan = scheduledUpdate?.plan || subscription.plan
  if (
    plan.tier === SubscriptionPlanTier.Pro &&
    relevantPlan.tier === SubscriptionPlanTier.Basic
  ) {
    state.buttonLabel = 'Upgrade'
    state.buttonIcon = <UpgradePlanIcon size={20} />
  } else if (
    plan.tier === SubscriptionPlanTier.Basic &&
    relevantPlan.tier === SubscriptionPlanTier.Pro
  ) {
    state.buttonLabel = 'Downgrade'
    state.buttonColor = 'red'
    state.buttonIcon = <DowngradePlanIcon size={20} />
  }
  if (plan.requiresAcademic && !user.academic?.isVerified) {
    state.buttonAction = undefined
    state.disabledReason =
      'You need to verify your academic status to subscribe to this plan'
  }
  if (isSubscriptionActionRequired(subscription)) {
    state.buttonAction = undefined
    state.disabledReason =
      subscription.status === SubscriptionStatus.Trialing
        ? 'You must verify a payment method before you can change plans'
        : 'You must pay the outstanding invoice before you can change plans'
  }
  return state
}

export function PlanCardSkeleton() {
  return (
    <Skeleton
      radius={CARD_RADIUS}
      width={CARD_WIDTH}
      height={CARD_MIN_HEIGHT}
    />
  )
}

type FreePlanCardProps = {
  period: SubscriptionPlanPeriod
  user: UserCompleteWithoutSubscriptionFragment
  subscription?: UserSubscriptionWithScheduledUpdateFragment | null
  onClick: () => void
}

function getFreePlanFeatures(user: UserCompleteWithoutSubscriptionFragment) {
  const features = [
    { label: 'Unlimited uploads', included: true },
    { label: 'Document keywords', included: true },
    { label: 'Document annotations', included: true },
    { label: 'Linked note-taking', included: true },
  ]
  if (user.freeTierType === FreeTierType.Usage) {
    features.push({
      label: '5 free summaries',
      included: true,
    })
  } else if (user.freeTierType === FreeTierType.Time) {
    features.push({
      label: 'One week free summaries',
      included: true,
    })
  }
  return features
}

export const FreePlanCard = (props: FreePlanCardProps) => {
  const { period, user, subscription, onClick } = props
  const displayPeriod = periodToDiplay[period]
  const cardState = getFreePlanCardState(subscription)
  const features = getFreePlanFeatures(user)

  const { classes } = useStyles({
    background: (theme) =>
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    buttonColor: (theme) =>
      cardState.buttonColor ??
      (theme.colorScheme === 'dark' ? 'white' : 'dark'),
    color: (theme) =>
      theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[4],
  })
  return (
    <Paper
      className={classes.root}
      radius={CARD_RADIUS}
      sx={(theme) => ({
        border: `1px solid ${
          theme.colorScheme === 'dark'
            ? theme.colors.gray[6]
            : theme.colors.dark[1]
        }`,
      })}
    >
      <Text className={classes.name} component="h3" size="lg">
        Free
      </Text>
      <div className={classes.priceContainer}>
        <Text className={classes.price}>
          £0.00
          <Text className={classes.perMonth} component="span" size="xs">
            / Month
            {period !== SubscriptionPlanPeriod.Month && '*'}
          </Text>
        </Text>
        {period !== SubscriptionPlanPeriod.Month && (
          <Text className={classes.asterix} inline>
            * billed {displayPeriod.normalSuffixed}
          </Text>
        )}
      </div>
      <Text size="xs" className={classes.description}>
        Write notes, manage files, and markup documents - all in one easy-to-use
        app.
      </Text>
      <div className={classes.features}>
        {features.map((feature, index) => (
          <Text key={index} className={classes.feature} size="xs">
            {feature.included ? (
              <IncludedIcon size="1.5em" className={classes.includedIcon} />
            ) : (
              <ExcludedIcon size="1.5em" className={classes.excludedIcon} />
            )}
            {feature.label}
          </Text>
        ))}
      </div>
      <div className={classes.buttonContainer}>
        {/* TODO: Make button state dependent on subscription */}
        <Button
          variant="outline"
          className={classes.button}
          leftIcon={
            cardState.buttonIconPosition !== 'right' && cardState.buttonIcon
          }
          rightIcon={
            cardState.buttonIconPosition === 'right' && cardState.buttonIcon
          }
          disabled={cardState.buttonAction === undefined}
          title={cardState.disabledReason}
          onClick={onClick}
        >
          {cardState.buttonLabel}
        </Button>
      </div>
    </Paper>
  )
}
