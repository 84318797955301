import { SubscriptionPlanPeriod } from '@/api'
import { MantineSize, SegmentedControl } from '@mantine/core'

const periodToLabel: Record<SubscriptionPlanPeriod, string> = {
  [SubscriptionPlanPeriod.Year]: 'Choose Yearly',
  [SubscriptionPlanPeriod.Month]: 'Choose Monthly',
}
const periods = Object.keys(periodToLabel) as SubscriptionPlanPeriod[]

export type ChoosePeriodProps = {
  period: SubscriptionPlanPeriod
  onChange: (period: SubscriptionPlanPeriod) => void
  size?: MantineSize
}

export function ChoosePeriod(props: ChoosePeriodProps) {
  const { period, onChange, size } = props
  return (
    <div style={{ width: 'fit-content' }}>
      <SegmentedControl
        size={size}
        data={periods.map((period) => ({
          value: period,
          label: periodToLabel[period],
        }))}
        value={period}
        onChange={(value: SubscriptionPlanPeriod) => onChange(value)}
      />
    </div>
  )
}
