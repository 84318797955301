import { createStyles, Modal, Text, Button, Stack } from '@mantine/core'
import { IconAlertTriangle as WarningIcon } from '@tabler/icons-react'

const useStyles = createStyles({
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export type AreYouSureModalProps = {
  open: boolean
  title: React.ReactNode
  description: React.ReactNode
  confirmButtonLabel: React.ReactNode
  loading?: boolean
  error?: Error
  onConfirm: () => void
  onClose: () => void
}

export function AreYouSureModal(props: AreYouSureModalProps) {
  const {
    open,
    title,
    description,
    confirmButtonLabel,
    loading,
    error,
    onConfirm,
    onClose,
  } = props
  const { classes } = useStyles()
  return (
    <Modal opened={open} onClose={onClose} title={title} radius="lg">
      <Stack spacing="lg">
        {typeof description === 'string' ? (
          <Text size="sm">{description}</Text>
        ) : (
          description
        )}
        <div className={classes.actions}>
          <Button
            onClick={onConfirm}
            leftIcon={<WarningIcon size={16} />}
            loading={loading}
            color="red"
          >
            {confirmButtonLabel}
          </Button>
          <Button onClick={onClose} variant="subtle">
            Cancel
          </Button>
        </div>
        {error && (
          <Text color="red" size="sm">
            {error.message}
          </Text>
        )}
      </Stack>
    </Modal>
  )
}
