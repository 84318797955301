import { useLayoutEffect, useEffect, useRef } from 'react'

type ScrollState = {
  element: HTMLElement
  scrollTop: number
}

export const useRestoreScroll = (
  ref: React.RefObject<HTMLElement>,
  trigger: boolean,
) => {
  const scrollStateRef = useRef<ScrollState | null>(null)
  useLayoutEffect(() => {
    if (!trigger) return
    if (
      !scrollStateRef.current ||
      scrollStateRef.current.element !== ref.current
    ) {
      // element has changed - no scroll restore
      return
    }
    ref.current.scrollTop = scrollStateRef.current.scrollTop
  }, [ref, scrollStateRef, trigger])
  useEffect(() => {
    const element = ref.current
    if (!element) return
    const handler = () => {
      if (scrollStateRef.current) {
        scrollStateRef.current.element = element
        scrollStateRef.current.scrollTop = element.scrollTop
      }
      scrollStateRef.current = { element, scrollTop: element.scrollTop }
    }
    element.addEventListener('scroll', handler)
    return () => {
      element.removeEventListener('scroll', handler)
    }
  }, [ref, scrollStateRef])
}
