import { useConsumeLicenseKeyMutation } from '@/api'

export const useConsumeLicenseKey = () => {
  const [execute, results] = useConsumeLicenseKeyMutation()
  const consumeLicenseKey = async (id: string) => {
    const { data } = await execute({
      variables: { input: { id } },
    })
    return data?.consumeLicenseKey
  }

  return [consumeLicenseKey, results] as const
}
