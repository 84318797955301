import React from 'react'
import { constructRoutes, DefaultFallback } from '@/router'
import { toDefaultImport } from '@/utils/common'
import NavLayout from './nav-layout'

export const routes = constructRoutes([
  {
    type: 'lazy',
    path: '/',
    LazyComponent: React.lazy(() => import('./home')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/find-account',
    auth: 'unauthenticated',
    LazyComponent: React.lazy(() => import('./find-account')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/sso-callback',
    auth: 'unauthenticated',
    LazyComponent: React.lazy(() => import('./sso-callback')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/login',
    auth: 'unauthenticated',
    LazyComponent: React.lazy(() => import('./login')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/login-verified',
    LazyComponent: React.lazy(() => import('./login-verified')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/forgot-password',
    auth: 'unauthenticated',
    LazyComponent: React.lazy(() => import('./forgot-password')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/signup',
    auth: 'unauthenticated',
    LazyComponent: React.lazy(() => import('./signup')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/verify-signup',
    auth: 'unauthenticated',
    LazyComponent: React.lazy(() => import('./verify-signup')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/signup-verified',
    LazyComponent: React.lazy(() => import('./signup-verified')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/logout',
    auth: 'authenticated',
    LazyComponent: React.lazy(() => import('./logout')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'redirect',
    path: '/onboarding',
    auth: 'authenticated',
    redirect: '/subscription/plans',
  },
  {
    type: 'normal',
    path: '/subscription',
    auth: 'authenticated',
    children: [
      {
        type: 'lazy',
        path: 'plans',
        LazyComponent: React.lazy(() =>
          import('./subscription').then(toDefaultImport('SelectPlanView')),
        ),
        fallback: <DefaultFallback />,
      },
      {
        type: 'lazy',
        path: 'subscribe/:planId',
        LazyComponent: React.lazy(() =>
          import('./subscription').then(toDefaultImport('SubscribeToPlanView')),
        ),
        fallback: <DefaultFallback />,
      },
      {
        type: 'lazy',
        path: 'action-pending-intent',
        LazyComponent: React.lazy(() =>
          import('./subscription').then(
            toDefaultImport('ActionPendingIntentView'),
          ),
        ),
        fallback: <DefaultFallback />,
      },
      {
        type: 'lazy',
        path: 'pending-intent-actioned',
        LazyComponent: React.lazy(() =>
          import('./subscription').then(
            toDefaultImport('PendingIntentActionedView'),
          ),
        ),
        fallback: <DefaultFallback />,
      },
    ],
  },
  {
    type: 'normal',
    auth: 'authenticated',
    element: <NavLayout />,
    children: [
      {
        type: 'lazy',
        path: '/account',
        LazyComponent: React.lazy(() =>
          import('./account').then(toDefaultImport('AccountLayout')),
        ),
        fallback: <DefaultFallback />,
        children: [
          {
            type: 'redirect',
            index: true,
            redirect: '/account/settings',
          },
          {
            type: 'lazy',
            path: 'settings',
            LazyComponent: React.lazy(() =>
              import('./account').then(toDefaultImport('AccountView')),
            ),
            fallback: <DefaultFallback />,
          },
          {
            type: 'lazy',
            path: 'subscription',
            LazyComponent: React.lazy(() =>
              import('./account').then(toDefaultImport('SubscriptionView')),
            ),
            fallback: <DefaultFallback />,
          },
          {
            type: 'lazy',
            path: 'billing',
            LazyComponent: React.lazy(() =>
              import('./account').then(toDefaultImport('BillingView')),
            ),
            fallback: <DefaultFallback />,
          },
          {
            type: 'lazy',
            path: 'app-settings',
            LazyComponent: React.lazy(() =>
              import('./account').then(toDefaultImport('AppSettingsView')),
            ),
            fallback: <DefaultFallback />,
          },
        ],
      },
      {
        type: 'lazy',
        path: '/dashboard',
        LazyComponent: React.lazy(() => import('./dashboard')),
        fallback: <DefaultFallback />,
      },
      {
        type: 'normal',
        path: '/summarise',
        children: [
          {
            type: 'lazy',
            path: 'history',
            LazyComponent: React.lazy(() => import('./quick-summary-history')),
            fallback: <DefaultFallback />,
          },
          {
            type: 'lazy',
            path: ':resourceId',
            LazyComponent: React.lazy(() => import('./quick-summary')),
            fallback: <DefaultFallback />,
          },
          {
            type: 'lazy',
            index: true,
            LazyComponent: React.lazy(() => import('./summarise')),
            fallback: <DefaultFallback />,
          },
        ],
      },
      {
        type: 'normal',
        path: '/projects',
        children: [
          {
            type: 'lazy',
            path: ':projectId',
            LazyComponent: React.lazy(() => import('./project')),
            fallback: <DefaultFallback />,
          },
          {
            // TODO: build a projects-only view
            type: 'redirect',
            index: true,
            redirect: '/dashboard',
          },
        ],
      },
    ],
  },
  {
    type: 'lazy',
    path: '/link-account',
    auth: 'authenticated',
    LazyComponent: React.lazy(() => import('./link-account')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/import-prompt',
    auth: 'authenticated',
    LazyComponent: React.lazy(() => import('./import-prompt')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/api-keys',
    auth: 'authenticated',
    LazyComponent: React.lazy(() => import('./api-keys')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/verify-academic-email/:token',
    auth: 'authenticated',
    LazyComponent: React.lazy(() => import('./verify-academic-email')),
    fallback: <DefaultFallback />,
  },
  {
    type: 'lazy',
    path: '/admin',
    auth: 'authenticated',
    LazyComponent: React.lazy(() =>
      import('./admin').then(toDefaultImport('AdminLayout')),
    ),
    fallback: <DefaultFallback />,
    children: [
      {
        type: 'redirect',
        index: true,
        redirect: '/admin/user',
      },
      {
        type: 'lazy',
        path: 'user',
        LazyComponent: React.lazy(() =>
          import('./admin').then(toDefaultImport('UserView')),
        ),
        fallback: <DefaultFallback />,
      },
      {
        type: 'lazy',
        path: 'cohort',
        LazyComponent: React.lazy(() =>
          import('./admin').then(toDefaultImport('CohortView')),
        ),
        fallback: <DefaultFallback />,
      },
      {
        type: 'lazy',
        path: 'license-keys',
        LazyComponent: React.lazy(() =>
          import('./admin').then(toDefaultImport('LicenseKeysView')),
        ),
        fallback: <DefaultFallback />,
      },
      {
        type: 'lazy',
        path: 'prompts',
        LazyComponent: React.lazy(() =>
          import('./admin').then(toDefaultImport('PromptView')),
        ),
        fallback: <DefaultFallback />,
      },
    ],
  },
  {
    type: 'redirect',
    path: '*',
    redirect: '/',
  },
])
