import { useSetSubscriptionPromotionMutation } from '@/api'

export const useSetSubscriptionPromotion = () => {
  const [execute, result] = useSetSubscriptionPromotionMutation()
  const setSubscriptionPromotion = async (promotionCode: string) => {
    const { data } = await execute({
      variables: {
        input: { promotionCode },
      },
    })
    return data?.setSubscriptionPromotion
  }
  return [setSubscriptionPromotion, result] as const
}
