import { useUser } from '@/api'
import { useCompleteTour } from './useCompleteTour'
import { useEffect } from 'react'

// ! TOUR IDS BELOW 5000 ARE RESERVED BY INTERNAL TOURS. IF INTERCOM EVER GENERATES
// ! A TOUR WITH AN ID BELOW 5000, IT CANNOT BE USED HERE.

// Quick Summary Tour 2 is shown automatically by Intercom when a user first
// completes the Quick Summary flow. Triggering Quick Summary Tour 2 via Intercom's
// API would require breaking the encapsulation of the ResourceOverview component.
export enum IntercomTour {
  QuickSummary1 = 371976,
  LinkedNotes = 372319,
  Search = 372390,
  Project = 375646,
  Notes = 375836,
}

export const useIntercomTour = (tourId: IntercomTour) => {
  const { completedTours } = useUser()
  const [completeTour, { loading }] = useCompleteTour()
  const isCompleted = completedTours.includes(tourId)
  const start = () => {
    if (isCompleted || loading) return
    if (window.Intercom) {
      window.Intercom('startTour', tourId)
      completeTour(tourId)
    }
  }
  return { start, isCompleted }
}

export const useAutoIntercomTour = (
  tourId: IntercomTour,
  isReady?: boolean,
) => {
  const { start, isCompleted } = useIntercomTour(tourId)
  useEffect(() => {
    if (isCompleted || isReady === false) return
    start()
  }, [isCompleted, isReady]) // eslint-disable-line react-hooks/exhaustive-deps
}
