import { useCompleteTourMutation } from '@/api'

export const useCompleteTour = () => {
  const [execute, result] = useCompleteTourMutation()
  const completeTour = async (tourId: number) => {
    const { data } = await execute({
      variables: {
        input: {
          tourId,
        },
      },
    })
    return data?.completeTour
  }
  return [completeTour, result] as const
}
