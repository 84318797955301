import { useCallback, useContext, useEffect, useState } from 'react'
import { UNSAFE_NavigationContext, Navigator } from 'react-router-dom'
import type { Blocker, Transition } from 'history'

type BlockerNavigator = Navigator & {
  location: Location
  block(blocker: Blocker): () => void
}

/**
 * Unstable - relies on internals of react-router-dom. apparently breaks in v6.4.0
 * https://github.com/remix-run/react-router/issues/8139#issuecomment-1176523524
 */
export function useBlockNavigation(shouldBlock: boolean) {
  const { navigator } = useContext(UNSAFE_NavigationContext)
  const [transition, setTransition] = useState<Transition | null>(null)
  useEffect(() => {
    if (!shouldBlock) return
    const { block, location } = navigator as BlockerNavigator
    const currentPath = location.pathname
    const unblock = block((transition) => {
      const { location } = transition
      const targetPath = location.pathname
      if (targetPath === currentPath) {
        return
      }
      setTransition({
        ...transition,
        retry() {
          unblock()
          transition.retry()
        },
      })
    })
    return () => {
      unblock()
    }
  }, [navigator, shouldBlock])
  const leavePage = useCallback(() => {
    transition?.retry()
    setTransition(null)
  }, [transition])
  const stayOnPage = useCallback(() => {
    setTransition(null)
  }, [])
  const isBlocked = !!transition
  return [isBlocked, { stayOnPage, leavePage }] as const
}
