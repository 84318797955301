import { getenv } from '@/utils/common'

const config = {
  stage: getenv('REACT_APP_STAGE', 'unknown'),
  sentryDsn: getenv('REACT_APP_SENTRY_DSN'),
  segmentApiKey: getenv('REACT_APP_SEGMENT_API_KEY'),
  intercomAppId: getenv('REACT_APP_INTERCOM_APP_ID'),
  clerkFrontendApi: getenv('REACT_APP_CLERK_FRONTEND_API', true),
  graphqlEndpoint: `${getenv('REACT_APP_SERVER_BASE_URL', true)}/graphql`,
  pdftronLicense: getenv('REACT_APP_PDFTRON_LICENSE', true),
  stripePublishableKey: getenv('REACT_APP_STRIPE_PUBLISHABLE_KEY', true),
  betaClientBaseUrl: getenv('REACT_APP_BETA_CLIENT_BASE_URL', true),
  companionUrl: getenv('REACT_APP_COMPANION_URL', true),
}

if (process.env.NODE_ENV !== 'production') {
  const style = 'color: #00bff3; font-size: 1.2em; font-weight: bold;'
  for (const [key, value] of Object.entries(config)) {
    console.log(`%c${key}\n`, style, value)
  }
}

export default config as Readonly<typeof config>
