import { PartialRecord } from '@/utils/types'
import { useCallback } from 'react'
import {
  Navigate as BaseNavigate,
  NavigateProps as BaseNavigateProps,
  useNavigate as useBaseNavigate,
  NavigateOptions as BaseNavigateOptions,
  useSearchParams,
  To,
} from 'react-router-dom'

type ExcludeFn = (key: string, value: string) => boolean
type ExcludeObj = PartialRecord<string, boolean>

export function mergeSearchParams(
  to: To,
  searchParams: URLSearchParams,
  exclude?: ExcludeFn | ExcludeObj,
) {
  const toStr =
    typeof to === 'string'
      ? to
      : (to.pathname ?? '') + (to.search ?? '') + (to.hash ?? '')

  let excludeFn: ExcludeFn
  if (typeof exclude === 'function') {
    excludeFn = exclude
  } else if (exclude) {
    excludeFn = (key) => !!exclude[key]
  } else {
    excludeFn = () => false
  }

  const url = new URL(toStr, 'https://www.placeholder.com')
  for (const [key, value] of searchParams) {
    if (excludeFn(key, value)) continue
    url.searchParams.append(key, value)
  }
  return url.pathname + url.search + url.hash
}

export type NavigateProps = BaseNavigateProps & {
  propagateSearchParams?: boolean
}

export function Navigate(props: NavigateProps) {
  const { propagateSearchParams, to: _to, ...rest } = props
  const [searchParams] = useSearchParams()
  const to = !propagateSearchParams ? _to : mergeSearchParams(_to, searchParams)
  return <BaseNavigate {...rest} to={to} />
}

export interface NavigateOptions extends BaseNavigateOptions {
  propagateSearchParams?: boolean
}

export interface NavigateFunction {
  (to: To, options?: NavigateOptions): void
  (delta: number): void
}

export const useNavigate = (): NavigateFunction => {
  const _navigate = useBaseNavigate()
  const [searchParams] = useSearchParams()
  const navigate = useCallback<
    (to: To | number, options?: NavigateOptions) => void
  >(
    (to, options) => {
      if (typeof to === 'number') {
        return _navigate(to)
      }
      const { propagateSearchParams, ...rest } = options ?? {}
      const _to = !propagateSearchParams
        ? to
        : mergeSearchParams(to, searchParams)
      return _navigate(_to, rest)
    },
    [_navigate, searchParams],
  )
  return navigate
}
