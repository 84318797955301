import { Database } from './database'
import { DocumentEntry, DocumentData, DocumentMeta } from './types'

export class DocumentClient {
  private readonly db: Database

  constructor(db: Database) {
    this.db = db
  }

  public async get(id: string): Promise<DocumentEntry | null> {
    try {
      const documentEntry = await this.db.transaction(
        'r',
        this.db.documentMeta,
        this.db.documentData,
        async () => {
          const [documentMeta, documentData] = await Promise.all([
            this.db.documentMeta.get(id),
            this.db.documentData.get(id),
          ])
          if (!documentMeta || !documentData) return null
          const document = { ...documentMeta, ...documentData }
          return document
        },
      )
      return documentEntry
    } catch {
      throw new Error('Failed to get document')
    }
  }

  public async getMeta(id: string): Promise<DocumentMeta | null> {
    try {
      const documentMeta = await this.db.documentMeta.get(id)
      return documentMeta || null
    } catch {
      throw new Error('Failed to get document')
    }
  }

  public async list(
    offset: number = 0,
    limit: number = 20,
  ): Promise<[DocumentMeta[], number | undefined]> {
    const documenttMetas = await this.db.documentMeta
      .orderBy('id')
      .offset(offset)
      .limit(limit)
      .toArray()
    const newOffset = offset + documenttMetas.length
    const noMoreItems = documenttMetas.length < limit
    return [documenttMetas, noMoreItems ? undefined : newOffset]
  }

  public async create(input: DocumentEntry): Promise<DocumentEntry> {
    const { id, data, version } = input
    const documentMeta: DocumentMeta = { id, version }
    const documentData: DocumentData = { id, data }
    const documentEntry = { ...documentMeta, ...documentData }
    try {
      await this.db.transaction(
        'rw',
        this.db.documentMeta,
        this.db.documentData,
        async () => {
          await Promise.all([
            this.db.documentMeta.add(documentMeta),
            this.db.documentData.add(documentData),
          ])
        },
      )
      return documentEntry
    } catch {
      throw new Error('Failed to create document')
    }
  }

  public async delete(id: string): Promise<void> {
    try {
      await this.db.transaction(
        'rw',
        this.db.documentMeta,
        this.db.documentData,
        async () => {
          await Promise.all([
            this.db.documentMeta.delete(id),
            this.db.documentData.delete(id),
          ])
        },
      )
    } catch {
      throw new Error('Failed to delete document')
    }
  }
}
