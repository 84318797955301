import { useEffect, useState } from 'react'
import { PaymentRequest, PaymentMethod, Stripe } from '@stripe/stripe-js'
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import { Currency } from '@/api'
import { useReferentialId, useValueRef } from '@/common/hooks'
import { MaybePromise } from '@/utils/types'

export type PaymentRequestButtonProps = {
  label: string
  amount: number
  currency: Currency
  onReady?: () => void
  onPaymentMethod: (
    paymentMethod: PaymentMethod,
    stripe: Stripe,
  ) => MaybePromise<void>
}

export const PaymentRequestButton = (props: PaymentRequestButtonProps) => {
  const { label, amount, currency, onReady, onPaymentMethod } = props
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()
  const paymentRequestId = useReferentialId(paymentRequest || {})
  const onReadyRef = useValueRef(onReady)
  const onPaymentMethodRef = useValueRef(onPaymentMethod)
  const stripe = useStripe()
  useEffect(() => {
    if (!stripe) return
    const paymentRequest = stripe.paymentRequest({
      country: 'GB',
      currency,
      total: { label, amount },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    })
    paymentRequest.canMakePayment().then((result) => {
      if (!result) return
      setPaymentRequest(paymentRequest)
      const onReady = onReadyRef.current
      if (onReady) onReady()
      paymentRequest.on(
        'paymentmethod',
        async ({ paymentMethod, complete }) => {
          const onPaymentMethod = onPaymentMethodRef.current
          try {
            await onPaymentMethod(paymentMethod, stripe)
            complete('success')
          } catch (error) {
            console.error(error)
            complete('fail')
          }
        },
      )
    })
  }, [stripe, label, amount, currency, onReadyRef, onPaymentMethodRef])
  if (!paymentRequest) {
    return null
  }
  return (
    <PaymentRequestButtonElement
      // https://github.com/stripe/react-stripe-elements/issues/284
      // TODO: don't really get it, but will keep around
      key={paymentRequestId}
      options={{ paymentRequest }}
    />
  )
}
