import { useEffect } from 'react'
import { NavigateOptions, useNavigate } from 'react-router-dom'

export const useConditionalNavigateOnMount = (
  to: string,
  condition: boolean,
  options?: NavigateOptions,
) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (condition) {
      navigate(to, options)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export const useConditionalNavigate = (
  to: string,
  condition: boolean,
  options?: NavigateOptions,
) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (condition) {
      navigate(to, options)
    }
  }, [to, condition, options, navigate])
}
