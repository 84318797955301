import { useEffect } from 'react'
import {
  ApolloCache,
  ApolloError,
  FieldReadFunction,
  gql,
  useApolloClient,
} from '@apollo/client'
import { Fetch, PartialRecord, Json } from '@/utils/types'
import { DatabaseClient } from '@/local-database/client'
import * as generated from './generated'
import { Nlp } from './types'

type ReferenceCounts = PartialRecord<string, number>

const NLP_REFERENCES = new WeakMap<ApolloCache<any>, ReferenceCounts>()

const NlpReferences = {
  get(cache: ApolloCache<any>, resourceId: string) {
    const counts = NLP_REFERENCES.get(cache) || {}
    return counts[resourceId] || 0
  },

  increment(cache: ApolloCache<any>, resourceId: string) {
    const counts = NLP_REFERENCES.get(cache) || {}
    const resourceCount = (counts[resourceId] || 0) + 1
    counts[resourceId] = resourceCount
    NLP_REFERENCES.set(cache, counts)
  },

  decrement(cache: ApolloCache<any>, resourceId: string) {
    const counts = NLP_REFERENCES.get(cache) || {}
    const resourceCount = (counts[resourceId] || 0) - 1
    if (resourceCount <= 0) {
      delete counts[resourceId]
    } else {
      counts[resourceId] = resourceCount
    }
    NLP_REFERENCES.set(cache, counts)
  },
}

export const useResourceNlpQuery: typeof generated.useResourceNlpQuery = (
  options,
) => {
  const id = options.variables?.id
  const client = useApolloClient()
  const result = generated.useResourceNlpQuery(options)
  useEffect(() => {
    if (id == null) return undefined
    // when mounting / id changes we increment the ref count for the new id
    NlpReferences.increment(client.cache, id)
    return () => {
      // when unmounting / id changes we decrement the ref count for the old id
      NlpReferences.decrement(client.cache, id)
      // if the ref count for the old id is 0 then we evict nlp from the cache
      if (NlpReferences.get(client.cache, id) === 0) {
        client.cache.evict({
          id: client.cache.identify({ __typename: 'Resource', id }),
          fieldName: 'nlp',
        })
      }
    }
  }, [id, client.cache])
  return result
}

const NlpFragment = gql`
  fragment NlpFragment on Resource {
    nlp @client
  }
`

export function createNlpReadFunction(db: DatabaseClient, fetch: Fetch) {
  const read: FieldReadFunction = (existing, { readField, cache, storage }) => {
    const __typename = readField<string>('__typename')
    const id = readField<string>('id')
    const hasNlp = readField<boolean>('hasNlp')
    const nlpUrl = readField<string>('nlpUrl')
    if (!__typename || !id || !hasNlp || !nlpUrl) return null
    // have to check reference count here because for some reason
    // the read function is called after cache.evict()
    // have to check storage.isFetching in case read function gets called multiple times
    // before we get a response
    if (!existing && NlpReferences.get(cache, id) && !storage.isFetching) {
      ;(async () => {
        storage.isFetching = true
        try {
          let nlp: Nlp
          const cachedNlpEntry = await db.nlp.get(id)
          if (cachedNlpEntry) {
            nlp = cachedNlpEntry.data as Nlp
          } else {
            const response = await fetch(nlpUrl)
            if (!response.ok) {
              throw new Error(response.status.toString(10))
            }
            nlp = (await response.json()) as Nlp
            // not awaiting on purpose
            db.nlp.create({ id, data: nlp as Json })
          }
          // return early if the reference count is zero by this point
          if (!NlpReferences.get(cache, id)) return
          cache.writeFragment({
            id: cache.identify({ __typename, id }),
            fragment: NlpFragment,
            data: { nlp },
          })
        } catch (error) {
          // return early if the reference count is zero by this point
          if (!NlpReferences.get(cache, id)) return
          cache.writeFragment({
            id: cache.identify({ __typename, id }),
            fragment: NlpFragment,
            data: {
              nlp: new ApolloError({ errorMessage: 'Failed to load nlp' }),
            },
          })
        }
        storage.isFetching = false
      })()
    }
    return existing
  }
  return read
}
