import { forwardRef } from 'react'
import {
  createStyles,
  PolymorphicComponentProps,
  PolymorphicRef,
  SharedTextProps,
  Text,
} from '@mantine/core'

const useStyles = createStyles((theme) => ({
  root: {
    borderColor: 'currentColor',
    borderInlineStart: '0.15em solid',
    paddingInlineStart: '1em',
  },
}))

export type CalloutProps<C> = PolymorphicComponentProps<C, SharedTextProps>

type CalloutComponent = (<C = 'div'>(
  props: CalloutProps<C>,
) => React.ReactElement) & {
  displayName?: string
}

export const Callout: CalloutComponent = forwardRef(function Callout(
  props: CalloutProps<'div'>,
  ref: PolymorphicRef<'div'>,
) {
  const { component, className, ...delegated } = props
  const { classes, cx } = useStyles()
  return (
    <Text
      ref={ref}
      component={component || 'div'}
      className={cx(classes.root, className)}
      {...delegated}
    />
  )
}) as any

Callout.displayName = '@genei/Callout'
