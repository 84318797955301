import { createStyles } from '@mantine/core'
import { IconChevronDown, TablerIconsProps } from '@tabler/icons-react'

type StyleParams = {
  opened: boolean
  degree: number
}

const useStyles = createStyles((_, params: StyleParams) => ({
  chevron: {
    transform: `RotateZ(${params.opened ? 0 : -params.degree}deg)`,
    transition: 'transform 0.3s',
  },
}))

export type DynamicChevronProps = TablerIconsProps & {
  opened: boolean
  degree: number
}

export const DynamicChevron = (props: DynamicChevronProps) => {
  const { opened, degree, className, ...delegated } = props
  const { classes, cx } = useStyles({ opened, degree })
  return (
    <IconChevronDown
      className={cx(classes.chevron, className)}
      {...delegated}
    />
  )
}
