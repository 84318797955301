import { DependencyList, useCallback, useRef, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { v4 as autoId } from 'uuid'

type AsyncCallback = (...args: any[]) => Promise<any>
type AsyncCallbackReturn<T extends AsyncCallback> = [
  T,
  {
    data?: Awaited<ReturnType<T>>
    loading: boolean
    called: boolean
    error?: Error
  },
]

export function useAsyncCallback<T extends AsyncCallback>(
  callback: T,
  deps: DependencyList,
): AsyncCallbackReturn<T> {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [called, setCalled] = useState(false)
  const [data, setData] = useState<Awaited<ReturnType<T>> | undefined>(
    undefined,
  )
  const executionIdRef = useRef<string | undefined>(undefined)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const execute = useCallback<T>(
    (async (...args) => {
      const executionId = autoId()
      executionIdRef.current = executionId
      setLoading(true)
      setCalled(true)
      setError(undefined)
      setData(undefined)
      try {
        const data = await callback(...args)
        if (executionIdRef.current === executionId) {
          unstable_batchedUpdates(() => {
            setData(data)
            setLoading(false)
          })
        }
        return data
      } catch (error) {
        const definitelyError =
          error instanceof Error ? error : new Error('Something went wrong')
        if (executionIdRef.current === executionId) {
          unstable_batchedUpdates(() => {
            setError(definitelyError)
            setLoading(false)
          })
        }
        throw error
      }
    }) as T,
    deps,
  )

  return [execute, { data, loading, error, called }]
}
