import React from 'react'
import ReactDOM from 'react-dom'
import 'allotment/dist/style.css'
import { ErrorBoundary } from '@sentry/react'
import { BrowserRouter } from 'react-router-dom'
import { ClerkProvider } from '@clerk/clerk-react'
import { ColorScheme, ColorSchemeProvider } from '@mantine/core'
import { useColorScheme, useLocalStorage } from '@mantine/hooks'
import { NotificationsProvider } from '@mantine/notifications'
import { initSentry } from '@/sentry'
import { NoteSaveStateProvider } from '@/note-save-state'
import { ApolloProvider, UserProvider } from '@/api'
import { db } from '@/local-database'
import { ThemeProvider, GlobalStyles } from '@/styles'
import { OnlineStatusProvider } from '@/common'
import { monkeyPatchURLApi } from '@/utils/debug'
import config from '@/config'
import App from './App'
import ErrorView from './ErrorView'
import reportWebVitals from './reportWebVitals'

initSentry()
if (process.env.NODE_ENV === 'development') {
  monkeyPatchURLApi()
}

function Root() {
  const preferredColorScheme = useColorScheme()
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: preferredColorScheme,
  })
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))
  // @ts-ignore
  window.toggleColorScheme = toggleColorScheme
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <ThemeProvider
        withGlobalStyles
        withNormalizeCSS
        colorScheme={colorScheme}
      >
        <ErrorBoundary fallback={ErrorView} showDialog>
          <OnlineStatusProvider pingUrl="/ping.txt">
            <ClerkProvider frontendApi={config.clerkFrontendApi}>
              <ApolloProvider
                graphqlEndpoint={config.graphqlEndpoint}
                db={db}
                fetch={fetch}
              >
                <UserProvider>
                  <BrowserRouter>
                    <NoteSaveStateProvider>
                      <NotificationsProvider>
                        <GlobalStyles />
                        <App />
                      </NotificationsProvider>
                    </NoteSaveStateProvider>
                  </BrowserRouter>
                </UserProvider>
              </ApolloProvider>
            </ClerkProvider>
          </OnlineStatusProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </ColorSchemeProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
