import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import config from '@/config'

export function initSentry() {
  try {
    Sentry.init({
      dsn: config.sentryDsn,
      integrations: [
        new BrowserTracing({
          // TODO: can't use until https://github.com/getsentry/sentry-javascript/issues/5338 resolved
          // routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          //   React.useEffect,
          //   useLocation,
          //   useNavigationType,
          //   createRoutesFromChildren,
          //   matchRoutes
          // )
        }),
        new CaptureConsoleIntegration({
          levels: ['warn', 'error', 'debug', 'assert'],
        }),
      ],
      environment: config.stage,
      tracesSampleRate: config.stage === 'prod' ? 0.1 : 1.0,
    })
  } catch (e) {
    console.error(e)
  }
}
