import { createStyles, Text } from '@mantine/core'
import {
  CapterraIcon,
  TechCrunchIcon,
  TrustPilotDarkIcon,
  TrustPilotIcon,
  YCombinatorIcon,
} from './SocialProofIcons'

const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing.xl,
    '& > *': {
      width: '100px',
    },
    '& > div > img': {
      display: 'block',
      marginInline: 'auto',
    },
  },
  ycColor: {
    color: '#fa592a',
  },
  tcColor: {
    color: '#009321',
  },
}))

export type SocialProofsProps = {
  className?: string
}

export function SocialProofs(props: SocialProofsProps) {
  const { className } = props
  const { classes, theme, cx } = useStyles()
  return (
    <div className={cx(classes.root, className)}>
      <img
        width="100px"
        src={theme.colorScheme === 'dark' ? TrustPilotDarkIcon : TrustPilotIcon}
        alt="5 star Trustpilot rating"
      />
      <img width="100px" src={CapterraIcon} alt="5 start Capterra rating" />
      <div>
        <Text
          align="center"
          size="sm"
          mt={6}
          mb="xs"
          className={classes.ycColor}
        >
          YC S2021
        </Text>
        <img width="75px" src={YCombinatorIcon} alt="Funded by Y Combinator" />
      </div>
      <div>
        <Text
          align="center"
          size="sm"
          mt={6}
          mb="xs"
          className={classes.tcColor}
        >
          Featured on TechCrunch
        </Text>
        <img width="100px" src={TechCrunchIcon} alt="Featured on TechCrunch" />
      </div>
    </div>
  )
}
