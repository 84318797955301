import { useDeletePaymentMethodMutation } from '@/api'

export const useDeletePaymentMethod = () => {
  const [execute, result] = useDeletePaymentMethodMutation()
  const deletePaymentMethod = async (id: string) => {
    const { data } = await execute({
      variables: {
        input: { id },
      },
    })
    return data!.deletePaymentMethod
  }
  return [deletePaymentMethod, result] as const
}
