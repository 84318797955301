import { useEffect } from 'react'
import { useValueRef } from './useValueRef'

export type BeforeUnloadHandler = (event: BeforeUnloadEvent) => string | void

export const useBeforeUnload = (handler: BeforeUnloadHandler) => {
  const eventListenerRef = useValueRef((event: BeforeUnloadEvent) => {
    const returnValue = handler(event)
    // Handle legacy `event.returnValue` property
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    if (typeof returnValue === 'string') {
      return (event.returnValue = returnValue)
    }
    // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
    // instead it requires `event.returnValue` to be set
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
    if (event.defaultPrevented) {
      return (event.returnValue = '')
    }
  })

  useEffect(() => {
    const eventListener = (event: BeforeUnloadEvent) =>
      eventListenerRef.current(event)
    window.addEventListener('beforeunload', eventListener)
    return () => {
      window.removeEventListener('beforeunload', eventListener)
    }
  }, [eventListenerRef])
}
