import {
  Box,
  createStyles,
  DefaultProps,
  MantineNumberSize,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '@mantine/core'
import { forwardRef } from 'react'

type StyleParams = {
  spacing: MantineNumberSize
}

const useStyles = createStyles((theme, params: StyleParams) => ({
  root: {
    '& > * + *': {
      marginTop:
        typeof params.spacing === 'number'
          ? params.spacing
          : theme.spacing[params.spacing],
    },
  },
}))

type SharedSpacedProps = DefaultProps & {
  spacing?: MantineNumberSize
}

export type SpacedProps<C> = PolymorphicComponentProps<C, SharedSpacedProps>
type SpacedComponent = (<C = 'div'>(
  props: SpacedProps<C>,
) => React.ReactElement | null) & {
  displayName?: string
}

export const Spaced: SpacedComponent = forwardRef(function Spaced(
  props: SpacedProps<'div'>,
  ref: PolymorphicRef<'div'>,
) {
  const { component, className, spacing = 'md', ...delegated } = props
  const { classes, cx } = useStyles({ spacing })
  return (
    <Box
      component={component || 'div'}
      className={cx(classes.root, className)}
      {...delegated}
      ref={ref}
    />
  )
}) as any
