import {
  useOnProjectCreateSubscription,
  useOnProjectDeleteSubscription,
} from './hooks'
import { onCreateProject, onDeleteProject } from './typePolicies'

type ProjectSubscriptionsProps = {
  skip?: boolean
}

export function ProjectSubscriptions(props: ProjectSubscriptionsProps) {
  const { skip } = props
  useOnProjectCreateSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const project = subscriptionData.data?.onProjectCreate
      if (!project) return
      onCreateProject(client.cache, project)
    },
  })
  useOnProjectDeleteSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const project = subscriptionData.data?.onProjectDelete
      if (!project) return
      onDeleteProject(client.cache, project)
    },
  })
  return null
}
