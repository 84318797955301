import { useMemo, useState } from 'react'
import {
  Button,
  createStyles,
  MantineTheme,
  Text,
  useMantineTheme,
} from '@mantine/core'
import {
  DefaultValuesOption,
  StripeElementsOptions,
  Appearance,
  StripeError,
} from '@stripe/stripe-js'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { getDimmedColor } from '@/styles/helpers'
import { Elements } from './Elements'

export type RedirectUrl = 'currentUrl' | (string & {})

export type SetupFormProps = {
  buttonLabel: string
  redirectUrl: RedirectUrl
  defaultValues?: DefaultValuesOption['billingDetails']
  setupIntentClientSecret: string
}

export function SetupForm(props: SetupFormProps) {
  const { setupIntentClientSecret } = props
  const theme = useMantineTheme()
  const options = useMemo<StripeElementsOptions | undefined>(() => {
    const options: StripeElementsOptions = {
      clientSecret: setupIntentClientSecret,
      appearance: getAppearanceFromTheme(theme),
    }
    return options
  }, [setupIntentClientSecret, theme])
  return (
    <Elements options={options}>
      <InternalSetupForm {...props} />
    </Elements>
  )
}

const useStyles = createStyles((theme) => ({
  root: {
    '& > * + *': {
      marginBlockStart: theme.spacing.xl,
    },
  },
}))

export function InternalSetupForm(props: SetupFormProps) {
  const { buttonLabel, redirectUrl, defaultValues } = props
  const { classes } = useStyles()
  const [showSubmit, setShowSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<StripeError>()
  const stripe = useStripe()
  const elements = useElements()
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!stripe || !elements) return
    setLoading(true)
    setError(undefined)
    const returnUrl =
      redirectUrl === 'currentUrl'
        ? window.location.href
        : new URL(redirectUrl, window.location.href).href
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: { return_url: returnUrl },
    })
    setLoading(false)
    if (error) {
      setError(error)
    }
  }
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          defaultValues: { billingDetails: defaultValues },
        }}
        onReady={(element) => {
          setShowSubmit(true)
          element.focus()
        }}
      />
      {showSubmit && (
        <Button
          type="submit"
          fullWidth
          color="blue"
          loading={loading}
          disabled={!stripe || !elements}
        >
          {buttonLabel}
        </Button>
      )}
      {error && (
        <Text color="red" size="sm">
          {error.message}
        </Text>
      )}
    </form>
  )
}

function getAppearanceFromTheme(theme: MantineTheme): Appearance {
  return {
    theme: theme.colorScheme === 'dark' ? 'night' : 'stripe',
    variables: {
      fontFamily: theme.fontFamily,
      fontSizeXs: `${theme.fontSizes.xs}px`,
      fontSizeSm: `${theme.fontSizes.sm}px`,
      fontSizeBase: `${theme.fontSizes.sm}px`,
      fontSizeLg: `${theme.fontSizes.lg}px`,
      fontSizeXl: `${theme.fontSizes.xl}px`,
      borderRadius: `${theme.radius.md}px`,
      colorPrimary: theme.colors.blue[6],
      colorText:
        theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      colorTextSecondary: getDimmedColor(theme),
      // derived from Mantine's <Text /> color
      colorDanger:
        theme.colorScheme === 'dark'
          ? theme.colors.red[5]
          : theme.colors.red[7],
      colorWarning:
        theme.colorScheme === 'dark'
          ? theme.colors.yellow[5]
          : theme.colors.yellow[7],
      colorSuccess:
        theme.colorScheme === 'dark'
          ? theme.colors.green[5]
          : theme.colors.green[7],
    },
    rules: {
      '.Tab': { boxShadow: 'none' },
      '.Input': { boxShadow: 'none' },
      '.Input--invalid': { boxShadow: 'none' },
    },
  }
}
