import { CSSObject, MantineColor, MantineTheme } from '@mantine/core'

export function getDimmedColor(theme: MantineTheme) {
  return theme.colorScheme === 'dark'
    ? theme.colors.dark[2]
    : theme.colors.gray[6]
}

export function getActionIconHover(theme: MantineTheme): CSSObject {
  return {
    '&:hover': {
      background:
        theme.colorScheme === 'dark'
          ? 'rgba(255, 255, 255, 0.03)'
          : 'rgba(0, 0, 0, 0.03)',
    },
  }
}

export function getLineColor(theme: MantineTheme) {
  return theme.fn.themeColor(
    theme.colorScheme === 'dark' ? 'dark' : 'gray',
    theme.colorScheme === 'dark' ? 6 : 2,
    false,
  )
}

export function focusRing(
  theme: MantineTheme,
  offset: number = 2,
  size: number = 2,
  color?: MantineColor,
): CSSObject {
  return {
    outlineOffset: offset,
    outline:
      theme.focusRing === 'always' || theme.focusRing === 'auto'
        ? `${size}px solid ${theme.fn.themeColor(
            color ?? theme.primaryColor,
            theme.colorScheme === 'dark' ? 7 : 5,
            false,
          )}`
        : 'none',
  }
}

export function focusStyles(
  theme: MantineTheme,
  offset: number = 2,
  size: number = 2,
  color?: MantineColor,
): CSSObject {
  return {
    WebkitTapHighlightColor: 'transparent',
    '&:focus': {
      ...focusRing(theme, offset, size, color),
    },
    '&:focus:not(:focus-visible)': {
      outline:
        theme.focusRing === 'auto' || theme.focusRing === 'never'
          ? 'none'
          : undefined,
    },
  }
}
