import { Text, Stack, Modal, Center, Button } from '@mantine/core'
import { IconMoodSad as CrashIcon } from '@tabler/icons-react'
import { SignedIn, SignedOut } from '@clerk/clerk-react'
import { getCurrentFullPath } from '@/utils/web'
import { useMaybeUser } from '@/api'
import { Navigate } from './Navigate'
import { AuthLevel } from './types'

function RedirectToLogin() {
  const currentPath = getCurrentFullPath()
  const searchParams = currentPath.startsWith('/logout')
    ? ''
    : `?redirect-url=${encodeURIComponent(currentPath)}`
  return <Navigate to={`/login${searchParams}`} replace />
}

export type AuthWrapperProps = {
  auth?: AuthLevel
  children: React.ReactNode
}

export function AuthWrapper(props: AuthWrapperProps) {
  const { auth, children } = props
  switch (auth) {
    case 'unauthenticated':
      return (
        <>
          <SignedIn>
            <Navigate to="/" replace propagateSearchParams />
          </SignedIn>
          <SignedOut>{children}</SignedOut>
        </>
      )
    case 'authenticated':
      return (
        <>
          <SignedIn>
            <AwaitUser>{children}</AwaitUser>
          </SignedIn>
          <SignedOut>
            <RedirectToLogin />
          </SignedOut>
        </>
      )
    default:
      return <>{children}</>
  }
}

function AwaitUser(props: React.PropsWithChildren<{}>) {
  const { user, loading, error, refetch } = useMaybeUser()
  if (loading) return null
  // TODO: figure out how to propagate clerk loading error to here
  if (error || !user) {
    return (
      <UserFetchError
        loading={loading}
        error={error ?? new Error('Something went wrong')}
        refetch={refetch}
      />
    )
  }
  return <>{props.children}</>
}

type UserFetchErrorProps = {
  error: Error
  loading: boolean
  refetch: () => void
}

function UserFetchError(props: UserFetchErrorProps) {
  const { error, loading, refetch } = props
  return (
    <Modal opened centered withCloseButton={false} onClose={() => {}}>
      <Center mb="xs">
        <CrashIcon size={28} />
      </Center>
      <Stack spacing="lg">
        <Text component="h1" size="xl" align="center" my={0}>
          Oops!
        </Text>
        <Text size="lg" align="center">
          Looks like we weren&apos;t able to retrieve your user information
        </Text>
        <Text size="sm" color="red" align="center">
          Error: {error.message}
        </Text>
        <div style={{ width: 'fit-content', marginInline: 'auto' }}>
          <Button loading={loading} color="gray" onClick={refetch}>
            Try again
          </Button>
        </div>
        <Text size="sm" align="center">
          If the problem persists, please message support through intercom or
          email{' '}
          <Text
            inherit
            component="a"
            variant="link"
            href="mailto:support@genei.io"
          >
            support@genei.io
          </Text>
          .
        </Text>
      </Stack>
    </Modal>
  )
}
