import {
  IconLetterA,
  IconLetterB,
  IconLetterC,
  IconLetterD,
  IconLetterE,
  IconLetterF,
  IconLetterG,
  IconLetterH,
  IconLetterI,
  IconLetterJ,
  IconLetterK,
  IconLetterL,
  IconLetterM,
  IconLetterN,
  IconLetterO,
  IconLetterP,
  IconLetterQ,
  IconLetterR,
  IconLetterS,
  IconLetterT,
  IconLetterU,
  IconLetterV,
  IconLetterW,
  IconLetterX,
  IconLetterY,
  IconLetterZ,
  IconQuestionMark,
  TablerIconsProps,
} from '@tabler/icons-react'

function getIconComponent(char: string) {
  switch (char.toUpperCase()) {
    case 'A':
      return IconLetterA
    case 'B':
      return IconLetterB
    case 'C':
      return IconLetterC
    case 'D':
      return IconLetterD
    case 'E':
      return IconLetterE
    case 'F':
      return IconLetterF
    case 'G':
      return IconLetterG
    case 'H':
      return IconLetterH
    case 'I':
      return IconLetterI
    case 'J':
      return IconLetterJ
    case 'K':
      return IconLetterK
    case 'L':
      return IconLetterL
    case 'M':
      return IconLetterM
    case 'N':
      return IconLetterN
    case 'O':
      return IconLetterO
    case 'P':
      return IconLetterP
    case 'Q':
      return IconLetterQ
    case 'R':
      return IconLetterR
    case 'S':
      return IconLetterS
    case 'T':
      return IconLetterT
    case 'U':
      return IconLetterU
    case 'V':
      return IconLetterV
    case 'W':
      return IconLetterW
    case 'X':
      return IconLetterX
    case 'Y':
      return IconLetterY
    case 'Z':
      return IconLetterZ
    default:
      return IconQuestionMark
  }
}

export type LetterIconProps = TablerIconsProps & {
  letter: string
}

export function LetterIcon(props: LetterIconProps) {
  const { letter, ...delegated } = props
  const Icon = getIconComponent(letter)
  return <Icon {...delegated} />
}
