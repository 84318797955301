import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
  SubscriptionPlanPeriod,
  useRegisterGlobalErrorListener,
  useUser,
} from '@/api'
import { useDisclosure } from '@mantine/hooks'
import {
  Modal,
  Text,
  Button,
  Divider,
  createStyles,
  ActionIcon,
  Stack,
} from '@mantine/core'
import { IconX as CloseIcon } from '@tabler/icons-react'
import { ErrorResponse } from '@apollo/client/link/error'
import {
  ChoosePeriod,
  LicenseKey,
  PlanCard,
  PlanCardSkeleton,
  SocialProofs,
  ToggleAcademic,
  useSubscriptionPlans,
} from '@/shared-billing'
import { Logo } from '@/common'
import { typographicScale } from '@/styles/typography'
import { getCurrentFullPath } from '@/utils/web'

export const FreeUpgradeModal = () => {
  const [opened, handlers] = useDisclosure(false)
  const registerErrorListener = useRegisterGlobalErrorListener()
  useEffect(() => {
    return registerErrorListener((response: ErrorResponse) => {
      const { graphQLErrors } = response
      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          if (error.extensions.code === 'FREE_TIER_LIMIT_REACHED') {
            handlers.open()
          }
        }
      }
    })
  }, [handlers, registerErrorListener])
  return (
    <Modal
      size={900}
      padding="xl"
      opened={opened}
      onClose={handlers.close}
      withCloseButton={false}
    >
      <ModalContent onClose={handlers.close} />
    </Modal>
  )
}

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flow-root',
    '& > *': {
      marginInline: 'auto',
    },
    '& > * + *': {
      marginBlockStart: theme.spacing.xl,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    margin: 0,
    textAlign: 'center',
    fontSize: typographicScale.getNthNext(theme.fontSizes.xl, 2),
    lineHeight: 1.2,
  },
  description: {
    margin: 0,
    textAlign: 'center',
    fontWeight: 400,
  },
  divider: {
    marginBlockStart: 2 * theme.spacing.lg,
    marginBlockEnd: 3 * theme.spacing.lg,
    marginInline: 'auto',
    maxWidth: 600,
  },
  plans: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing.xl,
    flexWrap: 'wrap',
  },
  proofs: {
    marginBlock: 3 * theme.spacing.xl,
  },
  retryButton: {
    marginInline: 'auto',
    display: 'block',
  },
}))

type ModalContentProps = {
  onClose: () => void
}

function ModalContent(props: ModalContentProps) {
  const { onClose } = props
  const navigate = useNavigate()
  const handleSelect = (planId: string) => {
    const currentPath = encodeURIComponent(getCurrentFullPath())
    navigate(
      `/subscription/subscribe/${planId}?cancel-path=${currentPath}&success-path=${currentPath}`,
    )
    onClose()
  }
  const { classes } = useStyles()
  const user = useUser()
  const [searchParams] = useSearchParams()
  const [requiresAcademic, setRequiresAcademic] = useState(
    searchParams.get('plans-academic') === 'true' ||
      !!user.academic?.isVerified,
  )
  const [period, setPeriod] = useState(SubscriptionPlanPeriod.Year)
  const { plans, loading, error, refetch } = useSubscriptionPlans(
    user.currency,
    {
      requiresAcademic,
      period,
    },
  )
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Logo radius="sm" color="green" />
        <ActionIcon onClick={onClose}>
          <CloseIcon size={18} />
        </ActionIcon>
      </div>
      <Stack spacing="md">
        <Text component="h1" className={classes.title}>
          You&apos;ve run out of
          <br />
          free summaries.
        </Text>
        <Text component="h2" color="dimmed" className={classes.description}>
          Choose a plan that fits your needs.
        </Text>
      </Stack>
      <Divider className={classes.divider} />
      <ToggleAcademic
        showAcademic={requiresAcademic}
        onChange={setRequiresAcademic}
      />
      <ChoosePeriod period={period} onChange={setPeriod} />
      {!loading ? (
        <>
          {!error ? (
            <>
              <div className={classes.plans}>
                {plans.map((plan) => (
                  <PlanCard
                    key={plan.id}
                    plan={plan}
                    user={user}
                    // Purposefully not passing subscription here
                    // This component should only be rendered when the user doesn't have a subscription
                    onClick={(_, action) => {
                      if (action === 'subscribe') {
                        handleSelect(plan.id)
                      }
                    }}
                  />
                ))}
              </div>
            </>
          ) : (
            <>
              <Text color="red" size="sm" align="center">
                Failed to fetch plans:
                <br />
                {error.message}
              </Text>
              <Button
                className={classes.retryButton}
                loading={loading}
                variant="light"
                color="gray"
                onClick={() => refetch()}
              >
                Try again
              </Button>
            </>
          )}
        </>
      ) : (
        <div className={classes.plans}>
          <PlanCardSkeleton />
          <PlanCardSkeleton />
        </div>
      )}
      <SocialProofs className={classes.proofs} />
      <LicenseKey />
    </div>
  )
}
