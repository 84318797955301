import { ApolloCache, MutationHookOptions, MutationTuple } from '@apollo/client'
import * as generated from './generated'
import {
  onMoveResource,
  onMoveNote,
  onCreateProject,
  onDeleteProject,
  onCreateResource,
  onDeleteResource,
  onCreateNote,
  onDeleteNote,
  onCreateFolder,
  onDeleteFolder,
  onCreateApiKey,
  onDeleteApiKey,
  onCreateResourceHighlight,
  onDeleteResourceHighlight,
  onRestoreSectionSummary,
  onCreateSubscription,
  onConsumeLicenseKey,
  onSetResourceProject,
  onCreatePrompt,
  onDeletePrompt,
  onCreateAiChat,
  onDeleteAiChat,
  onCreateAiChatMessage,
  onDeleteAiChatMessage,
  onDeletePaymentMethod,
  onSetDefaultPaymentMethod,
} from './typePolicies'

export * from './generated'
export { useResourceNlpQuery } from './nlp'
export { useResourceDocumentQuery } from './document'

function makeMutationHookWithUpdate<
  TData extends any,
  TVariables extends any,
  TUpdateData extends any,
>(
  useMutation: (
    baseOptions?: MutationHookOptions<TData, TVariables>,
  ) => MutationTuple<TData, TVariables>,
  update: (cache: ApolloCache<any>, data: TUpdateData) => void,
  getUpdateData: (data: TData) => TUpdateData,
): typeof useMutation {
  return (baseOptions) =>
    useMutation({
      ...baseOptions,
      update(...params) {
        const [cache, { data }] = params
        if (data) update(cache, getUpdateData(data))
        if (baseOptions?.update) baseOptions.update(...params)
      },
    })
}

export const useCreateSubscriptionMutation = makeMutationHookWithUpdate(
  generated.useCreateSubscriptionMutation,
  onCreateSubscription,
  (data) => data.createSubscription,
)

export const useConsumeLicenseKeyMutation = makeMutationHookWithUpdate(
  generated.useConsumeLicenseKeyMutation,
  onConsumeLicenseKey,
  (data) => data.consumeLicenseKey,
)

export const useCreateProjectMutation = makeMutationHookWithUpdate(
  generated.useCreateProjectMutation,
  onCreateProject,
  (data) => data.createProject,
)

export const useDeleteProjectMutation = makeMutationHookWithUpdate(
  generated.useDeleteProjectMutation,
  onDeleteProject,
  (data) => data.deleteProject,
)

export const useCreateResourceFromFileMutation = makeMutationHookWithUpdate(
  generated.useCreateResourceFromFileMutation,
  onCreateResource,
  (data) => data.createResourceFromFile,
)

export const useCreateResourceFromUrlMutation = makeMutationHookWithUpdate(
  generated.useCreateResourceFromUrlMutation,
  onCreateResource,
  (data) => data.createResourceFromUrl,
)

export const useSetResourceProjectMutation = makeMutationHookWithUpdate(
  generated.useSetResourceProjectMutation,
  onSetResourceProject,
  (data) => data.setResourceProject,
)

export const useDeleteResourceMutation = makeMutationHookWithUpdate(
  generated.useDeleteResourceMutation,
  onDeleteResource,
  (data) => data.deleteResource,
)

export const useCreateNoteMutation = makeMutationHookWithUpdate(
  generated.useCreateNoteMutation,
  onCreateNote,
  (data) => data.createNote,
)

export const useDeleteNoteMutation = makeMutationHookWithUpdate(
  generated.useDeleteNoteMutation,
  onDeleteNote,
  (data) => data.deleteNote,
)

export const useCreateFolderMutation = makeMutationHookWithUpdate(
  generated.useCreateFolderMutation,
  onCreateFolder,
  (data) => data.createFolder,
)

export const useDeleteFolderMutation = makeMutationHookWithUpdate(
  generated.useDeleteFolderMutation,
  onDeleteFolder,
  (data) => data.deleteFolder,
)

export const useCreateAiChatMutation = makeMutationHookWithUpdate(
  generated.useCreateAiChatMutation,
  onCreateAiChat,
  (data) => data.createAiChat,
)

export const useDeleteAiChatMutation = makeMutationHookWithUpdate(
  generated.useDeleteAiChatMutation,
  onDeleteAiChat,
  (data) => data.deleteAiChat,
)

export const useSendAiChatMessageMutation = makeMutationHookWithUpdate(
  generated.useSendAiChatMessageMutation,
  onCreateAiChatMessage,
  (data) => data.sendAiChatMessage,
)

export const useDeleteAiChatMessageMutation = makeMutationHookWithUpdate(
  generated.useDeleteAiChatMessageMutation,
  onDeleteAiChatMessage,
  (data) => data.deleteAiChatMessage,
)

export const useCreateApiKeyMutation = makeMutationHookWithUpdate(
  generated.useCreateApiKeyMutation,
  onCreateApiKey,
  (data) => data.createApiKey,
)

export const useDeleteApiKeyMutation = makeMutationHookWithUpdate(
  generated.useDeleteApiKeyMutation,
  onDeleteApiKey,
  (data) => data.deleteApiKey,
)

export const useCreateResourceHighlightMutation = makeMutationHookWithUpdate(
  generated.useCreateResourceHighlightMutation,
  onCreateResourceHighlight,
  (data) => data.createResourceHighlight,
)

export const useDeleteResourceHighlightMutation = makeMutationHookWithUpdate(
  generated.useDeleteResourceHighlightMutation,
  onDeleteResourceHighlight,
  (data) => data.deleteResourceHighlight,
)

export const useRestoreSectionSummaryMutation = makeMutationHookWithUpdate(
  generated.useRestoreSectionSummaryMutation,
  onRestoreSectionSummary,
  (data) => data.restoreSectionSummary,
)

export const useMoveResourceMutation = makeMutationHookWithUpdate(
  generated.useMoveResourceMutation,
  onMoveResource,
  (data) => data.moveResource,
)

export const useMoveNoteMutation = makeMutationHookWithUpdate(
  generated.useMoveNoteMutation,
  onMoveNote,
  (data) => data.moveNote,
)

export const useDeletePaymentMethodMutation = makeMutationHookWithUpdate(
  generated.useDeletePaymentMethodMutation,
  onDeletePaymentMethod,
  (data) => data.deletePaymentMethod,
)

export const useSetDefaultPaymentMethodMutation = makeMutationHookWithUpdate(
  generated.useSetDefaultPaymentMethodMutation,
  onSetDefaultPaymentMethod,
  (data) => data.setDefaultPaymentMethod,
)

export const useCreatePromptMutation = makeMutationHookWithUpdate(
  generated.useCreatePromptMutation,
  onCreatePrompt,
  (data) => data.createPrompt,
)

export const useDeletePromptMutation = makeMutationHookWithUpdate(
  generated.useDeletePromptMutation,
  onDeletePrompt,
  (data) => data.deletePrompt,
)
