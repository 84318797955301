import { useState } from 'react'
import { Button, createStyles, Modal, Text } from '@mantine/core'
import {
  PromotionCodeCompleteFragment,
  UserSubscriptionWithPromotionFragment,
} from '@/api'
import { Callout } from '@/common'
import { useSetSubscriptionPromotion } from './useSetSubscriptionPromotion'
import { PromotionCodeChip, PromotionCodeInput } from './PromotionCode'
import { getPromotionCodeDisplay } from './helpers'

export type SetSubscriptionPromotionModalProps = {
  opened: boolean
  subscription: UserSubscriptionWithPromotionFragment
  onClose: () => void
}

export function SetSubscriptionPromotionModal(
  props: SetSubscriptionPromotionModalProps,
) {
  const { opened, subscription, onClose } = props
  const hasPromotion = !!subscription.additionalInfo.promotion
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={hasPromotion ? 'Change promotion' : 'Add promotion'}
    >
      <ModalContent subscription={subscription} onClose={onClose} />
    </Modal>
  )
}

const useStyles = createStyles((theme) => ({
  root: {
    marginTop: 2 * theme.spacing.md,
    '& > * + *': {
      marginTop: theme.spacing.xl,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing.lg,
  },
}))

type ModalContentProps = {
  subscription: UserSubscriptionWithPromotionFragment
  onClose: () => void
}

function ModalContent(props: ModalContentProps) {
  const { subscription, onClose } = props
  const { classes, theme } = useStyles()
  const [promotionCode, setPromotionCode] =
    useState<PromotionCodeCompleteFragment | null>(null)
  const [setSubscriptionPromotion, { loading, error }] =
    useSetSubscriptionPromotion()
  const handleSetPromotion = async () => {
    if (!promotionCode) return
    await setSubscriptionPromotion(promotionCode.code)
      .then(() => onClose())
      .catch(() => {})
  }
  const currentPromotion = subscription.additionalInfo.promotion
  return (
    <div className={classes.root}>
      {promotionCode ? (
        <div>
          <Text size="sm" mb="xs">
            {currentPromotion ? 'New promotion: ' : 'Promotion: '}
            <span style={{ fontWeight: 600 }}>
              {`${getPromotionCodeDisplay(promotionCode)}`}
            </span>
          </Text>
          <PromotionCodeChip
            code={promotionCode.code}
            onDelete={() => setPromotionCode(null)}
          />
        </div>
      ) : (
        <PromotionCodeInput
          buttonLabel="Verify"
          planId={subscription.plan.id}
          onPromotionCode={setPromotionCode}
        />
      )}
      {currentPromotion && (
        <Callout size="sm" color="yellow">
          {'Changing promotion will replace the currently applied '}
          <span style={{ fontWeight: 600 }}>
            {`“${getPromotionCodeDisplay(currentPromotion)}”`}
          </span>
          {' promotion.'}
        </Callout>
      )}
      <div className={classes.actions}>
        <Button
          color="blue"
          onClick={handleSetPromotion}
          disabled={!promotionCode}
          loading={loading}
        >
          Apply promotion
        </Button>
        <Button
          onClick={onClose}
          variant="subtle"
          color={theme.colorScheme === 'dark' ? 'dark' : 'gray'}
        >
          Cancel
        </Button>
      </div>
      {error && (
        <Text color="red" size="sm">
          {error.message}
        </Text>
      )}
    </div>
  )
}
