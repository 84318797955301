import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
  from,
} from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { SessionResource } from '@clerk/types'
import { DatabaseClient } from '@/local-database/client'
import { Fetch, MaybePromise } from '@/utils/types'
import generatedIntrospection from './generated-fragment-matcher'
import { createTypePolicies } from './typePolicies'
import { WebSocketLink } from './WebSocketLink'

export type SessionRef = {
  current: SessionResource | null | undefined
}

export type GetAuthHeaders = () => MaybePromise<null | {
  Authorization: string
  'X-Clerk-Session': string
}>

export class ClientFactory {
  public readonly uri: string
  private readonly db: DatabaseClient
  private readonly fetch: Fetch
  private readonly onError: (response: ErrorResponse) => void

  constructor(
    uri: string,
    db: DatabaseClient,
    fetch: Fetch,
    onError: (errors: ErrorResponse) => void,
  ) {
    this.uri = uri
    this.db = db
    this.fetch = fetch
    this.onError = onError
  }

  /**
   * Creates an Apollo client with dynamic headers derived at request time from the
   * current Clerk session
   * @param sessionRef a ref object containing the Clerk session
   * @returns `ApolloClient` instance with `InMemoryCache`
   */
  public createFromSessionRef(sessionRef: SessionRef) {
    const getAuthHeaders: GetAuthHeaders = async () => {
      try {
        const session = sessionRef.current
        if (!session) return null
        const token = await session.getToken()
        if (!token) return null
        return {
          Authorization: token,
          'X-Clerk-Session': session.id,
        }
      } catch (error) {
        if (
          error instanceof Error &&
          error.message.startsWith('ClerkJS: Network error')
        ) {
          // Gets rid of the long and ugly ClerkJS error
          throw new Error(
            "Network error: Are you sure you're connected to the internet?",
          )
        }
        throw error
      }
    }
    return this.create(getAuthHeaders)
  }

  /**
   * Creates an Apollo client with static headers derived from the session id and token
   * @param sessionId Clerk session id - if `null` will create an _unauthenticated_ `ApolloClient`
   * @param token Clerk session token - if `null` will create an _unauthenticated_ `ApolloClient`
   * @returns `ApolloClient` instance with `InMemoryCache`
   */
  public createFromSessionIdAndToken(
    sessionId: string | null,
    token: string | null,
  ) {
    const getAuthHeaders: GetAuthHeaders = () => {
      if (!sessionId || !token) return null
      return {
        Authorization: token,
        'X-Clerk-Session': sessionId,
      }
    }
    return this.create(getAuthHeaders)
  }
  /**
   * Creates an Apollo client using the supplied `getAuthHeaders` function
   * @param getAuthHeaders function that returns `'Authorization'` and `'X-Clerk-Session'` headers or `null`
   * @returns `ApolloClient` instance with `InMemoryCache`
   */
  private create(getAuthHeaders: GetAuthHeaders) {
    const httpLink = new HttpLink({ uri: this.uri })
    const authMiddleware = this.createAuthMiddleware(getAuthHeaders)
    const authHttpLink = authMiddleware.concat(httpLink)
    const wsLink = this.createWebSocketLink(getAuthHeaders)
    const dualLink = this.createDualLink(authHttpLink, wsLink)

    const inMemoryCache = new InMemoryCache({
      addTypename: true,
      typePolicies: createTypePolicies(this.db, this.fetch),
      possibleTypes: generatedIntrospection.possibleTypes,
    })

    const errorLink = onError((response) => this.onError(response))

    const client = new ApolloClient({
      connectToDevTools: process.env.NODE_ENV === 'development',
      assumeImmutableResults: true,
      queryDeduplication: true,
      link: from([errorLink, dualLink]),
      cache: inMemoryCache,
    })

    return client
  }

  private createAuthMiddleware(getAuthHeaders: GetAuthHeaders) {
    return setContext(async (req, ctx) => {
      const authHeaders = await getAuthHeaders()
      if (!authHeaders) return ctx
      return {
        ...ctx,
        headers: {
          ...ctx?.headers,
          ...authHeaders,
        },
      }
    })
  }

  private createWebSocketLink(getAuthHeaders: GetAuthHeaders) {
    return new WebSocketLink({
      url: this.uri.replace(/^http/, 'ws'),
      shouldRetry: () => true,
      connectionParams: async () => {
        const authHeaders = await getAuthHeaders()
        return {
          headers: authHeaders,
        }
      },
    })
  }

  private createDualLink(httpLink: ApolloLink, wsLink: WebSocketLink) {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )
  }
}
