import { useCallback, useEffect, useRef } from 'react'
import throttle from 'lodash.throttle'
import { useValueRef } from './useValueRef'

type KeepAliveTimerOptions = {
  onExpired: (aliveForMs: number) => void
  keepAliveIntervalMs: number
  keepAliveThrottleMs?: number | null
  minAliveForMs?: number | null
}

class KeepAliveTimer {
  private timer?: NodeJS.Timeout
  private keepAliveIntervalMs: number
  private minAliveForMs: number | null
  private initialisedAt: Date | null = null
  private lastKeepAliveAt: Date | null = null
  private onExpired: (aliveFor: number) => void
  public keepAlive: () => void

  constructor(options: KeepAliveTimerOptions) {
    this.keepAliveIntervalMs = options.keepAliveIntervalMs
    this.minAliveForMs = options.minAliveForMs ?? null
    this.keepAlive = this.createKeepAlive(options.keepAliveThrottleMs ?? null)
    this.onExpired = options.onExpired
  }

  public updateOptions(options: Partial<KeepAliveTimerOptions>) {
    const {
      keepAliveIntervalMs,
      keepAliveThrottleMs,
      minAliveForMs,
      onExpired,
    } = options
    if (keepAliveIntervalMs !== undefined) {
      this.keepAliveIntervalMs = keepAliveIntervalMs
    }
    if (minAliveForMs !== undefined) {
      this.minAliveForMs = minAliveForMs
    }
    if (keepAliveThrottleMs !== undefined) {
      this.keepAlive = this.createKeepAlive(keepAliveThrottleMs)
    }
    if (onExpired !== undefined) {
      this.onExpired = onExpired
    }
  }

  private createKeepAlive(throttleMs: number | null) {
    if (throttleMs === null) {
      return this.keepAliveUnthrottled
    }
    return throttle(() => this.keepAliveUnthrottled(), throttleMs, {
      leading: true,
      trailing: true,
    })
  }

  private keepAliveUnthrottled() {
    clearTimeout(this.timer)
    const now = new Date()
    if (this.initialisedAt === null) {
      this.initialisedAt = now
    }
    this.lastKeepAliveAt = now
    this.timer = setTimeout(
      () => this.handleExpiration(),
      this.keepAliveIntervalMs,
    )
  }

  private handleExpiration() {
    const aliveForMs = this.getAliveForMs()
    if (this.minAliveForMs === null || aliveForMs >= this.minAliveForMs) {
      this.onExpired(aliveForMs)
    }
    this.initialisedAt = null
    this.lastKeepAliveAt = null
  }

  private getAliveForMs() {
    if (this.initialisedAt === null || this.lastKeepAliveAt === null) {
      return 0
    }
    return this.lastKeepAliveAt.getTime() - this.initialisedAt.getTime()
  }
}

export const useKeepAliveTimer = (options: KeepAliveTimerOptions) => {
  const { onExpired, keepAliveIntervalMs, keepAliveThrottleMs, minAliveForMs } =
    options
  const onExpiredRef = useValueRef(onExpired)
  const keepAliveTimerRef = useRef<KeepAliveTimer | null>(null)
  const keepAlive = useCallback(() => {
    if (!keepAliveTimerRef.current) {
      keepAliveTimerRef.current = new KeepAliveTimer({
        onExpired(aliveFor) {
          onExpiredRef.current(aliveFor)
        },
        keepAliveIntervalMs,
        keepAliveThrottleMs,
        minAliveForMs,
      })
    }
    keepAliveTimerRef.current.keepAlive()
  }, [onExpiredRef, keepAliveIntervalMs, keepAliveThrottleMs, minAliveForMs])
  useEffect(() => {
    const keepAliveTimer = keepAliveTimerRef.current
    if (!keepAliveTimer) return
    keepAliveTimer.updateOptions({
      keepAliveIntervalMs,
      keepAliveThrottleMs,
      minAliveForMs,
    })
  }, [keepAliveIntervalMs, keepAliveThrottleMs, minAliveForMs])
  return keepAlive
}
