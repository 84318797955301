import {
  useOnResourceCreateSubscription,
  useOnResourceRenameSubscription,
  useOnResourceDeleteSubscription,
  useOnResourceDocumentSubscription,
  useOnResourceMetadataSubscription,
  useOnResourceFiguresSubscription,
  useOnResourceSectionsSubscription,
  useOnResourceSetProjectSubscription,
  useOnResourceNlpSubscription,
} from './hooks'
import {
  onCreateResource,
  onDeleteResource,
  onSetResourceProject,
} from './typePolicies'

type ResourceSubscriptionsProps = {
  skip?: boolean
}

export function ResourceSubscriptions(props: ResourceSubscriptionsProps) {
  const { skip } = props
  useOnResourceCreateSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const resource = subscriptionData.data?.onResourceCreate
      if (!resource) return
      onCreateResource(client.cache, resource)
    },
  })
  useOnResourceSetProjectSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const resource = subscriptionData.data?.onResourceSetProject
      if (!resource) return
      onSetResourceProject(client.cache, resource)
    },
  })
  useOnResourceDeleteSubscription({
    skip,
    onSubscriptionData({ client, subscriptionData }) {
      const resource = subscriptionData.data?.onResourceDelete
      if (!resource) return
      onDeleteResource(client.cache, resource)
    },
  })
  useOnResourceRenameSubscription({ skip })
  useOnResourceDocumentSubscription({ skip })
  useOnResourceMetadataSubscription({ skip })
  useOnResourceFiguresSubscription({ skip })
  useOnResourceSectionsSubscription({ skip })
  useOnResourceNlpSubscription({ skip })
  return null
}
