import { TYPOGRAPHIC_SCALE_FACTOR } from './constants'

export class TypographicScale {
  public readonly factor: number

  constructor(factor: number) {
    if (factor <= 1) {
      throw new Error('Scale factor must be greater than 1')
    }
    this.factor = factor
  }

  public getScale(start: number, count: number) {
    this.nonPositiveIntThrows(count)
    const scale: number[] = [start]
    for (let i = 0; i < count - 1; i++) {
      const lastValue = scale[scale.length - 1]
      scale.push(this.getNext(lastValue))
    }
    return scale
  }

  public getNext(value: number) {
    this.nonPositiveIntThrows(value)
    return Math.round(value * this.factor)
  }

  public getNthNext(value: number, n: number) {
    this.nonPositiveIntThrows(n)
    let result = value
    for (let i = 0; i < n; i++) {
      result = this.getNext(result)
    }
    return result
  }

  public getPrev(value: number) {
    this.nonPositiveIntThrows(value)
    return Math.round(value / this.factor)
  }

  public getNthPrev(value: number, n: number) {
    this.nonPositiveIntThrows(n)
    let result = value
    for (let i = 0; i < n; i++) {
      result = this.getPrev(result)
    }
    return result
  }

  private nonPositiveIntThrows(value: number) {
    if (!Number.isInteger(value) || value < 1) {
      throw new Error('Value must be an integer greater than 0')
    }
  }
}

export const typographicScale = new TypographicScale(TYPOGRAPHIC_SCALE_FACTOR)
