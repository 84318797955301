import { Reference } from '@apollo/client'
import { isPlainObject } from './common'
import { WithoutTypename } from './types'

export const areRefsEqual = (ref1: Reference, ref2: Reference) =>
  ref1.__ref !== undefined &&
  ref2.__ref !== undefined &&
  ref1.__ref === ref2.__ref

export const hasRef = (refs: Reference[], ref: Reference) =>
  refs.some((r) => areRefsEqual(r, ref))

export const filterRef = (refs: Reference[], ref: Reference) =>
  refs.filter((r) => !areRefsEqual(r, ref))

export function deepRemoveTypename<T>(obj: T): WithoutTypename<T> {
  if (Array.isArray(obj)) {
    return obj.map(deepRemoveTypename) as WithoutTypename<T>
  }
  if (isPlainObject(obj)) {
    const { __typename: _, ...objNoTypename } = obj as { __typename?: string }
    const newObj: Record<string, any> = {}
    for (const [key, value] of Object.entries(objNoTypename)) {
      newObj[key] = deepRemoveTypename(value)
    }
    return newObj as WithoutTypename<T>
  }
  return obj as WithoutTypename<T>
}

export class RefSet {
  private set: Set<string>
  constructor(iterable?: Iterable<Reference> | null) {
    this.set = new Set(
      iterable ? Array.from(iterable).map((item) => item.__ref) : iterable,
    )
  }

  add(reference: Reference): this {
    this.set.add(reference.__ref)
    return this
  }

  delete(reference: Reference): boolean {
    return this.set.delete(reference.__ref)
  }

  has(reference: Reference): boolean {
    return this.set.has(reference.__ref)
  }
}
